import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
import { getAllFundManagmentAdmin, fundSummary} from '../utils/reducer';
import DataTable from 'react-data-table-component';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils.js';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const AdminFundSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [filterTerm, setFilterTerm] = useState('');
    let dummydata = [
        {
            loanid : 300255,
            lendercaseid : '4FRH35',
            propertyaddress : 'California Grove Street -3',
            deal : '31%',
            fundedloans :'65.00%',
            unpaidbalance : '49.00%',
            maturitydate : '2023-09-15T00:00:00Z'
        }
    ]
    const [loanDetails,setLoanDetails] = useState([...dummydata]);
    const [summary,setSumary] = useState({});

    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const columns = [
        {
          name: 'LOAN ID',
          selector: (row) => row?.loanid || '' ,
          minWidth: '10%',
          maxWidth: '10%',
        },
        {
            name: 'LENDER CASE ID',
            selector: (row) => row?.lead?.lendercaseid || '' ,
            minWidth: '14%',
            maxWidth: '14%',
          },
          {
            name: 'PROPERTY ADDRESS',
            selector: (row) => row?.propertyinfo?.[0]?.propertyfulladdress || '' ,
            minWidth: '18%',
            maxWidth: '18%',
          },
          {
            name: 'DEAL %',
            selector: (row) => row?.deal || '' ,
            minWidth: '10%',
            maxWidth: '10%',
          },
          {
            name: 'FUNDED LOANS',
            selector: (row) => row?.fundedloans || '' ,
            minWidth: '14%',
            maxWidth: '14%',
          }, {
            name: 'UNPAID BALANCE',
            selector: (row) => row?.unpaidbalance || '' ,
            minWidth: '14%',
            maxWidth: '14%',
          },
        {
            name: 'MATURITY DATE',
            selector: (row) =>  row?.cleartoclose?.currentmaturitydate
            ? moment.utc(row?.cleartoclose?.currentmaturitydate).format("MM-DD-YYYY") : '',
            minWidth: '14%',
            maxWidth: '14%',
        },
       
        
      ];
    
    

  
      useEffect(() => {
        // let filter = filterTerm;
        // fetchLoanDetails(currentPage,perPage,filter);
        getFundsName()
    }, []);

    const getFundsName =()=>{
        dispatch(showLoader());
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data) {
                setFundsDropdown(response?.result?.data);
                const defaultFund = response.result.data.find(fund => fund.default === true);
                const defaultUuid = defaultFund ? defaultFund.uuid : null;
                if (defaultUuid) {
                    setFilterTerm(defaultUuid);
                    fetchLoanDetails(currentPage, perPage, defaultUuid);
                } else {
                    setFilterTerm(''); 
                    fetchLoanDetails(currentPage,perPage,'');
                }
            }else{
                setFundsDropdown([]);
                dispatch(hideLoader());
            };
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    };
    const fetchLoanDetails = (pageno,perpage,filter) => {
        dispatch(showLoader());
        fundSummary(pageno,perpage,filter).then(function (response) {
            if (response?.result) {
                let result = response?.result;
                if(result){
                    // setLoanDetails(result);
                    setSumary(response?.result);
                    // setTotalRows(response?.result?.assetcount);
                }else{
                    // setLoanDetails([]);
                    setSumary({})
                  // setInvestorsData(InvestorsDummyData);
                };
                setTotalRows(response?.result?.totalCount);
                // setTotalRows(response?.result?.totalCount);
                
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
                setLoanDetails([]);
                setSumary({})
            })
    };
    const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
         
        // Format table data for Excel
        const formattedData = loanDetails.map(item => ({
            'LOAN ID' : item?.loanid || '',
            'LENDER CASE ID' : item?.lead?.lendercaseid  || '',
            'PROPERTY ADDRESS' : item?.propertyinfo?.[0]?.propertyfulladdress  || '',
            'DEAL' : item?.deal || '',
            'FUNDED LOANS' : item?.fundedloans || '',
            'UNPAID BALANCE' : item?.unpaidbalance || '',
            'MATURITY DATE' : item.cleartoclose?.currentmaturitydate ? moment.utc(item.cleartoclose?.currentmaturitydate).format("MM-DD-YYYY") : '',
            
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'fund_loan_details' + fileExtension);
    };


    const handleTypeFilter = (e) => {
        let search = '';
        let filter = e.target.value;
        setCurrentPage(1);
        fetchLoanDetails(1,perPage,filter);
        setFilterTerm(e.target.value);
    }

    const handlePageChange = (page) => {
            console.log('rannnn');
            let filter = '';
            let search = '';
             if (filterTerm) {
                fetchLoanDetails(page,perPage,filterTerm);
            } else {
                fetchLoanDetails(page,perPage,filter);
            }
            setCurrentPage(page);
      };
        const handlePerRowsChange = async (newPerPage, page) => {
            console.log('i ran tooo')
            let filter = '';
            let search = '';
            if (filterTerm) {
                fetchLoanDetails(page,newPerPage,filterTerm);
            } else {
                fetchLoanDetails(page,newPerPage,filter);
            }
            setCurrentPage(page);
            setPerPage(newPerPage);

        };

   
      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };

    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management add_default fund_summary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Fund Summary</h4>
                      
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className='search'>
                                        <h5 className="title mt-0 mb-0">{filterTerm ? fundsDropdown?.find((fund) => fund.uuid === filterTerm)?.name : 'ALL FUNDS'}</h5>
                                        <div className="d-flex align-items-center form-group">
                                            <label>Select a Fund</label>
                                            <select id="fundname" value={filterTerm} onChange={handleTypeFilter} name="fundname" className="form-control" tabindex="1">
                                                <option value="">Select</option>
                                                {fundsDropdown?.map((fundoption,index)=>{
                                                    return(
                                                        <option key={index} value={fundoption?.uuid}>{fundoption?.name}</option>
                                                    )
                                                })}
                                            
                                            </select>
                                        </div>
                                    </div>
                                    <div className="steps">
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text">{summary?.fundTotal ? formatCurrency(summary?.fundTotal) : '$0.00'}</span>
                                    <span className="text-type">Fund Total</span>
                                    </div>
                                    <img src="assets/images/icons/summarize.svg" class="img" alt=""/>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text">{summary?.totalInvestors ? (summary?.totalInvestors) : '0'}</span>
                                    <span className="text-type">Total Number of Investor Accounts</span>
                                    </div>
                                    <img src="assets/images/icons/non_fund.svg" class="img" alt=""/>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text">{summary?.fundedLoans ? formatCurrency(summary?.fundedLoans) : '$0.00'}</span>
                                    <span className="text-type">Funded Loans</span>
                                    </div>
                                    <img src="assets/images/icons/fund_loans.svg" class="img" alt=""/>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text">{summary?.totalFundLoans ? (summary?.totalFundLoans) : '0'}</span>
                                    <span className="text-type">Total Number of Funded Loans</span>
                                    </div>
                                    <img src="assets/images/icons/money_bag.svg" class="img" alt=""/>
                                    </div>
                                   </div>
                                   <hr className="hr"></hr>
                                   {/* <div className='search'>
                                    <h5 className="title mt-0 mb-0"> Cash on Hand</h5>
                                    </div> */}
                                    {/* <div className="table-responsive">
                                        <table className="table db_table tsr_table los_table">

                                            <thead className="table-light">
                                                <tr>
                                                    <th width="45%">Trust account name </th>
                                                    <th width="45%">bank name</th>
                                                    <th width="10%" className="text-end">cash on hand</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                               <tr>
                                                    <td>Easy Street Capital LLC</td>
                                                    <td>Plains Capital Bank</td>
                                                    <td className="text-end">$5,136,346.09</td>
                                                    </tr>
                                                  
                                            
                                            </tbody>
                                        </table>
                                    </div> */}
                                    {/* <hr className="hr"></hr> */}
                                    {/* <div className='search'>
                                    <h5 className="title mt-0 mb-0"> Loan details</h5>
                                    <button id="exportloandetails" onClick={handleExportToExcel} type="button" class="border_btn">Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                    </div> */}
                                        {/* <div class="table-responsive">
                                        {loanDetails?.length > 0 && 
                                      <DataTable className='emailmanager_table'
                                      columns={columns}
                                      data={loanDetails}
                                      pagination
                                      customStyles={customStyles}
                                      persistTableHead
                                      noDataComponent="No results found" // Show a message when there are no rows
                                      paginationTotalRows={totalRows}
                                      paginationServer
                                      paginationPerPage={perPage}
                                      paginationDefaultPage={currentPage}
                                      onChangePage={handlePageChange}
                                      onChangeRowsPerPage={handlePerRowsChange}
                                      paginationRowsPerPageOptions={[10, 15, 30, 40]}
                                      paginationComponentOptions={{
                                          rowsPerPageText: 'Per Page',
                                      }}
                                  />
                                    }
                              
                                        </div> */}

                                    {/* {loanDetails?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    } */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input type="text" placeholder="Enter" className="form-control" />
                                            <button type="button" className="fs_btn ml_20">Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b> <br />
                                        Begin creating your checklist by typing the <span className="color">Line item name
                                        </span> and clicking<span className="color"> Add</span> button.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button type="button" className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminFundSummary;