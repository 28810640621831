import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {addEmailManager ,getSingleEmailTemplateAdmin , getValuesDropdown} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import Select from 'react-select';
import MultiLevelSelect from 'react-select-multi-level';
//import formValuesKeys from '../JsonData/formValuesKeys.json';
import {transformData} from '../utils/commonUtils.js';
import ValuesDropdown from './ValuesDropdown.js';

const CreateEmailTemplate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedEmail = location?.state?.data ;
    const mode = location?.state?.mode;
    // console.log('selectedEmail---->',selectedEmail);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [type , setType] = useState((selectedEmail?.templateid) ? 'update' : 'create');
    const [content, setContent] = useState('');
    const [message , setMessage] = useState('Updated Successfully');
    const [emailOldData,setEmailOldData] = useState(
        { 
            name: '' ,
            template : ''
        }
    );
    const [emailData, setEmailData] = useState(
        { 
            name: '',
            template : ''
        }
    );
    const [editorValidate,setEditorValidate] = useState(false);
    const [isEqual,setIsEqual] = useState(false);
    
    const [errors, setErrors] = useState({});
    // const [formVariable,setFormVariable] = useState([]);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
    const configdata = useSelector(state => state.configdata);
    const [ stagesToMatch, setStagesToMatch] = useState(configdata?.admin?.loanStatus || [
        "Account Executive Review",
        "Underwriting",
        "Term Sheet Issued",
        "Processing",
        "Reviewed/Requested",
        "On Hold",
        "Docs Out",
        "Funded",
        "Closed",
        "Cancelled",
        "Terms Not Issued",
        "Servicing Setup",
        "Servicing"
    ]);

    const sendEmailTo = [
        "Account Executive",
        "Borrower",
        "Borrower and Broker",
        "Broker",
        "Processor",
        "Servicing",
        "Super Admin/Executive",
        "Underwriter"
    ];

    const [ruleTypeOptions, setRuleTypeOptions] = useState(['Stage', 'Date Rules']);
    const [dateOptions, setDateOptions] = useState([
        '45 days prior to maturity date', 
        '30 days prior to maturity date', 
        '30 days after funding date', 
        '45 days after funding date', 
        '60 days after funding date', 
        'Loan is Paid Off'
    ]);
    

    useEffect(()=>{
        if(selectedEmail?.templateid){
            getSingleTemplate(selectedEmail?.templateid);
        }
    },[]);
    const getSingleTemplate =(templateId)=>{
        dispatch(showLoader());
        getSingleEmailTemplateAdmin(templateId).then(function (response) {
            if (response?.result?.data?.[0]) {
                const result = response?.result?.data?.[0];
                setEmailOldData(result);
                setEmailData(result);
                setContent(result?.content || result?.template)
                // setEmailData()
            }
            dispatch(hideLoader())
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader())
            })
    }
   
    // const jsdom = require("jsdom");
    // const { JSDOM } = jsdom;
 

        // useEffect(() => {
        //     // dispatch(showLoader());
        //     getTermSheets().then(function (response) {
        //         if (response?.result) {
        //             setPastData(response?.result);
        //             // console.log('past data---->',response?.result);
        //         }
        //         // dispatch(hideLoader());
        //     }).catch((err) => {
        //         // dispatch(hideLoader());
        //         console.error.bind("my account error", err);
        //     })
        // },[])

        // console.log("selected email",selectedEmail);

        useEffect(() => {
            console.log('quillValuequillValue',emailData)
            
            setEmailData((prev) => ({
                ...prev,
                ['template']: content,
            }));
        }, [content]);
    
    const normalizeHtml = (html) => {
        // Remove extra whitespaces and normalize HTML formatting
        // return html.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim();

        // Remove HTML tags but preserve all spaces and line breaks
        return html?.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const areObjectsEqual = () => {
        //console.log("editor old",emailOldData?.template);
        //console.log("editor new",emailData?.template);
        const keys1 = Object.keys(emailOldData);
        const keys2 = Object.keys(emailData);

        // If the number of keys is different, objects are not equal
        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys2) {

            // If values are not equal, objects are not equal
            if (key === "name" && (emailOldData[key] !== emailData[key])) {
                return false;
            }else if(key === "template"){
                const normalizedEditor1Content = normalizeHtml(emailOldData[key]);
                const normalizedEditor2Content = normalizeHtml(emailData[key]);
                if(normalizedEditor1Content !== normalizedEditor2Content){
                    return false;
                }
            }else if (emailOldData[key] !== emailData[key]) {
                return false;
            }
            

            
        }
         // If all keys and values match, objects are equal
        return true;
        //return Object.keys(emailOldData).some(key => emailOldData[key] === emailData[key]);
    };

    useEffect(() => {
        if(type === "update"){
            const dataEqual = areObjectsEqual();
            console.log("Is equal",dataEqual);
            setIsEqual(dataEqual);
        }
       

    },[emailData])

    const handleChange = (e) => {
        //const { name , value} = e.target;
        let name = e.target.name;
        let value = e.target.value;
        // let newValue = "";
        // if(name === "name"){
        //     const regex = /^[a-zA-Z\s]+$/;
           
        //     for(let i = 0; i< value?.length ; i++){
        //         if(!regex.test(value[i])){
        //             newValue += "";
        //         }else{
        //             newValue += value[i];
        //         }
        //     }
        //     value = newValue;
           
        // }
        // if(name !== "template"){
           // setEmailData({...emailData,[name] : value});

        if (name === "reminder") {
            value = value.replace(/\s+/g, ''); // Removes all spaces
        }
            setEmailData((prevEmailData) => ({
                ...prevEmailData,
                [name]: value,
            }));
        // }
         
    };

    const updateEmailData = (newData) => {
        setEmailData(prevState => ({
            ...prevState,
            ...newData
        }));
    };

    useEffect(() => {
        if(selectedEmail?.templateid){
            updateEmailData({templateid: (location?.state?.data?.templateid)});
        }
    },[])

   
    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          console.log("Error class added to element:", inputElement);
        }
      };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    useEffect(() => {
        const inputElement = document.querySelector(`.jodit-react-container`);
        if(inputElement.classList.contains('invalid-input')){
            removeErrorClass("jodit-react-container");
        }
    },[emailData?.template])
   

    const handleSave = ()=>{
        // console.log("existing data",termSheetsData);
        // const fieldsToCheck = {
        //      sheetName : 'sheetName', programType: 'programType'
        // };
        console.log(emailData?.template);
        console.log(content);
        let error = {};
        const emailInputs = ['name', 'template', 'sendEmailOnLoanStatusChange', 'sendEmailFrom', 'sendEmailTo', 'emailSubjectLine','daterules','reminder'];
        const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/; 

        // for (const input of emailInputs) {
        //     if (input === "template" && (isWhitespace?.test(emailData[input]) || !emailData[input] || emailData[input] === "" || emailData[input] === "<p><br></p>" || emailData[input] === undefined)) {
        //         //error[input] = 'required';
        //         addErrorClass("jodit-react-container");
        //     }else if (input === "sendEmailTo" && Array.isArray(emailData?.[input]) && emailData?.[input].length === 0) {
        //         error[input] = 'required';
        //     }else if ((!emailData[input] || emailData[input] === "" || emailData[input] === undefined)) {
        //         error[input] = 'required';
        //     }
        // }

        for (const input of emailInputs) {
            // Check for 'template' field
            if (input === "template" && (isWhitespace?.test(emailData[input]) || !emailData[input] || emailData[input] === "" || emailData[input] === "<p><br></p>" || emailData[input] === undefined)) {
                addErrorClass("jodit-react-container");
            }
            // Check for 'sendEmailTo' field
            else if (input === "sendEmailTo" && Array.isArray(emailData?.[input]) && emailData?.[input].length === 0) {
                error[input] = 'required';
            }
            // Check for 'type' field
            else if (input === "daterules" && (!emailData[input] || emailData[input] === "")) {
                error[input] = 'required';
            }
            // Check for other required fields
            else if (input !== "sendEmailOnLoanStatusChange" && input !== "reminder" && (!emailData[input] || emailData[input] === "" || emailData[input] === undefined)) {
                error[input] = 'required';
            }
            // Check for conditional requirements based on 'type'
            else if (emailData['daterules'] === "Stage" && (emailData['sendEmailOnLoanStatusChange'] === "" || emailData['sendEmailOnLoanStatusChange'] === undefined)) {
                error['sendEmailOnLoanStatusChange'] = 'required'; // Make this field mandatory if type is "Stage"
            }
            else if (emailData['daterules'] === "Date Rules" && (emailData['reminder'] === "" || emailData['reminder'] === undefined)) {
                error['reminder'] = 'required'; // Make this field mandatory if type is "Date Rules"
            }
        }

        console.log("errors", error);
        setErrors(error);
        const inputElement = document.querySelector(`.jodit-react-container`);
        if (Object.keys(error).length === 0 && !inputElement.classList.contains('invalid-input')) {
            console.log("email data",emailData);
                dispatch(showLoader());
                addEmailManager(emailData).then(function (response) {
                    console.log('responseresponse',response);
                    setPopupOpen(true);
                    if(response?.result !== "Template Name Already Exists"){
                       
                        let msg = type === "create" ? 'Email Template created Successfully' : 'Email Template updated Successfully';
                        setMessage(msg);
                        
                    }else{
                        setMessage("Template Name Already Exists!");
                    }
    
                    dispatch(hideLoader());
                }).catch((err) => {
                    dispatch(hideLoader());
                    console.error.bind("Login error", err);
                })
            
            
        }
    }
    

    const closePopup = () => {
        setPopupOpen(false);
        navigate('/emailmanager');
    };



    

    const handleChangeValues = (value) => {
        const selectedValue = value;

    // setFormVariables((prevFormVariables) => {
    //     if (!prevFormVariables.includes(selectedValue)) {
    //         return [...prevFormVariables, selectedValue];
    //     }
    //     return prevFormVariables; 
    // });

        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
    };

    // useEffect(() => {
    //     dispatch(showLoader());
    //     getValuesDropdown().then(function (response) {
    //         if (response?.result) {
    //             const result = response?.result;
    //             console.log("get values dropdown",result);
    //             setFormValuesData(result);
    //         }
    //         dispatch(hideLoader())
    //     }).catch((err) => {
    //           console.error.bind("get values dropdown  error", err);
    //           dispatch(hideLoader())
    //     })
    // },[])

    // Toggle sub-options on category click
    // const toggleSubOptions = (index) => {
    //     setOpenCategoryIndex(openCategoryIndex === index ? null : index);
    // };


    // useEffect(() => {
    //     if (formValuesData && formValuesData.length > 0) {
    //         console.log("form values data",formValuesData)
    //         const transformedData = transformData(formValuesData);
    //         console.log("Formatted Dropdown Data", transformedData);
    //         setFormValues(transformedData); // Set array, not stringified data
    //     }
    // }, [formValuesData])
    const [sendEmailOptions,setSendEmailOptions] = useState([]);
    useEffect(() => {
        const transformedResult = sendEmailTo?.map(item => ({
            value: item,
            label: item
          }));
          setSendEmailOptions(transformedResult)
    }, [])
    const [selectedEmails, setSelectedEmails] = useState([]);
    const handleSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedEmails(selectedValues);
    };

    
    useEffect(() => {
     
            updateEmailData({sendEmailTo: selectedEmails});
      
    }, [selectedEmails])
    useEffect(() => {
     
        console.log('emailData',emailData);
  
}, [emailData])

    
    
    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message === "Sheet Name or Program Type already Exists." || message === "Template Name Already Exists!"? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{type === 'update' ? 'Update Email Template' : 'Create Email Template'} </h4>
                                <a id="navigateEmailManager" href="#" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Select Term Sheet</label>
                                                            <select name='termSheet' onChange={handleChange} className={`form-control ${errors?.termSheet && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, termSheet: null }) }}>
                                                                {termSheet.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                               
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Template Name</label>
                                                            <input id="emailTemplateName" name='name' value={emailData?.name} onChange={handleChange} type="text" className={`form-control ${errors?.name && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, name: null }) }} placeholder="Enter Template Name" />
                                                        </div>
                                                    </div>
                                                    
                                                    <ValuesDropdown onChange={handleChangeValues}/>
                                                        
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Rule Type</label>
                                                            <select 
                                                                id="ruleType" 
                                                                name="daterules" 
                                                                className={`form-control ${errors?.daterules && 'invalid-input'}`}
                                                                onFocus={() => { setErrors({ ...errors, daterules: null }) }}
                                                                //tabIndex="1" 
                                                                onChange={handleChange} 
                                                                value={emailData?.daterules}
                                                            >
                                                                <option value="">Select</option>
                                                                {ruleTypeOptions.map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Email Reminder Dates</label>
                                                            <select 
                                                                id="emailReminder" 
                                                                name="reminder" 
                                                                className={`form-control ${errors?.reminder && 'invalid-input'}`}
                                                                onFocus={() => { setErrors({ ...errors, reminder: null }) }}
                                                                //tabIndex="1" 
                                                                onChange={handleChange} 
                                                                value={emailData?.reminder}
                                                                disabled = {emailData?.daterules?.trim() !== 'Date Rules'}
                                                                style={{ background: (emailData?.daterules?.trim() !== 'Date Rules') ? 'rgb(233, 236, 239)' : ''}}
                                                            >
                                                                <option value="">Select</option>
                                                                {dateOptions.map((option, index) => (
                                                                    <option key={index} value={option.replace(/\s+/g, '')}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                {/* {dynamicDropdowns?.map((input,index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <div className="form-group">
                                                                <label>{input?.label}</label>
                                                            <MultiLevelSelect
                                                                options={input?.options}
                                                                onChange={(e)=>{handleSelectChange(e)}}
                                                                placeholder={input?.placeholder}
                                                            />
                                                            </div>
                                                        </div>
                                                    ))} */}
                                                    
                                                </div>
                                                <div className="row">
                                                <div className="col-md-3">
                                                 <div className="form-group">
                                                        <label>Send email when loan goes into</label>
                                                        <select id = "sendEmailOnLoanStatusChange" onChange={handleChange} value={emailData?.sendEmailOnLoanStatusChange}  name='sendEmailOnLoanStatusChange' 
                                                        className={`form-control ${errors?.sendEmailOnLoanStatusChange && 'invalid-input'}`} 
                                                        onFocus={() => { setErrors({ ...errors, sendEmailOnLoanStatusChange: null }) }}
                                                        disabled = {emailData?.daterules !== 'Stage'}
                                                        style={{ background: (emailData?.daterules !== 'Stage') ? 'rgb(233, 236, 239)' : ''}}
                                                        >
                                                        <option value={''}>Select</option>
                                                            {stagesToMatch.map((data,index)=>(
                                                                <option value={data}>{data}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                            <label>Send Email From</label>
                                                            <input id="sendEmailFrom" name='sendEmailFrom' value={emailData?.sendEmailFrom} onChange={handleChange} type="text" className={`form-control ${errors?.sendEmailFrom && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailFrom: null }) }} placeholder="Enter Send Email From" />
                                                        </div>  
                                                </div>
                                                <div className="col-md-3">
                                                 <div className="form-group">
                                                        <label>Send Email To</label>
                                                        {/* <select id = "sendEmailTo" onChange={handleChange} value={emailData?.sendEmailTo}  name='sendEmailTo' className={`form-control ${errors?.sendEmailTo && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailTo: null }) }}>
                                                            {sendEmailTo.map((data,index)=>(
                                                                <option value={data} key={index}>{data}</option>
                                                            ))}
                                                        </select> */}
                                                        <Select
                                                    
                                                  
                                                    options={sendEmailOptions}
                                                    isMulti
                                                    value={sendEmailOptions?.filter(option => emailData?.sendEmailTo?.includes(option.value))}
                                                    className={`w-100 ${errors.sendEmailTo && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailTo: null }) }}
                                                    id="w3review"
                                                    onChange={handleSelectChange}
                                                    name='sendEmailTo'
                                                   
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                            <label>Email Subject Line</label>
                                                            <input id="emailSubjectLine" name='emailSubjectLine' value={emailData?.emailSubjectLine} onChange={handleChange} type="text" className={`form-control ${errors?.emailSubjectLine && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, emailSubjectLine: null }) }} placeholder="Enter Email Subject Line" />
                                                        </div>  
                                                </div>

                                                </div>
                                                
                        
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div>
                                                          
                                                            <EditorEdit content={content} setContent={setContent}  type={type} name = "template" formValuesOption={formValuesOption}  valueDependency={valueDependency}/>
                                                        </div>
                                                        {/* <label className="toggle">
                                                            <input className="toggle-checkbox" onChange={(e)=>handleCheckBox(e)} checked={termSheetsData?.whiteLabel} type="checkbox" />
                                                            <div className="toggle-switch"></div>
                                                            <span className="toggle-label">White Label</span>
                                                        </label> */}
                                                    </div>
                                                </div>
                                                <div className="btn-group mt_10">
                                                 
                                                {/* {type === 'update' && <a href="#" onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" disabled = {termBool} style={{'opacity' : (termBool === true) ? '0.6' : '1'}}>Update</a>} */}
                                                    {<button id="submitEmail" type = "button" style = {{'opacity' : (type === "update" && isEqual === true) ? '0.6' : '1' }} disabled = {(type === "update" && isEqual === true) && 'true' } onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" > {mode === 'edit' ? 'Update' : 'Create'}</button>}
                                                    {/* <a href="#" onClick={(e) => {e.preventDefault(); handleDelete()}} className="border_btn">Delete</a> */}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateEmailTemplate