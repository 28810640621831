import React, { useState, useEffect } from 'react'
import CreateUser from './CreateUser';
import CreateUserUserInfo from './CreateUserUserInfo';
import CreateUserStateLicenses from './CreateUserStateLicenses';
import CreateUserLoanAssignments from './CreateUserLoanAssignments';
import { getUserDataFromLocalStorage } from "../../utils/local";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate} from 'react-router-dom';
import {getselectedUser} from "../../utils/reducer";
import Loader from '../Loader';
import {useSelector} from 'react-redux';
const CreateUserPage = () => {
  const loading = useSelector((state) => state.loaderVisible);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = location.state || {};
  const [userData, setUserData] = useState(user || {});
    const [currentPage, setCurrentPage] = useState('userinfo');
    const [unique_id, setunique_id] = useState(uuidv4());
    const [isEdit, setisEdit] = useState(false);
    const [finalData, setFinalData]= useState(userData || {
      userInfo : null,
      securityInfo : null,
      stateLicenses : null,
      loanAssignMent : null,
      id:unique_id,
      isNewUser:true
    });
    const [checkData , setCheckData] = useState([]);
    const [FormChanged, setFormChanged] = useState(false);
    let userDat = getUserDataFromLocalStorage();
    // userinfo states
    const [userInfoDetails, setUserInfoDetails] = useState(userData?.userInfo || {firstname : '', lastname : '' , state : '', emailid : '', phone : '', fax : '', username : '', manager : userDat?.username || '', teamlead : '', referral : '', password : ''});
    const [imgData,setImageData]= useState({
        img : '',
        imgFileData : null
    });
    const [quillValue, setQuillValue] = useState(userInfoDetails?.aboutme || '');
    const [signaturetext, setSignatureText] = useState(userInfoDetails?.signature || "");

    //state Licences Page
    const [licenseData, setLicenseData] = useState(userData?.stateLicenses?.Licenses || []);

    //loan assignments
    
    const [loanInputs,setLoanInputs] = useState(userData?.loanAssignMent ||
      {
      pickloselectable : false,
      // roundrobin: false,
      loanofficer : "",
      defaultcloser : "",
      defaultcloser2 : "",
      // selectstate : "",
      loanprocessor : "",
      loanfunder : "",
      loanfunder2 : "",
      // selectcountry : "",
      defaultopener : "",
      defaultpostclosure : "",
      defaultunderwriter : "",
      defaultshipper: "",
      defaultservicer2: "",
      defaultproccessor2: "",
      defaultaccounting: "",
      defaultaccounting2: "",
      defaulttreasury: "",
      defaultsecondary: "",
  });
  

    useEffect(() => {
      if(user){
        setisEdit(true)
        getselectedUser(user?.userid).then(function (response) {
          if (response?.statusCode == 200) {
            setUserData(response?.result?.[0]);
            console.log('response?.result?.[0]',response?.result?.[0]);
            setFinalData(response?.result?.[0]);
            setCheckData(response?.result?.[0]?.loanAssignMent);
            setUserInfoDetails(response?.result?.[0]?.userInfo);
            setLicenseData(response?.result?.[0]?.stateLicenses?.Licenses || [])
            setLoanInputs(response?.result?.[0]?.loanAssignMent)
            setSignatureText(response?.result?.[0]?.userInfo.emailsignature);
            setQuillValue(response?.result?.[0]?.userInfo.aboutme);
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
      }
    }, [user])
    
    const checkIdAndNavigate = (nextPage) => {
      if (finalData._id) {
          setCurrentPage(nextPage);
      } else {
          console.log("Cannot navigate without _id");
      }
  };

    const handleBack = ()=>{
      if(currentPage === 'userinfo'){
        // navigate it back
        navigate('/usermanagement')
      }
      else if(currentPage === 'securityInfo'){
        setCurrentPage('userinfo');
      }else if(currentPage === 'stateLicenses'){
        setCurrentPage('securityInfo');
      }else if(currentPage === 'loanAssignMent'){
        setCurrentPage('stateLicenses');
      }
    }

  return (
    <>
    {loading ? <Loader/> : null} 
   {currentPage === 'userinfo' ? <CreateUserUserInfo isEdit={isEdit} finalData={finalData} userInfoDetails={userInfoDetails} setUserInfoDetails={setUserInfoDetails} imgData={imgData} setImageData={setImageData} setFinalData={setFinalData} handleBack={handleBack} setCurrentPage={setCurrentPage} quillValue={quillValue} setQuillValue={setQuillValue} signaturetext={signaturetext} setSignatureText={setSignatureText} checkIdAndNavigate={checkIdAndNavigate} isLoggedIn = {userData?.loggedin}/> : null} 
   {currentPage === 'securityInfo' ? <CreateUser isEdit={isEdit} finalData={finalData} setFinalData={setFinalData} handleBack={handleBack} setCurrentPage={setCurrentPage}/> : null} 
   {currentPage === 'stateLicenses' ? <CreateUserStateLicenses isEdit={isEdit} finalData={finalData} licenseData={licenseData} setLicenseData={setLicenseData} setFinalData={setFinalData} handleBack={handleBack} setCurrentPage={setCurrentPage}/> : null} 
   {currentPage === 'loanAssignMent' ? <CreateUserLoanAssignments isEdit={isEdit} finalData={finalData} setFinalData={setFinalData} handleBack={handleBack} setCurrentPage={setCurrentPage} loanInputs={loanInputs} setLoanInputs={setLoanInputs} checkData={checkData}/> : null} 
    
    </>
  )
}



export default CreateUserPage