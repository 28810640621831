import React,{useState, useEffect} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import Footer from '../Footer';
import PropertyInfoSelected from './PropertyInfo2';
import PropertySearch from './PropertyInfo3';
import { useLoanInfo } from '../../utils/loanInfoContext';
import { useUserType } from '../../utils/UserTypeContext';
const PropertyInfo = () => {
    const { useType, setUseType } = useUserType();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    useEffect(() => {
      const userTypeFromUrl = urlParams.get('usertype');
      if (userTypeFromUrl) {
        setUseType(userTypeFromUrl);
      }
    }, [urlParams, setUseType]);
  let formElementsbrockerJson = [
    {
      "type": "buttonGroup",
      "label": "IS THE BORROWER PURCHASING OR REFINANCING?",
      "options": [
        { "value": "Purchase", "label": "Purchase" },
        { "value": "Refinance", "label": "Refinance" }
      ],
      "name": "loanfor",
      "id" : "purchaseRefinanceBroker"
    },
    {
      "type": "buttonGroup",
      "label": "WHAT STEP IN THE PROCESS ARE YOU AT?",
      "options": [
        { "value": "Doing research for borrower", "label": "Doing research for borrower" ,"id" : "researchBroker" },
        { "value": "Borrower submitted an offer", "label":"Borrower submitted an offer" ,"id" : "submitOfferBroker" },
        { "value": "Borrower has property under contract", "label": "Borrower has property under contract" ,"id" : "purchaseContractBroker" }
      ],
      "name": "stepIntheProcess",
      
    },
    {
      "type": "locationsearch",
      "label": "WHAT AREA ARE YOU LOOKING?",
      "placeholder": "Search City and Zip Code",
      "name": "propertyPlace",
      "id" : "locationBorrower"
    },
  ]
  let formElementsborrowerJson = [
    {
      "type": "buttonGroup",
      "label": "ARE YOU PURCHASING OR REFINANCING?",
      "options": [
        { "value": "Purchase", "label": "Purchase" },
        { "value": "Refinance", "label": "Refinance" }
      ],
      "name": "loanfor",
      "id" : "purchaseRefinanceBorrower"
    },
    {
      "type": "buttonGroup",
      "label": "WHAT STEP IN THE PROCESS ARE YOU AT?",
      "options": [
        { "value": "I'm doing research", "label": "I'm doing research","id" : "researchBorrower" },
        { "value": "I submitted an offer I feel good about", "label":"I submitted an offer I feel good about" ,"id" : "submitOfferBorrower" },
        { "value": "I have signed a purchase contract", "label": "I have signed a purchase contract","id" : "purchaseContractBorrower" }
      ],
      "name": "stepIntheProcess",
     
    },
    {
      "type": "locationsearch",
      "label": "AWESOME! WHAT IS THE ADDRESS?",
      "placeholder": "Search City and Zip Code",
      "name": "propertyPlace",
      "id" : "locationBorrower"
    },
  ]
const { loanInfo, setLoanInfo } = useLoanInfo();
const [currentCategory, setCurrentCategory] = useState('infoSelection');
const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo ? loanInfo?.propertyinfo : {});
const [formJSON, setformjson] = useState(useType == 'broker' ? formElementsbrockerJson : formElementsborrowerJson);
const [prevMenu, setPrevMenu] = useState('infoSelection');
const navigate = useNavigate();
console.log('formJSONhereer',formJSON);
  useEffect(() => {
    console.log('properyInfo',loanInfo);
    setLoanInfo((prevLoanInfo) => ({
      ...prevLoanInfo,
      propertyinfo: properyInfo,
    }));
  }, [properyInfo]);
  useEffect(() => {
    if (properyInfo.hasOwnProperty('PropertyPlace')) {
      setCurrentCategory('propertyPlace');
    } 
  }, []);
  useEffect(() => {
    if (useType && useType == 'broker') {
      setformjson(formElementsbrockerJson);
    }else{
      setformjson(formElementsborrowerJson);
    } 
    setLoanInfo((prevLoanInfo) => ({
      ...prevLoanInfo,
      requestor: useType || 'borrower',
    }));
  }, [useType]);
  
  
const PropertyInfoSelection = (props) => {
    return (
     <div className="cont">
        <h3 className="feild_title">{props?.formJSON[0]?.label}</h3>
        <div className="btn-group">
            <button id="purchasing" type="button" className="cp_btn"  style={properyInfo?.loanfor === 'Purchase' ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} onClick={(e) => {setPrevMenu('Purchase'); handleInputChange('loanfor', 'Purchase'); nextForm('Purchase');}}>Purchasing</button>
            <button id="refinancing" type="button" className="cp_btn" style={properyInfo?.loanfor === 'Refinance' ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} onClick={(e) => {setPrevMenu('Refinance'); handleInputChange('loanfor', 'Refinance'); nextForm('Refinance');}}>Refinancing</button>
        </div>
    </div>
    )
}
const navBack = (e) => {
  e.preventDefault()
  console.log('currentCategory',currentCategory);
     if (currentCategory === 'Purchase' || currentCategory === 'Refinance') {
        setCurrentCategory('infoSelection')
    } if (currentCategory === 'propertySearch') {
        setCurrentCategory(prevMenu)
        if(properyInfo?.loanfor == 'Refinance'){
          setCurrentCategory('infoSelection')
        }
    }else if (currentCategory === 'propertyPlace' ) {
        setCurrentCategory('propertySearch')
    }
}

const nextForm = (page) => {
    setCurrentCategory(page)
    if(page == 'Refinance'){
      setCurrentCategory('propertySearch')
    }
}

const handleInputChange = (key, value) => {
    // Update the formData with the new key-value pair
   
    setProperyInfo((prevData) => {
      // Create a copy of the previous data
      let updatedData = { ...prevData };

      // Conditionally delete the key from the copied data
      if (key == 'loanfor' && value === "Refinance") {
        delete updatedData.stepIntheProcess;
      }
  
      // Update the copied data with the new key-value pair
      updatedData = {
        ...updatedData,
        [key]: value,
      };
  console.log('ey, value',key, value);
      return updatedData;
    });
  };
  return (
    <div className="create_process">
       <Header />
        <div className="content_wrp">
            <InfoBar currentStage={'propertyinfo'} properyInfo={properyInfo} progress={(currentCategory === 'Purchase' || currentCategory === 'Refinance') ? '30%':currentCategory == 'propertySearch' ? '75%' :currentCategory == 'propertyPlace' ? '100%' : '0%'}/>
            <div className="content_blk">
                <div className="hed">
                    
               {currentCategory != 'infoSelection' && <a href="#" className="back material-icons" onClick={(e) => {navBack(e)}}> keyboard_backspace </a>}
                    <h2 className="title">Property Info </h2>
                </div>
                <PropertyInfoSelection setCurrentCategory={setCurrentCategory} formJSON={formJSON}/>
                {properyInfo?.loanfor == 'Purchase' && <PropertyInfoSelected properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>}
                {properyInfo?.loanfor && ((properyInfo?.loanfor == 'Purchase' && properyInfo?.stepIntheProcess ) || properyInfo?.loanfor == "Refinance") && <PropertySearch properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>}
                {/* {currentCategory == 'infoSelection' && <PropertyInfoSelection setCurrentCategory={setCurrentCategory}/>}
                {(currentCategory === 'Purchasing' || currentCategory === 'Refinancing') &&  <PropertyInfoSelected properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} />}
                {currentCategory == 'propertySearch' && <PropertySearch properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} />}
                {currentCategory == 'propertyPlace' && <PropertyPlace properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} />} */}
            </div>
        </div>
       <Footer />
    </div>
  );
};

export default PropertyInfo;