import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';   
import {loanUpdate, getloanStatus, updateloanStatus, searchLoanApi, getLoanDetails, getToolWorkflowAlerts, updateAlertStatus} from "../utils/reducer";
import { getUserDataFromLocalStorage } from "./../utils/local";
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import {getAllLoansPipeline } from "./../redux/reducers/reducer";
import { formatCurrency } from '../utils/commonUtils.js';
import { usePipeline } from '../utils/PipelineContext';
import { hideLoader, showLoader } from '../redux/reducers/Actions.js';
const PipelineStatus = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loansData = useSelector(state => state.loansdata);
  const totalcount = useSelector(state => state.totalcount);
  console.log('loansData',loansData);
  console.log('totalcount',totalcount);
  const { loanInfo, setLoanInfo } = useLoanInfo();
    const initialIndex = loansData.findIndex(item => item.loanid === loanInfo?.loanid);
    const [currentIndex, setCurrentIndex] = useState(initialIndex);
    const [showConfirmation,setShowConfirmation] = useState(false);
    const scrollContainerRef = useRef(null);
    const [currentItem, setCurrentItem] = useState(loansData[currentIndex]);
    const [maxReachedStage, setMaxReachedStage] = useState(currentItem?.maxStage || '');
    // const [LoanStatusHistory, setLoanStatusHistory] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchErr, setSearchErr] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [showAlertPopup, setShowAlertPopup] = useState(false);
    const [allAlerts, setallAlerts] = useState([]);
    const [CurrentAlert, setCurrentAlert] = useState({});
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const inputRef = useRef(null);
    // const [CheckStatusAPi,setCheckStatusApi] = useState(true);
    // const [accountExecutiveStatus,setAccountExecutiveStatus] = useState({});
    const {LoanStatusHistory,setLoanStatusHistory,prevLoanIdRefStatus,setcurrentStageIndex,currentStageIndex,setcurrentStg,currentStg,currentStage,setCurrentStage,CheckStatusAPi,setCheckStatusApi ,accountExecutiveStatus,setAccountExecutiveStatus,stages} = usePipeline(); 
      useEffect(() => {
        // Check if token is present in local storage
        const token = localStorage.getItem('accessToken');
  
        if (!token) {
          // Redirect to the login page
          window.location.href = '/login'; // Replace with your login page route
        }
      }, []); 
      
    
      const prevLoanIdRef = useRef(null);

    //   const prevLoanIdRefStatus = useRef(null);

      useEffect(() => {
        const params = new URLSearchParams(location.search);
        const loanId = params.get('loanid');
        console.log('loanId',loanId)
        console.log('prevLoanIdRef.current',prevLoanIdRefStatus.current);
        if (loanId && loanId !== prevLoanIdRefStatus.current) {
          getloanStatusHistory(loanId);
          prevLoanIdRefStatus.current = loanId; // Update prevLoanIdRef here
        }
      }, [location.search]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const loanId = params.get('loanid');
    console.log('loanId',loanId)
    console.log('prevLoanIdRef.current',prevLoanIdRef.current);
    console.log('CheckStatusAPi',CheckStatusAPi)
    if (loanId && loanId !== prevLoanIdRef.current) {
      getLoanDetails(loanId)
        .then(response => {
          console.log('getLoanDetails', response);
          if (response?.result[0]) {
            setLoanInfo(response?.result[0]);
            
            if(CheckStatusAPi){
                console.log('status calleddddd')
                let status = response?.result?.[0]?.status
                handleAllLoansAPi(status);
            }
            setCheckStatusApi(false);
            console.log(response);
          }else{
            navigate('/pipeline');
          }
        })
        .catch(err => {
          console.error('my account error', err);
        });

    //   getloanStatusHistory(loanId);
      prevLoanIdRef.current = loanId; // Update prevLoanIdRef here

      getToolWorkflowAlerts(loanId).then(function(response) {
        const res = response?.result;
        if(response?.statusCode === 200 && res?.length > 0) {
            console.log('get alert api tools work flow response', res);
            setallAlerts(res); 
            const currentRoute = location.pathname.split('/').pop();
            checkRoute(res, currentRoute);
        }else{
            setallAlerts([]); 
        }
    })
    .catch((err) => {
        console.error("get alert api tools work flow error ", err); 
    });
    }
  }, [location.search]);


  const handleAllLoansAPi =(statusName)=>{
    let pgNum = '';
    let assert = '';
    let status = location.state ? '' : statusName;
    let filter = location.state || '';
    dispatch(getAllLoansPipeline(pgNum,assert,status,'',filter));
    // dispatch(getAllLoansPipeline(pgNum,assert,status,'',filter,false,"A100"));

  }
  

    //   useEffect(() => {
    //     setCurrentItem(loansData[currentIndex])
    //    console.log('sdfsfsdfs', currentIndex);
    //   }, [currentIndex]); 
    useEffect(()=>{
        const token = localStorage.getItem('accessToken');
        if(token){
            console.log('pepepeppeline');
            // dispatch(getAllLoansPipeline(''));
        }
    },[])
      useEffect(() => {
        // Check if loansdata is not available
        // console.log('loansDataloansDataloansData',loansData);
        if (!loansData || totalcount == 0) {
        //   dispatch(getAllLoansPipeline(''));
        }else{
            const intIndex = loansData.findIndex(item => item.loanid === loanInfo?.loanid);
            setCurrentIndex(intIndex);
            setCurrentItem(loanInfo);  
        }
      }, [loansData]);
      useEffect(() => {
        // console.log('loanInfohereereinstatus',loansData,loanInfo);
        if(!loanInfo){
            //navigate('/pipeline');
        }else{
            const intIndex = loansData.findIndex(item => item.loanid === loanInfo?.loanid);
            setCurrentIndex(intIndex);
            setCurrentItem(loanInfo);
        }
      }, [loanInfo]);
      
      const scroll = (scrollOffset) => {
        scrollContainerRef.current.scrollLeft += scrollOffset;
    };

    const handleNext = () => {
        console.log('loansData', loansData);
        const nextIndex = (currentIndex + 1) % totalcount;
        const nextLoanId = loansData[nextIndex].loanid;
        navigate(`?loanid=${nextLoanId}`);
      };
    
      const handlePrev = () => {
        const prevIndex = (currentIndex - 1 + totalcount) % totalcount;
        const prevLoanId = loansData[prevIndex].loanid;
        navigate(`?loanid=${prevLoanId}`);
      };
    
    // useEffect(() => {
    //     setLoanInfo(currentItem);
    //   }, [currentItem]);
    // const stages = [
    //     "Account Executive Review",
    //     "Underwriting",
    //     "Term Sheet Issued",
    //     "Processing",
    //     "Reviewed/Requested",
    //     "On Hold",
    //     "Docs Out",
    //     "Funded",
    //     "Closed",
    //     "Cancelled",
    //     "Terms Not Issued",
    //     "Servicing Setup",
    //     "Servicing"
    // ];
    // const [currentStage, setCurrentStage] = useState(currentItem?.currentStage || stages[0]);
    // const [currentStg, setcurrentStg] = useState(currentItem?.currentStage || stages[0]);
    // const [currentStageIndex, setcurrentStageIndex] = useState(stages.indexOf(currentStage));
    const getItemClass = (stageName) => {
        let index = stages.indexOf(stageName);
        
        if (index < 0) return "ag-category-carousel_item"; // Stage not found in array
        const stageExistsInHistory = LoanStatusHistory && LoanStatusHistory.length > 0 && LoanStatusHistory?.some(status => status.currentstatus === stageName || status.previousstatus === stageName);
        if (index === currentStageIndex) return "ag-category-carousel_item in_progress";
        if (index < currentStageIndex) return "ag-category-carousel_item completed";
        if (stageExistsInHistory) return "ag-category-carousel_item prev_completed";
       
        
        return "ag-category-carousel_item";
    };

    const getloanStatusHistory = (loanId) => {
        getloanStatus(loanId).then(function (response) {
          if(response?.result && Array.isArray(response?.result) && response?.result.length > 0) {
            setLoanStatusHistory(response?.result);
            const latestAccountExecutiveData = response?.result?.find(
                item => item.currentstatus === "Account Executive Review"
              );
            console.log('Account Executive Data',latestAccountExecutiveData);
            setAccountExecutiveStatus(latestAccountExecutiveData);
            let defaultStatusObject = response?.result.find(obj => obj.defaultstatus === true);
            let curStag = defaultStatusObject ? defaultStatusObject.currentstatus : currentStage;
            setcurrentStg(curStag)
            setCurrentStage(curStag)
            setcurrentStageIndex(stages.indexOf(curStag));
            
          }
          }).catch((err) => {
            console.error.bind("updateStage error", err);
          })
      }

    const setCurrentStageOnClick = (stageName) => {
        setcurrentStg(stageName)
        setShowConfirmation(true)
    };
    // console.log('currentStage',currentStage, currentItem);
    useEffect(() => {
        setCurrentStage(currentItem?.currentStage || stages[0])
        setcurrentStg(currentItem?.currentStage || stages[0])
      }, [currentItem]);
    const closePopup = () => {
        setShowConfirmation(false);
    };

    const updateStage = () => {
        dispatch(showLoader());
        // console.log('currentItem',currentItem);
        let data = currentItem;
        if (!data) {
            data = {}; // Initialize data as an empty object if it doesn't exist
        }
        data.currentStage = currentStg;
        data.maxStage = maxReachedStage;
        if (stages.indexOf(currentStg) > stages.indexOf(maxReachedStage)) {
            setMaxReachedStage(currentStg); // Update the maximum reached stage if the clicked stage is ahead
            data.maxStage = currentStg;
        }
        
        let userDat = getUserDataFromLocalStorage();
       
        data.stagedPerson = {
            userId: userDat.userId,
            username: userDat.username,
            firstname: userDat.firstname,
            lastname: userDat.lastname
        };
        console.log('userDat',data);
        let defaultStatusObject = LoanStatusHistory.find(obj => obj.defaultstatus === true);
        let data2 = {
            "currentstatus": currentStg,
            "reverted": false,
            "previousstatuschangedby": LoanStatusHistory.length > 0 ? (defaultStatusObject?.changedby ?? LoanStatusHistory[0]?.changedby) : userDat.username,
            "previousstatus": LoanStatusHistory.length > 0 ? (defaultStatusObject?.currentstatus ?? LoanStatusHistory[0]?.currentstatus) : 'Account Executive Review',
            "previousstatusid": LoanStatusHistory.length > 0 ? (defaultStatusObject?.statusid ?? LoanStatusHistory[0]?.statusid) : '',
        }
        
        updateloanStatus(loanInfo?.loanid,data2).then(function (response) {
            console.log('here is the response');
            dispatch(hideLoader());
            getloanStatusHistory(loanInfo?.loanid);
            handleAllLoansAPi(currentStg);
            }).catch((err) => {
                dispatch(hideLoader());
              console.error.bind("updateStage error", err);
            })
        return
        loanUpdate(data).then(function (response) {
            console.log('here is the response');
            }).catch((err) => {
              console.error.bind("updateStage error", err);
            })
    };
    const handleStageClick = (stage) => {
        setCurrentStageOnClick(stage);
       
    };
    const selectSearched = (loan) => {
        const intIndex = loansData.findIndex(item => item.loanid === loan?.loanid);
        // setCurrentIndex(intIndex);
        // setCurrentItem(loan);
        handleModalHidden()
        navigate(`?loanid=${loan?.loanid}`);
        console.log('loan selected', loan?.status);
        handleAllLoansAPi(loan?.status);
    }
    const handleModalHidden = () => {
        setLoans([]);
        setSearchInputValue('')
    }
    useEffect(() => {
        // Add event listener for modal hidden event
        const modal = document.getElementById('searchpopup');
        modal.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup on unmount
        return () => {
            modal.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);
    // const handlSearch = () => {
    //     if (searchInputValue.length > 0) {
    //         searchLoanApi(searchInputValue).then(function (response) {
    //             console.log('getLoanDetails--->', response);
    //             setSearchInputValue('')
    //             if (response?.result && response?.result?.length > 0) {
    //                 const intIndex = loansData.findIndex(item => item.loanid === response?.result[0]?.loanid);
    //                 setCurrentIndex(intIndex);
    //                 setCurrentItem(response?.result[0]);
    //                 console.log(response);
                    
    //             }
    //         }).catch((err) => {
    //             console.error.bind("my account error", err);
    //         })

    //     } else {
    //         setSearchErr("please search by any keyword")
    //     }
    // }
    const [loans, setLoans] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [searchLoader, setSearchLoader] = useState(null);

    const handleSearchLoans = (e) => { 
        const value = e.target.value
       
            setSearchInputValue(e.target.value); 
            setSearchErr("") 
         
            if (value.trim().length > 2) {
                setSearchLoader(true)
                clearTimeout(typingTimeout);
                const newTypingTimeout = setTimeout(() => {
                searchLoanApi(value.trim()).then(function (response) {
                  
                    if (response?.result) {
                        // console.log('sdfsfsfd',response?.result);
                        setLoans(response?.result)

                    }
                    setSearchLoader(false)
                }).catch((err) => {
                    setSearchLoader(false)
                    console.error.bind("my account error", err);
                })
            }, 300);
            setTypingTimeout(newTypingTimeout);
    
            }else {
                clearTimeout(typingTimeout);
                setLoans([]);
            }
        
      
    }
   


    const checkRoute = (alerts, route) => {  
        // Define related routes
        const relatedRoutes = {
            'drawloansummary': 'servicingloansummary',
            'servicingloansummary': 'drawloansummary',
            'dealunderwritingtermsheet': 'underwritingtermsheet',
            'underwritingtermsheet': 'dealunderwritingtermsheet'
        };
    
        // Find an object in the alerts array that matches the route or its related route
        const matchedObject = alerts.find(obj => 
            obj.section === route || 
            obj.section === relatedRoutes[route]
        );
    
        // If a matching object is found and dontShow is not true, show an alert message
        if (matchedObject && !matchedObject?.dontShow) {
            setCurrentAlert(matchedObject);
            setShowAlertPopup(true);
        }
    }
    
    const updateAlert = (alerts, route) => { 
        if(dontShowAgain){
            console.log('CurrentAlert',loanInfo?.loanid, CurrentAlert);
            updateAlertStatus(loanInfo?.loanid, CurrentAlert?.alertid).then(function (response) {
                if(response?.result && Array.isArray(response?.result) && response?.result.length > 0) {
           
                }
                }).catch((err) => {
                  console.error.bind("updateStage error", err);
                })
        }
    
    } 
     
      let borrowerfullname = currentItem?.entity && currentItem?.entity?.[0] && currentItem?.entity?.[0]?.legalname ? currentItem?.entity?.[0]?.legalname : currentItem?.applicant?.[0]?.borrowerfirstname + ' '+currentItem?.applicant?.[0]?.borrowerlastname;



        // let borrowerfullname = currentItem?.entity && currentItem?.applicant?.[0].borrowerfirstname + ' '+currentItem?.applicant?.[0].borrowerlastname;

        //added press key down and key up to search loan
        const handleKeyDown = (e) => {
            if (showOptions && loans.length > 0) {
                if (e.key === 'ArrowDown') {
                    setFocusedIndex((prevIndex) => (prevIndex + 1) % loans.length);
                    e.preventDefault();
                } else if (e.key === 'ArrowUp') {
                    setFocusedIndex((prevIndex) => {
                        if (prevIndex === -1) {
                            return loans.length - 1;
                        } else {
                            return (prevIndex - 1 + loans.length) % loans.length;
                        }
                    });
                    e.preventDefault();
                } else if (e.key === 'Enter') {
                    if (focusedIndex >= 0 && focusedIndex < loans.length) {
                        selectSearched(loans[focusedIndex]);
                        e.preventDefault();
                        setFocusedIndex(-1);
                    }
                }
            }
        }
        

        useEffect(() => {
            setFocusedIndex(-1); // Reset focus index when search input changes
        }, [searchInputValue]);


        return (
            <div> <div className="loan_panel">
                {showConfirmation && <SweetAlert
                            warning
                            showCancel
                            confirmButtonText="Yes, delete it!"
                            cancelButtonText="No, cancel!"
                            title="Are you sure?"
                            onConfirm={() => {setCurrentStage(currentStg); setShowConfirmation(false);updateStage(currentStg)}}
                            onCancel={closePopup}
                            focusCancel={true}
                        >
                                <p>Are you sure you want to change to this stage?</p>
                        </SweetAlert>}
                        {showAlertPopup && 
                                <div className="modal popup conditional_popup deletepop_new alert_popup fade show" id="messagepopup" tabindex="-1"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content ">

                                            <div className="modal-body">
                                                <button type="button" className="close" data-bs-dismiss="modal"
                                                    aria-label="Close"><span className="material-symbols-outlined" onClick={()=>setShowAlertPopup(false)}>close</span></button>
                                            <span className="material-symbols-outlined alert_icon"> warning </span>
                                                <h3>Alert</h3>
                                                <p>{CurrentAlert?.reasonfordenial}
                                                </p>
                                                <label className='alert_check'>
                                                    <input type="checkbox" className='check' checked={dontShowAgain} onChange={(e) => setDontShowAgain(e.target.checked)} />
                                                    Don't show this again
                                                </label>
                                                <button type="button" className="fs_btn pop_btn" onClick={()=>{setShowAlertPopup(false); updateAlert()}} >Ok</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
            <div className="left">
                <div className="row">
                    <div className="col-md-2">
                    <div className="form-group ">
                            <label>Loan ID{currentItem?.entity?.[0]?.legalname && <span>/Entity</span>}</label>
                            <p>{currentItem?.loanid}{currentItem?.entity?.[0]?.legalname && <span>/{currentItem?.entity?.[0]?.legalname}</span>}</p>
                        </div>
                        <div className="form-group mb-0">
                            {/* <label>Lender Case ID</label>
                            <p>{currentItem?.lead?.lendercaseid ? currentItem?.lead?.lendercaseid :'N/A'}</p> */}
                            <label>Borrower</label>
                            <p>
                                {currentItem?.applicant?.[0] && (
                                    <p>{borrowerfullname}</p>
                                )}
                            </p>
                        </div>
                        
                    </div>
                    <div className="col-md-2">
                    <div className="form-group">
                            {/* <label>Borrower</label>
                            <p>
                            {currentItem?.applicant?.[0] && (
                                                                        <p>{borrowerfullname}</p>
                                                                    )}</p> */}
                            <label>Lender Case ID</label>
                            <p>{currentItem?.lead?.lendercaseid ? currentItem?.lead?.lendercaseid :'N/A'}
                            
                            </p>
                        </div>
                        <div className="form-group mb-0">
                            <label>Guarantor</label>
                            <p>{currentItem?.applicant?.[0]?.guarantorstitle ? currentItem?.applicant?.[0]?.guarantorstitle :
                            <>
                            {
                            currentItem?.applicant?.[0].borrowerfirstname && currentItem.applicant?.[0].borrowerfirstname + ' '}
                                {currentItem?.applicant?.[0].borrowerlastname && currentItem.applicant?.[0].borrowerlastname}</>
                            }
                                </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div className="form-group">
                            <label>Property Address</label>
                            <p>{currentItem?.propertyinfo?.[0]?.propertyfulladdress || currentItem?.propertyinfo?.[0]?.propertyaddress}</p>
                        </div>
                        <div className="form-group mb-0">
                            <label>Borrower Phone</label>
                            <p>{currentItem?.applicant?.[0]?.borrowerphone}</p>
                        </div>
                    </div>
                
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>Loan amount</label>
                            <p>{currentItem?.loanamount ? formatCurrency(currentItem?.loanamount) : 'N/A'}</p>
                        </div>
                        {/* {currentItem?.applicant?.[0]?.isborroweranentity == 'Yes' && <div className="form-group mb-0">
                            <label>Entity</label>
                            <p>{currentItem?.applicant?.[0]?.entityname}</p>
                            
                        </div>} */}
                        <div className="form-group mb-0">
                            <label>Borrower Email</label>
                            <p>{currentItem?.applicant?.[0]?.borroweremail}</p>
                        </div>

                        
                    </div>
                    <div className="col-md-3">
                        <div className='row'>
                            <div className='col-md-6'>
                            <div className="form-group  ">
                            <label>Folder</label>
                            <p>{currentItem?.updateworkflow?.folder || 'N/A'}</p>
                        </div>
                        {currentItem?.requestor == "BROKER" && <div className="form-group mb-0">
                            <label>Broker</label>
                            <p>
                            {currentItem.broker?.[0].brokerfirstname && currentItem.broker?.[0].brokerfirstname + ' '}
                            {currentItem.broker?.[0].brokerlastname && currentItem.broker?.[0].brokerlastname}
                            </p>
                        </div>}
                            </div>
                            <div className='col-md-6'>
                            {/* <div className="form-group">
                            <label>Phase</label>
                            <p>{currentItem?.updateworkflow?.phase || 'N/A'}</p>
                        </div> */}
                        <div className="form-group mb-0">
                            <div className="text_slider">
                                <button id="changeLoanPrevious" type="button" className="sl_btn"  onClick={handlePrev}>
                                    <span className="material-symbols-outlined icon">
                                        arrow_back_ios_new
                                    </span>
                                </button>
                                <div className="sl_content">
                                    <label>Loan</label>
                                    <p> {currentIndex+1}/{totalcount}</p>
                                </div>
                                <button id="changeLoanNext" type="button" className="sl_btn"  onClick={handleNext}>
                                    <span className="material-symbols-outlined icon">
                                        arrow_forward_ios
                                    </span>
                                </button>
                            </div>
                        </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            {/* <div className="right">
            <div className="clmn mt-0">
                <button type="button" className="dsb_btn mb-0" data-bs-toggle="modal"
                                                    data-bs-target="#searchpopup" data-bs-whatever="@mdo">Search  <span
                    className="material-symbols-outlined icon"> Search </span></button>
                <button onClick={() => { navigate('/notes?loanid='+loanInfo.loanid);}} type="button" className="dsb_btn mb-0">Notes <span
                    className="material-symbols-outlined icon"> description </span></button>
                </div>
            

                <div className="clmn mr-0">
                    <button type="button" onClick={() => { navigate('/tasks');}} className="dsb_btn">Tasks <span className="material-icons icon"> checklist
                    </span></button>
                    <button type="button" onClick={() => { navigate('/needs');}} className="dsb_btn">Needs <span className="material-icons icon">
                        format_list_bulleted </span></button>
                </div>

            </div> */}
            </div>
            <div className="wizard">
            <section className="ag-card-block">
                <div className="ag-format-container">
                    <div className="ag-category-carousel_box">
                        {/* <div className="ag-carousel-arrow_box">
                            <i className="js-ag-carousel-arrow_prev ag-carousel-arrow"> <span
                                className="material-symbols-outlined svg-arrow-left"> chevron_left </span> </i>

                            <i className="js-ag-carousel-arrow_next ag-carousel-arrow"> <span
                                className="material-symbols-outlined svg-arrow-right"> chevron_right </span> </i>
                        </div> */}
                            <button id="leftWizard" className="scroll-btn left" onClick={() => {scrollContainerRef.current.scrollBy({ left: -150, behavior: 'smooth' }); }}><span className="material-symbols-outlined"> chevron_left </span></button>
                                <div ref={scrollContainerRef} className="scroll-container">
                        <ul >
                        {stages.map((stage, ind) => {
                            let defaultStatusObject = LoanStatusHistory.find(obj => obj.defaultstatus === true);
                            let currentState = LoanStatusHistory.find(obj => obj.currentstatus === stage) || null;
                            return(
                                <li key={stage} className={getItemClass(stage)} >
                                    <p className="info">
                                        { defaultStatusObject?.currentstatus == stage && defaultStatusObject?.defaultstatus && (
                                            <>
                                                {/* {currentItem?.stagedPerson?.firstname}  */}
                                                {currentState?.changedby}
                                                <span className="sub">{moment.utc(currentState?.changedon).format("MM-DD-YYYY hh:mm:ss A")}</span>
                                            </>
                                        )}
                                    </p>
                                    {stage === 'Account Executive Review' ? 
                                    (
                                    <div className='info_hover'>
                                    <p className="info">
                                    {/* {currentItem?.stagedPerson?.firstname}  */}
                                    {accountExecutiveStatus?.changedby}
                                    <span className="sub">{moment.utc(accountExecutiveStatus?.changedon).format("MM-DD-YYYY hh:mm:ss A")}</span>
                            </p>
                            </div>) : 
                            <>
                            {currentState && currentStage != stage &&(
                                <div className='info_hover'>
                                        <p className="info">
                                        {/* {currentItem?.stagedPerson?.firstname}  */}
                                        {currentState?.changedby}
                                        <span className="sub">{moment.utc(currentState?.changedon).format("MM-DD-YYYY hh:mm:ss A")}</span>
                                </p>
                                </div>)}</>
                                }
                                    
                                    <div onClick={() => handleStageClick(stage)} className={[stage !== currentStage && stages.indexOf(stage) > stages.indexOf(currentStage) && stages.indexOf(stage) <= stages.indexOf(maxReachedStage) ? "indicator indicatorred" : "indicator"]} />
                                    <h3>{stage}</h3>
                                </li>
                            )})}
                        </ul>
                                </div>
                        <button id="rightWizard" className="scroll-btn right" onClick={() => {scrollContainerRef.current.scrollBy({ left: 150, behavior: 'smooth' }); }}><span className="material-symbols-outlined"> chevron_right </span></button>
                    </div>

                </div>
                                <div className="modal new_popup fade searchpopup"
                                    id="searchpopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">                    
                                            <div className="modal-body">
                                            <button type="button" onClick={()=>{setSearchLoader(false);setSearchInputValue("");setLoans([]);}}  className="pop_close" id="close_popup" data-bs-dismiss="modal"
                                                        aria-label="Close"><span className="material-symbols-outlined icons">close</span></button>
                                            <div className="form-group"><label> Search</label>
                                            <div className='search_wrp'>
                                            <input id="loanNamePipelineStatus" ref={inputRef} placeholder='Search Loan ID, First/Last Name, or Address' onFocus={() => { setSearchErr("");setShowOptions(true)}} type="text" value={searchInputValue} className={`form-control ${searchErr.length > 0 && 'invalid-input'}`} onChange={handleSearchLoans} onKeyDown={handleKeyDown} />

                                            {searchLoader && searchInputValue.length>0 && <img src='assets/images/loader.gif' className='search_spinner' />}
                                                            {!searchLoader && searchInputValue.length>0 && <img src='assets/images/close.svg' onClick={()=>{setLoans([]);setSearchInputValue("")}} className='search_close' />}


                                            <button id="searchLoanPipelineStatus" type="button" className="fs_btn pe-none" 
                                                        ><span className="material-symbols-outlined icon pe-none">search</span></button>
                                            </div>
                                                    {showOptions && (
                                                        <div className="dropdown">
                                                            {searchInputValue.trim().length>=2 && loans.length > 0 ? loans.map((option, index) => {
                                                                let borrowerfullname = option?.entity ? option?.entity?.[0]?.legalname:option?.applicant?.[0].borrowerfirstname + ' '+option?.applicant?.[0].borrowerlastname;
                                                                let title =  option.loanid +' - '+option?.propertyinfo[0]?.propertyaddress+', '+borrowerfullname
                                                                return(<div key={index} data-bs-dismiss="modal" className={`item ${focusedIndex === index ? 'focused' : ''}`} onClick={() => { selectSearched(option);}}>
                                                                    {title}
                                                                </div>)
                                                            }) : loans?.length === 0 && searchInputValue.length >= 2 && !searchLoader && <div className='item'>No searched Data found</div> }
                                                        </div>
                                                    )}                                
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            </section>
                            
            </div></div>
        );
};

export default PipelineStatus;