import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import DataTable from 'react-data-table-component';
import Blockingtextmsg from './Blockingtext';
import { useLoanInfo } from '../utils/loanInfoContext';
import Loader from './Loader';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { formatCurrency } from '../utils/commonUtils';
import moment from 'moment';
import { getPayments,getAudit,getExtension } from '../utils/reducer';
const ServicingHistory = () => {
  const dispatch = useDispatch();
    const blockingtext = 'History cannot be accessed until servicing setup gets completed';
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const [tableKey,setTableKey] = useState(0);
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const summaryTable = [
        {
          name: 'Payment Entered Date',
          selector: (row) => row.paymentEnteredDate,
          //   sortable: true,
          minWidth: '11%',
          maxWidth: '11%',
        },
        {
          name: 'Received Date',
          selector: (row) => row.receivedDate,
          //   sortable: true,
          minWidth: '9%',
          maxWidth: '9%',
        },
        {
          name: 'Due Date',
          selector: (row) => row.dueDate,
          //   sortable: true,
          minWidth: '9%',
          maxWidth: '9%',
        },
        {
          name: 'Reference #',
          selector: (row) => row.referenceNumber,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
        },
        {
          name: 'Type',
          selector: (row) => row.type,
          //   sortable: true,
          minWidth: '6%',
          maxWidth: '6%',
        },
        {
          name: 'Total Amount Received(USD)',
          selector: (row) => row.totalAmountReceived,
          //   sortable: true,
          minWidth: '13%',
          maxWidth: '13%',
          center: true, // Right-align text
        },
        {
          name: 'Applied To Interest',
          selector: (row) => row.appliedToInterest,
          //   sortable: true,
          minWidth: '10%',
          maxWidth: '10%',
          center: true, // Right-align text
        },
        {
          name: 'Applied To Principal',
          selector: (row) => row.appliedToPrincipal,
          //   sortable: true,
          minWidth: '11%',
          maxWidth: '11%',
          center: true, // Center-align text
        },
        {
          name: 'Applied To Late Charge',
          selector: (row) => row.appliedToLateCharge,
          //   sortable: true,
          minWidth: '12%',
          maxWidth: '12%',
          center: true, // Center-align text
        },
        {
          name: 'Applied To Reserve',
          selector: (row) => row.appliedToReserve,
          //   sortable: true,
          minWidth: '11%',
          maxWidth: '11%',
          center: true, // Center-align text
        },
      ];
      const extensionhistoryTable = [
        
        {
          name: 'Previous Maturity Date',
          selector: (row) => row?.originalmaturitydate ?  moment.utc(row?.originalmaturitydate).format('MM-DD-YYYY') : '',
          //   sortable: true,
          minWidth: '25%',
          maxWidth: '25%',
          // center: true, // Center-align text
        },
        {
          name: 'New Maturity Date',
          selector: (row) => row?.newmaturitydate ?  moment.utc(row?.newmaturitydate).format('MM-DD-YYYY') : '',
          //   sortable: true,
          minWidth: '25%',
          maxWidth: '25%',
          // center: true, // Center-align text
        },
        {
          name: 'Changed On ',
          selector: (row) => row?.created ?  moment.utc(row?.created).format('MM-DD-YYYY') : '',
          //   sortable: true,
          minWidth: '25%',
          maxWidth: '25%',
          // center: true, 
        },
        {
          name: 'Changed By',
          selector: (row) => row?.createdBy?.name,
          //   sortable: true,
          minWidth: '25%',
          maxWidth: '25%',
        },
        // {
        //   name: 'Fee Description',
        //   selector: (row) => row.feeDescription,
        //   //   sortable: true,
        //   minWidth: '9%',
        //   maxWidth: '9%',
        // },
        // {
        //   name: 'Fee Amount (USD)',
        //   selector: (row) => row.feeAmount,
        //   //   sortable: true,
        //   minWidth: '6%',
        //   maxWidth: '6%',
        //   center: true, // Center-align text
        // },
        // {
        //   name: 'Fee Percent',
        //   selector: (row) => row.feePercent,
        //   //   sortable: true,
        //   minWidth: '6%',
        //   maxWidth: '6%',
        //   center: true, // Center-align text
        // },
        // {
        //   name: 'Fee Notes',
        //   selector: (row) => row.feeNotes,
        //   //   sortable: true,
        //   minWidth: '7%',
        //   maxWidth: '7%',
        // },
        // {
        //   name: 'Applied 1098 Reportable Principal',
        //   selector: (row) => row.applied1098ReportablePrincipal,
        //   //   sortable: true,
        //   minWidth: '10%',
        //   maxWidth: '10%',
        //   center: true, // Center-align text
        // },
        // {
        //   name: 'Paid Date',
        //   selector: (row) => row.paidDate,
        //   //   sortable: true,
        //   minWidth: '8%',
        //   maxWidth: '8%',
        // },
        // {
        //   name: 'Waived Date',
        //   selector: (row) => row.waivedDate,
        //   //   sortable: true,
        //   minWidth: '8%',
        //   maxWidth: '8%',
        // },
        // {
        //   name: 'Deferred Date',
        //   selector: (row) => row.deferredDate,
        //   //   sortable: true,
        //   minWidth: '7%',
        //   maxWidth: '7%',
        // },
        // {
        //   name: 'Last Edited By',
        //   selector: (row) => row.lastEditedBy,
        //   //   sortable: true,
        //   minWidth: '7%',
        //   maxWidth: '7%',
        // },
      ];
      const AuditTable = [
        // {
        //   name: 'Loan id',
        //   selector: (row) => row?.loanid,
        //   //   sortable: true,
        //   minWidth: '15%',
        //   maxWidth: '15%',
        // },
        {
          name: 'Action Date/Time',
          selector: (row) => row?.created ?  moment.utc(row?.created).format('MM-DD-YYYY / h:mm:ss A') : '',
          //   sortable: true,
          minWidth: '20%',
          maxWidth: '20%',
        },
        {
          name: 'Action Item',
          selector: (row) => row?.type ? formatType(row?.type) : '',
          //   sortable: true,
          minWidth: '20%',
          maxWidth: '20%',
        },
        {
          name: 'Action By',
          selector: (row) => row?.modifiedBy?.name,
          //   sortable: true,
          minWidth: '20%',
          maxWidth: '20%',
        },
        {
          name: 'Action Description',
          cell: (row) => (
            <table style={{ width: '100%' }}>
              <tbody>
                {row?.modifications?.map((mod, index) => (
                  <tr key={index}>
                    <td style={{width : '36%'}}>{formActionName(mod?.field)}</td>
                    <td style={{width : '32%'}}>{formatValue(mod?.field,mod?.oldvalue)}</td>
                    <td style={{width : '32%'}}>{formatValue(mod?.field,mod?.newvalue)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ),
          // selector: (row) => row?.modifications?.map(mod => formatModification(mod)).join(' '),
          //   sortable: true,
          minWidth: '40%',
          maxWidth: '40%',
          // center : true,
        },
       
      
      ];
      const historytable = [
        {
          name: 'Loan Amount (USD)',
          selector: (row) => row.loanAmount,
          //   sortable: true,
          minWidth: '6%',
          maxWidth: '6%',
        },
        {
          name: 'Interest Rate',
          selector: (row) => row.interestRate,
          //   sortable: true,
          minWidth: '4%',
          maxWidth: '4%',
          center: true,
        },
        {
          name: 'Loan Term',
          selector: (row) => row.loanTerm,
          //   sortable: true,
          minWidth: '4%',
          maxWidth: '4%',
          center: true,
        },
        {
          name: 'Start Date',
          selector: (row) => row.startDate,
          //   sortable: true,
          minWidth: '7%',
          maxWidth: '7%',
        },
        {
          name: 'Days In Year',
          selector: (row) => row.daysInYear,
          //   sortable: true,
          minWidth: '7%',
          maxWidth: '7%',
          center: true,
        },
        {
          name: 'Months Left',
          selector: (row) => row.monthsLeft,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          center: true,
        },
        {
          name: 'Charge Interest On All',
          selector: (row) => row.chargeInterestOnAll,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          center: true,
        },
        {
          name: 'Initial Interest Choice',
          selector: (row) => row.initialInterestChoice,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          center: true,
        },
        {
          name: 'Initial Interest Amount (USD)',
          selector: (row) => row.initialInterestAmount,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          right: true,
        },
        {
          name: 'First Pay Date',
          selector: (row) => row.firstPayDate,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
        },
        {
          name: 'Current Due Date',
          selector: (row) => row.currentDueDate,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          center: true,
        },
        {
          name: 'Draw Balance (USD)',
          selector: (row) => row.drawBalance,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          center: true,
        },
        {
          name: 'Updated Date',
          selector: (row) => row.updatedDate,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
        },
        {
          name: 'Updated By',
          selector: (row) => row.updatedBy,
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
        },
      ];
      // const paymentsTable = [
      //   {
      //     name: 'Received Date',
      //     selector: (row) => row.receivedDate,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      //   {
      //     name: 'Due Date',
      //     selector: (row) => row.dueDate,
      //     //   sortable: true,
      //     minWidth: '6%',
      //     maxWidth: '6%',
      //   },
      //   {
      //     name: 'Ref Number',
      //     selector: (row) => row.refNumber,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //     right: true, // Right-align text
      //   },
      //   {
      //     name: 'From Borrower',
      //     selector: (row) => row.fromBorrower,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //     center: true, // Center-align text
      //   },
      //   {
      //     name: 'From Reserves',
      //     selector: (row) => row.fromReserves,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //     center: true,
      //   },
      //   {
      //     name: 'To Reserves',
      //     selector: (row) => row.toReserves,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      //   {
      //     name: 'To Principal',
      //     selector: (row) => row.toPrincipal,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      //   {
      //     name: 'To Interest',
      //     selector: (row) => row.toInterest,
      //     //   sortable: true,
      //     minWidth: '7%',
      //     maxWidth: '7%',
      //     right: true, // Right-align text
      //   },
      //   {
      //     name: 'Late Charge (USD)',
      //     selector: (row) => row.lateCharge,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      //   {
      //     name: 'Comment User',
      //     selector: (row) => row.commentUser,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //     center: true,
      //   },
      //   {
      //     name: 'Pay Method',
      //     selector: (row) => row.payMethod,
      //     //   sortable: true,
      //     minWidth: '7%',
      //     maxWidth: '7%',
      //     center: true,
      //   },
      //   {
      //     name: 'Check/ACH Number',
      //     selector: (row) => row.checkACHNumber,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      //   {
      //     name: 'Check/ACH Date',
      //     selector: (row) => row.checkACHDate,
      //     //   sortable: true,
      //     minWidth: '8%',
      //     maxWidth: '8%',
      //   },
      // ];
      const paymentsTable = [
        {
          name: 'PAYMENT ENTERED DATE',
          selector: (row) => row?.paymentdate ?  moment.utc(row?.paymentdate).format("MM-DD-YYYY") : '',
        //   //   sortable: true,
        minWidth: '12%',
          maxWidth: '12%',
        },
        {
          name: 'DUE DATE',
          selector: (row) => row?.duedate ?  moment.utc(row?.duedate).format("MM-DD-YYYY") : '',
        //   //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
        },
        {
          name: 'PAYMENT DATE',
          selector: (row) => row?.paymentdate ?  moment.utc(row?.paymentdate).format("MM-DD-YYYY") : '',
        //   //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
        },
        {
          name: 'AMOUNT PAID',
          selector: (row) => row?.amountpaid ? formatCurrency(row?.amountpaid) : '',
        //   //   sortable: true,
        minWidth: '9%',
        maxWidth: '9%',
         
        },
        {
          name: 'PRINCIPAL AMOUNT',
          selector: (row) => row?.principalamount ? formatCurrency(row?.principalamount) : '',
        //   //   sortable: true,
        minWidth: '10%',
        maxWidth: '10%',
         
        },
        {
          name: 'INTEREST AMOUNT',
          selector: (row) => row?.interestamount ? formatCurrency(row?.interestamount) : '',
        //   //   sortable: true,
        minWidth: '10%',
        maxWidth: '10%',
         
        },
        {
            name: 'EXTENSION FEE',
            selector: (row) => row?.extensionfee ? formatCurrency(row?.extensionfee) : '',
          //   //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
           
          },
        {
          name: 'SERVICING FEE',
          selector: (row) => row?.servicingfee ? formatCurrency(row?.servicingfee) : '',
        //   //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
         
        },
        {
          name: 'LATE FEE',
          selector: (row) => row?.latefee ? formatCurrency(row?.latefee) : '',
        //   //   sortable: true,
        minWidth: '6%',
        maxWidth: '6%',
         
        },
        {
            name: 'NSF FEE',
            selector: (row) => row?.nsffee ? formatCurrency(row?.nsffee) : '',
          //   //   sortable: true,
          minWidth: '6%',
          maxWidth: '6%',
           
        },
        {
              name: 'OTHER FEE',
              selector: (row) => row?.otherfee ? formatCurrency(row?.otherfee) : '',
            //   //   sortable: true,
            minWidth: '7%',
            maxWidth: '7%',
             
        },
        {
            name: 'REFERENCE#',
            selector: (row) => row?.reference || '',
          //   //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
          },
    ];

    const formatType =(field)=>{
      if(field === 'payments'){
        return 'Payments'
      }else if(field === 'servicingsetup'){
        return 'Servicing Setup'
      }else if(field === 'serviceFee'){
        return 'Fee Management'
      }
    }
    const formatValue = (field,value) => {
      if(field ==='loanterm'){
        return value;
      }
      if(field === 'interestrate'){
        return value
      }
      if (value === "" || value === null || value === undefined) {
        return "empty";
      }
      if (typeof value === "boolean") {
        return value.toString(); 
      }
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      return formatCurrency(parseFloat(value));
    }
      if (moment(value, moment.ISO_8601, true).isValid()) {
        return moment(value).format('MM/DD/YYYY');
      }
      return value;
    };
    const formActionName = (field)=>{
      let fieldName = {
        "isLoanLocked" : "Loan Locked",
        "loanterm" : "Loan Term",
        "interestrate" : "Interest Rate",
        'cleartoclose.actualwire_funddate' : "Loan Start Date",
        'cleartoclose.scheduledfirstpaymentdate':"First Payment Due Date",
        'cleartoclose.declaredmaturitydate' : "Original Maturity Date",
        "effectiveDate" : "Effective Date",
        "feeAmount" : "Fee Amount",
        "notes" : "Notes",
        "deferred" : "Deferred",
        "interest1098reportable" : "Interest 1098 Reportable",
        "principal1098reportable" : "Principal 1098 Reportable",
        "monthlyrecurring" : "Monthly recurring",
        "quickFee" : "Quick Fee",
        "otherfeetitle" : "Other Fee Title",
        "amountfromborrower" : "Amount From Borrower",
        "extensionfee" : "Extension Fee",
        "otherfee" : "Other Fee",
        "servicingfee" : "Servicing Fee",
        "nsffee" : "Nsf Fee",
        "principalamount" : "Principal Amount",
        
      }
      let actionName = fieldName[field] || field
      return actionName
    }
    
    const paymentsGet=()=>{
      dispatch(showLoader());
      getPayments(loanid).then(function (response) {
          if (response?.result?.data?.length > 0) {
              console.log('pay getPayments',response?.result);
              setTableData(response?.result.data);
          }else{
            setTableData([]);
          }
          dispatch(hideLoader());
      })
          .catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
          })
  }
  const auditGet = () => {
    dispatch(showLoader());
    getAudit(loanid).then(function (response) {
      if (response?.result?.data?.length > 0) {
        console.log(' getAudit', response?.result);
        setTableData(response?.result?.data);
      } else {
        setTableData([]);
      }
      dispatch(hideLoader());
    })
      .catch((err) => {
        console.error.bind("my account error", err);
        dispatch(hideLoader());
      })
  }
  const extensionGet = () => {
    dispatch(showLoader());
    getExtension(loanid).then(function (response) {
      if (response?.result?.data?.length > 0) {
        console.log(' getExtension', response?.result);
        setTableData(response?.result.data);
      } else {
        setTableData([]);
      }
      dispatch(hideLoader());
    })
      .catch((err) => {
        console.error.bind("my account error", err);
        dispatch(hideLoader());
      })
  }
      
  const hanldeAuditView = ()=>{

  }
      
      const summaryData = [
        {
          paymentEnteredDate: '4th March, 2024',
          receivedDate: '4th March, 2024',
          dueDate: '4th March, 2024',
          referenceNumber: '12316544',
          type: 'Online',
          totalAmountReceived: '30,000',
          appliedToInterest: '10.02%',
          appliedToPrincipal: 'Lorem Ipsum',
          appliedToLateCharge: 'Lorem Ipsum',
          appliedToReserve: 'Lorem Ipsum',
        },
        {
          paymentEnteredDate: '4th March, 2024',
          receivedDate: '4th March, 2024',
          dueDate: '4th March, 2024',
          referenceNumber: '12316544',
          type: 'Online',
          totalAmountReceived: '30,000',
          appliedToInterest: '10.02%',
          appliedToPrincipal: 'Lorem Ipsum',
          appliedToLateCharge: 'Lorem Ipsum',
          appliedToReserve: 'Lorem Ipsum',
        },
        // Add more rows as needed
      ];
    const extensiondata = [
        {
            previousMaturityDate: '4th March, 2024',
            newMaturityDate: '4th March, 2024',
            createdDate: '4th March, 2024',
            createdBy: 'John Doe',
            feeDescription: 'Processing Fee',
            feeAmount: 30000,
            feePercent: '10.02%',
            feeNotes: 'Payment processing fee',
            applied1098ReportablePrincipal: '20,000',
            paidDate: '4th March, 2024',
            waivedDate: 'N/A',
            deferredDate: 'N/A',
            lastEditedBy: 'Jane Smith',
        },]
    const auditData = [
        {
            actiondate: '4th March, 2024',
            receivedDate: '4th March, 2024',
            actionitem: 'Servicing Data Change',
            actiondescription: 'Lorem Ipsum',
            updatetime: '4th March, 2024',
            updatedby: '	Kelly Smith'
        },

    ];
    const historydata = [
        {
            loanAmount: '30,000',
            interestRate: '0.00%',
            loanTerm: '6',
            startDate: '4th March, 2024',
            daysInYear: '360',
            monthsLeft: '6',
            chargeInterestOnAll: 'True',
            initialInterestChoice: 'Closing',
            initialInterestAmount: '30,000',
            firstPayDate: '4th March, 2024',
            currentDueDate: '4th March, 2024',
            drawBalance: '30,000',
            updatedDate: '4th March, 2024',
            updatedBy: 'Lorem Ipsum',
        },]
  //   const paymentsData = [
  //        {
  //   receivedDate: '4th March, 2024',
  //   dueDate: '4th March, 2024',
  //   refNumber: '123456',
  //   fromBorrower: 'John Doe',
  //   fromReserves: '5,000',
  //   toReserves: '2,500',
  //   toPrincipal: '10,000',
  //   toInterest: '1,500',
  //   lateCharge: '50',
  //   commentUser: 'System',
  //   payMethod: 'Online',
  //   checkACHNumber: '533565650',
  //   checkACHDate: '4th March, 2024',
  // },
  //   ];
              
    // 
    const [tableData,setTableData] = useState([]);
    const [coloumnsData,setColoumnsData] = useState(summaryTable);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage,setPerPage] = useState(10);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [ActiveTab,setActiveTab] = useState('payments');

    useEffect(() => {
        if(loanid){
          // paymentsGet();
            // initializer();
        }
      }, [loanid]);

      useEffect(() => {
        // replace data with api calls 

        if(loanid){
            setCurrentPage(1);
            setPerPage(10);
            setTableData([]);
            if(ActiveTab ==='Summary table'){
                setTableData(summaryData);
                setColoumnsData(summaryTable); 
            }else if (ActiveTab ==='extension history'){
              extensionGet();
                setColoumnsData(extensionhistoryTable); 
            }else if(ActiveTab ==='audit'){
              auditGet()
                setTableData(auditData);
                setColoumnsData(AuditTable); 
            }else if(ActiveTab==='history table'){
                setTableData(historydata);
                setColoumnsData(historytable); 
            }else if(ActiveTab==='payments'){
                paymentsGet();
                setColoumnsData(paymentsTable); 
            };
            setTableKey(prevKey => prevKey + 1);
        }
      }, [loanid]);

    const tabChange =(tab)=>{
        let tabName = tab;
        setCurrentPage(1);
        setPerPage(10);
        if(tabName ==='Summary table'){
            setTableData(summaryData);
            setColoumnsData(summaryTable); 
            
        }else if (tabName ==='extension history'){
          if(ActiveTab!== 'extension history'){
            setTableData([]);
            extensionGet()
            setColoumnsData(extensionhistoryTable); 
           };
        }else if(tabName ==='audit'){
          if(ActiveTab!== 'audit'){
            setTableData([]);
           auditGet();
           setColoumnsData(AuditTable); 
          };
        }else if(tabName==='history table'){
            setTableData(historydata);
            setColoumnsData(historytable); 
        }else if(tabName==='payments'){
          if(ActiveTab!== 'payments'){
            setTableData([]);
            paymentsGet();
            setColoumnsData(paymentsTable); 
          };
        };
        setActiveTab(tab);
        setTableKey(prevKey => prevKey + 1);
        
    }

    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
   

    return (
        <div id="layout-wrapper" className="dashboard">
           {loading ? <Loader /> : null}
                <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
                <h2 className="form_title">Servicing <span className="space">&#10095;</span>  <span className="sub">History</span> </h2>
                {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
            <Blockingtextmsg  textmessage={blockingtext}/> : 
            <div className="card user_management detailed_app">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" onClick={()=>{tabChange('payments')}} href="#LOAN" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">Payments</span>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link active" onClick={()=>{tabChange('Summary table')}} data-bs-toggle="tab" href="#ENTITY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Summary Table</span>
                                </a>
                            </li> */}
                             <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" onClick={()=>{tabChange('audit')}} href="#HMDA" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Audit History</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" onClick={()=>{tabChange('extension history')}} href="#APPLICANT" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">extension History</span>
                                </a>
                            </li>
                           
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" onClick={()=>{tabChange('history table')}} href="#PROPERTY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">History Table</span>
                                </a>
                            </li>
                             */}


                        </ul>
                        <div className="tab-pane active"  role="tabpanel">



                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{ActiveTab}</h4>
                                </div>
                                {tableData?.length > 0 ?  
                                <div className="table-responsive">
                                <DataTable className='contacts'
                                    key={tableKey}
                                    columns={coloumnsData}
                                    data={tableData}
                                    pagination
                                    customStyles={customStyles}
                                    paginationTotalRows={totalRows}
                                    // paginationServer
                                    // paginationPerPage={perPage}
                                    paginationDefaultPage={currentPage}
                                    sortServer={true}
                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Per Page',
                                    }}
                                />
                            </div> : 
<div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>
                                }
                                

                            </div>

                        <div className="tab-content pt-15 text-muted history">
                            {/* <div className="tab-pane active" id="ENTITY" role="tabpanel">



                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Summary Table</h4>
                                </div>
                                <div className="table-responsive">
                                <DataTable className='contacts'
                            // key={tableKey}
                                columns={coloumnsData}
                                data={tableData}
                                pagination
                                customStyles={customStyles}
                                paginationTotalRows={totalRows}
                                paginationServer
                                paginationPerPage={perPage}
                                paginationDefaultPage={currentPage}
                                // onChangeRowsPerPage={handlePerRowsChange}
                                // onChangePage={handlePageChange}
                                // onSort={handleSort}
                                sortServer={true}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                            />
                                </div>


                            </div> */}
                            <div className="tab-pane extension_history" id="APPLICANT" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h4 className="mb-sm-0 font-size-18">Extension History</h4> */}
                                </div>
                                <div className="table-responsive">
                                    {/* <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="8%" className="text-center">Previous Maturity Date</th>
                                                <th width="8%" className="text-center">New Maturity  Date</th>
                                                <th width="8%">Created Date</th>
                                                <th width="7%">Created  By</th>
                                                <th width="8%">Fee Description</th>
                                                <th width="6%" className="text-center">Fee Amount (USD)</th>
                                                <th width="4.5%" className="text-center">Fee Percent</th>
                                                <th width="7%">Fee Notes</th>
                                                <th width="10%" className="text-center">Applied 1098 Reportable Principal</th>
                                                <th width="8%">Paid Date</th>
                                                <th width="8%">Waived Date</th>
                                                <th width="7%">Deferred Date</th>
                                                <th width="7%">Last Edited By</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">30,000</td>
                                                <td>10.02%</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>

                                        </tbody>
                                    </table> */}
                                </div>

                            </div>
                            <div className="tab-pane extension_history" id="HMDA" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h4 className="mb-sm-0 font-size-18">Audit History</h4> */}
                                </div>
                                {/* <div className="table-responsive">
                                    <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>

                                                <th width="23%">Action Date</th>
                                                <th width="22%">Action Item</th>
                                                <th width="25%">Action Description</th>
                                                <th width="20%">Update Date/Time</th>
                                                <th width="10%">Updated By</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>Servicing Data Change</td>
                                                <td>Lorem Ipsum</td>
                                                <td>4th March, 2024 1:03:09 AM</td>
                                                <td>Kelly Smith</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div> */}


                            </div>
                            <div className="tab-pane extension_history" id="PROPERTY" role="tabpanel">

                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h4 className="mb-sm-0 font-size-18">History Table</h4> */}
                                </div>
                                <div className="table-responsive">
                                    {/* <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="6%" className="text-center">Loan Amount (USD)</th>
                                                <th width="4%" className="text-center">Interest rate</th>
                                                <th width="4%" className="text-center">Loan Term</th>
                                                <th width="7%">Start Date</th>
                                                <th width="4%" className="text-center">Days In Year</th>
                                                <th width="4%" className="text-center">Months Left</th>
                                                <th width="6.8%" className="text-center">Charge Interest On All</th>
                                                <th width="7%" className="text-center">Initial Interest Choice</th>
                                                <th width="7%" className="text-end">Initial Interest Amount (usd)</th>
                                                <th width="7%">First Pay Date</th>
                                                <th width="7%" className="text-center">Current Due Date</th>
                                                <th width="6%" className="text-center">Draw Balance (USD)</th>
                                                <th width="7%">Updated Date</th>
                                                <th width="6%">Updated By</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">30,000</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">6</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">360</td>
                                                <td className="text-center">6</td>
                                                <td className="text-center">True</td>
                                                <td className="text-center">Closing</td>
                                                <td className="text-end">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-center">4th March, 2024</td>
                                                <td className="text-center">30,000</td>
                                                <td>4th March, 2024</td>
                                                <td>Lorem Ipsum</td>
                                            </tr>

                                        </tbody>
                                    </table> */}
                                </div>

                            </div>
                            <div className="tab-pane extension_history" id="LOAN" role="tabpanel">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h4 className="mb-sm-0 font-size-18">Payments</h4> */}
                                </div>
                                {/* <div className="table-responsive">
                                    <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="6%">rECEIVED dATE</th>
                                                <th width="5%">Due Date</th>
                                                <th width="5%" className="text-end">Ref Number</th>
                                                <th width="7%" className="text-center">From Borrower</th>
                                                <th width="6%" className="text-center">From Reserves</th>
                                                <th width="5%">tO rESERVES</th>
                                                <th width="5%">To Principal</th>
                                                <th width="4%" className="text-end">To Interest</th>
                                                <th width="5%" className="text-center">Late Charge (usd)</th>
                                                <th width="3%" className="text-center">Comment User</th>
                                                <th width="0.1%" className="text-center">Pay Method</th>
                                                <th width="3%">Check/ACH Number</th>
                                                <th width="3%">Check/ach Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>
                                            <tr>
                                                <td>4th March, 2024</td>
                                                <td>4th March, 2024</td>
                                                <td className="text-end">6</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Lorem Ipsum</td>
                                                <td className="text-end">0.00%</td>
                                                <td className="text-center">30,000</td>
                                                <td className="text-center">Lorem Ipsum</td>
                                                <td>Online</td>
                                                <td>533565650</td>
                                                <td>4th March, 2024</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div> */}
                            </div>
                            {/* <div className="tab-pane property loan_audit" id="REGULATION" role="tabpanel">


                            </div> */}

                        </div>

                    </div>
                </div>
            }
                
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicingHistory;