import React, { useState ,useRef ,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import CurrencyInput from 'react-currency-input-field';
import LocationSearch from '../components/LocationsSeachBox';
import { useSelector} from 'react-redux';
import { formatNumericInput} from './../utils/commonUtils';

let formElementsJsoneasybuild = [
  {
    "type": "text",
    "label": "What was the Purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceBuild"
  },
  {
    "type": "date",

    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceBuild"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceBuild"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceBuild"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "poolNoRefinanceBuild"},
      { "value": "Yes", "label": "YES" ,"id" : "poolYesRefinanceBuild"}
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the Area of the Lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceBuild"
  },
  {
    "type": "buttonGroup",
    "label": "Did you finish building the property?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "finishedBuildingNoRefinanceBuild"},
      { "value": "Yes", "label": "YES" ,"id" : "finishedBuildingYesRefinanceBuild"}
    ],
    "name": "finishedbuilding",
    
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the New Refinance Loan to provide construction funding?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "constructionFundingNoRefinanceBuild"},
      { "value": "Yes", "label": "YES" ,"id" : "constructionFundingYesRefinanceBuild"}
    ],
    "name": "constructionfunding",
   
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the current value",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceBuild"
  },
  {
    "type": "date",
    "label": "What is the Target Closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceBuild"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "YES" ,"id" : "collateralpropertyYesRefinanceBuild"},
      { "value": "No", "label": "NO" ,"id" : "collateralpropertyNoRefinanceBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfix = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    "options": [
      { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png' , "id" : "singleRefinancingFix" },
      { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png', "id" : "duplexRefinancingFix" },
      { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexRefinancingFix" },
      { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexRefinancingFix"},
      { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsRefinancingFix"},
      { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townRefinancingFix"},
    ],
    "name": "propertytype",
   
  },
  {
    "type": "text",
    "label": "What was the Purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceFix"
  },
  {
    "type": "date",
    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceFix"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceFix"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceFix"
  },
  {
    "type": "number",
   
    "label": "Year built?",
    "name": "yearbuilt",
    "id" : "yearBuiltRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "poolNoRefinanceFix"},
      { "value": "Yes", "label": "YES" ,"id" : "poolYesRefinanceFix"}
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the Area of the Lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Did you renovate the property?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "renovatedNoRefinanceFix"},
      { "value": "Yes", "label": "YES" ,"id" : "renovatedYesRefinanceFix"}
    ],
    "name": "renovatedproperty"
  },
  {
    "type": "text",
    "label": "What was the cost of the renovation completed?",
    "placeholder": "Renovation cost",
    "name": "renovationcost",
    "id" : "renovationCostRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the new refinance loan to provide rehab funding?",
    "options": [
      { "value": "No", "label": "NO" , "id" : "rehabFundingNoRefinanceFix"},
      { "value": "Yes", "label": "YES" , "id" : "rehabFundingYesRefinanceFix"}
    ],
    "name": "refinanceloanrehabfunding" 
  },
  {
    "type": "text",
    "label": "How much is the remaining rehab budget?",
    "placeholder": "Remaining rehab budget",
    "name": "remainingrehabbudget",
    "id" : "rehabBudgetRefinanceFix"
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the current value",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceFix"
  },
  {
    "type": "date",
    "label": "What is the Target Closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceFix"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "YES" ,"id" : "collateralpropertyYesRefinanceFix"},
      { "value": "No", "label": "NO" ,"id" : "collateralpropertyNoRefinanceFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasybuildpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    "options": [
      { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png' , "id" : "singlePurchasingBuild" },
      { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png' , "id" : "duplexPurchasingBuild"},
      { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png'  , "id" : "triplexPurchasingBuild" },
      { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexPurchasingBuild"},
      { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsPurchasingBuild"},
      { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townPurchasingBuild"},
    ],
    "name": "propertytype"
  },
  {
    "type": "text",
    "label": "What was the Purchase price (LOT/PROPERTY)?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseBuild"
  },
  {
    "type": "text",
    "label": "What is the construction budget?",
    "placeholder": "Enter price",
    "name": "constructionbudget",
    "id" : "constructionBudgetPurchaseBuild"
  },
  
  {
    "type": "text",
    "label": "What is the total square footage of the new property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseBuild"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseBuild"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseBuild"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "poolNoPurchaseBuild"},
      { "value": "Yes", "label": "YES" ,"id" : "poolYesPurchaseBuild"}
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseBuild"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedPurchaseBuild"
  },
  {
    "type": "date",
    "label": "What is the Target Closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseBuild"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "YES" ,"id" : "collateralpropertyYesPurchaseBuild"},
      { "value": "No", "label": "NO" ,"id" : "collateralpropertyNoPurchaseBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfixpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    "options": [
      { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png', "id" : "singlePurchasingFix"  },
      { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png' , "id" : "duplexPurchasingFix"},
      { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexPurchasingFix"},
      { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexPurchasingFix"},
      { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsPurchasingFix"},
      { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townPurchasingFix"},
    ],
    "name": "propertytype"
  },
  {
    "type": "text",
    "label": "What was the Purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseFix"
  },
  {
    "type": "text",
    "label": "What is the rehab budget?",
    "placeholder": "Enter price",
    "name": "construction_rehab_budget",
    "id" : "rehabBudgetPurchaseFix"
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the loan to provide rehab funding?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "rehabFundNoPurchaseBuild"},
      { "value": "Yes", "label": "YES" ,"id" : "rehabFundYesPurchaseBuild"}
    ],
    "name": "refinanceloanrehabfunding"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseFix"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseFix"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseFix"
  },
  {
    "type": "number",
    "label": "Year built?",
    "name": "yearbuilt",
    "id" : "yearBuiltPurchaseFix"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "poolNoPurchaseFix"},
      { "value": "Yes", "label": "YES" ,"id" : "poolYesPurchaseFix"}
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseFix"
  },
  {
    "type": "text",
    "label": "What is the after-renovated value?",
    "placeholder": "Enter price",
    "name": "renovationarv",
    "id" : "renovationArvPurchaseFix"
  },
  {
    "type": "date",
    "label": "What is the Target Closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseFix"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "YES" ,"id" : "collateralpropertyYesPurchaseFix"},
      { "value": "No", "label": "NO" ,"id" : "collateralpropertyNoPurchaseFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]




let collateralJSON = [
  {
    "type": "buttonGroup",
    "label": "Is there a current loan on the property?",
    "options": [
      { "value": "Yes", "label": "YES" ,"id" : "collateralCurrentLoanYes"},
      { "value": "No", "label": "NO" ,"id" : "collateralCurrentLoanNo"},
      
    ],
    "name": "currentloan"
  },
  {
    "type": "text",
    "label": "What is the Existing Loan Amount?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "collateralExistingLoan"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Proposed lien position?",
    "name" : "proposedlienposition",
    "id" : "collatrealProposedLienPosition",
    "options" : [
      { "value": "", "label": "Select the Proposed Lien Position" },
      { "value": "1st Position", "label": "1st Position" },
      { "value": "2nd Position", "label": "2nd Position" },
    ]
  },
  {
    "type": "text",
    "label": "What is the total square footage of the new property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "collateralPropertyArea"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "collateralTotalBedrooms"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "collateralTotalBathrooms"
  },
  {
    "type": "number",
    "label": "Year built?",
    "name": "collateralyearbuilt",
    "id" : "collateralYearBuilt"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "No", "label": "NO" ,"id" : "collateralpoolNo"},
      { "value": "Yes", "label": "YES" ,"id" : "collateralPoolYes"}
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Enter the square footage of the lot",
    "name": "lotarea",
    "id" : "collateralLotArea"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "collateralOccupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "text",
    "label": "What is the Estimated Value?",
    "placeholder": "0.00",
    "name": "estimatedvalue",
    "id" : "collateralEstimatedValue"
  },
  {
    "type": "address",
    "label": "Collateral Property Home Address",
    "placeholder": "Enter address",
    "name": "propertyPlace",
    "id" : "collateralCoborrowerAddress"
  },
  
]


const RefinancingBuild = () => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [dealInfo, setDealInfo] = useState(loanInfo?.dealinfo ? loanInfo?.dealinfo : {});
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const [formJSON, setformjson] = useState((loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuild : (loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY FIX') ? formElementsJsoneasyfix : (loanInfo?.propertyinfo?.loanfor == "Purchase" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuildpurchase :formElementsJsoneasyfixpurchase);
    const dateInputRef = useRef(null);
    const [showCollateral, setShowCollateral] = useState(false);
    const [collateralData, setCollateralData] = useState(loanInfo?.collateralproperty || {});
    const [collateralFlag, setCollateralFlag] = useState(loanInfo?.collateralFlag || false);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });
  
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    let floatKeys  = [
      'purchaseprice',
      'existingloan',
      'currentvalue',
      'completedvalue',
      'renovationcost',
      'remainingrehabbudget',
      'constructionbudget',
      'construction_rehab_budget',
      'renovationarv'
    ]

    const handleCurrencyInputs = (name,value)=>{
        
        console.log(value)
        floatKeys.forEach(key => {
          if(key === name){
            let updatedValue = isNaN(value) ? '' : value;
            setDealInfo((prevData) => {
              // Create a copy of the previous data
              let updatedData = { ...prevData };
              // Update the copied data with the new key-value pair
              updatedData = {
                ...updatedData,
                [key]: updatedValue,
              };
          
              console.log('valuevaluevalue',loanInfo);
              return updatedData;
            }); 
          }
        })
        
      
    }
    
    const handleInputChange = (e) => {
        e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
       const allowedKeys = ['purchaseprice', 'existingloan', 'completedvalue', 'currentvalue', 'renovationcost', 'remainingrehabbudget','construction_rehab_budget','constructionbudget', 'renovationarv'];
     
      //  let numberTypeRegex = /^[1-9][0-9]*$/
      //  if (allowedKeys.includes(key) && value !== '') {
      //   // Remove non-numeric characters and prepend '$'
      //   let numericValue = value.replace(/[^0-9]/g, '');
      //   let formattedValue = `$${Number(numericValue).toLocaleString()}`;
  
      //   let finalformatVal = (formattedValue === "$0") ? '' : formattedValue;
      //   value = finalformatVal;
      //  }
      const numberTypeRegex = /^[0-9]*\.?[0-9]*$/;
      if (allowedKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        // if(value.startsWith('$')){
        //   let replace = value.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (numberTypeRegex.test(replace)) && (value !== "0")? '$' + formatPrice(replace) : '';
        // }else{
        //   value = (numberTypeRegex.test(value)) && (value !== "0")? '$' + formatPrice(value) : '';
        // }
        let formattedAmount = formatPrice(value);
        value = formattedAmount;
        // if(formattedAmount.startsWith('$')){
        //   let replace = formattedAmount.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (formattedAmount !== "0" )? '$' + replace : '';
        // }else{
        //   value = (formattedAmount !== "0")? '$' + formattedAmount : '';
        // }
      }

       const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       const numRegex = /^(?!.*(0{2,}|[^0]\b0\b))\d*$/;
       if (allowedNumberInputKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
       console.log('valuevalue',value);

          //date validation
          const allowedDateInputKeys = ['purchasedate','constructioncompleteddate','yearbuilt'];
          // const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
          const yearRegex = /^[0-9]{4}$/;
          const currentYear = new Date().getFullYear(); 
          // console.log(currentYear); // Output: 2022
          if(key === "constructioncompleteddate" && allowedDateInputKeys.includes(key) && value !== ''){
            // console.log("date ",value);
              // let yearSplit = value.split("-");
              // console.log("date",value.split("-"));
              // console.log(yearRegex.test(yearSplit[0]));
              // let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] < currentYear ? '' : value;
              // value = dateValue;
              // Check if year and month values are less than or equal to 4 digits
              // Extract year from the input date, ignoring leading zeros
              
              const [year,month,day] = value.split('-');
                // console.log("split date",year?.length);
              if (year?.length > 4) {
                // console.log("Year or month value exceeds 4 digits.");
                value = '';
              }
              
                 
              

              value = value;
          }else if(key === "purchasedate" && allowedDateInputKeys.includes(key) && value !== ''){
            let yearSplit = value.split("-");
            // console.log("date",value.split("-"));
            console.log(yearRegex.test(yearSplit[0]));
            let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] > currentYear ? '' : value;
            value = dateValue;
          }else if(key === "yearbuilt" && allowedDateInputKeys.includes(key) && value !== ''){
              // const isValidYear = /^\d{4}$/.test(value);
              if (value > currentYear) {
                value = '';
              } else {
                value = value;
              }
            
            
          }

          //type numbervalidation
          // const allowedNumberTypeInputs = ['totalbathrooms','totalbedrooms'];
          // const singleDigitRegex = /^[0-9]$/;
          // if(allowedNumberTypeInputs.includes(key) && value !== ''){
          //     let numVal = !(singleDigitRegex.test(value) === true) ? '' : value;
          //     value = numVal;
          // }


       if (parseFloat(value) < 0) {
        // If it's negative, set the input value to an empty string or handle it as needed
        value = '';
      }
        setDealInfo((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            [key]: value,
          };
      
          console.log('valuevaluevalue',loanInfo);
          return updatedData;
        });
    };

    // const handleButtonChange = (key, value) => {

      
    //     // Update the formData with the new key-value pair
    //     setDealInfo((prevData) => {
    //       // Create a copy of the previous data
    //       let updatedData = { ...prevData };
    //       // Update the copied data with the new key-value pair
    //       updatedData = {
    //         ...updatedData,
    //         [key]: value,
    //       };
    //       console.log('updatedDatahere',updatedData);
    //       setLoanInfo((prevDealInfo) => ({
    //         ...prevDealInfo,
    //         dealinfo: updatedData,
    //       }));
      
    //       return updatedData;
    //     });

        

         
        
    //};

    const handleButtonChange = (key, value) => {
      setDealInfo((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        updatedData = {
          ...updatedData,
          [key]: value,
        };
        setLoanInfo((prevDealInfo) => ({
          ...prevDealInfo,
          dealinfo: updatedData,
          iscollateralflag:collateralFlag,
          collateralproperty : collateralData
        }));
        return updatedData;
      });
  };
    
    //dropdown inputs
    const handleDropdownChange = (e) =>{

      e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
      setDealInfo((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        // Update the copied data with the new key-value pair
        updatedData = {
          ...updatedData,
          [key]: value,
        };
    
        console.log('valuevaluevalue',loanInfo);
        return updatedData;
      });
    }

    const handleDealData = ()=>{
      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        dealinfo: dealInfo,
        iscollateralflag:collateralFlag,
        collateralproperty : collateralData
      }));
    }

   

    const validateInputs = () => {
        const requiredInputs = document.querySelectorAll('.select_box input');
        const buttonGroups = document.querySelectorAll('.btn-group');
        const chooseBoxes = document.querySelectorAll('.rf_boxs');
        const chooseDropdowns = document.querySelectorAll('.select_box select');
        const searchWrpElement = document.querySelector(".search_wrp");
      
        let isValid = true;
      
        // Function to remove error styles for an input
        const removeErrorStyles = (input) => {
          input.classList.remove('invalid-input');
        };
      
        // Function to remove error styles for a button group
        const removeGroupErrorStyles = (group) => {
          group.classList.remove('invalid-group');
        };

          // Function to remove error styles for a choose option boxes group
          const removeBoxErrorStyles = (box) => {
            box.classList.remove('invalid-box');
          };
      
        // Add event listeners to remove error styles on input focus/change
        requiredInputs.forEach((input) => {
          input.addEventListener('input', () => removeErrorStyles(input));
        });
      
        // Add event listeners to remove error styles on button click
        buttonGroups.forEach((group) => {
          const buttons = group.querySelectorAll('.select-btn');
          buttons.forEach((button) => {
            button.addEventListener('click', () => removeGroupErrorStyles(group));
          });
        });

          // Add event listeners to remove error styles on box click
          chooseBoxes.forEach((rfbox) => {
            const boxes = rfbox.querySelectorAll('.rf_boxs');
            boxes.forEach((bx) => {
              bx.addEventListener('click', () => removeBoxErrorStyles(rfbox));
            });
        });

        // Add event listeners to remove error styles on dropdown
        chooseDropdowns.forEach((selectDropdown) => {
          
          selectDropdown.addEventListener('change', () => removeErrorStyles(selectDropdown));
        });
      
        // Validate inputs and button groups
        const allowedKeys = ['constructioncompleteddate','purchaseprice', 'existingloan', 'completedvalue', 
        'currentvalue', 'renovationcost', 'remainingrehabbudget' , 'purchasedate' ,'collateralyearbuilt' ,'yearbuilt'];
        // const dateInputs = ['constructioncompleteddate'];

        for (const input of requiredInputs) {
          const inputValue = input.value.trim();

          if (input?.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && !inputValue) {
            // Handle empty input case
            input.classList.add('invalid-input');
            isValid = false;
          } else if (input.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && allowedKeys.includes(input.name) && inputValue === '$0') {
            // Handle the case where the value is $0 for allowed keys
            input.classList.add('invalid-input');
            isValid = false;
          }else if(input?.name === "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value < currentDate)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }else if(input?.name === "purchasedate" && input?.name !== "constructioncompleteddate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value > currentDate)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }else if((input?.name === "collateralyearbuilt" || input?.name === "yearbuilt")&& (!input?.value || inputValue.length < 4)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }
        
        
         
        }

       
      
        for (const group of buttonGroups) {
          const buttons = group.querySelectorAll('.cp_btn');
          const selectedButtons = Array.from(buttons).filter(btn => btn.style.background !== '');
      
          if (selectedButtons.length === 0) {
            group.classList.add('invalid-group');
            isValid = false;
          }
        }

          for (const box of chooseBoxes) {
            const boxes = box.querySelectorAll('.box');
            const selectedBoxes = Array.from(boxes).filter(bx => bx.style.background !== '');
        
            if (selectedBoxes.length === 0) {
              box.classList.add('invalid-box');
            isValid = false;
          }
        }

        for (const selectDropdown of chooseDropdowns) {
          if (!selectDropdown.value) {
            selectDropdown.classList.add('invalid-input');
            isValid = false;
          }
        }

       
        if (!collateralData["propertyaddress"] || selectedPlace?.trim() === "") {
         
          const searchWrpElement = document.querySelector(".search_wrp");
      
          if (searchWrpElement) {
            isValid = false;
            searchWrpElement.classList.add("invalid-input");
          }
        }
        
        return isValid;
      };
    const submitDealInfo = () => {
      const isValid = validateInputs()
      
      console.log("dealInfohere", isValid, loanInfo);
      console.log('collateral data',collateralData);
      if (isValid) {
        // setLoanInfo((prevDealInfo) => ({
        //   ...prevDealInfo,
        //   dealinfo: dealInfo,
        // }));
        setLoanInfo((prevDealInfo) => ({
          ...prevDealInfo,
          dealinfo: dealInfo,
          iscollateralflag:collateralFlag,
          collateralproperty : collateralData
        }));
        navigate('/StrategyInfo');
        console.log("valuevaluevalue", loanInfo);
      }
    };
    const getTodayDate = () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };

    // Function to format the price with commas
    const formatPrice = (value) => {
      const valueRep = value.replace(/\D/g, '');
      // return valueRep.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Format the number with commas
      // Format the number with commas and add a dollar sign
      const formattedValue = `$${Number(valueRep).toLocaleString()}`;

      return formattedValue;
    };


    /* collateral functionality */

    const handleCollateralButton = (key, value ,id) => {

      const buttonId = id; // Construct button ID if needed
      removeError(buttonId); // Remove the error class from the button group
      
      const newValue = (value === 'Yes');
  
      // Clear collateralData if the specific condition is met
      if (key === 'collateraladditionalproperty') {
        if (value === 'No') {
            // Clear all entries except collateraladditionalproperty, set its value to 'No'
            setCollateralData(prevCollateralData => ({
                collateraladditionalproperty: 'No'
            }));
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        } else {
            // Update collateraladditionalproperty with the new value
            const updatedCollateralData = { ...collateralData, [key]: value };
            setCollateralData(updatedCollateralData);
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        }
        setShowCollateral(newValue);
        setCollateralFlag(newValue);
      } else {
          // Update collateralData with the new value for other keys
          const updatedCollateralData = { ...collateralData, [key]: value };
          setCollateralData(updatedCollateralData);
      }
  
      removeError(key);
  
      // Update loanInfo if needed with the new collateral data
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: collateralData // Make sure to merge correctly
      // }));
    };
  

    const handleCollateralDropdown = (e)=> {
      const {name , value} = e.target;
      // Update the collateralData with the new value
      const updatedCollateralData = { ...collateralData, [name]: value };
  
      setCollateralData(updatedCollateralData); // Update state with new value
  
      // Now pass the updated object to setLoanInfo
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: updatedCollateralData // Make sure to merge correctly
      // }));
      
      
    }

   
    const handleCollateralChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      //const { name, value } = e.target;
      
      // Log to confirm input handling
      console.log(`Changed ${name}: ${value}`);

      if(name == "collateralyearbuilt"){
        const currentYear = new Date().getFullYear();
        if(value  > currentYear){
            value = '';
        }
      }

      const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       if (allowedNumberInputKeys.includes(name) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
      
      const updatedCollateralData = { ...collateralData, [name]: value };
    
      setCollateralData(updatedCollateralData); // Update state
      
      // Update the loan information state accordingly
      // setLoanInfo((prevLoanInfo) => ({
      //   ...prevLoanInfo,
      //   collateralproperty: updatedCollateralData 
      // }));
    };
    
    const handleCollateralCurrencyInputs = (name,value)=>{
        
      console.log(value)
      //floatKeys.forEach(key => {
        //if(key === name){
          let updatedValue = isNaN(value) ? '' : value;
          // setDealInfo((prevData) => {
          //   // Create a copy of the previous data
          //   let updatedData = { ...prevData };
          //   // Update the copied data with the new key-value pair
          //   updatedData = {
          //     ...updatedData,
          //     [key]: updatedValue,
          //   };
        
          //   console.log('valuevaluevalue',loanInfo);
          //   return updatedData;
          // }); 
          const updatedCollateralData = { ...collateralData, [name]: updatedValue };
    
          setCollateralData(updatedCollateralData);
          
        //}
      //})
      
    
  }

  const removeError = (buttonId) => {
    // Find the button element using the buttonId
    const button = document.getElementById(buttonId);

    if (button) {
        // Find the parent .btn-group container
        const group = button.closest('.btn-group');

        if (group) {
            group.classList.remove('invalid-group');
        } else {
            console.warn('Button group not found for buttonId:', buttonId);
        }
    } else {
        console.warn('Button not found for buttonId:', buttonId);
    }
};


  //collateral address
  useEffect(() => {

    if (collateralData["propertyaddress"] || selectedPlace?.trim() !== "") {
        const searchWrpElement = document.querySelector(".search_wrp");
        if (searchWrpElement) {
          searchWrpElement.classList.remove("invalid-input");
        }
    }

    if (selectedPlace) {
      setCollateralData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        // Update the copied data with the new key-value pair
        let selectedAddress = {
          'propertyaddress': addressData?.address?.trim() || addressData?.fullAddress,
          'propertystate': addressData?.state,
          'propertyzipcode': addressData?.zipcode,
          'propertygeolocation': addressData?.geolocation,
          'propertygooglemaplink': addressData?.url,
          'propertyareaaddress': addressData?.area,
          'propertycity': addressData?.city,
          'propertyfulladdress': addressData?.fullAddress,
          'propertystateshort': addressData?.stateShortName,
          'propertycounty': addressData?.county,
        };
        updatedData = {
          ...updatedData,
          ...selectedAddress,
        };
  
        return updatedData;
      });
    }

  }, [selectedPlace]);
  


    return (
      

                <div className="content_blk">
                    <div className="hed">
                        <a href="#" onClick={(e) => {e.preventDefault(); navigate('/loanProgram');handleDealData()}} className="back material-icons"> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">{loanInfo?.propertyinfo
                            ?.programtype?.split(' ')[0]} <span  className="sub">{loanInfo?.propertyinfo
                                ?.programtype?.split(' ').slice(1).join(' ')}</span></span> Deal Info
                        </h2>
                    </div>
                    <div className="cont reb">
                      {formJSON.map((element, index) => (
                        <div key={index} className='di_section'>
                          {element.type === 'buttonGroup' ? (
                            <><h3 className="feild_title">{element.label}</h3>
                            <div className="btn-group">
                              {element.options.map((option) => (

                                
                                <button
                                  key={option.value}
                                  type="button"
                                  className="cp_btn"
                                  // style={
                                  //   dealInfo[element.name] === option.value
                                  //     ? { background: 'rgba(149, 201, 113, 0.20)' }
                                  //     : {}
                                  // }
                                  style={
                                    
                                    {background: (dealInfo[element.name] === option.value || collateralData[element.name] == option.value) ?'rgba(149, 201, 113, 0.20)' : '' }
                                      
                                  }
                                  onClick={() => (element.name === 'collateraladditionalproperty') ? handleCollateralButton(element.name, option.value , option.id) : handleButtonChange(element.name, option.value)}
                                  id = {option.id}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div></>
                          ): element.type === "chooseOption" ? (
                            <><h3 className="feild_title">{element.label}</h3>
                          <div className="rf_boxs">
                            {element.options.map((option) => (
                              <div className="box"
                                key={option.value}
                                type="button"
                                style={
                                  dealInfo[element.name] === option.value
                                    ? { background: 'rgba(149, 201, 113, 0.20)' }
                                    : {}
                                }
                                onClick={() => handleButtonChange(element.name, option.value)}
                                id={option.id}
                              >
                              <img src={option.img} className="icon" alt="" ></img>
                                <h3>  {option.label}</h3>
                              </div>
                            ))}
                          </div> </>
                        ): element.type === "dropdown" ? (
                          <><h3 className="feild_title">{element.label}</h3>
                        <div className='select_box'>
                          <select id={element.id} name={element.name} value={dealInfo[element.name] || ''} type={element.type} className="form-control" onChange={handleDropdownChange}>
                            {element.options.map((option) => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                          </select>
                          
                        </div> </>
                      ): (
                          ((element.name === "remainingrehabbudget" && dealInfo?.refinanceloanrehabfunding != 'Yes') || (element.name === "renovationcost" && dealInfo?.renovatedproperty != 'Yes')) ? ""
                          : <>
                            <div className='select_box'>
                              <h3 className="feild_title">{element.label}</h3>
                              {floatKeys.includes(element.name) ? 
                                <CurrencyInput
                                    id={element.id}
                                    name={element.name}
                                    //   defaultValue={1000}
                                    placeholder="$0.00"
                                    decimalsLimit={2}
                                    className="form-control"
                                    prefix="$"
                                    groupSeparator=","
                                    value={dealInfo[element.name] || ''}
                                    onValueChange={(value, name, values) => {
                                      //console.log(values)
                                      handleCurrencyInputs(name,value);
                                    }}
                                /> :
                                <input
                                  id={element.id}
                                  type={element.type}
                                  placeholder={element.placeholder}
                                  className="form-control"
                                  name={element.name}
                                  min={element.name === 'constructioncompleteddate' ? currentDate : ''} // Set minimum date allowed
                                  onChange={handleInputChange}
                                  value={dealInfo[element.name] || ''}
                                
                                  {...(
                                    (element.type === 'date' && element.name === 'constructioncompleteddate') ? { min: getTodayDate() } :
                                    (element.type === 'date' && element.name === 'purchasedate') ? { max: getTodayDate() } :
                                    {}
                                  )}
                                />
                              }
                              </div>
                            </>
                          )}
                        </div>
                      ))}


                      {/* collateral inputs section*/}

                      {
                        (collateralData?.collateraladditionalproperty && collateralData?.collateraladditionalproperty === 'Yes') 
                           && (
                          collateralJSON.map((collateralKey,index) => (
                            <div key={index} className='di_section'>
                              {collateralKey.type === 'buttonGroup' ? (
                                <>
                                  <h3 className="feild_title">{collateralKey.label}</h3>
                                  <div className="btn-group">
                                    {collateralKey.options.map((option) => (
                                      <button
                                        name={collateralKey.name}
                                        key={option.value}
                                        type="button"
                                        className="cp_btn"
                                        style={{
                                          background: collateralData[collateralKey.name] === option.value ? 'rgba(149, 201, 113, 0.20)' : ''
                                        }}
                                        onClick={() => handleCollateralButton(collateralKey.name, option.value,option.id)}
                                        id={option.id}
                                        
                                      >
                                        {option.label}
                                      </button>
                                    ))}
                                  </div>
                                </>
                              ) : collateralKey.type === "dropdown" ? (
                                <>
                                  <h3 className="feild_title">{collateralKey.label}</h3>
                                  <div className='select_box'>
                                    <select
                                      id={collateralKey.id}
                                      name={collateralKey.name}
                                      value={collateralData[collateralKey.name]}
                                      className="form-control"
                                      onChange={handleCollateralDropdown}
                                    >
                                      {collateralKey.options.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                      ))}
                                    </select>
                                  </div>
                                </>
                              ) : collateralKey.type === "text" ? 
                                ((collateralKey.name === 'existingloan' && collateralData['currentloan'] && collateralData['currentloan'] == 'Yes') ?
                                    <>
                                        <h3 className="feild_title">{collateralKey.label}</h3>
                                        <div className='select_box'>
                                          <CurrencyInput
                                            id={collateralKey.id}
                                            name={collateralKey.name}
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={collateralData[collateralKey.name] || ''}
                                            onValueChange={(value, name, values) => {
                                              //console.log(values)
                                              handleCollateralCurrencyInputs(name,value);
                                            }}
                                          /> 
                                        </div>
                                    </>
                                    
                                  :
                                  (collateralKey.name === 'estimatedvalue') ?
                                    <>
                                        <h3 className="feild_title">{collateralKey.label}</h3>
                                        <div className='select_box'>
                                          <CurrencyInput
                                            id={collateralKey.id}
                                            name={collateralKey.name}
                                            //   defaultValue={1000}
                                            placeholder="$0.00"
                                            decimalsLimit={2}
                                            className="form-control"
                                            prefix="$"
                                            groupSeparator=","
                                            value={collateralData[collateralKey.name] || ''}
                                            onValueChange={(value, name, values) => {
                                              //console.log(values)
                                              handleCollateralCurrencyInputs(name,value);
                                            }}
                                          /> 
                                        </div>
                                    </>
                                    
                                  :
                                  (collateralKey.name !== 'existingloan' && collateralKey.name !== 'estimatedvalue') ?
                                  <>
                                  
                                    <h3 className="feild_title">{collateralKey.label}</h3>
                                    <div className='select_box'>
                                      
                                        <input
                                          id={collateralKey.id}
                                          type={collateralKey.type}
                                          placeholder={collateralKey.placeholder}
                                          className="form-control"
                                          name={collateralKey.name}
                                          
                                          onChange={handleCollateralChange}
                                          value={collateralData[collateralKey.name] || ''}
                                        
                                        
                                        />
                                      
                                        
                                    </div>
                                  </> 
                                  : null
                              ) : collateralKey.type === "number" ? (
                                <>
                                  <h3 className="feild_title">{collateralKey.label}</h3>
                                  <div className='select_box'>
                                      <input
                                        id={collateralKey.id}
                                        type={collateralKey.type}
                                        placeholder={collateralKey.placeholder}
                                        className="form-control"
                                        name={collateralKey.name}
                                        
                                        onChange={handleCollateralChange}
                                        value={collateralData[collateralKey.name] || ''}
                                      
                                      
                                      />
                                  </div>
                                </>
                              ):(collateralKey?.type === 'address') ?
                                <>
                                     <div className="col-md-12" key={index}> 
                                      <div className="search_block">
                                          <h3 className="feild_title">{collateralKey.label}</h3>
                                          <div className="search_wrp">
                                            <span className="material-icons-outlined">
                                              location_on
                                            </span>
                                            {mapsKey && 
                                            <LocationSearch name={collateralKey.name} setAddressData={setAddressData} 
                                                addressData={addressData} 
                                                setSelectedPlace={setSelectedPlace} 
                                                selectedPlace={collateralData.propertyfulladdress || selectedPlace} 
                                                
                                            />}
                                            {/* <button className="search_btn"> */}
                                              <span className="material-symbols-outlined">
                                                search
                                              </span>
                                            {/* </button> */}
                                          </div>
                                      </div>
                                    </div>
                                </>
                               
                              : null}
                            </div>
                          ))
                      )}

                       {/* End collateral inputs section*/}


                      <div className="text-center mt-10">
                        <button id="dealInfoNext" type="button" onClick={submitDealInfo} className="cp_btn mt-10">
                          Next
                        </button>
                      </div>
                    </div>
                    
                </div>
                
                
    );
};

export default RefinancingBuild;