import React , { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import LocationSearch from '../LocationsSeachBox';
import { useSelector} from 'react-redux';

const BorrowerInfo2 = (props) => {
   const navigate = useNavigate();
   const [selectedPlace, setSelectedPlace] = useState(null);
    const {currentPage,setCurrentPage} = props;
    const {borrowerInfoOneData,setBorrowerInfoOneData} = props;
    const configdata = useSelector(state => state.configdata);
  const mapsKey = configdata?.common?.googleMapsKey;
    // console.log("data",borrowerInfoOneData)
    const [address,setAddress] = useState("");
    const [addressData,setAddressData] = useState({});
    const handleSearch = (value) => {
        setAddress(value);
        setBorrowerInfoOneData({...borrowerInfoOneData,['address']:value});
        setCurrentPage(currentPage + 1);
        // console.log("complete data",props.data);
    }

  useEffect(() => {
    console.log('selectedPlacenew',selectedPlace);
    if(selectedPlace){
        handleSearch(selectedPlace)
    }
  }, [selectedPlace]);

    console.log("width"+props.setColor);
    return (
                <div className="content_blk">
                    <div className="hed">
                        {/* <a href="#" className="back material-icons" onClick={(e) => { navigate(-1); }}> keyboard_backspace </a> */}
                        <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); props.onPrevious();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">EASY <span className="sub"> BUILD</span></span> Borrower Info
                        </h2>
                    </div>
                    <div className="cont pi3 bi">
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Name
                            <strong> : {borrowerInfoOneData.firstName}  {borrowerInfoOneData.lastName}</strong> </span>
                    </div>
                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Email
                            <strong> : {borrowerInfoOneData.emailid}</strong> </span>
                    </div>

                    <div className="seleted_item">
                        <span className="material-icons-outlined"> done </span> <span className="text">My Phone
                            <strong> : {borrowerInfoOneData.phone}</strong> </span> 
                            <a href="#" className="back_btn" onClick={(e) => {e.preventDefault(); props.onPrevious();}}>
                               <span className="material-icons-outlined">
                                    shortcut
                                </span>Change
                            </a>
                        </div>
                    
                    <h3 className="feild_title">Residing Address</h3>
                    <div className="search_block">
                        <div className="search_wrp">
                            <span className="material-icons-outlined">
                                location_on
                            </span>
                            {mapsKey && 
                            
                            
                            <LocationSearch setSelectedPlace={setSelectedPlace} setAddressData={setAddressData} selectedPlace={borrowerInfoOneData.borrowerfulladdress || selectedPlace}/>
                             } {/* <input type="text" placeholder="Search City and Zip Code" className="form-control" /> */}
                            {/* <button className="search_btn" onClick={(e) => {navigate('/BorrowerInfo3');}}> */}
                            {/* <button className="search_btn" onClick={handleBorrowerInfoTwo}>
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            </button> */}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default BorrowerInfo2;