import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import {getFormTemplates,searchTemplate, deleteFormTemplate} from "../utils/reducer";
import SweetAlert from 'react-bootstrap-sweetalert';   
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Pagination from "react-js-pagination";
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import DataTable from 'react-data-table-component';
import { debounce } from 'lodash'; // Import debounce from lodash

const FormTemplates = () => {
      const navigate = useNavigate();
      const [selectedOption, setSelectedOption] = useState({});
      const [FormTemplates, setFormTemplates] = useState([]);
      const [showConfirmation,setShowConfirmation] = useState(false);
      const dispatch = useDispatch();
      const [isPopupOpen, setPopupOpen] = useState(false);
      const loading = useSelector((state) => state.loaderVisible); 
      const [searchTerm, setSearchTerm] = useState('');
      // const [searchReference,setSearchReference] = useState('');
      const [searchError,setSearchError] = useState(false);
      const [perPage, setPerPage] = useState(15);
      const [currentPage, setCurrentPage] = useState(1);
      const [totalCount,setTotalCount] = useState(null);
      const [filteredTemplates,setFilteredTemplates] = useState([])


      // useEffect(() => {
      //   getForms()
      // }, []);

      const getForms = (currentpage,perpage,searchVal) => {
        dispatch(showLoader());
        getFormTemplates(currentpage,perpage,searchVal).then(function (response) {
            if (response?.result) {
                console.log("form temp",response?.result);
               setFormTemplates(response?.result?.data);
               setFilteredTemplates(response?.result?.data);
               setTotalCount(response?.result?.totalCount);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
      };

      const getAllFormsDataDebounced = debounce((currentPage, perPage,searchVal) => {
        getForms(currentPage, perPage,searchVal);
      }, 500); // Adjust the delay (in ms) as needed

      useEffect(() => {
        let pageNum = (searchTerm) ? 0 : currentPage;
        let searchValue = (searchTerm) ? searchTerm : '';
        getAllFormsDataDebounced(pageNum, perPage, searchValue);
        // Cleanup function to cancel debounce on unmount or re-render
        return () => {
          getAllFormsDataDebounced.cancel();
        };
      }, [currentPage, perPage, searchTerm]);
  

      const searchTemplatesApi = (searchTerm)=>{
        dispatch(showLoader());
        searchTemplate(searchTerm).then(function (response) {
            if (response?.result) {
                console.log("form temp",response?.result);
               setFormTemplates(response?.result?.data);
               setTotalCount(response?.result?.totalCount);
              //  setSearchReference(searchTerm);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
      }
      // const handleSearch =()=>{
      //   if(searchTerm?.trim().length === 0){
      //     setSearchError(true);
      //     return;
      //   }
      //   setPerPage(15);
      //   setCurrentPage(1)
      //   searchTemplatesApi(searchTerm);
      // };
      const handleClearSearch = ()=>{
        setSearchError(false);
        setSearchTerm('');
        // setSearchReference('');
        setCurrentPage(1);
        setPerPage(15)
        getForms(1,15)
      }
      // useEffect(()=>{
      //   if(searchReference){
      //     searchTemplatesApi(currentPage,perPage,searchReference);
      //   }else{
      //     getForms(currentPage,perPage)
      //   }
      // },[currentPage,perPage]);

      const editForm = (form) => {
        navigate('/createformtemplates',{ state: { formTemplate:form } });
     }
     const deleteForm = () => {
        closePopup()
        dispatch(showLoader());
        deleteFormTemplate(selectedOption?.templateid).then(function (response) {
            if (response?.result) {
                dispatch(hideLoader());
                // if(searchReference){
                //   searchTemplatesApi(currentPage,perPage,searchReference);
                // }else{
                  getForms(currentPage,perPage);
                  setSearchTerm('')
                // }
                setPopupOpen(true);
                searchError(false);
            }
            }).catch((err) => {
                dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
     }
     const closePopup = () => {
        setPopupOpen(false)
        setShowConfirmation(false)
     }

     const handleSearchInputChange = (e) => {
      const searchTerm = e.target.value;
      setSearchTerm(searchTerm);
      if (!searchTerm?.trim()) {
          setFilteredTemplates(FormTemplates);
      } else {
          const searchFilter = FormTemplates?.filter((template) =>
              template?.name?.toLowerCase().includes(searchTerm?.trim()?.toLowerCase())
          );
          setFilteredTemplates(searchFilter);
      }
     }

     const handlePerPageChange = (newperPage,page) => {
      //setSearchTerm('');
      setPerPage(newperPage);
      setCurrentPage(page); // Reset to first page

    };

    const handlePageChange = (page) => {
      //setSearchTerm('');
      setCurrentPage(page);
     
    };

    const customStyles = {
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };

    const columns = [
        
        {
          name: 'FORM NAME',
          selector: (row) => row?.name,
          sortable: true,
          sortFunction: (a, b) => {
            const nameA = a?.name?.toLowerCase();
            const nameB = b?.name?.toLowerCase();
            return nameA.localeCompare(nameB);
        },
          minWidth: '50%',
          maxWidth: '50%',
        },
   
        {
            name: 'DOC TAG',
            selector: (row) => row?.docTag,
          //   sortable: true,
          //   sortFunction: (a, b) => {
          //     const tagA = a?.docTag?.toLowerCase();
          //     const tagB = b?.docTag?.toLowerCase();
          //     return tagA.localeCompare(tagB);
          // },
            minWidth: '40%',
            maxWidth: '40%',
        },
      
        {
            name: 'ACTIONS',
            cell: (row,index) => (
            <>
            
                 <button type="button" id={`editFormTemplate-${index}`} onClick={(e) => { editForm(row); }} className="icon_btn edit">
                             <span className="material-icons icon tooltip-container"><span className="tooltip">Edit</span>edit</span>
                </button>
                <button type="button" id={`deleteFormTemplate-${index}`} className="icon_btn edit" onClick={(e) => { setShowConfirmation(true); setSelectedOption(row); }}>
                    <span className="material-icons icon tooltip-container"><span className="tooltip">Delete</span>delete</span>
                </button>
          
            </>
            ),
            minWidth: '10%',
            maxWidth: '10%',
          },
      ];

      const clearSearch = () => {
        setSearchTerm(''); // Clear the search input
        setFilteredTemplates(FormTemplates);
        //setCurrentPage(1); // Optionally reset pagination
      };
    
     
    // const handlePerPageChange = (newperPage,page) => {
    //     setPerPage(newperPage);
    //     setCurrentPage(page);
    //   };

      // const handlePageChange = (page) => {
      //   setCurrentPage(page);
      // };
    return (
        <div id="layout-wrapper">

{loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={'success'}
             message={'Deleted Successfully'}
             onClose={closePopup}
            />
        )}
           <MainHeader />

            {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={deleteForm}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-formManager-yes',
                      className: 'btn btn-primary',
                      onClick: deleteForm,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-formManager-no',
                      className: 'btn btn-light',
                      onClick: closePopup,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this template?</p>
                {/* Additional content can go here */}
            </SweetAlert>}

            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Form Templates</h4>
                      
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className='search'>
                                        <button id="createFormTemplates" type="button"  onClick={(e) => {navigate('/createformtemplates');}}  className="create_btn">Create Form Template <span className="material-symbols-outlined icon"> add </span></button>
                                        <div className="right">
                                            <div className='form-group search_block'>
                                                <input id="searchFormTemplateText" type="text" className={`form-control ${searchError && 'invalid-input'}`} onFocus={()=>{setSearchError(false)}}  placeholder='Search' onChange = {handleSearchInputChange} value={searchTerm}/>
                                                {searchTerm && (
                                                    <button
                                                        id="clearSearchBtn"
                                                        className="material-symbols-outlined btn"
                                                        onClick={clearSearch}
                                                        style={{
                                                            marginLeft: '-30px', // Adjust the positioning as needed
                                                            background: 'transparent',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            color : '#000'
                                                        }}
                                                    >
                                                        close
                                                    </button>
                                                  )}
                                                <button  id="searchFormTemplates" className="material-symbols-outlined btn">search</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {searchReference && 
                                    <div className='search_result'>
                                    <h4>Search results</h4>
                                    <button type="button" className="border_btn" onClick={handleClearSearch}>Clear search </button>
                                </div>} */}
                                    <div className="table-responsive">
                                      {FormTemplates?.length > 0 && 
                                       <DataTable className='termsheetmanager_table'
                                       columns={columns}
                                       data={FormTemplates}
                                       pagination
                                       paginationServer
                                       paginationTotalRows={totalCount}
                                       customStyles={customStyles}
                                       paginationPerPage={perPage}
                                       paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                       paginationComponentOptions={{
                                           rowsPerPageText: 'Per Page',
                                       }}
                                       onChangePage={handlePageChange}
                                      onChangeRowsPerPage={handlePerPageChange}
                                   />
                                  //     <DataTable className='termsheetmanager_table'
                                  //     columns={columns}
                                  //     data={FormTemplates}
                                  //     pagination
                                  //     paginationServer
                                  //     paginationTotalRows={totalcount}
                                  //     customStyles={customStyles}
                                  //     paginationPerPage={'15'}
                                      
                                  //     paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                  //     paginationComponentOptions={{
                                  //       rowsPerPageText: 'Per Page',
                                  //     }}
                                      
                                  // />
                                  }
                                    
                                       
                                            
                                        {FormTemplates?.length ===0 && searchTerm ?  <> <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Templates found </p></div></> : ''}
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default FormTemplates;