import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { getallContacts, addContact, getLoanDetails, checkContacts, getActiveContacts, getContactLoans } from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PipelineStatus from './PipelineStatus';
import PopupMessage from './PopupMessage';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLoanInfo } from '../utils/loanInfoContext.js';
import { useLocation } from "react-router-dom";

const DealContacts = () => {
    const location = useLocation();
    const isDealContacts = location.pathname.includes("dealcontacts");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [contacts, setContacts] = useState([]);
    const [copyContact, setcopyContact] = useState([]);
    const [contactBtn, setContactBtn] = useState('add');
    const [showContactForm, setShowContactForm] = useState(false);
    const [contact, setContact] = useState({});
    const [errors, setErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [selectedContact, setSelectedContact] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterTerm, setFilterTerm] = useState('');
    const [statusChangeConfirmation, setStatusChangeConfirmation] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [SearchError, setSearchError] = useState(false);
    const [brokerBtn, setBrokerbtn] = useState(false);
    const [trusteeBtn, setTrusteeBtn] = useState(false);
    const [statusConfirmationMsg, setStatusConfirmationMsg] = useState('');
    const [showBrokerConfirmation, setShowBrokerConfirmation] = useState(false);
    const [checkContactStatus, setContactStatus] = useState('');
    const [existingBroker, setExistingBroker] = useState({});
    const [isContactChanged, setIsContactChanged] = useState(false);
    const [searchTract, setSearchTrack] = useState('');
    const [sortObj, setSortObj] = useState({});
    const isSorting = useRef(false);
    const [tableKey, setTableKey] = useState(0);
    const [assignedContacts, setAssignedContacts] = useState([]);
    const [contactloansList, setcontactloansList] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const options = configdata?.admin?.contactTypes || [];
    const initializer = () => {
        setShowBrokerConfirmation(false); setExistingBroker({}); setIsContactChanged(false);
        setContactStatus(''); setSortObj(); setSearchTrack('');
        setContactBtn('add'); setShowContactForm(false); setContact({}); setErrors({});
        setPopupOpen(false); setmessage(''); setDeleteConfirmation(false); setSearchError(false); setBrokerbtn(false); setTrusteeBtn(false)
        setSelectedContact(''); setSearchTerm(''); setFilterTerm(''); setStatusChangeConfirmation(false); setPage(1); setPerPage(10); setStatusConfirmationMsg('')
        setTableKey(prevKey => prevKey + 1);
    }

    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [loanid, setLoanid] = useState(loanInfo?.loanid);

    useEffect(() => {
        if (loanid) {
            let type = '';
            let search = '';
            let payload = {};
            setPage(1);
            setPerPage(10);
            fetchContacts(1, 10, type, search, payload);
            loancontacts();
            initializer();
        }
    }, [loanid]);
    useEffect(() => {
        console.log("loanInfo", loanInfo?.broker?.[0]?.brokeremail);
        let brokeremail = loanInfo?.broker?.[0]?.brokeremail
        setLoanid(loanInfo?.loanid);
        if (!loanInfo?.isbroker && brokeremail && !loanInfo?.activebroker) {
            console.log('rannnnn');
            checkContactsApi(brokeremail);
        }
    }, [loanInfo?.loanid]);
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(contact) !== JSON.stringify(copyContact);
        setIsContactChanged(isFormChanged);
        console.log(isFormChanged);
    }, [contact, copyContact]);

    const checkContactsApi = (email) => {
        checkContacts(email).then(function (response) {
            if (response?.result) {
                console.log(response?.result);
                if (response?.result === 'contact not exists') {
                    setContactStatus("new");
                    setShowBrokerConfirmation(true);
                } else if (response?.result) {
                    setContactStatus("old");
                    setShowBrokerConfirmation(true);
                    setExistingBroker(response?.result?.[0]);
                }
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
    }

    const fetchContacts = (pagenumber, perPage, typeValue, search, payload) => {
        let sortBy = payload?.sortBy || '';
        let sortType = payload?.sortType || '';
        dispatch(showLoader());
        let type = typeValue
        getallContacts(pagenumber, perPage, type, search, sortBy, sortType).then(function (response) {
            if (response?.result) {
                let result = response?.result?.data
                setContacts(result);
                // setcopyContacts(result);
                setTotalRows(response?.result?.totalCount);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };
    const loancontacts = () => {
        dispatch(showLoader());
        getActiveContacts(1, 10, loanid).then(function (response) {
            if (response?.result) {
                const activeContacts = response.result.data.filter(contact =>
                    contact?.loans?.some(loan => loan?.loanid === loanid && loan?.status === 'ACTIVE')
                );
                setAssignedContacts(activeContacts);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const contactloans = (contactid) => {
        dispatch(showLoader());
        getContactLoans(contactid).then(function (response) {
            if (response?.result) {
                // console.log(response, "cccloan")
                setcontactloansList(response?.result);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const editContact = (contact) => {
        setContact({ ...contact });
        setcopyContact({ ...contact });
        setErrors({});
        setContactBtn('update');
        setShowContactForm(!showContactForm);
        setSearchError(false);
        let loanStatus = contact?.loans?.some(loan => loan?.loanid === loanid && loan.status === 'ACTIVE');
        console.log(loanStatus, contact, loanid, contact.loans);
        setBrokerbtn(loanStatus && contact?.contact_type === 'Broker' ? true : false);
        setTrusteeBtn(loanStatus && contact?.contact_type === 'Trustee' ? true : false);

        contactloans(contact.contactid)
    };
    const handleCreate = () => {
        setShowContactForm(!showContactForm);
        setContact({});
        setErrors({});
        setContactBtn('add');
        setBrokerbtn(false);
        setTrusteeBtn(false);
    };
    const handleBack = () => {
        setContactBtn('add');
        setContact({});
        setErrors({});
        setShowContactForm(false);
        setSearchError(false);
        setBrokerbtn(false);
        setTrusteeBtn(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'contact_type') {
            let loanStatus = contact?.loans?.some(loan => loan.status === 'ACTIVE');
            if (loanStatus) {
                setPopupOpen(true);
                setmessage(`Contact is assigned to loans as Active ${contact?.contact_type}`);
                return;
            }
        }
        if (name === 'appraisal' || name === 'brokerstatus' || name === 'trusteestatus') {
            setContact((prev) => ({
                ...prev,
                [name]: e.target.checked,
            }));
            if (name === 'brokerstatus') {
                setBrokerbtn(e.target.checked);
            }
            if (name === 'trusteestatus') {
                setTrusteeBtn(e.target.checked);
            }
        }
        else {
            setContact((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        let phonetest = phoneRegex.test(phoneNumber);
        return phonetest;
      };
    const handleAddContact = () => {
        console.log('yesssss')
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        const fieldsToCheck = {
            'firstname': 'firstname',
            'lastname': 'lastname',
            // 'company': 'company',
            'email': 'email',
            //  'contact_type':'contact_type',
            //   'companynmlsid': 'companynmlsid', 'companylicense': 'companylicense',
            // 'mobile': 'mobile',
            //   'workphone': 'workphone', 
            // 'faxnumber': 'faxnumber', 'licensenumber': 'licensenumber', 'nmlsid': 'nmlsid', 'contacttitle': 'contacttitle', 'address': 'address' 
        };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!contact[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
        };
        if (contact.email) {
            if (!emailRegex.test(contact.email)) {
                error.email = 'Email Structure Error';
            }
        }
        if (!contact.mobile?.trim() && !contact.workphone?.trim()) {
            error.mobile = "Please provide either Mobile or Workphone";
            error.workphone = "Please provide either Mobile or Workphone";
        }
        if(contact.mobile){
            if(!isValidPhoneNumber(contact.mobile)){
                error['mobile'] = 'Please Enter Number'
            }
        }
        if(contact.workphone){
            if(!isValidPhoneNumber(contact.workphone)){
                error['workphone'] = 'Please Enter Number'
            }
        }
        console.log('errorrr', error)
        setErrors(error);
        if (Object.keys(error).length === 0) {
            //ready to api call
            let apiData = { ...contact, status: "ACTIVE" };
            if (contact?.contact_type !== 'Broker') {
                delete apiData['brokerstatus']
            }
            if (contact?.contact_type !== 'Trustee') {
                delete apiData['trusteestatus']
            }
            if (apiData?.contactid) {
                setmessage('Contact Updated Successfully');
            }
            addContactApiCall(apiData, loanid);
        }
    };

    const AddNewBrokerContact = () => {
        let apiData = {}
        if (checkContactStatus === 'new') {
            apiData = {
                'firstname': loanInfo?.broker?.[0]?.brokerfirstname ?? '',
                'lastname': loanInfo?.broker?.[0]?.brokerlastname ?? '',
                'email': loanInfo?.broker?.[0]?.brokeremail ?? '',
                'status': "ACTIVE",
                'brokerstatus': true,
                'mobile': loanInfo?.broker?.[0]?.brokerphonenumber ?? '',
                'contact_type': 'Broker',
            }
        } else if (checkContactStatus === 'old') {
            apiData = {
                ...existingBroker,
                'brokerstatus': true,
            }
        }
        setShowBrokerConfirmation(false);
        addContactApiCall(apiData, loanid);
    }
    let addContactApiCall = (apiData, loanid) => {
        if (apiData?.loans) {
            delete apiData['loans'];
        }
        dispatch(showLoader());
        addContact(apiData, loanid).then(function (response) {
            dispatch(hideLoader());
            if ((response?.result === 'contact added successfully')) {
                setPopupOpen(true);
                setmessage('Contact Added Successfully');
            }
            if (response?.result === 'Contact Email already exists') {
                setPopupOpen(true);
                setmessage(response?.result)
            } else if(response?.result === 'contact modified successfully') {
                setmessage('Contact Modified Successfully');
                setPopupOpen(true);
            }else{
                setPopupOpen(true);
                setmessage(response?.result)
            }

            setSearchTerm('');
            setSearchTrack('');
            setFilterTerm('');
            setLoandetails();
        }).catch((err) => {
            dispatch(hideLoader());
            setmessage(` ${err.message} `)
            console.error.bind("my account error", err);
        })
    }
    const setLoandetails = () => {
        getLoanDetails(loanid)
            .then(response => {
                console.log('getLoanDetails', response);
                if (response?.result[0]) {
                    setLoanInfo(response?.result[0]);
                    console.log(response);
                }
            })
            .catch(err => {
                console.error('my account error', err);
            });
    }

    const handleClose = () => {
        setShowBrokerConfirmation(false);
    }

    const columns = [
        {
            name: 'NAME',
            selector: (row) => `${row?.firstname} ${row?.lastname}`,
            sortable: true,
            width: '16%',
        },
        {
            name: 'ADDRESS',
            selector: (row) => row?.address || '',
            sortable: true,
            width: '16.7%',
        },
        {
            name: 'EMAIL ID',
            selector: (row) => row?.email || '',
            sortable: true,
            width: '18%',
        }, 
        {
            name: 'PHONE NUMBER',
            selector: (row) => row?.mobile || '',
            sortable: true,
            width: '10%',
        },
        {
            name: 'TYPE',
            selector: (row) => row?.contact_type || '',
            sortable: true,
            width: '8%',
        },
        {
            name: 'COMPANY NAME',
            selector: (row) => row?.company || '',
            sortable: true,
            width: '10%',
        },
        {
            name: 'COMPANY LICENSE ID',
            selector: (row) => row?.companylicense || '',
            sortable: true,
            width: '12%',
        },
       
        {
            name: 'ACTIONS',
            cell: (row, index) => {
                let brokerExist = row?.loans?.some(loan => loan?.loanid === loanid && loan?.status === 'ACTIVE');

                return (
                    <div className="btn-group" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        {(row?.contact_type === 'Broker' && !brokerExist) && (
                            <button
                                id={`addContacts-${index}`}
                                type="button"
                                onClick={(e) => handleBrokerActiveClick(row)}
                                className="btn btn-outline-secondary tooltip-container"
                            >
                                {(row?.contact_type === 'Broker' && !brokerExist) && (<span className="material-symbols-outlined icon">add</span>)}
                            </button>
                        )}
                        {((row?.contact_type === 'Trustee' || row?.contact_type === 'Escrow Company' || row?.contact_type === 'Insurance Company' || row?.contact_type === 'Title Company') && !brokerExist) && (
                            <button
                                id={`addContacts-${index}`}
                                type="button"
                                onClick={(e) => handleTrusteeActiveClick(row, row?.contact_type)}
                                className="btn btn-outline-secondary tooltip-container"
                            >
                                <span className="material-symbols-outlined icon">add</span>
                            </button>
                        )}




                        <button
                            id={`editContacts-${index}`}
                            type="button"
                            onClick={(e) => editContact(row)}
                            className="btn btn-outline-secondary tooltip-container"
                        >
                            <span className="tooltip">edit</span>
                            <span className="material-symbols-outlined icon">edit</span>
                        </button>
                        <button
                            id={`deleteContacts-${index}`}
                            type="button"
                            onClick={(e) => handleDeleteClick(row)}
                            className="btn btn-outline-secondary tooltip-container"
                        >
                            <span className="tooltip">delete</span>
                            <span className="material-symbols-outlined icon">delete</span>
                        </button>
                    </div>
                );
            },
            width: '9.4%',
            center: true,
        },

    ];
    const handleSort = (column, sortDirection) => {
        isSorting.current = true;
        console.log('sort funcitonnnn', column, sortDirection)
        let name = column?.name;
        let direction = sortDirection === "asc" ? "ascending" : "descending";
        const nameMappings = {
            "NAME": 'name',
            "COMPANY NAME": 'company',
            "TYPE": 'contact_type',
            "COMPANY LICENSE ID": 'companylicense',
            "PHONE NUMBER": 'mobile',
            "EMAIL ID": 'email',
            "ADDRESS": 'address'
        };

        name = nameMappings[name] || name;

        let payload = { sortType: direction, sortBy: name };
        setSortObj({ ...payload });
        let search = '';
        if (searchTract) {
            search = searchTract || '';
        }
        let type = filterTerm || '';
        setPage(1);
        // setPerPage(10);
        fetchContacts(1, perPage, type, search, payload);
        setTimeout(() => isSorting.current = false, 500);
    };

    const handleSearch = (e) => {
        let type = '';
        setSearchTerm(e.target.value);

    };
    const handleSearchClick = () => {
        setPage(1);
        setPerPage(10);
        if (!searchTerm?.trim()) {
            setSearchError(true);
            return;
        } else {
            setSearchError(false);
        }
        let type = '';
        let payload = {};
        setSortObj({});
        setSearchTrack(searchTerm);
        setFilterTerm('');
        fetchContacts(1, 10, type, searchTerm, payload);
        setTableKey(prevKey => prevKey + 1);
    }
    const conditionalRowStyles = [
        {
            when: row => row?.loans?.some(loan => loan?.loanid === loanid && loan?.status === 'ACTIVE'),
            style: {
                backgroundColor: '#E9F0E6',
            },
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const handleDeleteClick = (contactData) => {
        let loanStatus = contactData?.loans?.some(loan => loan.status === 'ACTIVE');
        if (loanStatus) {
            setPopupOpen(true);
            setmessage(`Contact is Assigned to Loans as Active ${contactData?.contact_type}`);
            return;
        }
        setSelectedContact(contactData);
        setDeleteConfirmation(true)
    }
    const handleDelete = () => {
        setDeleteConfirmation(false)
        setSearchTerm('');
        setSearchTrack('');
        let apiData = { ...selectedContact, status: "ARCHIVE" };
        setmessage(`Contact Deleted Successfully`);
        addContactApiCall(apiData, loanid)
    }
    const handleStatusActive = () => {
        setStatusChangeConfirmation(false);
        let apiData = selectedContact;
        addContactApiCall(apiData, loanid);
    }

    const handleTypeFilter = (e) => {
        let search = '';
        setSortObj({});
        let payload = {}
        setPage(1);
        setPerPage(10);
        fetchContacts(1, 10, e.target.value, search, payload);
        setTableKey(prevKey => prevKey + 1);
        setFilterTerm(e.target.value);
        setSearchTerm('');
        setSearchTrack('')
    }
    const handleBrokerActiveClick = (contactData) => {
        setStatusConfirmationMsg('Broker');
        setSelectedContact({ ...contactData, brokerstatus: true });
        setStatusChangeConfirmation(true);
        setmessage('Contact is Assigned as Active Broker Successfully')
    }
    const handleTrusteeActiveClick = (contactData, contactType) => {
        setStatusConfirmationMsg(contactType);
        if(contactType === 'Trustee'){
            setSelectedContact({ ...contactData, trusteestatus: true });
        }else if(contactType === 'Escrow Company'){
            setSelectedContact({ ...contactData, escrowcompanystatus: true });
        }else if(contactType === 'Insurance Company'){
            setSelectedContact({ ...contactData, insurancecompanystatus: true });
        }else if(contactType === 'Title Company'){
            setSelectedContact({ ...contactData, titlecompanystatus: true });
        }
        setStatusChangeConfirmation(true);
        setmessage('Contact is Assigned as Active ' + contactType + ' Successfully');
    }
    const closePopup3 = () => {
        setStatusChangeConfirmation(false);
    }
    const handleClearSearch = () => {
        setSearchTrack('');
        setSearchTerm('');
        let type = '';
        let search = '';
        let payload = {};
        setSortObj({});
        setPage(1);
        setPerPage(10);
        fetchContacts(1, 10, type, search, payload);
        setTableKey(prevKey => prevKey + 1);
    }
    const closePopup = () => {
        if (popupMessage.toLowerCase().includes('successfully')) {
            setShowContactForm(false);
            let type = '';
            let search = '';
            let payload = {};
            setSortObj({});
            setPage(1);
            setPerPage(10);
            fetchContacts(1, 10, type, search, payload);
            loancontacts();
            setTableKey(prevKey => prevKey + 1);
        }
        setPopupOpen(false);
    };
    const closePopup2 = () => {
        setDeleteConfirmation(false)
    }

    // const options = ["Account Executive","Advisor","Applicants Attorney","Appraisal Company","Appraisal Management Company","Attorney","Bank Attorney","Bank Info","Borrower Attorney","Broker","Builder","Buyer","Buyer Real Estate Agent","BWCAgent","Closing","Closing Company","Condo Project Name","Correspondent Lender","CPA Tax Preparer","Custodian","Draw Inspector","Escrow","Escrow Company","FHA Consultant","Hazard Payee","HOA","Homeowners Insurance Company","Homeowners Insurance Contact","Initial Inspector","Inspector User","Institution","Insurance Company","Investor","Landlord Company","Lender","Let Me In","Listing Real Estate","Listing Real Estate Agent","Loan Servicer","Loan Title Holder","Loss Payee","Notary","Pest Control","Pledgor","Post-Servicing Purchaser","Processor","Property Manager","Referring Partner","Select Contact Type","Seller","Seller 2","Seller Attorney","Selling Real Estate Agent","Servicing","Settlement","Settlement Agent","Settlement Company","Short Sale Buyer","Sponsor","Surveyor","Tax - City","Tax - County","Tax - School","Third Party Inspector","Third Party Negotiator","Third Party Originator TPO","Title","Title Company","Trustee","Warehouse","Wholesale/DBO Partners"];

    // const options = ["Appraisal Management Company", "Broker", "Contractor", "Custodian", "Escrow Company", "Hazard Insurance Contact", "Insurance Company", "Liability Insurance Contact", "Loan Servicer",  "Title Company", "Trustee"]

    // const options = ["Broker","Title Company","Escrow Company","Insurance Company","Trustee"]
    const handlePageChange = (page) => {
        if (!isSorting.current) {
            console.log('rannnn');
            let type = '';
            let search = '';
            let payload = { ...sortObj };
            if (searchTerm) {
                fetchContacts(page, perPage, type, searchTerm, payload);
            } else if (filterTerm) {
                fetchContacts(page, perPage, filterTerm, search, payload);
            } else {
                fetchContacts(page, perPage, type, search, payload);
            }
            setPage(page);
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        if (!isSorting.current) {
            console.log('i ran tooo')
            let type = '';
            let search = '';
            let payload = { ...sortObj };
            if (searchTerm) {
                fetchContacts(page, newPerPage, type, searchTerm, payload);
            } else if (filterTerm) {
                fetchContacts(page, newPerPage, filterTerm, search, payload);
            } else {
                fetchContacts(page, newPerPage, type, search, payload);
            }
            setPage(page);
            setPerPage(newPerPage);
        }
    };

    const handleLoanIdClick = async (e, loanid) => {
        // console.log("loanid",loanid)
        // navigate("/dashboard?loanid="+loanid)
        window.open("/dashboard?loanid="+loanid, '_blank', 'noopener,noreferrer');
    }

    const fields = [
        { label: "First Name", name: "firstname", type: "text", placeholder: "Enter First Name", className: "capitalize-text" },
        { label: "Last Name", name: "lastname", type: "text", placeholder: "Enter Last Name", className: "capitalize-text" },
        { label: "Company Name", name: "company", type: "text", placeholder: "Enter Company Name", className: "capitalize-text" },
        { label: "Select Type", name: "contact_type", type: "select", options: options },
        { label: "Company NMLS ID", name: "companynmlsid", type: "text", placeholder: "Enter Company NMLS ID" },
        { label: "Company License ID", name: "companylicense", type: "text", placeholder: "Enter Company License ID" },
        { label: "Address", name: "address", type: "text", placeholder: "Enter Address" },
        { label: "Email", name: "email", type: "text", placeholder: "Enter Email", errorMsg: 'Invalid Email Format' },
        { label: "Home Phone", name: "mobile", type: "text", placeholder: "(xxx) xxx-xxxx", format: formatPhoneNumber },
        { label: "Work Phone", name: "workphone", type: "text", placeholder: "(xxx) xxx-xxxx", format: formatPhoneNumber },
        { label: "Fax Number", name: "faxnumber", type: "text", placeholder: "(xxx) xxx-xxxx", format: formatPhoneNumber },
        { label: "License Number", name: "licensenumber", type: "text", placeholder: "Enter License Number" },
        { label: "NMLS ID Enter", name: "nmlsid", type: "text", placeholder: "Enter NMLS ID Enter" },
        { label: "Contact Title", name: "contacttitle", type: "text", placeholder: "Enter Contact Title", className: "capitalize-text" },
    ];

    const columns2 = [
        {
            name: 'NAME',
            selector: (row) => `${row?.firstname} ${row?.lastname}`,
            width: '18%',
        },
        , {
            name: 'ADDRESS',
            selector: (row) => row?.address || '',
            width: '22%',
        },
        {
            name: 'EMAIL ID',
            selector: (row) => row?.email || '',
            width: '18%',
        },
        {
            name: 'PHONE NUMBER',
            selector: (row) => row?.mobile || '',
            width: '14%',
        },
       
        {
            name: 'TYPE',
            selector: (row) => row?.contact_type || '',
            width: '12%',
        },
        {
            name: 'COMPANY LICENSE ID',
            selector: (row) => row?.companylicense || '',
            width: '16%',
        },

    ];


    const contactloanTable = [
        
        {
            name: 'LOAN ID',
            cell: (row) => <div onClick={(e) => handleLoanIdClick(e, row?.loanid)} className='loanID'>{row?.loanid}</div>,
            selector: (row) => `${row?.loanid}`,
            width: '19%',
        },
        {
            name: 'BORROWER NAME',
            selector: (row) => `${row?.applicant?.[0].borrowerfirstname} ${row?.applicant?.[0].borrowerlastname}`,
            width: '20%',
        },
        ,{
            name: 'REQUESTOR',
            selector: (row) => row?.requestor || '',
            width: '16%',
        },
        {
            name: 'LOAN FOR',
            selector: (row) => row?.loanfor || '',
            width: '12%',
        },
        {
            name: 'PROGRAM TYPE',
            selector: (row) => row?.programtype || '',
            width: '13%',
        },
        {
            name: 'LOAN AMOUNT',
            selector: (row) => row?.loanamount ? formatCurrency(row?.loanamount) : '',
            width: '13%',
        },
        {
            name: 'ACTIONS',
            cell: (row, index) => {
                

                return (
                    <div className="btn-group">
                      
                        <button
                            id={`viewLoan-${index}`}
                            type="button"
                            onClick={(e) =>  handleLoanIdClick(e, row?.loanid)}
                            className="btn btn-outline-secondary tooltip-container"
                        >
                            <span className="tooltip">view</span>
                            <span className="material-symbols-outlined icon">visibility</span>
                        </button>
                        
                    </div>
                );
            },
            width: '7%',
        },
    ];
    const handleFocus = (fieldName) => {
        if(fieldName === 'workphone' || fieldName === 'mobile'){
            setErrors((prevErrors) => ({ ...prevErrors, 
                mobile: null,
                workphone: null,
            }));
        }else{
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }));
        }
    };
    

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage
                    type={popupMessage.toLowerCase().includes('successfully') ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            )}
            {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-contact-yes',
                        className: 'btn btn-primary',
                        onClick: handleDelete,
                        style: { marginRight: '8px', fontSize: '16px' }
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-contact-no',
                        className: 'btn btn-light',
                        onClick: closePopup2,
                        style: { fontSize: '16px' }
                    }, 'No')
                ]}
            >
                <p>Do you really want to delete this Contact</p>
            </SweetAlert>}
            {statusChangeConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes"
                cancelButtonText="No!"
                title="Are you sure?"
                onConfirm={handleStatusActive}
                onCancel={closePopup3}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-status-yes',
                        className: 'btn btn-primary',
                        onClick: handleStatusActive,
                        style: { marginRight: '8px', fontSize: '16px' }
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-status-no',
                        className: 'btn btn-light',
                        onClick: closePopup3,
                        style: { fontSize: '16px' }
                    }, 'No')
                ]}
            >
                <p>Assign this Contact as Active {statusConfirmationMsg}</p>
            </SweetAlert>}
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">{isDealContacts ? 'Deal' : 'Servicing'} <span className="space">&#10095;</span>  <span className="sub"> Contacts</span> </h2>

                <div className="card user_management detailed_app">


                    <div className="card-body my_account">

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a id="dealInfoRatePoints" className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">LOAN CONTACTS</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="dealInfoLockHistory" className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">ALL CONTACTS</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content pt-15 text-muted">

                            <div className="tab-pane active" id="ENTITY" role="tabpanel">
                                <div className='search start mt-10 justify-content-start align-items-center'>
                                    {/* <h3 className="title mb-0 me-4">CONTACTS ASSIGNED TO THIS LOAN</h3> */}
                                    <div className="perr_diem mt-0">
                                        <p>To assign a contact to this loan, Go to All Contacts and click on the '+ plus(+)" icon button</p>
                                    </div>
                                </div>
                                {assignedContacts?.length > 0 ?
                                    <DataTable className='contacts'
                                        columns={columns2}
                                        data={assignedContacts}
                                        //   pagination
                                        customStyles={customStyles}
                                    //   paginationPerPage={5}
                                    //   paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                    //   paginationComponentOptions={{
                                    //       rowsPerPageText: 'Per Page',
                                    //   }}
                                    />
                                    : <div className="inprogress_page"> <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Contacts Found</p></div>}
                            </div>


                            <div className="tab-pane" id="APPLICANT" role="tabpanel">

                                {!showContactForm && <>

                                    <div className='search start mt-10 justify-content-start align-items-center'>
                                        {/*<h3 className="title mb-0 me-4">Contacts</h3>
                                         <div className='contact_right'>
                           <div className="active_broker mr_50">
                                   <label>Active Trustee</label>
                                   <p>{loanInfo?.activetrustee || 'N/A'}</p>
                               </div>
                           <div className="active_broker">
                                   <label>Active Broker</label>
                                   <p>{loanInfo?.activebroker || 'N/A'}</p>
                               </div>
                           </div> */}
                                        

                                    </div>
                                    <div className='search'>
                                        <button id="addNewContact" type="button" className="create_btn" onClick={(e) => { handleCreate(); }}>Add New Contact</button>
                                        <div className="perr_diem mt-0">
                                            <p>Click on '+ plus(+)" icon to assign a contact to this loan</p>
                                        </div>
                                        <div className="right">
                                        
                                            <div className="form-group fbt">
                                                <label>Filter By Type</label>
                                                <select id="selectContacts" value={filterTerm} name='filtercontact' onChange={(e) => handleTypeFilter(e)} className={`form-control`}  >
                                                    <option value="" selected>Select</option>
                                                    {options?.map(option => (<option key={option} value={option}>{option}</option>))}
                                                </select>
                                            </div>
                                            <div className='form-group search_block'>
                                                <input id="prefillsSearchText" type="text" className={`form-control ${SearchError && 'invalid-input'}`} onFocus={(e) => { setSearchError(false) }} placeholder='Search Name' value={searchTerm} onChange={handleSearch} />
                                                <button id="prefillsSearch" className="material-symbols-outlined btn" onClick={handleSearchClick}>search</button>
                                            </div>

                                        </div>
                                    </div>
                                    {searchTract &&
                                        <div className='search_result'>
                                            <h4>Search results</h4>
                                            <button type="button" className="border_btn" onClick={handleClearSearch}>Clear search </button>
                                        </div>
                                    }

                                    {contacts?.length > 0 && <DataTable className='contacts'
                                        key={tableKey}
                                        columns={columns}
                                        data={contacts}
                                        pagination
                                        customStyles={customStyles}
                                        paginationTotalRows={totalRows}
                                        paginationServer
                                        paginationPerPage={perPage}
                                        paginationDefaultPage={page}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        conditionalRowStyles={conditionalRowStyles}
                                        onChangePage={handlePageChange}
                                        onSort={handleSort}
                                        sortServer={true}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Per Page',
                                        }}
                                    />}</>}
                                {((!showContactForm && contacts?.length === 0 && searchTerm) || (!showContactForm && contacts?.length === 0 && filterTerm)) &&
                                    <div className="inprogress_page">
                                        <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Contacts Found</p></div>
                                }

                                {showContactForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h3 className="title mb-0">{contactBtn === 'add' ? 'Add New' : 'Update'} Contact</h3>
                                        <a id="navigateContactBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handleBack(); }} ><span className="material-icons icon"> arrow_back
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row">


                                            {fields.map((field, index) => (
                                                <div key={index} className="col-md-3">
                                                    <div className="form-group">
                                                        <label>{field.label}</label>
                                                        {field.type === "select" ? (
                                                            <select
                                                                id={`contact${field.name.charAt(0).toUpperCase() + field.name.slice(1)}`}
                                                                value={contact?.[field.name]}
                                                                name={field.name}
                                                                onChange={handleChange}
                                                                className={`form-control ${errors?.[field.name] && 'invalid-input'}`}
                                                                onFocus={() => setErrors({ ...errors, [field.name]: null })}
                                                            >
                                                                <option value="" selected>Select</option>
                                                                {field?.options?.map(option => (<option key={option} value={option}>{option}</option>))}
                                                            </select>
                                                        ) : (
                                                            <input
                                                                id={`contact${field.name.charAt(0).toUpperCase() + field.name.slice(1)}`}
                                                                type={field.type}
                                                                value={contact?.[field.name]}
                                                                name={field.name}
                                                                onChange={handleChange}
                                                                onInput={field.format ? (e) => { e.target.value = field.format(e.target.value); handleChange(e); } : null}
                                                                placeholder={field.placeholder}
                                                                className={`form-control ${field.className || ''} ${errors?.[field.name] && 'invalid-input'}`}
                                                                onFocus={() => handleFocus(field.name)}
                                                                // onFocus={() => setErrors({ ...errors, [field.name]: null })}
                                                            />
                                                        )}
                                                        {(field?.errorMsg && errors?.[field.name] && contact?.[field.name]?.trim()) && <span className="error-message" style={{ color: "red" }}>{field?.errorMsg}</span>}
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className="col-md-3">
                                       <div className="form-group">

                                       </div>
                                   </div>
                                   <div className="col-md-3">
                                       <div className="form-group">

                                       </div>
                                   </div>
                                    {contactBtn === 'add' && 
                                   <>
                                    <div className="col-md-3">
                                       <div className="form-group">
                                           <label className="toggle mt-0">
                                               <span className="toggle-label">Approved</span>
                                               <input id="contactApproved" className="toggle-checkbox" type="checkbox" checked={contact?.appraisal} name='appraisal' onChange={handleChange}/>
                                               <div className="toggle-switch"></div>
                                           </label>
                                       </div>
                                   </div>
                                   {contact?.contact_type === 'Broker' && 
                                   <div className="col-md-3">
                                   <div className="form-group">
                                       <label className="toggle mt-0">
                                           <span className="toggle-label">Assign this Contact as Active Broker ?</span>
                                           <input id="contactBrokerAssign" className="toggle-checkbox" type="checkbox" checked={brokerBtn} name='brokerstatus' onChange={handleChange}/>
                                           <div className="toggle-switch"></div>
                                       </label>
                                   </div>
                               </div>
                                   }
                                    {contact?.contact_type === 'Trustee' && 
                                   <div className="col-md-3">
                                   <div className="form-group">
                                       <label className="toggle mt-0">
                                           <span className="toggle-label">Assign this Contact as Active Trustee ?</span>
                                           <input id="contactTrusteeAssign" className="toggle-checkbox" type="checkbox" checked={trusteeBtn} name='trusteestatus' onChange={handleChange}/>
                                           <div className="toggle-switch"></div>
                                       </label>
                                   </div>
                               </div>
                                   }
                                   </>
                                   } */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <br/>
                                                    <button id="saveContact" onClick={(e) => { handleAddContact(); }} className="fs_btn" disabled={!isContactChanged} style={{ 'opacity': isContactChanged ? '1' : '0.6', }}>{contactBtn === 'add' ? 'Save' : 'Update'} Contact</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {contactBtn !== 'add' && showContactForm &&
                                    contactloansList && contactloansList?.length > 0 &&
                                    <DataTable className='contacts'
                                        columns={contactloanTable}
                                        data={contactloansList[0].loanDetails}
                                        //   pagination
                                        customStyles={customStyles}
                                    //   paginationPerPage={5}
                                    //   paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                    //   paginationComponentOptions={{
                                    //       rowsPerPageText: 'Per Page',
                                    //   }}
                                    />


                                }


                            </div>

                        </div>







                    </div>
                    {showBrokerConfirmation &&
                        <div className="modal message_popup popup deletepop_new duplicate_contacts  fade show" id="messagepopup" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <button type="button" onClick={(e) => { setShowBrokerConfirmation(false) }} className="close_button" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                        <img src="assets/images/icons/switch_account.png" alt="" />
                                        <h3>{checkContactStatus === 'new' ? 'New Contact' : 'Duplicate Contacts'}</h3>
                                        <p>
                                            {checkContactStatus === 'new'
                                                ? 'This email does not exist in contacts. Do you want to add it and make this contact an active broker?'
                                                : (checkContactStatus !== 'new' && existingBroker?.contact_type !== 'Broker')
                                                    ? `This email matches an existing contact, but the contact type is ${existingBroker?.contact_type}. Please choose another contact`
                                                    : 'A new broker’s email address matches with an existing contact in our system.'}
                                        </p>

                                        <div className="card">
                                            <div className="card-body">
                                                {checkContactStatus === 'new' ?
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p className="title">New Broker</p>
                                                            <p>{loanInfo?.broker?.[0]?.brokerfirstname + ' ' + loanInfo?.broker?.[0]?.brokerlastname}</p>
                                                            <p>{loanInfo?.broker?.[0]?.brokeremail}</p>
                                                        </div>

                                                    </div> :
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p className="title">New Broker</p>
                                                            <p>{loanInfo?.broker?.[0]?.brokerfirstname + ' ' + loanInfo?.broker?.[0]?.brokerlastname}</p>
                                                            <p>{loanInfo?.broker?.[0]?.brokeremail}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <p className="title">Existing Contact</p>
                                                            <p>{existingBroker?.firstname + ' ' + existingBroker?.lastname}</p>
                                                            <p>{existingBroker?.email}</p>
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            {checkContactStatus === 'new'
                                                ? 'Do you wish to add this Broker as Active'
                                                : (checkContactStatus !== 'new' && existingBroker?.contact_type !== 'Broker')
                                                    ? ''
                                                    : 'Do you wish to map the existing contact information to this broker?'}
                                        </p>

                                        <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <button type="button" onClick={handleClose} className="fs_btn pop_btn outline_button">CHOOSE ANOTHER</button>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-column align-items-start">
                                                    <button type="button" disabled={(checkContactStatus!= 'new' && existingBroker.contact_type!== 'Broker') ? true : false} style={{'opacity' : (checkContactStatus!= 'new' && existingBroker.contact_type!== 'Broker') ? 0.6 : 1}}  className="fs_btn pop_btn outline_button" onClick={AddNewBrokerContact}>{checkContactStatus === 'new' ? 'SAVE AND ASSIGN AS ACTIVE BROKER' : 'ASSIGN AS ACTIVE BROKER'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <PipelineFooter />
        </div>
    );
};

export default DealContacts;