import React, { useState, useEffect, useRef} from 'react';
import data from "../../JsonData/data.json";
import User from '../CreateUser/User';
import PipelinePermissions from '../CreateUser/pipelinePermission.js';
import { useNavigate } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';    
import MainHeader from '../MainHeader';
import SideMenu from '../SideMenu';
import {useSelector, useDispatch} from 'react-redux';
import {getMenus} from "../../redux/reducers/reducer";
import { getRoleMenus, createUser, getselectedUser, getPipelinemenus , getUserPermissionsAdmin} from "../../utils/reducer";
import { showLoader, hideLoader } from '../../redux/reducers/Actions.js';
import HomeFooter from '../HomeFooter';
const CreateUser = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menusdata = useSelector(state => state.menusdata);
    const [role,setRole] = useState(props.finalData?.securityInfo?.role || 'Admin');
    const [status,setStatus] = useState(props.finalData?.securityInfo?.status || '');
    const [show,setShow] = useState(false);
    const [userData,setUserData] = useState(props.finalData?.securityInfo?.permission || []);//final data
    const [pipelineMenus, setPipelineMenus] = useState([])
    const [userDataPipeline,setUserDataPipeline] = useState(props.finalData?.securityInfo?.pipelinePermissions || pipelineMenus);//final data
    const [sectionsdata,setsSectionsdata] = useState([]);
    const [rolepermissions,setRolepermissions] = useState([]);
    const [allRoles,setAllRoles] = useState([]);
    function usePrevious(value) {
        // Use a ref to store the previous value
        const ref = useRef();
      
        useEffect(() => {
          ref.current = value;
        }, [value]); // Only update the ref when the value changes
      
        // Return the current value of the ref (i.e., the previous value of the input)
        return ref.current;
      }
    const prevRole = usePrevious(role);
    useEffect(() => {
    if(menusdata?.length == 0){
        dispatch(getMenus())
    }else if(menusdata?.length > 0){
        const permissions = menusdata.map(menu => {
           

            return menu;
        });
        console.log('permissionsheree',permissions);
        setsSectionsdata(permissions);
        if(userData?.length == 0){
            setUserData(permissions)
        }
        
       
    }
    }, [menusdata]);
    useEffect(() => {
        getPipelinemenus().then(function (response) {
            if (response?.statusCode == 200) {
                setPipelineMenus(response?.result);
                if(userDataPipeline?.length == 0){
                    setUserDataPipeline(response?.result)
                }
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    }, []);

  useEffect(() => {
    if(menusdata?.length > 0 && role){
        getRoleMenus(role).then(function (response) {
            if (response?.statusCode == 200) {
                let rolePremissions = response?.result?.[0] || [];
                 if (role !== prevRole && prevRole) {
                 setUserData(rolePremissions?.permissions)
                 setRolepermissions(rolePremissions)
                 setUserDataPipeline(rolePremissions?.pipelinepermissions);
                 }
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
         
     }
   }, [role]);

    
    const handleSetCreateUser = (data) => {
        // console.log(data);
        setUserData(data);
    }
    const handleSetCreateUser2 = (data) => {
        // console.log(data);
        setUserDataPipeline(data);
    }
    
    
    const handleRole = (e) => {
        if(props.finalData?.securityInfo?.role != e.target.value){
        setRole(e.target.value);
        props.setFinalData(prevState => ({...prevState, securityInfo: {...prevState.securityInfo, role: e.target.value},}));
        }
    }
    useEffect(() => {
        props.setFinalData(prevState => ({...prevState, securityInfo: {...prevState?.securityInfo, role: role, status: status},}));
       }, []);


    const handleSaveRole = () => {
        if(role === '' ){
            setShow(!show);
            // return true;
        }else{
            let jsonData = (userData?.length !== 0) ? userData : sectionsdata;
            // props.setFinalData(prevState => ({...prevState, securityInfo: { ...prevState.securityInfo, permission: jsonData,},}));
            // props.setCurrentPage('stateLicenses');
            props.setFinalData(prevState => {
                console.log('setFinalData', prevState);
                const updatedData = { ...prevState, securityInfo: { ...prevState.securityInfo, permission: jsonData, pipelinePermissions: userDataPipeline} };
                console.log('updatedDataupdatedData', updatedData, props.finalData);
                
                // Now call createUser with the updatedData
                dispatch(showLoader());
                createUser(updatedData).then(response => {
                    console.log('response here created', response);
                    // Perform actions after successful creation
                   
                    if (!response?.result?.error) {
                        if(updatedData?.userid){

                            getselectedUser(updatedData?.userid).then(function (response) {
                                if (response?.statusCode == 200) {
                                  props.setFinalData(response?.result?.[0]);
                                }
                                }).catch((err) => {
                                  console.error.bind("my account error", err);
                                })
                        }
                        props.setCurrentPage('stateLicenses');
                    }
                    dispatch(hideLoader());
                }).catch(err => {
                    dispatch(hideLoader());
                    console.error(err);
                    // Handle error
                });

                return updatedData; // Return the new state
            });
            // navigate("/loanassignments");
        }
        
        
    }
    const handleNavigateCancel = ()=>{
        navigate('/usermanagement');
      }
    

    const handleStatus = (e) =>{
        setStatus(e.target.value);
        props.setFinalData(prevState => ({...prevState, securityInfo: {...prevState.securityInfo, status: e.target.value},}));
    }

    const handleAlertClose = () => {
        setShow(!show);
    }

    //calling all roles API
    useEffect(() => {
        dispatch(showLoader());
        getUserPermissionsAdmin().then(function (response) {
            if (response?.result) {
                //setAllRoles(response?.result);
                console.log('get all roles  response---->',response?.result);
                const updatedRoles = response.result
                    .filter(sheet => sheet.status !== 'INACTIVE' && sheet.status !== 'ARCHIVED')
                    .map(sheet => ({
                        "label": sheet?.type,
                        "value": sheet?.type,
                        "roleid" : sheet?.roleid,
                        "status" : sheet?.status
                    }));
        
                console.log('updatedRoles', updatedRoles);
                setAllRoles(updatedRoles);
               
              }
              dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("my account error", err);
        })
    },[])

   

    return (
        <div id="layout-wrapper">


            <MainHeader/>
            <SideMenu />

            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{props?.isEdit ? 'Update' : 'Create'} User</h4>
                                    <a href="#" id="securityInfoBack" onClick={(e) => {e.preventDefault(); props.handleBack()}} className="back_btn">
                                        <span className="material-icons icon"> arrow_back</span>BACK
                                    </a>
                                </div>
                                <div className="steps">
                                    <div id="userInfoTab" className="box bx_progress"  onClick={(e) => {props.setCurrentPage('userinfo')}}>
                                        <div className="fill" style={{width: "100%"}}></div>
                                        <span className="text">User
                                            Info</span>
                                        <img src="assets/images/icons/User-Info.svg" className="img" alt="" />
                                    </div>
                                    <div id="securityInfoTab" className="box bx_progress active" onClick={(e) => {props.setCurrentPage('securityInfo')}}>
                                        <span className="text">Security
                                            Info</span>
                                        <img src="assets/images/icons/Security-Info.svg" className="img" alt="" />
                                    </div>
                                    <div id="stateLicensesTab" className="box" onClick={(e) => {props.setCurrentPage('stateLicenses')}}>
                                        <span className="text">State
                                            Licenses</span>
                                        <img src="assets/images/icons/StateLicenses.svg" className="img" alt="" />
                                    </div>
                                    <div id="loanAssignmentsTab" className="box" onClick={(e) => {props.setCurrentPage('loanAssignMent')}}>
                                        <span className="text">My Default Loan
                                            Assignments</span>
                                        <img src="assets/images/icons/LoanAssignments.svg" className="img" alt="" />
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body ">
                                        <div className='filters role_block'>
                                            {/* {data?.roledata?.map((rolesMap,rolesIndex) => (
                                                    (rolesMap?.roles) ?
                                                    <div className="left" key={rolesIndex}>
                                                        <div className="form-group">
                                                            <label>{rolesMap.roles.label}</label>
                                                            <select id="selectRole" className="form-control" onChange={handleRole} value={props.finalData?.securityInfo?.role || role}>
                                                                {rolesMap.roles.options.map((roleOptions,optionIndex) => (
                                                                   
                                                                    <option key={optionIndex} value={roleOptions.value}>{roleOptions.label}</option>
                                                                ))}
                                                               
                                                                
                                                            </select>
                                                        </div> 
                                                    </div> :
                                                    (rolesMap?.status) ?
                                                        <div className="form-group mr-0" key={rolesIndex}>
                                                            <label>{rolesMap.status.label}</label>
                                                            <select id="selectStatus" className="form-control width-inherit" value={props.finalData?.securityInfo?.status || status} onChange={handleStatus}>
                                                                {rolesMap.status.options.map((statusOptions,optionIndex) => (
                                                                    <option key={optionIndex} value={statusOptions.value}>{statusOptions.label}</option>
                                                                ))}
                                                                
                                                                
                                                            </select>
                                                        </div> : ""
                                            ))} */}

                                                
                                                   
                                                    <div className="left">
                                                        <div className="form-group">
                                                            <label>Select Role</label>
                                                            <select id="selectRole" className="form-control" onChange={handleRole} value={props.finalData?.securityInfo?.role || role}>
                                                            {allRoles?.map((rolesMap,rolesIndex) => ( 
                                                                   
                                                                <option key={rolesIndex} value={rolesMap.value}>{rolesMap.label}</option>
                                                                
                                                            ))}  
                                                                
                                                            </select>
                                                        </div> 
                                                    </div> 
                                                    {data?.roledata?.map((rolesMap,rolesIndex) => (
                                                        (rolesMap?.status) ?
                                                        <div className="form-group mr-0" key={rolesIndex}>
                                                            <label>{rolesMap.status.label}</label>
                                                            <select id="selectStatus" className="form-control width-inherit" value={props.finalData?.securityInfo?.status || status} onChange={handleStatus}>
                                                                {rolesMap.status.options.map((statusOptions,optionIndex) => (
                                                                    <option key={optionIndex} value={statusOptions.value}>{statusOptions.label}</option>
                                                                ))}
                                                                
                                                                
                                                            </select>
                                                        </div> : ""
                                                    ))}
                                                       
                                                    
                                         
                                                   
                                               

                                           
                                            

                                        </div>
                                        <div className="section">
           
                                         <div className='row'>
                                        <div className="roles col-md-6">
                                                <User rolepermissions={rolepermissions} sectiondata={sectionsdata} roleName={role} childUserData={handleSetCreateUser} userData={userData}/>
                                         
                                            
                                        </div>
                                        <div className="roles col-md-6">
                                                <PipelinePermissions pipelineMenus={pipelineMenus} rolepermissions={rolepermissions} sectiondata={sectionsdata} roleName={role} childUserData={handleSetCreateUser2} userData={userDataPipeline} isEdit={props?.isEdit}/>
                                         
                                            
                                        </div>
                                        </div>
                                        </div>
                                        <div className="btn-group mt_30">
                                            <button id="cancelAdmin" onClick={(e) => {handleNavigateCancel()}} className="border_btn mr_10">Cancel</button>
                                            <button id="submitAdmin" className="fs_btn" onClick={(e) => {handleSaveRole()}}>{props.isEdit ? 'Update' : 'Save'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

               <HomeFooter />
            </div>
            {show && <SweetAlert
            warning
            title="Select Role is Empty"
            onConfirm={handleAlertClose}
            >
            {/* Display your validation error message */}
            Please select role 
            </SweetAlert>}

        </div>
        
    );
};

export default CreateUser;