import React, { createContext, useState, useContext, useRef } from "react";

// Create the Pipeline context
export const PipelineContext = createContext();

// Custom hook to access the Pipeline context
export const usePipeline = () => useContext(PipelineContext);

// Provider to store Pipeline state
export const PipelineProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [route, setRoute] = useState("");  // Track route changes
    const prevRouteRef = useRef();
    const[advFormData,setAdvFormData]=useState({});
    const [advFlag, setAdvFlag] = useState(false);
    const [showPage, setShowPage] = useState('');
    const [advanceNameSearch, setAdvanceNameSearch] = useState('');
    // const [filteredStage, setFilteredStage] = useState('Account Executive Review');
    const [filteredStage, setFilteredStage] = useState('ALL');
    const [filteredLoans, setfilteredLoans] = useState([]);
    const [sortObj, setSortObj] = useState({});
    const [getTaskData, setGetTaskData] = useState({});

    //pipeline status context usestate values
    const [CheckStatusAPi,setCheckStatusApi] = useState(true);
    const prevLoanIdRefStatus = useRef(null);
    const [accountExecutiveStatus,setAccountExecutiveStatus] = useState({});
    const stages = [
        "Account Executive Review",
        "Underwriting",
        "Term Sheet Issued",
        "Processing",
        "Reviewed/Requested",
        "On Hold",
        "Docs Out",
        "Funded",
        "Closed",
        "Cancelled",
        "Terms Not Issued",
        "Servicing Setup",
        "Servicing"
    ];
    const [LoanStatusHistory, setLoanStatusHistory] = useState([]);
    const [currentStage, setCurrentStage] = useState( stages[0]);
    const [currentStg, setcurrentStg] = useState(stages[0]);
    const [currentStageIndex, setcurrentStageIndex] = useState(stages.indexOf(currentStage));
    const initializeStatus = ()=>{
        prevLoanIdRefStatus.current = null;
        setCheckStatusApi(true)
        setAccountExecutiveStatus({});
        setLoanStatusHistory([]);
        setCurrentStage(stages[0]);
        setcurrentStg(stages[0]);
        setcurrentStageIndex(stages.indexOf(currentStage));
    }
    
    function usePreviousRoute() {
        React.useEffect(() => {
            prevRouteRef.current = route;
        }, [route]);

        return prevRouteRef.current;
    }

    return (
        <PipelineContext.Provider value={{LoanStatusHistory,setLoanStatusHistory,initializeStatus,prevLoanIdRefStatus,setcurrentStageIndex,currentStageIndex,setcurrentStg,currentStg,currentStage,setCurrentStage,stages,accountExecutiveStatus,setAccountExecutiveStatus,CheckStatusAPi,setCheckStatusApi,
         currentPage, setCurrentPage, perPage, setPerPage, route, setRoute, usePreviousRoute,advFormData,setAdvFormData ,advFlag, setAdvFlag,showPage, sortObj, setSortObj,setShowPage,advanceNameSearch, setAdvanceNameSearch,filteredStage, setFilteredStage,filteredLoans, setfilteredLoans,getTaskData, setGetTaskData}}>
            {children}
        </PipelineContext.Provider>
    );
};
