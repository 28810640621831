import React, {useState, useEffect, useRef } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {getOrganization, generateForms1098, deleteForm1098, copyForm1098, 
    emailForm1098, getForm1098Details, getmultiForm1098Details , sendExcelDataForm1098 , searchLoanApi , getForm1098LoanIds} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import Select from 'react-select';
import MultiLevelSelect from 'react-select-multi-level';
//import formValuesKeys from '../JsonData/formValuesKeys.json';
import {transformData} from '../utils/commonUtils.js';
import ValuesDropdown from './ValuesDropdown.js';
import { useLoanInfo } from '../utils/loanInfoContext';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import FileViewer from './DocViewer';
import * as XLSX from 'xlsx';
import JSZip from 'jszip'; // Import JSZip for zipping files
import FileSaver from 'file-saver'; // Import FileSaver for saving files
import CustomPagination from './CustomPagination';
import SweetAlert from 'react-bootstrap-sweetalert';   

const Adminformform1098 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    console.log('location',location?.state);
    const mode = location?.state?.mode;
    // console.log('selectedEmail---->',selectedEmail);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedYear, setYear] = useState(null);
    const [formValues,setFormValues] = useState({   loanIDs: [],});
    const [namesDropdown,setNamesDropdown] = useState([]);
    const [organizationData,setOrganizationData] = useState([]);
    const [yearValid, setYearValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [selectedRows, setSelectedRows] = useState(false);
    const [form1098Data, setForm1098Data] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [showDoc, setshowDoc] = useState(false);
    const configdata = useSelector(state => state.configdata);
    const [docPage, setDoc] = useState({});
    const [loanIDInput, setLoanIDInput] = useState("");
    const [emailError,setEmailError] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [fileName, setFileName] = useState('');
    const [importFileData,setImportFileData] = useState([]);
    const [importFileTableData,setImportFileTableData] = useState([]);
    const [selectedImportRows, setSelectedImportRows] = useState(new Set()); // Use Set for managing selected rows
    const [toggledImportClearRows, setToggledImportClearRows] = useState(false); // Just an example usage
    const [showBulkDownload, setShowBulkDownload] = useState(false);
    const [page,setPage] = useState(1);
    const [perPage,setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    //custom pagination
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortDirection,setSortDirection] = useState('asc');
    const [sortedPdfData, setSortedPdfData] = useState([]); // State for sorted data
    const[sortBool,setSortBool] = useState(false);

    const [showDelete, setShowDelete] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const[isImportClicked,setIsImportClicked] = useState(false);
    const [filteredLoans,setFilteredLoans] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const inputRef = useRef(null);
    const [searchLoader, setSearchLoader] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [searchErr, setSearchErr] = useState("");

    const [formErrors,setFormErrors] = useState({});
    let formErr = {};

    const startYear = loanInfo?.wireinstructions?.actualwire_funddate ? new Date(loanInfo?.wireinstructions?.actualwire_funddate).getFullYear() : new Date().getFullYear();
    let endYear = loanInfo?.cleartoclose?.proposedmaturitydate || '';
    let closingDate = loanInfo?.cleartoclose?.actualclosingdate;
    console.log('loanInfo', loanInfo?.loanterm);


    if (!endYear && loanInfo?.loanterm && closingDate) {
        const closingDateClone = new Date(closingDate);
        closingDateClone.setMonth(closingDateClone.getMonth() + loanInfo.loanterm);
        closingDateClone.setDate(1);
        closingDateClone.setMonth(closingDateClone.getMonth() + 1);
        let enddate = closingDateClone.toISOString().split('T')[0];
        endYear = enddate ? new Date(enddate).getFullYear() : '';
        console.log('closingDateClone', closingDateClone.toDateString());
    }
    console.log('wireinstructions',startYear, endYear)
    const years = [2021,2022,2023,2024,2025];
    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }
    console.log('wireinstructions',years);

    useEffect(() => {
        getOrganizationData();
        getForm1098()
    }, []);
    
    const getOrganizationData = () => {
        dispatch(showLoader());
        getOrganization('form1098').then(function (response) {
          console.log('getOrganization',response);
          if (response?.result) {
            //   setFormData({...formData,...response?.result?.[0]});
            setOrganizationData(response?.result);
          
          }
          dispatch(hideLoader());
          }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("getOrganization error", err);
          })
      }


    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);

        // Check if at least one row is selected
        const hasSelectedRows = selectedRows.length > 0;

        // Show the bulk download button if at least one row is selected
        setShowBulkDownload(hasSelectedRows);

        console.log('Selected Rows: ', selectedRows);
    };

    const handleImportChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        console.log('Selected Rows: ', selectedRows);
        //const updatedData = selectedRows.filter(item => item.loanid !== row.loanid);
        setImportFileTableData(selectedRows);
    };



    const handleForm = (e) => {
        let {name , value} = e.target;
        //console.log(name + " " +value);
        let linExists = organizationData?.find(org => org.name === value);
        console.log("lin exists",linExists);
        // Prepare the updated form values.
        const newFormValues = {
            ...formValues,
            [name]: value // Update the current field based on the input
        };

        // If the organization exists, update lenderlin accordingly
        if (linExists) {
            console.log("lenderlin",linExists.lenderlin);
            newFormValues.lenderlin = linExists.lenderlin;
        }else {
            // Clear lenderlin if no match is found
            newFormValues.lenderlin = "";
        }
        console.log('newFormValues',newFormValues);
        setFormValues(newFormValues);
        
       
      }
   


      useEffect(() => {
        if(organizationData?.length !== 0){
            const orgNames = organizationData.map(org => org.name);
            setNamesDropdown(orgNames);
        }
  },[organizationData])

   

    const handleSave = ()=>{
        // console.log("existing data",termSheetsData);
        // const fieldsToCheck = {
        //      sheetName : 'sheetName', programType: 'programType'
        // };
        let error = {};
       
     
    }
    


    const generateForm = (loanid, year, lendername, lenderlin) => {
        let formErr = {}; // Initialize form errors
        let updatedData = [];
        
        // Check if no file is selected and required form values are empty
        if (!fileName && formValues.loanIDs.length === 0 && !year && !formValues.name) {
            dispatch(showLoader());
            setPopupOpen(true);
            setSuccessMessage("Please either import the file or fill in the required details.");
            setMessageType('warning'); // Set to warning since it's a user input issue
            dispatch(hideLoader());
            return; // Stop further execution
        }

        // Check if no rows are selected when a file is imported
        if (fileName && selectedImportRows.size === 0) {
            dispatch(showLoader());
            setPopupOpen(true);
            setSuccessMessage("No rows have been selected.");
            setMessageType('warning');
            dispatch(hideLoader());
            return;
        }
    
        // If no file is selected but other details are provided
        if (!fileName && (year || formValues?.name || formValues?.loanIDs?.length !== 0)) {
            // Validate the year field
            if (year?.length !== 4) {
                formErr["year"] = "Invalid year. Please enter a 4-digit year.";
                setYearValid(true);
            }
    
            // Validate the lender name
            if (!formValues?.name) {
                formErr["name"] = "Required";
            }
    
            // Validate loan IDs
            if (formValues?.loanIDs?.length === 0) {
                formErr["searchInputValue"] = "Required";
            }
    
            // If there are errors, set form errors and do not proceed
            if (Object.keys(formErr).length > 0) {
                setFormErrors(formErr);
                return;
            }
    
            // If valid, prepare data for API call
            if (!fileName && lendername && lenderlin) {
                formValues.loanIDs.forEach((id) => {
                    updatedData.push({
                        loanid: id,
                        year: parseInt(year),
                        lendername: lendername,
                        lenderlin: lenderlin,
                    });
                });
            }
        } else if (fileName) {
            // Handle case when a file is imported
            updatedData = importFileTableData
                .filter(item => item.status?.trim().toLowerCase() !== "rejected"
                        && selectedImportRows.has(item.loanid)) // Include only selected items
                .map(({ name, ...item }) => ({
                    ...item,
                    lendername: name // Set lendername to the value of name
                }));
    
            console.log("Filtered Import data", updatedData);
        }
       
    
        // Proceed to make the API call if data is ready
        if (updatedData.length > 0) {
            dispatch(showLoader());
    
            generateForms1098(updatedData)
                .then(function (response) {
                    console.log("Final data", updatedData);
                   
                    // Check for rejected loans in the response
                    if (response?.result?.rejectedloans && response.result?.rejectedloans.length > 0) {
                        const rejectedLoanIDs = Array.isArray(response.result.rejectedloans) ? response.result.rejectedloans : [];
                        setPopupOpen(true);
                        setMessageType('warning'); // Set warning for rejected loans
                        setSuccessMessage(`The following loan numbers do not lie between the respective years: ${rejectedLoanIDs.join(", ")}`);
                        dispatch(hideLoader());
                        return;
                    }
    
                    // Successful response with no rejected loans
                    if (response?.statusCode === 200 && response?.result?.length !== 0) {
                        console.log("response?.result ", response?.result);
                        setPopupOpen(true);
                        setMessageType('success'); // Set success type for successful generation
                        setSuccessMessage('Form 1098 Generated Successfully');
                        getForm1098();
    
                        // Reset form or file based on the condition
                        if (fileName) {
                            setFileName(null);
                            setIsImportClicked(!isImportClicked);
                        } else {
                            setYear('');
                            setFormValues({
                                lenderlin: '',
                                name: '',
                                loanIDs: []
                            });
                        }
                    }
                    dispatch(hideLoader());
                })
                .catch((err) => {
                    setPopupOpen(true);
                    // Generic error handling
                    setMessageType('error'); // Set error type for API call errors
                    setSuccessMessage(err?.message || String(err));
                    console.error("error", err);
                    dispatch(hideLoader());
                });
        } else {
            console.log("No valid data to send for Form 1098 generation.");
        }
    
        // Update form errors if any
        setFormErrors(formErr);
    };
    
    
    
    const resetFormState = () => {
        if (fileName) {
            setFileName(null);
        } else {
            setYear("");
            setFormValues({
                lenderlin: "",
                name: "",
                loanIDs: [],
            });
        }
    };
    

    const deleteForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        deleteForm1098(formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Deleted Successfully')
                    getForm1098()
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };

    const copyForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        copyForm1098(loanid, formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Copied Successfully')
                    getForm1098()
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };

    const emailForm = (loanid) => {
        let formData = {
            formid: []
        }
        if(selectedRows && selectedRows?.length > 0){
            selectedRows.forEach(item => {
                formData.formid.push(item.formid)
            })
        } else {
            return 
        }
       
        console.log(formData, 'formData')

        dispatch(showLoader());
        emailForm1098(loanid, formData)
            .then(function (response) {
                if (response?.statusCode === 200) {
                    console.log("response?.result ", response?.result)
                    setPopupOpen(!isPopupOpen)
                    setSuccessMessage('Form 1098 Email Sent Successfully')
                    getForm1098()
                    handleClearRows()
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                setPopupOpen(!isPopupOpen)
                setMessageType('warning')
                setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
                handleClearRows()
            });
    };

    
    const getForm1098 = () => {
        dispatch(showLoader());
        getmultiForm1098Details(page,perPage)
            .then(function (response) {
                if (response?.statusCode === 200 && response?.result?.length !== 0) {

                    
                    setForm1098Data(response?.result?.data);
                    setTotalRows(response?.result?.totalCount);
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };


    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }


    const closePopup = () => {
        setPopupOpen(false);
    }

    // Custom Checkbox Component
    const CustomCheckbox = ({ id ,checked, onChange, disabled }) => (
        <input
            id = {id}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
    );

     // Handle select all checkbox change
     const handleSelectAll = () => {
        const allSuccessLoanIds = new Set(importFileTableData
            .filter(row => row.status === 'Success')
            .map(row => row.loanid));
        const newSelection = allSuccessLoanIds.size === selectedImportRows.size ? new Set() : allSuccessLoanIds;
        setSelectedImportRows(newSelection);
    };


     // Check if all selectable rows are selected
     const allSelected = importFileTableData
     .filter(row => row.status === 'Success')
     .every(row => selectedImportRows.has(row.loanid));
    
    const importcolumns = [
        {
            name: (
                <CustomCheckbox
                    id="select-all-checkbox"
                    checked={allSelected}
                    onChange={handleSelectAll}
                    disabled={importFileTableData.every(row => row.status !== 'Success')} // Disable if no rows can be selected
                />
            ),            cell: (row) => (
                <CustomCheckbox
                    id={`checkbox-${row.loanid}`}
                    checked={selectedImportRows.has(row.loanid)}
                    onChange={() => handleCheckboxChange(row.loanid)}
                    disabled={row.status !== 'Success'} // Disable checkbox if status is not 'Success'
                />
            ),
            width: '5%',
        },
        {
            name: 'LOAN ID',
            selector: (row) => row?.loanid || '',
            sortable: true,
            width: '10%',
        },
        {
            name: 'YEAR',
            selector: (row) => row?.year || '',
            sortable: true,
            width: '10%',
        },
        {
            name: 'ORGANIZATION',
            selector: (row) => row?.name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'LENDER EIN',
            selector: (row) => row?.lenderlin,
            sortable: true,
            width: '10%',
        },
        {
            name: 'RESULT',
            selector: (row) => row?.status || '',
            width: '20%',
            sortable: true,
        },
        {
            name: 'REASON',
            selector: (row) =>
                row?.rejectableReason ||  <span style={{ color: '#000',fontSize : '2em' }}>-</span>,
            width: '15%',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className="btn-group" key={index}>
                    {/* <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon">
                        edit </span></button> */}
                    {/* <button type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon" onClick={(e) => handleFile(e, row)}>
                        visibility </span></button> */}
                    <button id={`delete-import-${index}`} type="button" className="btn btn-outline-secondary" onClick={(e) => handleDeleteFileData(e, row)}><span className="material-symbols-outlined icon">
                        delete </span></button>
                </div>
            ),
            width: '10%',
            sortable: false,

        },
    ];

    const columns = [
        {
            name: 'LOAN ID',
            selector: (row) => row?.loanid || '',
            width: '10%', // Reduced for compactness
            sortable: true,
        },
        {
            name: 'YEAR',
            selector: (row) => row?.year || '',
            width: '10%', // Reduced width for year
            sortable: true,
        },
        {
            name: 'FILE NAME',
            selector: (row) => row?.filename || '',
            width: '20%', // Increased to fit longer file names
            sortable: true,
        },
        {
            name: 'CREATED ON',
            selector: (row) => row?.created ? moment.utc(row?.created).format("MM-DD-YYYY") : '',
            width: '20%', // Kept same for consistency
            sortable: true,
        },
        {
            name: 'CREATED BY',
            selector: (row) => row?.createdBy?.username,
            width: '20%', // Kept same for consistency
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div className="btn-group" key={index}>
                    <button type="button" className="btn btn-outline-secondary">
                        <span className="material-symbols-outlined icon" onClick={(e) => handleFile(e, row)}>
                            visibility
                        </span>
                    </button>
                    <button type="button" className="btn btn-outline-secondary" onClick={(e) => handleDownloadFile(e, row)}>
                        <span className="material-symbols-outlined icon">
                            download
                        </span>
                    </button>
                </div>
            ),
            width: '17%', // Reduced for better space management
            sortable: false,
        },
    ];
    
    // Calculate total width
    const totalWidth = columns.reduce((acc, column) => acc + parseFloat(column.width), 0);
    console.log("Total Width Percentage:", totalWidth);
    
    

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };

    const handleFile = (e, row) => {
        e.preventDefault()
        setshowDoc(true);
        let source = configdata?.common?.resourcesCloudfront + row.filepath;
        let fileObject = {
            url: source,
            filename: row.filename,
            type: "pdf"
        }
        setDoc(fileObject)
    };


    // const handleDownloadFile = (e, row) => {
    //     e.preventDefault()

    //     console.log("row", row)
    //     // let source = configdata?.common?.resourcesCloudfront + row.filepath;
    //     // const link = document.createElement('a');
    //     // link.href = source;
    //     // link.setAttribute('download', row.filename); //or any other extension
    //     // link.setAttribute('target', "_blank");
    //     // document.body.appendChild(link);
    //     // link.click();

    //     let filePath = row.filepath;
    //     let source = `${configdata?.common?.resourcesCloudfront}${filePath}`;
        
    //     // Create an anchor element
    //     const link = document.createElement("a");
    //     link.href = source;
    
    //     // Set the download attribute to specify the file name
    //     link.download = row.filename; // Use the actual filename from the row
    
    //     // Append to the document and trigger a click event
    //     document.body.appendChild(link);
    //     link.click();
    
    //     // Remove the anchor element from the document
    //     document.body.removeChild(link);
    // };
    
    
    const handleDownloadFile = async (e, row) => {
        e.preventDefault();
    
        console.log("row", row);
        
        let filePath = row.filepath;
        let source = `${configdata?.common?.resourcesCloudfront}${filePath}`;
        
        try {
            const response = await fetch(source);
            if (!response.ok) throw new Error("Network response was not ok");
            
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            // Create an anchor element
            const link = document.createElement("a");
            link.href = url;
    
            // Set the download attribute to specify the file name
            link.download = row.loanid + "-" +row.filename; // Use the actual filename from the row
    
            // Append to the document and trigger a click event
            document.body.appendChild(link);
            link.click();
    
            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };
    
    
    const handleLoanIDChange = (e) => {
        // Remove any non-numeric characters
        const value = e.target.value.replace(/\D/g, '');
        setLoanIDInput(value); // Update the input with only numeric characters
        setEmailError(null); // Reset error when typing
    };
    

    const removeLoanID = (loanToRemove) => {
        setFormValues((prevData) => ({
            ...prevData,
            loanIDs: prevData.loanIDs.filter(
                (loanID) => loanID.toString().trim() !== loanToRemove.toString().trim()
            ),
        }));
    };
    

    const handleEmailKeyDown = (e) => {
        // On comma, enter, or space, we want to add the email
        if (["Enter", ","].includes(e.key)) {
            e.preventDefault();
                if (!formValues.loanIDs?.includes(loanIDInput)) {
                    setFormValues((prevData) => ({
                        ...prevData,
                        loanIDs: [...prevData.loanIDs, loanIDInput], // Add new email to the array
                    }));
                }
                setLoanIDInput(""); // Clear input
            
        }
    };

    // const handleFileImport = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         // Check the file type
    //         const validExtensions = ['.xlsx', '.xls'];
    //         const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();

    //         if (!validExtensions.includes(`.${fileExtension}`)) {
    //             dispatch(showLoader());
    //             setPopupOpen(true);
    //             setSuccessMessage("Please Upload Excel Files only.");
    //             dispatch(hideLoader());
    //             //return; // Exit the function if the file type is invalid
    //         }
    //         else{
    //             setFileName(file.name); // Set the file name in the state
    //             const reader = new FileReader();
    
    //             reader.onload = (evt) => {
    //                 const arrayBuffer = evt.target.result;
    //                 const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            
    //                 const sheetName = workbook.SheetNames[0];
    //                 const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            
    //                 // Initialize array to store loan IDs
    //                 let loanIdsArray = [];
                    
    //                 worksheet.forEach((row, rowIndex) => {

    //                     // Case 1: Loan Ids as a string with comma-separated values
    //                     const loanIdsField = row["Loan Ids"];

    //                     // Add loan IDs from string format
    //                     if (loanIdsField && loanIdsField.includes(',')) {
    //                         loanIdsArray = loanIdsArray.concat(
    //                             loanIdsField.split(',').map(id => id.trim())
    //                         );
    //                     }
    //                     // else if (typeof loanIdsField === 'number') {
    //                     //     // If it's a single number, push it directly into the array
    //                     //     loanIdsArray.push(loanIdsField.toString());
    //                     // }
                    
    //                         // Extract the year, organization, and Lender EIN from the first row
    //                     else {
    //                             //setYear(row?.year || '');
                
    //                             // Handle organization and lender EIN
    //                             // setFormValues({
    //                             //     // name: row?.Organization || '',
    //                             //     // lenderlin: row?.["Lender EIN"] || '',
    //                             //     loanIDs: [], // Initialize empty, we'll fill it later
    //                             // });

    //                             // Start extracting Loan IDs from the second entry onward
    //                             const loanIdKeys = Object.entries(row).filter(([key, value]) => key.startsWith("Loan Id") || (!isNaN(value) && value !== '' && typeof value === 'number'));
                    
    //                             loanIdKeys.forEach(([key, value]) => {
    //                                 if (value && value !== row?.year) {
    //                                     loanIdsArray.push(value.toString()); // Convert to string if necessary
    //                                 }
    //                             });
    //                         }
            
                            
                        
                        
    //                 });
            
    //                 // Updating the loan IDs in the form values
    //                 setFormValues( ({
    //                     name:worksheet[0]?.Organization,
    //                     lenderlin: worksheet[0]?.["Lender EIN"],  
    //                     loanIDs: loanIdsArray, // Set the collected Loan IDs
    //                 }));
    //                 setYear( worksheet[0]?.year);
            
    //                 // Log the final processed data
    //                 console.log("Processed Excel data:", {
    //                     name: worksheet[0]?.Organization || '',
    //                     lenderlin: worksheet[0]?.["Lender EIN"] || '',
    //                     loanIDs: loanIdsArray,
    //                     year: worksheet[0]?.year || ''
    //                 });
    //             };
    
    //             // Use readAsArrayBuffer instead of readAsBinaryString
    //             reader.readAsArrayBuffer(file);
    //         }
    //     }
    
        
    // }; //loan ids row format 
    
    // const handleFileImport = (e) => {
    //     const file = e.target.files[0];
        
    //     if (file) {
    //       // Check the file type
    //       const validExtensions = ['.xlsx', '.xls'];
    //       const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
      
    //       if (!validExtensions.includes(`.${fileExtension}`)) {
    //         dispatch(showLoader());
    //         setPopupOpen(true);
    //         setSuccessMessage("Please Upload Excel Files only.");
    //         dispatch(hideLoader());
    //         return; // Exit the function if the file type is invalid
    //       }
      
    //       setFileName(file.name); // Set the file name in the state
    //       const reader = new FileReader();
      
    //       reader.onload = (evt) => {
    //         const arrayBuffer = evt.target.result;
    //         const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    //         const sheetName = workbook.SheetNames[0];
    //         const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      
    //         // Initialize array to store loan IDs
    //         let loanIdsArray = [];
      
    //         // Iterate through the rows of the worksheet
    //         worksheet.forEach((row, rowIndex) => {
    //           // Handle organization and lender EIN only from the first row
    //           if (rowIndex === 0) {
    //             setFormValues({
    //               name: row?.Organization || '',
    //               lenderlin: row?.["Lender EIN"] || '',
    //               loanIDs: [], // Initialize empty; we'll fill it later
    //             });
    //             setYear(row?.year);
    //           }
      
    //           // Extract Loan IDs
    //           const loanIdsField = row["Loan Ids"];
      
    //           // Case 1: Check if Loan Ids field is a string with comma-separated values
    //           if (loanIdsField && typeof loanIdsField === 'string') {
    //             // Split by comma and trim whitespace
    //             loanIdsArray = loanIdsArray.concat(
    //               loanIdsField.split(',').map(id => id.trim())
    //             );
    //           } else if (loanIdsField && typeof loanIdsField === 'number') {
    //             // Case 2: Single numeric value in a cell
    //             loanIdsArray.push(loanIdsField.toString());
    //           } else {
    //             // Case 3: Check for individual Loan IDs in subsequent rows (assuming they are under Loan Ids column)
    //             Object.entries(row).forEach(([key, value]) => {
    //               if (key.startsWith("Loan Id") && value) {
    //                 loanIdsArray.push(value.toString()); // Add individual Loan IDs
    //               }
    //             });
    //           }
    //         });
      
    //         // Update form values with the collected Loan IDs
    //         setFormValues(prevValues => ({
    //           ...prevValues,
    //           loanIDs: loanIdsArray, // Set the collected Loan IDs
    //         }));
      
    //         // Log the final processed data
    //         console.log("Processed Excel data:", {
    //           name: worksheet[0]?.Organization || '',
    //           lenderlin: worksheet[0]?.["Lender EIN"] || '',
    //           loanIDs: loanIdsArray,
    //           year: worksheet[0]?.year || ''
    //         });
    //       };
      
    //       // Use readAsArrayBuffer instead of readAsBinaryString
    //       reader.readAsArrayBuffer(file);
    //     }
    // }; // column format loan ids 
      
    const sendExcelData = () => {
        dispatch(showLoader());
           
            sendExcelDataForm1098(importFileData)
            .then(function (response) {
                if (response?.statusCode === 200 && response?.result) {
                    console.log("Import file ", response?.result);
                    setImportFileTableData(response?.result);
                    //setPopupOpen(!isPopupOpen)
                    //setSuccessMessage('File Imported Successfully')
                   
                }
                dispatch(hideLoader());
            })
            .catch((err) => {
                // setPopupOpen(!isPopupOpen)
                // setMessageType('warning')
                // setSuccessMessage(err)
                console.error("error", err);
                dispatch(hideLoader());
            });
    }

    useEffect(() => {
        if(importFileData?.length !== 0){
            sendExcelData();
        }
        
    },[importFileData])

    // const handleFileImport = (e) => {
    //     const file = e.target.files[0];
    
    //     if (file) {
    //         const validExtensions = ['.xlsx', '.xls'];
    //         const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
    
    //         if (!validExtensions.includes(`.${fileExtension}`)) {
    //             dispatch(showLoader());
    //             setPopupOpen(true);
    //             setSuccessMessage("Please upload only .xlsx or .xls Excel files.");
    //             dispatch(hideLoader());
    //             return;
    //         }
    
            
    //         const reader = new FileReader();
    
    //         reader.onload = (evt) => {
    //             const arrayBuffer = evt.target.result;
    //             const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    
    //             // Expected headers
    //             const expectedHeaders = ["Year", "Organization", "Lender EIN", "Loan ID"];
    
    //             // Get the actual headers from the first row of the worksheet
    //             const actualHeaders = worksheet[0];
    
    //             // Compare the headers
    //             const headersMatch = expectedHeaders.every((header, index) => header === actualHeaders[index]);
    
    //             if (!headersMatch) {
    //                 dispatch(showLoader());
    //                 setPopupOpen(true);
    //                 setSuccessMessage("Invalid file format. Please ensure the headers are correct.");
    //                 dispatch(hideLoader());
    //                 return; // Exit if headers don't match
    //             }else{
    //                 setFileName(file.name);
    //                 // Continue processing the file if headers match
    //                 let importedData = [];
                        
    //                 worksheet.slice(1).forEach((row) => {
    //                     const organization = row[1] || '';  // Extract organization
    //                     const lenderEIN = row[2] || '';     // Extract lender EIN
    //                     const loanID = row[3] || '';        // Extract loan ID
    //                     const year = row[0] || '';          // Extract year

    //                     importedData.push({
    //                         name: organization,
    //                         lenderlin: lenderEIN,
    //                         loanid: loanID,
    //                         year: year,
    //                     });
    //                 });

    //                 setImportFileData(importedData);
    //                 console.log("Processed Excel data:", importedData)
    //             }
    //             // Reset file input to allow re-importing the same file
    //             e.target.value = null;
               
               
    //         };
    
    //         reader.readAsArrayBuffer(file);
    //     }
    // };
    


    // const handleDeleteFileData = (e,row) => {
    //     dispatch(showLoader());
    //     const updatedData = importFileTableData.filter(item => item.loanid !== row.loanid);
    //     setImportFileTableData(updatedData);
    //     setPopupOpen(true);
    //     setSuccessMessage("Deleted Succesfully");
    //     dispatch(hideLoader());
    // }

   // Automatically select rows with status "Success"
   
   const handleFileImport = (e) => {
    const file = e.target.files[0];

    if (file) {
        const validExtensions = ['.xlsx', '.xls'];
        const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();

        if (!validExtensions.includes(`.${fileExtension}`)) {
            dispatch(showLoader());
            setPopupOpen(true);
            setSuccessMessage("Please upload only .xlsx or .xls Excel files.");
            dispatch(hideLoader());
            return;
        }

        const reader = new FileReader();

        reader.onload = (evt) => {
            const arrayBuffer = evt.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            // Expected headers
            const expectedHeaders = ["Year", "Organization", "Loan ID"];

            // Get the actual headers from the first row of the worksheet
            const actualHeaders = worksheet[0];

            // Compare the headers
            const headersMatch = expectedHeaders.every((header, index) => header === actualHeaders[index]);

            if (!headersMatch) {
                dispatch(showLoader());
                setPopupOpen(true);
                setSuccessMessage("Invalid file format. Please ensure the headers are correct.");
                dispatch(hideLoader());
                return; // Exit if headers don't match
            } else {
                // Check if there are rows after the header
                if (worksheet.length <= 1) {
                    dispatch(showLoader());
                    setPopupOpen(true);
                    setSuccessMessage("No data Found in the file. Please upload a file that contains data.");
                    dispatch(hideLoader());
                    return;
                } else {
                    setFileName(file.name);
                    // Continue processing the file if headers match and there are rows
                    let importedData = [];
                    let uniqueRows = new Set();

                    worksheet.slice(1).forEach((row) => {
                        const organization = row[1] || '';  // Extract organization
                        const loanID = row[2] || '';        // Extract loan ID
                        const year = row[0] || '';          // Extract year

                        // Skip the row if all cells are empty
                        if (!organization && !loanID && !year) {
                            return; // Continue to the next row
                        }

                        // Create a unique key for the row
                        const rowKey = `${year}|${organization.trim().toLowerCase()}|${loanID}`;

                        // Check if the rowKey is already in the set of unique rows
                        if (uniqueRows.has(rowKey)) {
                            return; // Skip adding this row to importedData
                        }

                        // Add the unique key to the set
                        uniqueRows.add(rowKey);

                        let linExists = organizationData?.find(org => org.name === organization);
                        let lenderLin = null;
                        if (linExists) {
                            lenderLin = linExists.lenderlin;
                        }

                        importedData.push({
                            name: organization,
                            lenderlin: lenderLin,
                            loanid: loanID,
                            year: year,
                        });
                    });

                    setImportFileData(importedData);
                    console.log("Processed Excel data:", importedData);
                }
            }

            // Reset file input to allow re-importing the same file
            e.target.value = null;
        };

        reader.readAsArrayBuffer(file);
    }
};





   useEffect(() => {
        const initialSelection = new Set(importFileTableData
            .filter(row => row.status === 'Success')
            .map(row => row.loanid));
        setSelectedImportRows(initialSelection); // Update selected rows based on initial data
    }, [importFileTableData]);

    // Handle checkbox change
    const handleCheckboxChange = (loanId) => {
        const newSelection = new Set(selectedImportRows);
        //let updatedTableData = [...importFileTableData]
        if (newSelection.has(loanId)) {
            newSelection.delete(loanId); // Deselect
            // Remove from importFileTableData
            //updatedTableData = updatedTableData.filter(row => row.loanid !== loanId);
        } else {
            newSelection.add(loanId); // Select
        }
        setSelectedImportRows(newSelection);
        //setImportFileTableData(updatedTableData);
    };

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        const folder = zip.folder("1098 Forms");
    
        dispatch(showLoader()); // Indicate loading
    
        try {
            const addedFiles = [];
    
            for (const row of selectedRows) {
                let source = `${configdata?.common?.resourcesCloudfront}${row.filepath}`;
                console.log(`Fetching: ${source}`);
    
                const response = await fetch(source, {
                    method: 'GET',
                    headers: {
                        // Include any necessary headers, e.g., authorization
                    },
                });
    
                if (response.ok) {
                    const blob = await response.blob();
                    let fileNameWithExtension = row.filename; // Ensure this includes the correct file extension
    
                    // Check if the filename has an extension
                    if (fileNameWithExtension.includes('.')) {
                        console.warn(`Filename does not include an extension: ${fileNameWithExtension}`);
                        // Optionally, you can append a default extension if needed
                        fileNameWithExtension = row.loanid +'-form1098.pdf'; // or whatever default you wish to add
                    }
    
                    folder.file(fileNameWithExtension, blob); // Use the filename for the ZIP
                    addedFiles.push(fileNameWithExtension);
                    console.log(`Added file: ${fileNameWithExtension}`);
                } else {
                    console.warn(`File not found: ${row.filename}, Status: ${response.status}`);
                }
    
                await new Promise(r => setTimeout(r, 300)); // Add delay
            }
    
            if (addedFiles.length > 0) {
                const content = await zip.generateAsync({ type: 'blob' });
                FileSaver.saveAs(content, "bulk_download.zip");
                console.log('ZIP content generated');
            } else {
                console.log("No files were found for download.");
            }
        } catch (error) {
            console.error("Error during bulk download:", error);
        } finally {
            dispatch(hideLoader()); // Hide loading
        }
    };
    
    
    
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setPage(page);
        
    };
    
    const handlePageChange = (newPage) => {
        setPage(newPage);
       
    };

    useEffect(() => {
      
        getForm1098()
       
        
    }, [page,perPage]);
    
    //custom pagination
    const handleImportPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleImportRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    const paginatedImportData = importFileTableData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handleYear = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setYear(value);
        
    }

    // Function to handle when the delete button is clicked
    const handleDeleteFileData = (e, row) => {
        setDeleteData(row);
        setShowDelete(true);
    };

    // Function to confirm the delete action
    const handleDelete = (data, index) => {
        const updatedData = importFileTableData.filter(item => item.loanid !== data.loanid);
        setImportFileTableData(updatedData);
        setShowDelete(false); // Close the SweetAlert popup
        setPopupOpen(true); // Show success message
        setSuccessMessage("Deleted Successfully");
        dispatch(hideLoader());
    };

    const handleDownloadSampleFile = () => {
       
        
        let filePath = "easystreetcapitaldev/imports/format_form1098.xlsx";
        let source = `${configdata?.common?.resourcesCloudfront}${filePath}`;
         // Create an anchor element
        const link = document.createElement("a");
        link.href = source;

        // Set the download attribute to specify the file name (optional)
        link.download = "sample_form1098.xlsx";

        // Append to the document and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the document
        document.body.removeChild(link);
       
    }

    // Function to close the SweetAlert popup
    const closePopup2 = () => {
        setShowDelete(false);
    };

    const handleImport = () => {
        setIsImportClicked(!isImportClicked);
    }

    const handleNavigateImport = (e) => {
        e.preventDefault();
        //navigate(-1);
        if(fileName){
            setFileName('');

        }else{
            setIsImportClicked(!isImportClicked);
        }
       
       
       
    }

    //calling loan serach API
    useEffect(() => {
        //dispatch(showLoader());
           
        getForm1098LoanIds()
            .then(function (response) {
                if (response?.statusCode === 200 && response?.result) {
                    console.log("Import file ", response?.result);
                    setFilteredLoans(response.result);
                    //setPopupOpen(!isPopupOpen)
                    //setSuccessMessage('File Imported Successfully')
                   
                }
                //dispatch(hideLoader());
            })
            .catch((err) => {
                // setPopupOpen(!isPopupOpen)
                // setMessageType('warning')
                // setSuccessMessage(err)
                console.error("error", err);
                //dispatch(hideLoader());
            });
    },[loanIDInput])
    

    const selectSearched = (loan) => {
       // handleNavigation('/dashboard', loan);
    }

    //added press key down and key up to search loan
    const handleKeyDown = (e) => {
        if (showOptions && filteredLoans.length > 0) {
            if (e.key === 'ArrowDown') {
                
                setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredLoans.length);
                e.preventDefault();
            } else if (e.key === 'ArrowUp') {
                //setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredLoans.length) % filteredLoans.length);
                setFocusedIndex((prevIndex) => {
                    if (prevIndex === -1) {
                      return filteredLoans.length - 1;
                    } else {
                      return (prevIndex - 1 + filteredLoans.length) % filteredLoans.length;
                    }
                });
                  
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (focusedIndex >= 0 && focusedIndex < filteredLoans.length) {
                    selectSearched(filteredLoans[focusedIndex]);
                    e.preventDefault();
                    setFocusedIndex(-1);
                    
                }
            }
            
            // else if (e.key === 'Escape') {
            //     // Reset focused index when Escape key is pressed
            //     setFocusedIndex(-1);
            // }
        }
    }

    const handleFocus = (e) => {
        const { name , value} = e.target;
        if(name == "year"){
            setYearValid(false);
        }else{
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null
            }));
            
        }
    }

    // const handleSort = (column, sortDirection) => {
    //     const sortField = column.selector; // This can be either a string or a function
    
    //     // Sort data based on the selected column and direction
    //     const sortedData = [...form1098Data].sort((a, b) => {
    //         const aValue = typeof sortField === 'function' ? sortField(a) : a[sortField]; // Adjust based on whether sortField is a function
    //         const bValue = typeof sortField === 'function' ? sortField(b) : b[sortField]; // Same here
    
    //         if (aValue < bValue) {
    //             return sortDirection === 'asc' ? -1 : 1;
    //         }
    //         if (aValue > bValue) {
    //             return sortDirection === 'asc' ? 1 : -1;
    //         }
    //         return 0;
    //     });
    
    //     // Update your data with the sorted result
    //     setForm1098Data(sortedData);
    // };
    
    const handleSort = (column, sortDirection) => {
       
        const sortField = column.selector;
    
        // Modify safeValue to ensure it always returns a string
        const safeValue = value => (value == null ? '' : String(value));
    
        const newSortedData = [...form1098Data].sort((a, b) => {
            const aValue = typeof sortField === 'function' ? sortField(a) : safeValue(a[sortField]);
            const bValue = typeof sortField === 'function' ? sortField(b) : safeValue(b[sortField]);
    
            console.log(`Sorting by column: ${column.name}`);
    
            // Proper sorting logic for "CREATED BY"
            if (column.name === 'CREATED BY') {
                const aCreatedBy = safeValue(a.createdBy?.username).toLowerCase();
                const bCreatedBy = safeValue(b.createdBy?.username).toLowerCase();
    
                console.log(`Comparing: ${aCreatedBy} vs ${bCreatedBy}`);
                return sortDirection === 'asc'
                    ? aCreatedBy.localeCompare(bCreatedBy)
                    : bCreatedBy.localeCompare(aCreatedBy);
            }
    
            // General string comparison
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' 
                    ? aValue.localeCompare(bValue) 
                    : bValue.localeCompare(aValue);
            }
    
            // Fallback for numbers or other types
            return sortDirection === 'asc' 
                ? safeValue(aValue) - safeValue(bValue) 
                : safeValue(bValue) - safeValue(aValue);
        });
    
        //setForm1098Data(sortedData);
         // Update sorted data and toggle sort direction
         setSortedPdfData(newSortedData);
         setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
         //setSortBool(!sortBool);
       
    };
    
    

    const handleSortImport = (column, sortDirection) => {
        const sortField = column.selector;
    
        // Sort data based on the selected column and direction
        const sortedData = [...importFileTableData].sort((a, b) => {
            const aValue = typeof sortField === 'function' ? sortField(a) : a[sortField]; // Adjust based on whether sortField is a function
            const bValue = typeof sortField === 'function' ? sortField(b) : b[sortField]; // Same here
    
            if (aValue < bValue) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    
        // Update your data with the sorted result
        setImportFileTableData(sortedData);
        // Optionally toggle sort direction
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };
    
    
      
    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

      

           <MainHeader />
            <SideMenu />

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
               
                        <div className="row">
                            <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Form 1098</h4>
                                {isImportClicked && <a id="navigateImportBack" href="#" onClick={handleNavigateImport} className="back_btn"><span className="material-icons icon"> arrow_back
                                </span>BACK</a>}
                            </div>
                                <div className="card user_management detailed_app services_form form1098">
                                        
                                            <div className="card-body my_account">
                                            {!isImportClicked && !fileName && <h3 class="title mb-0 form-title">Generate Form 1098</h3>}
                                                {!isImportClicked && <div className='form_section'>
                                                    <div className='d-flex align-items-center justify-content-between w-100'>
                                                       
                                                            <div className="form-group year"><label>Year</label>
                                                                <input id="form98Year" type='text' name="year" className={`form-control ${yearValid && 'invalid-input'}`} value={selectedYear} onChange={handleYear} placeholder='Enter year' maxLength="4" onFocus={(e) => handleFocus(e)}/>
                                                            </div>
                                                        
                                                      
                                                            <div className="form-group ms-2 organization"><label>Organization</label>
                                                                <select  id="form98Organization" className={`form-control ${formErrors?.name && 'invalid-input'}`} name="name" value={formValues?.name} onChange={handleForm} onFocus={(e) => handleFocus(e)}>
                                                                    <option value="">Select Organization</option>
                                                                    {namesDropdown.map((name, index) => (
                                                                        <option key={index} value={name} >
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        
                                                     
                                                            <div className="form-group ms-2 organization"><label>Lender EIN</label>
                                                                <input id="lenderEin" type='text' className='form-control lenderlin' name="lenderlin"
                                                                    value={formValues?.lenderlin} placeholder='Lender EIN'
                                                                    readOnly
                                                                    style={{ background: 'rgb(233, 236, 239)' }}
                                                                />

                                                            </div>
                                                        
                                                            <div className="form-group filters ms-2">
                                                                <label>Loan Id's</label>
                                                                <span style={{color:'red'}} className='ms-2'>(You can add multiple Loan Ids.)</span>
                                                                
                                                                <div className="email-input-container">
                                                                    <div className='form-group search_block'>
                                                                        <input 
                                                                            id="form98LoanIds"
                                                                            ref={inputRef} 
                                                                            type="text" 
                                                                            placeholder='Search Loan ID' 
                                                                            name="searchInputValue" 
                                                                            value={loanIDInput ?? ""} 
                                                                            onChange={handleLoanIDChange} 
                                                                           
                                                                            onFocus={(e) => { 
                                                                                setSearchErr(""); 
                                                                                setShowOptions(true); 
                                                                                handleFocus(e);
                                                                            }} 
                                                                            onKeyDown={handleKeyDown}
                                                                            className={`form-control ${formErrors?.searchInputValue && 'invalid-input'}`}
                                                                        />
                                                                        
                                                                        {searchLoader && loanIDInput.length > 0 && 
                                                                            <img src='assets/images/loader.gif' className='search_spinner' />}
                                                                        {!searchLoader && loanIDInput.length > 0 && 
                                                                            <img 
                                                                                src='assets/images/close.svg' 
                                                                                onClick={() => { 
                                                                                    setFilteredLoans([]); 
                                                                                    setLoanIDInput(""); 
                                                                                }} 
                                                                                className='search_close' 
                                                                            />}
                                                                        <button className="material-symbols-outlined btn pe-none">search</button>
                                                                        
                                                                        {/* {showOptions && (
                                                                            <div className="dropdown" style={{height : (filteredLoans?.length > 0 ? '200px' : 0),overflowY : 'auto'}}>
                                                                                {loanIDInput.trim().length >= 2 && filteredLoans?.length > 0 ? (
                                                                                    filteredLoans.map((option, index) => {
                                                                                        let title = option;
                                                                                        return (
                                                                                            <div 
                                                                                                key={index} 
                                                                                                className={`item ${focusedIndex === index ? 'focused' : ''}`} 
                                                                                                onClick={() => { 
                                                                                                    selectSearched(option);
                                                                                                    setFormValues((prevData) => {
                                                                                                        if (!prevData.loanIDs.includes(option)) {
                                                                                                            return {
                                                                                                                ...prevData,
                                                                                                                loanIDs: [...prevData.loanIDs, option],
                                                                                                            };
                                                                                                        }
                                                                                                        return prevData;
                                                                                                       
                                                                                                    });
                                                                                                    setLoanIDInput(""); // Clear input after selecting
                                                                                                    setShowOptions(false); // Hide options after selection
                                                                                                }}
                                                                                            >
                                                                                                {title}
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    filteredLoans?.length === 0 && loanIDInput.length >= 2 && !searchLoader && (
                                                                                        <div className='item'>No searched Data found</div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )} */}
                                                                        {showOptions && (
                                                                            <div className="dropdown" style={{ height: filteredLoans?.length > 0 ? '200px' : 0, overflowY: 'auto' }}>
                                                                                {loanIDInput.trim().length >= 2 && filteredLoans?.length > 0 ? (
                                                                                    filteredLoans
                                                                                        .sort((a, b) => {
                                                                                            const aStr = String(a).toLowerCase(); // Convert to string and lowercase
                                                                                            const bStr = String(b).toLowerCase(); // Convert to string and lowercase
                                                                                            const aMatch = aStr.includes(loanIDInput.toLowerCase());
                                                                                            const bMatch = bStr.includes(loanIDInput.toLowerCase());
                                                                                            return aMatch === bMatch ? 0 : aMatch ? -1 : 1;
                                                                                        })
                                                                                        .map((option, index) => {
                                                                                            const title = String(option); // Ensure title is a string
                                                                                            const isMatch = title.includes(loanIDInput); // Check for match
                                                                                            return (
                                                                                                <div
                                                                                                    key={index}
                                                                                                    className={`item ${focusedIndex === index ? 'focused' : ''} ${isMatch ? 'highlighted' : ''}`}
                                                                                                    onClick={() => {
                                                                                                        selectSearched(option);
                                                                                                        setFormValues((prevData) => {
                                                                                                            if (!prevData.loanIDs.includes(option)) {
                                                                                                                return {
                                                                                                                    ...prevData,
                                                                                                                    loanIDs: [...prevData.loanIDs, option],
                                                                                                                };
                                                                                                            }
                                                                                                            return prevData;
                                                                                                        });
                                                                                                        setLoanIDInput(""); // Clear input after selecting
                                                                                                        setShowOptions(false); // Hide options after selection
                                                                                                    }}
                                                                                                >
                                                                                                    {title}
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                ) : (
                                                                                    filteredLoans?.length === 0 && loanIDInput.length >= 2 && !searchLoader && (
                                                                                        <div className='item'>No searched Data found</div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                    
                                                                    {/* Render Loan ids */}
                                                                    <div className="selected_email">
                                                                        {formValues?.loanIDs?.map((loanid, index) => (
                                                                            <div id="form98CloseLoanId" className="email" key={index} onClick={() => removeLoanID(loanid)} style={{cursor: 'pointer' }}>
                                                                                {loanid}
                                                                                <span 
                                                                                    className="material-symbols-outlined"
                                                                                    
                                                                                    //style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    close
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="form-group ms-2 generate"><label></label>
                                                                {(!isImportClicked) && <button id="form98Generate" type="button"  onClick={(e) => generateForm(loanInfo?.loanid, selectedYear , formValues?.name,formValues?.lenderlin)}  className="create_btn ">Generate</button>}
                                                            </div>
                                                     
                                                    </div>
                                                </div>}

                                                <div className='search'>
                                                
                 
                                                
                                                        {/* Instructional message for multiple emails */}
                                                        {/* <p className="help_text">
                                                        You can add multiple loan id'd. Press "Enter" to add.
                                                        </p> */}
                                                     
                                                         <div className='dsp_flx'>
                                                         {!isImportClicked && !fileName && <h3 class="title mb-0 me-4">Generated History</h3>}
                                                         {showBulkDownload && !fileName && !isImportClicked && (
                                                            <button id="form98BulkDownload" onClick={handleBulkDownload} className="create_btn me-2">Download Selected Files</button>
                                                        )}
                                                       
                                                       
                                                    
                                                        
                                                         </div>
                                                         {!isImportClicked && <button id="form98Import" onClick={handleImport} className="create_btn ">Import</button>}
                                                       {(fileName) && (
                                                            <div className='me-auto d-flex'>
                                                                <h5>File Name : </h5>
                                                                <p class="import-file-name ms-2">{fileName}</p>
                                                            </div>
                                                            
                                                        )}
                                                      
                                                        {fileName && <div>
                                                            <button id="form98Generate" type="button"  onClick={(e) => generateForm(loanInfo?.loanid, selectedYear , formValues?.name,formValues?.lenderlin)}  className="create_btn ms-auto">Generate</button>
                                                        </div>} 
                                                       
                                                        {/* {yearValid && <p className='error yearError'> Please select year</p>} */}
                                                
                                                    {/* <div className="btn-group">
                                                        <button type="button" onClick={(e) => copyForm(loanInfo?.loanid)} className="create_btn">COPY<span className="material-symbols-outlined">content_copy</span></button>
                                                        <button type="button" onClick={(e) => deleteForm(loanInfo?.loanid)} className="create_btn">DELETE<span className="material-symbols-outlined">delete</span></button>
                                                        <button type="button" onClick={(e) => emailForm(loanInfo?.loanid)} className="create_btn">EMAIL<span className="material-symbols-outlined">mail</span></button>
                                                    </div> */}
                                                </div>

                                                {isPopupOpen &&
                                                <PopupMessage
                                                    type={(typeof successMessage === 'string' && (
                                                        successMessage === "Please either import the file or fill in the required details." || 
                                                        successMessage === "Please upload only .xlsx or .xls Excel files." || 
                                                        successMessage === "Invalid file format. Please ensure the headers are correct." || 
                                                        successMessage === "No data Found in the file. Please upload a file that contains data." || 
                                                        successMessage.startsWith("The following loan numbers do not lie between the respective years:") ||
                                                        successMessage === "No rows have been selected."
                                                    )) ? 'warning' : messageType}
                                                    message={successMessage || ""}
                                                    onClose={closePopup}
                                                />
                                                }
                                                             {showDelete && (
                                                                <SweetAlert
                                                                    warning
                                                                    showCancel
                                                                    confirmButtonText="Yes, delete it!"
                                                                    cancelButtonText="No, cancel!"
                                                                    title="Are you sure?"
                                                                    onConfirm={() => handleDelete(deleteData, deleteIndex)} // Pass the correct data and index
                                                                    onCancel={closePopup2}
                                                                    focusCancel={true}
                                                                    customButtons={[
                                                                        React.createElement('button', {
                                                                            id: 'delete-import-yes',
                                                                            className: 'btn btn-primary',
                                                                            onClick: () => handleDelete(deleteData, deleteIndex), // Ensure correct data is passed
                                                                            style: { marginRight: '8px', fontSize: '16px' }
                                                                        }, 'Yes'),
                                                                        React.createElement('button', {
                                                                            id: 'delete-import-no',
                                                                            className: 'btn btn-light',
                                                                            onClick: closePopup2,
                                                                            style: { fontSize: '16px' }
                                                                        }, 'No')
                                                                    ]}
                                                                >
                                                                    <p>Do you really want to delete this Record?</p>
                                                                </SweetAlert>
                                                            )}


                                                {(isImportClicked && !fileName) && <div className='d-flex justify-content-center align-items-center import_content' 
                                                   >
                                                    <div id="form98UploadFile" className="btn btn-gray jr-btn un-btn"> 
                                                        <input type="file" accept=".xlsx, .xls" onChange={handleFileImport}
                                                    id="fileInput"/>
                                                    <img src="https://d9nwtjplhevo0.cloudfront.net/orasi/admin/resources/orasiv1/images/upload-icon.png?auto=compress,format"/></div>
                                                    <h5>Click the Icon to select the file</h5>
                                                   
                                                            {/* Custom Import Button */}
                                                 
                                                   
                                                    <button id="form98DownloadSampleFile" className="create_btn mt-3" onClick={handleDownloadSampleFile}><span className="material-symbols-outlined">download_2</span>Sample Template</button>

                                                </div>}

                                               

                                               


                                                <div className="table-responsive">
                                                    {(fileName && isImportClicked) ? (
                                                        <>
                                                            <DataTable
                                                                columns={importcolumns}
                                                                data={paginatedImportData}
                                                                pagination={false} // Disable built-in pagination
                                                                customStyles={customStyles}
                                                                persistTableHead
                                                                sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                                                onSort={handleSortImport} // Add a handler if server-side sorting is require
                                                            />
                                                            <CustomPagination
                                                                totalRows={importFileTableData.length}
                                                                rowsPerPage={rowsPerPage}
                                                                currentPage={currentPage}
                                                                onPageChange={handleImportPageChange}
                                                                onRowsPerPageChange={handleImportRowsPerPageChange}
                                                            />
                                                        </>
                                                    ) : (!isImportClicked && !fileName) ? (
                                                        <DataTable
                                                            columns={columns}
                                                            data={form1098Data}
                                                            pagination
                                                            customStyles={customStyles}
                                                            paginationTotalRows={totalRows}
                                                            paginationServer
                                                            paginationPerPage={perPage}
                                                            paginationDefaultPage={page}
                                                            selectableRows
                                                            clearSelectedRows={toggledClearRows}
                                                            onSelectedRowsChange={handleChange}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                            paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                                            paginationComponentOptions={{
                                                                rowsPerPageText: 'Per Page',
                                                            }}
                                                            persistTableHead
                                                            sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                                            onSort={handleSort} // Add a handler if server-side sorting is require
                                                            //zdcurrentPage={page} // Make sure you appropriately set the currentPage prop
                                                        />
                                                    ) : null}
                                                </div>

                                               


                                                {showDoc && <FileViewer source={docPage?.url} type={docPage?.type} close={setshowDoc} name={docPage?.filename} />}

                                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <HomeFooter />
            </div>

        </div>
    );
};

export default Adminformform1098;