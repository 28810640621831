import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useLocation, useNavigate} from 'react-router-dom';
import {getSingleInvestor,addTransaction,getTransaction, updateTransactions , deleteTransaction} from "../utils/reducer";
import SweetAlert from 'react-bootstrap-sweetalert';   
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils.js';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TransactionSummary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location',location?.state);
    const investorid = location.state?.investorid;
    const investoruuid = location.state?.selectedinvestor?.investorid;
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
    const [investorData, setInvestorData] = useState(location.state?.selectedinvestor);
    const [transactionData,setTransactionData] = useState({
        totaldeposits : 0,
        totalwithdrawals : 0,
        closingbalance : 0,
        interestGain : 0
    });
    const [filter , setFilter] = useState({});
    const [sortBy,setSortBy] = useState('Descending');
    const [sorted , setSorted] = useState(false);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [tableKey, setTableKey] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteID, setDeleteID] = useState('');
    const [reasons , setReasons] = useState([]);
    // transactions states
    const [transactionInfo,setTransactionInfo] = useState([]);
    const columns = [
        // {
        //   name: 'Transaction Id',
        //   selector: (row) => row?.transactionId || '' ,
        //   minWidth: '24%',
        //   maxWidth: '24%',
        // },
        {
            name: 'Date',
            selector: (row) => row?.transactionDate ? moment.utc(row?.transactionDate).format("MM-DD-YYYY") : '',
            minWidth: '14%',
            maxWidth: '14%',
        },
       
        ,{
            name: 'Notes',
            selector: (row) => {
                return (
                    <div className="clmn">
                            <span>{row?.notes}</span>
                        </div>
                );
            },
            minWidth: '28%',
            maxWidth: '28%',
          },
          ,{
            name: 'DEPOSITS',
            cell: (row, index) => (
                <>
                    {row?.deposit ? (
                        row?.reason?.length > 0 ? (
                            <>
                            <p id={'reasonedit-deposit'+index} data-bs-target= '#reasonpopup'// Dynamically set target modal
               data-bs-whatever='reasonpopup' // Optional, if needed for additional context
               data-bs-toggle="modal" className='editvalue'
               onClick={()=>{setReasons(row?.reason)}}
               style={{ cursor: "pointer" }}> {formatCurrency(row?.deposit)}</p>
                            <spam>  </spam></>
                        ) : (
                            formatCurrency(row?.deposit)
                        )
                    ) : (
                        ''
                    )}
                </>
            ),
            
            // selector: (row) => row?.deposit ?  formatCurrency(row?.deposit) : '',
            minWidth: '12%',
            maxWidth: '12%',
          },
          ,{
            name: 'withdrawals',
            cell: (row, index) => (
                <>
                    {row?.withdraw ? (
                        row?.reason?.length > 0 ? (
                            <>
                            <p id={'reasonedit-withdraw'+index} data-bs-target= '#reasonpopup'// Dynamically set target modal
               data-bs-whatever='reasonpopup' // Optional, if needed for additional context
               data-bs-toggle="modal" className='editvalue'
               onClick={()=>{setReasons(row?.reason)}}
               style={{ cursor: "pointer" }}> {formatCurrency(row?.withdraw)}</p>
                            <spam>  </spam></>
                        ) : (
                            formatCurrency(row?.withdraw)
                        )
                    ) : (
                        ''
                    )}
                </>
            ),
            // selector: (row) => row?.withdraw ?  formatCurrency(row?.withdraw) : '',
            minWidth: '12%',
            maxWidth: '12%',
          },
          {
          name: 'Interest Gain',
          cell: (row, index) => (
            <>
                {row?.interest ? (
                    row?.reason?.length > 0 ? (
                        <>
                        <p id={'reasonedit-interest'+index} data-bs-target= '#reasonpopup'// Dynamically set target modal
           data-bs-whatever='reasonpopup' // Optional, if needed for additional context
           data-bs-toggle="modal" className='editvalue'
           onClick={()=>{setReasons(row?.reason)}}
           style={{ cursor: "pointer" }}> {formatCurrency(row?.interest)}</p>
                        <spam>  </spam></>
                    ) : (
                        formatCurrency(row?.interest)
                    )
                ) : (
                    ''
                )}
            </>
        ),
        //   selector: (row) =>  row?.interest ?  formatCurrency(row?.interest) : '',
          minWidth: '12%',
          maxWidth: '12%',
        },
          ,{
            name: 'balance',
            selector: (row) => row?.balance ? formatCurrency(row?.balance) : '$0.00',
            minWidth: '12%',
            maxWidth: '12%',
          },
          {
            name: 'ACTIONS',
            cell: (row,index) => (<>
            <>
            <button
                    id={`editTransaction-${index}`}
                    onClick={(e) => {
                        handleEdit(row); // Your edit logic here
                    }}
                    data-bs-target={row.type === 'INTEREST' ? "#interestupdate" : row?.type==='DEPOSIT' ? "#adddeposit" : "#addwithdrawal"} // Dynamically set target modal
                    data-bs-whatever={row.type === 'DEPOSIT' ? "Deposit" : "Withdrawal"} // Optional, if needed for additional context
                    data-bs-toggle="modal"
                    className="icon_btn edit"
                >
                    <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">
                        edit
                    </span>
                </button>
              <button id={`deleteTransaction-${index}`}
                data-bs-target={row.type === 'INTEREST' ? "#interestupdate" : row?.type==='DEPOSIT' ? "#adddeposit" : "#addwithdrawal"} // Dynamically set target modal
               data-bs-whatever={row.type === 'DEPOSIT' ? "Deposit" : "Withdrawal"} // Optional, if needed for additional context
               data-bs-toggle="modal"
              onClick={(e) => { handleDelete(row); }} className="icon_btn edit">
              <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon">delete</span>
            </button>
            </>
            
                </>
            ),
            minWidth: '10%',
            maxWidth: '10%',
          },
      ];

    //add withdrawal
   const [withdrawalData , setWithdrawalData] = useState({});
    const [formErrors, setFormErrors] = useState({});

     //add deposit
   const [depositData , setDepositData] = useState({});

  
   // interest states
   const [interestData,setInterestData] = useState({});

    useEffect(() => {
        if(investorid){
            getTransactionData(currentPage,perPage,{},sortBy);
        }
    },[]);
    // api's 
    const getTransactionData = (page,perpage,filter,sortBy) => {
        dispatch(showLoader());
        let type = filter || {}
        getTransaction(page,perpage,investoruuid,type,sortBy)
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    let responsedata = response?.result?.data
                    setTransactionInfo(responsedata);
                    setTotalRows(response?.result?.totalCount);
                    setTransactionData({
                        totaldeposits: response?.result?.totalDepositAmount,
                        totalwithdrawals: response?.result?.totalWithdrawAmount,
                        closingbalance: response?.result?.closingBalance,
                        interestGain : response?.result?.interestGain
                    });
                    // if(!filter?.fromdate && !filter?.todate && !filter?.sortby){
                    //     const totals = responsedata.reduce(
                    //         (acc, transaction) => {
                    //             if (transaction.type === 'DEPOSIT') {
                    //                 acc.totaldeposits += transaction.amount || 0;
                    //             } else if (transaction.type === 'WITHDRAW') {
                    //                 acc.totalwithdrawals += transaction.amount || 0;
                    //             }
                    //             return acc;
                    //         },
                    //         { totaldeposits: 0, totalwithdrawals: 0 }
                    //     );
                    //     const closingBalance = responsedata.length > 0 ? responsedata[0].balance : 0;
    
                       
                    // }
                    
                    dispatch(hideLoader());
                } else {
                    setTransactionInfo([]);
                    hideLoader([]);
                }
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    const addTransactionApi = (data) => {
        dispatch(showLoader());
        addTransaction(data)
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    setPopupOpen(true);
                    let filter = {};
                     setCurrentPage(1);
                   getTransactionData(1, perPage, filter,sortBy);
                    setFilter({});
                }
                dispatch(showLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    const updateTransactionApi = (id,data) => {
        dispatch(showLoader());
        updateTransactions(id,data)
            .then(function(response) {
                console.log('responseeee', response);
                if (response?.result) {
                    setPopupOpen(true);
                    let filter = {};
                     setCurrentPage(1);
                    getTransactionData(1, perPage, filter,sortBy);
                    setFilter({});
                }
                dispatch(showLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    const deleteTransactionsApi = (data) => {
        dispatch(showLoader());
        deleteTransaction(investoruuid,deleteID,data)
            .then(function(response) {
                console.log('responseeee', response);
                if (response?.result) {
                    setPopupOpen(true);
                    let filter = {};
                     setCurrentPage(1);
                    getTransactionData(1, perPage, filter,sortBy);
                    setFilter({});
                }
                dispatch(showLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };

    
    const handleChange =(e,section)=>{
        let {name , value ,type} = e.target;
        if(section === 'withdrawal'){
            setWithdrawalData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }else if(section === 'deposit'){
            setDepositData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }else if(section === 'interest'){
            setInterestData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
       
    };
    const handleEdit =(data)=>{
        setDeleteID('');
        setFormErrors({});
        console.log(data);
        if(data?.type === 'DEPOSIT'){
            let stateDepositData = {
                type : data?.type,
                depositamount : data?.deposit,
                depositnotes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setWithdrawalData({});
            setInterestData({})
            setDepositData(stateDepositData);
        }else if(data?.type === 'WITHDRAW'){
            let stateWithdrawData = {
                type : data?.type,
                withdrawalamount : data?.withdraw,
                withdrawalnotes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setDepositData({});
            setInterestData({})
            setWithdrawalData(stateWithdrawData);
        }else if(data?.type === 'INTEREST'){
            let stateInterestData = {
                type : data?.type,
                interest : data?.interest,
                notes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setInterestData(stateInterestData);
            setDepositData({});
            setWithdrawalData({});
        }
    };
    const handleDelete =(data)=>{
        if(data?.type === 'DEPOSIT'){
            let stateDepositData = {
                type : data?.type,
                depositamount : data?.deposit,
                depositnotes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setDepositData(stateDepositData);
            setWithdrawalData({});
        }else if(data?.type === 'WITHDRAW'){
            let stateWithdrawData = {
                type : data?.type,
                withdrawalamount : data?.withdraw,
                withdrawalnotes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setWithdrawalData(stateWithdrawData);
            setDepositData({});
        }else if(data?.type === 'INTEREST'){
            let stateInterestData = {
                type : data?.type,
                interest : data?.interest,
                notes : data?.notes,
                transactionDate : data?.transactionDate,
                transactionId : data?.transactionId
            }
            setInterestData(stateInterestData);
            setWithdrawalData({});
        }
        setFormErrors({});
        setDeleteID(data?.transactionId);
        // setShowConfirmation(true);
    };

    const handleDeleteTransaction =()=>{
        console.log('deleteID',deleteID);
        
        if(withdrawalData?.transactionId){
            const fieldsToCheck = { 
                'withdrawalamount' : 'withdrawalamount','transactionDate' : 'transactionDate', 'reason' : 'reason'
        };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = withdrawalData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!withdrawalData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(withdrawalData.withdrawalamount == 0){
            error['withdrawalamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors---> withdraw',error)
        if (Object.keys(error).length === 0) {
            setShowConfirmation(true);
            document.getElementById('closedeposit').click();
            document.getElementById('closewithdrawal').click();
            setMessage('Deleted Successfully');
        }
        }else if(depositData?.transactionId){
            const fieldsToCheck = { 
            'depositamount' : 'depositamount','transactionDate' : 'transactionDate' ,'reason' : 'reason'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = depositData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!depositData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(depositData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors---> deposit',error)
        if (Object.keys(error).length === 0) {
            setShowConfirmation(true);
            document.getElementById('closedeposit').click();
            document.getElementById('closewithdrawal').click();
            setMessage('Deleted Successfully');
        }
        }
        
    };
    const handleDeleteInterest =()=>{
        console.log('investorData',depositData);
        const fieldsToCheck = { 
            'interest' : 'interest','transactionDate' : 'transactionDate','reason' : 'reason'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = interestData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!interestData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(interestData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            setShowConfirmation(true);
            document.getElementById('interestpopup').click();
            document.getElementById('closedeposit').click();
            document.getElementById('closewithdrawal').click();
            setMessage('Deleted Successfully');
        }
    }
    const handleDeleteData =()=>{
        console.log(deleteID,withdrawalData,depositData);
        if(withdrawalData?.transactionId){
            deleteTransactionsApi(withdrawalData);
        }else if(depositData?.transactionId){
            deleteTransactionsApi(depositData);
        }else if(interestData?.transactionId){
            deleteTransactionsApi(interestData);
        }
        setShowConfirmation(false);
    };


    useEffect(()=>{
        console.log('depositData',depositData,'withdrawalData',withdrawalData);
    },[depositData,withdrawalData]);
   
    const handleInvestorFocus = (e) => {
            setFormErrors({...formErrors,[e.target.name] : ''});
       
      }
    const handleAdd = ()=>{
        setFormErrors({});
        setWithdrawalData({});
        setDepositData({});
        setDeleteID('');
    }

    const handleSort =(e)=>{
        setFilter({
            ...filter,
            [e.target.name] : e.target.value,
        });
    };
    // const handleSortApi =()=>{
    //     if(filter?.fromdate && filter?.todate){
    //         getTransactionData(currentPage,perPage,filter,sortBy);
    //         setSorted(true);
    //     }
    // }
    const handleSortBy = (e)=>{
        setSortBy(e.target.value);
        getTransactionData(currentPage,perPage,filter,e.target.value);
    }
    
    useEffect(()=>{
        console.log('filter',filter)
        if(filter?.fromdate && filter?.todate){
            getTransactionData(currentPage,perPage,filter,sortBy);
            setSorted(true);
        }
    },[filter]);

    const handleClearSort = () => {
        let filter = '';
        setCurrentPage(1);
        getTransactionData(1, perPage, filter,sortBy);
        setTableKey(prevKey => prevKey + 1);
        setSorted(false);
        setFilter({});
    };

    const handleAddWithdrawal = () => {
        console.log('investorData',withdrawalData);
        const fieldsToCheck = { 
            'withdrawalamount' : 'withdrawalamount','transactionDate' : 'transactionDate'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = withdrawalData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!withdrawalData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(withdrawalData.withdrawalamount == 0){
            error['withdrawalamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData =
            {"type":"WITHDRAW","notes":withdrawalData?.withdrawalnotes,"amount":parseFloat(withdrawalData?.withdrawalamount),"investorid":investoruuid,"transactionDate":withdrawalData?.transactionDate}
            apiData.amount =  apiData.amount ?  parseFloat(apiData.amount) :  null;
            document.getElementById('closewithdrawal').click();
            setMessage('Withdrawal Added Successfully')
            addTransactionApi(apiData);
            setSorted(false);
            setFilter({});
            // api call
        }
    };
    
    const handleAddDeposit = () => {
        console.log('investorData',depositData);
        const fieldsToCheck = { 
            'depositamount' : 'depositamount','transactionDate' : 'transactionDate'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = depositData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!depositData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(depositData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData = 
            {"type":"DEPOSIT","notes":depositData?.depositnotes,"amount":parseFloat(depositData?.depositamount),"investorid":investoruuid,"transactionDate":depositData?.transactionDate}
            apiData.amount =  apiData.amount ?  parseFloat(apiData.amount) :  null;
            document.getElementById('closedeposit').click();
            setMessage('Deposit Added Successfully')
            addTransactionApi(apiData);
            setSorted(false);
            setFilter({});
            // api call
        }
    };

    const handleUpdateDWithdrawal =()=>{
        console.log('investorData',withdrawalData);
        const fieldsToCheck = { 
            'withdrawalamount' : 'withdrawalamount','transactionDate' : 'transactionDate','reason' : 'reason'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = withdrawalData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!withdrawalData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(withdrawalData.withdrawalamount == 0){
            error['withdrawalamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData = 
                {"type": withdrawalData?.type ,"notes":withdrawalData?.withdrawalnotes,"amount":parseFloat(withdrawalData?.withdrawalamount),"investorid":investoruuid,"transactionDate":withdrawalData?.transactionDate , 
                'transactionId' :withdrawalData?.transactionId  ,'reason' : withdrawalData?.reason}
            updateTransactionApi(withdrawalData?.transactionId,apiData)
            document.getElementById('closewithdrawal').click();
            setMessage('Withdrawal Updated Successfully');
            
        }
        
    }

    const handleUpdateDeposit =()=>{
        console.log('investorData',depositData);
        const fieldsToCheck = { 
            'depositamount' : 'depositamount','transactionDate' : 'transactionDate','reason' : 'reason'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = depositData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!depositData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(depositData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData = 
                {"type":depositData?.type,"notes":depositData?.depositnotes,"amount":parseFloat(depositData?.depositamount),"investorid":investoruuid,"transactionDate":depositData?.transactionDate, 'transactionId' :depositData?.transactionId  ,'reason' : depositData?.reason}
            updateTransactionApi(depositData?.transactionId,apiData)
            document.getElementById('closedeposit').click();
        setMessage('Deposit Updated Successfully');
        }
       
    };
    const handleUpdateInterest =()=>{
        console.log('investorData',depositData);
        const fieldsToCheck = { 
            'interest' : 'interest','transactionDate' : 'transactionDate','reason' : 'reason'
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = interestData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!interestData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if(interestData.depositamount == 0){
            error['depositamount'] = 'please enter more than 0'
        }
        setFormErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            let apiData = 
                {"type":interestData?.type,"notes":interestData?.notes,"interest":parseFloat(interestData?.interest),"investorid":investoruuid,"transactionDate":interestData?.transactionDate, 'transactionId' :interestData?.transactionId  ,'reason' : interestData?.reason}
            updateTransactionApi(interestData?.transactionId,apiData)
            document.getElementById('interestpopup').click();
        setMessage('Interest Updated Successfully');
        }
    }
    const closePopup = () => {
        setPopupOpen(false);
        setShowConfirmation(false);
    };
    const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
         
        // Format table data for Excel
        const formattedData = transactionInfo.map(item => ({
            
            // 'TRANSACTION ID' : item?.transactionId || '',
            'DATE' : item.transactionDate ? moment.utc(item.transactionDate).format("MM-DD-YYYY") : '',
            'NOTES' : item?.notes || '',
            'DEPOSITS' : item?.deposit ? formatCurrency(item?.deposit) : '' ,
            'WITHDRAWALS': item?.withdraw  ? formatCurrency(item?.withdraw) : '',
            'INTEREST GAIN' : item?.interest ? formatCurrency(item?.interest) : '',
            'BALANCE' : item?.balance ? formatCurrency(item?.balance) : '$0.00',
            
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, investorData?.firstname + ' ' + investorData?.lastname + '_transaction' + fileExtension);
    };

    const handlePageChange = (page) => {
        // if (!isSorting.current) {
            console.log('rannnn',filter);
            let search = '';
            // let payload = { ...sortObj };
            if(filter?.fromdate && filter?.todate){
                getTransactionData(page,perPage,filter,sortBy);
                setSorted(true);
            }else{
                getTransactionData(page,perPage,'',sortBy);
            }
            setCurrentPage(page);
        // }
      };
        const handlePerRowsChange = async (newPerPage, page) => {
          // if (!isSorting.current) {
            console.log('i ran tooo')
            
            // let payload = { ...sortObj };
            if(filter?.fromdate && filter?.todate){
                getTransactionData(page,newPerPage,filter,sortBy);
                setSorted(true);
            }else{
                getTransactionData(page,newPerPage,'',sortBy);
            }
            setCurrentPage(page);
            setPerPage(newPerPage);
          // }
        };


   
      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };
      return (
        <div id="layout-wrapper">

{showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={() => handleDeleteData()}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                  React.createElement('button', {
                      id: 'delete-transaction-yes',
                      className: 'btn btn-primary',
                      onClick: handleDeleteData,
                      style: { marginRight: '8px' ,fontSize: '16px'}
                  }, 'Yes'),
                  React.createElement('button', {
                      id: 'delete-transaction-no',
                      className: 'btn btn-light',
                      onClick: closePopup,
                      style: { fontSize: '16px'}
                  }, 'No')
              ]}
            >
                    <p>Do you really want to delete this transaction?</p>
                {/* Additional content can go here */}
            </SweetAlert>}
 {isPopupOpen && (
        <PopupMessage
          type={'success'}
          message={message}
          onClose={closePopup}
        />
      )}
{loading ? <Loader/> : null} 
           <MainHeader />
         
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement fund_management transaction_page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">transaction</h4>
                                <a href="#" id="navigateManagerBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                <div className="steps">
                                    <div className="deposit_withdrawl">
                                    <button type="button" id='addwithdraw'
                                     disabled={transactionData?.closingbalance === 0 ? true : false} style={{'opacity' : transactionData?.closingbalance === 0 ? 0.6 : 1}}
                                      onClick={handleAdd} data-bs-target="#addwithdrawal" data-bs-whatever="@mdo" data-bs-toggle="modal" className="create_btn new_button mb-1"> Withdrawal<span className="material-symbols-outlined">payments</span></button>
                                    <button type="button" id='addDeposit'  onClick={handleAdd} data-bs-target="#adddeposit" data-bs-whatever="@mdo" data-bs-toggle="modal" className="create_btn new_button"> Deposit<span className="material-symbols-outlined">savings</span></button>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info"><span className="text-type">Total Deposits</span>
                                    <span className="text">{transactionData?.totaldeposits ? formatCurrency(transactionData?.totaldeposits) : '$0.00'}</span>
                                    
                                    </div>
                                    <span className="material-symbols-outlined">savings</span>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text-type">Total Withdrawals</span>
                                    <span className="text">{transactionData?.totalwithdrawals ? formatCurrency(transactionData?.totalwithdrawals) : '$0.00'}</span>
                                   
                                    </div>
                                    <span className="material-symbols-outlined">payments</span>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text-type">Interest Gain</span>
                                    <span className="text">{transactionData?.interestGain ? formatCurrency(transactionData?.interestGain) : '$0.00'}</span>
                                   
                                    </div>
                                    <span className="material-symbols-outlined">trending_up</span>
                                    </div>
                                    <div className="box bx_progress">
                                    <div className="info">
                                    <span className="text-type">Closing Balance</span>
                                    <span className="text">{transactionData?.closingbalance ? formatCurrency(transactionData?.closingbalance) : '$0.00'}</span>
                                   
                                    </div>
                                    <span className="material-symbols-outlined">account_balance_wallet</span>
                                    </div>
                                   </div>
                                   <div className='search'>
                                        <h5 className="title mt-0 mb-0"> {investorData?.firstname ? (investorData?.firstname + ' ' + investorData?.lastname) : ''}</h5>
                                        <div className="d-flex align-items-center form-group">
                                        <div className="d-flex align-items-center">
                                            <label>From</label>
                                            <input id="fromdate" type="date" placeholder="mm-dd-yyy" name="fromdate" onChange={handleSort}  class="form-control null" value={filter?.fromdate || ''}/>
                                            </div>
                                            <div className="d-flex align-items-center">
                                            <label>To</label>
                                            <input id="todate" type="date" placeholder="mm-dd-yyy" name="todate" onChange={handleSort} max={new Date().toISOString().split('T')[0]} class="form-control null" value={filter?.todate || ''}/>
                                            </div>
                                        {/* <div className='clear'>
                                            <button type="button" className="border_btn" onClick={handleSortApi}>Filter </button>
                                        </div> */}
                                            {sorted &&
                                        <div className='clear'>
                                            {/* <h4>Sort results</h4> */}
                                            <button type="button" className="border_btn" onClick={handleClearSort}>Clear </button>
                                        </div>
                                    }
                                        <div className="d-flex align-items-center">
                                            <label>Sort By</label>
                                            <select id="sortby" name="sortby" value={sortBy|| ''} onChange={handleSortBy} className="form-control" tabindex="1">
                                                {/* <option value="">Select</option> */}
                                                <option value="Ascending">Ascending</option>
                                                 <option value="Descending">Descending</option>
                                            </select>
                                            </div>
                                            <button type="button" disabled={transactionInfo?.length == 0 ? true : false} style={{'opacity' : transactionInfo?.length == 0 ? 0.6 : 1}} onClick={handleExportToExcel} className=" es create_btn new_button ms-2">Export Statement<span className="material-symbols-outlined">arrow_outward</span></button>
                                        </div>
                                    </div>
                                    {/* {sorted &&
                                        <div className='search_result'>
                                            <h4>Sort results</h4>
                                            <button type="button" className="border_btn" onClick={handleClearSort}>Clear Sort </button>
                                        </div>
                                    } */}
                                <div class="table-responsive">
                                    {transactionInfo?.length > 0 && 
                                      <DataTable 
                                      key={tableKey}
                                      columns={columns}
                                      data={transactionInfo}
                                      paginationTotalRows={totalRows}
                                      paginationPerPage={perPage}
                                      paginationDefaultPage={currentPage}
                                      pagination
                                      paginationServer
                                      customStyles={customStyles}
                                      onChangePage={handlePageChange}
                                      onChangeRowsPerPage={handlePerRowsChange}
                                      paginationRowsPerPageOptions={[10, 15, 30, 40]}
                                      paginationComponentOptions={{
                                          rowsPerPageText: 'Per Page',
                                      }}
                                      persistTableHead
                                      noDataComponent="No results found" // Show a message when there are no rows
                                  />
                                    }
                              
                                        </div>

                                   

                                    {transactionInfo?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                    <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="addwithdrawal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{deleteID ? 'DELETE' :  withdrawalData?.transactionId ? 'UPDATE' : 'ADD'} WITHDRAWAL</h5>
                                        <button type="button" className="pop_close" id="closewithdrawal" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>WITHDRAWAL AMOUNT</label>
                                            <CurrencyInput
                                                         id={'withdrawal_amount'}
                                                         name={'withdrawalamount'}
                                                         placeholder="Enter Amount"
                                                         value={withdrawalData?.withdrawalamount}
                                                         className={`form-control null ${formErrors.withdrawalamount && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         disabled={deleteID ? true : false}
                                                         prefix="$"
                                                         onFocus={handleInvestorFocus}
                                                         onValueChange={(value, name, values) => {
                                                            setWithdrawalData((prev) => {
                                                                if (values.value <= transactionData.closingbalance) {
                                                                    return {
                                                                        ...prev,
                                                                        withdrawalamount:  (values.value) || '',
                                                                    };
                                                                } else {
                                                                    return prev; 
                                                                }
                                                            });
                                                         }}
                                                       />
                                            </div>
                                        </div>
                                              <div className="col-md-12">
                                                  <div className="form-group">
                                                      <label>Date</label>
                                                      <input id="withdraw_date"  disabled={deleteID ? true : false} type="date" className={`form-control  ${formErrors?.transactionDate && 'invalid-input'}`}  max={new Date().toISOString().split('T')[0]} placeholder="mm-dd-yyyy" name="transactionDate" onChange={(e)=>{handleChange(e,'withdrawal')}} value={ withdrawalData?.transactionDate ? (new Date(withdrawalData?.transactionDate).toISOString().split('T')[0]) : ''}
                                                        onFocus={handleInvestorFocus}/>
                                                  </div>
                                              </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Notes</label>
                                            <textarea  id="withdrawalnotes"  disabled={deleteID ? true : false} name='withdrawalnotes'  value={withdrawalData?.withdrawalnotes || ''} onChange={(e)=>{handleChange(e,'withdrawal')}} className="form-control null"  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        {withdrawalData?.transactionId && 
                                        <div className="col-md-12">
                                        <div className="form-group">
                                        <label>Reason</label>
                                        <textarea id="withdrawal_reason"   name='reason' value={withdrawalData?.reason || ''} onChange={(e)=>{handleChange(e,'withdrawal')}}  className={`form-control  ${formErrors?.reason && 'invalid-input'}`} onFocus={handleInvestorFocus}  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                        </div>
                                       </div>
                                        }
                                        
                                        
                                           
                                               <div className="col-md-12">
                                            <div className="form-group">
                                            {deleteID ? 
                                                 <button id="deleteTransaction-btn" onClick={handleDeleteTransaction} type="button"  className="fs_btn">Delete</button> :

                                                <button id="denial-btn"  onClick={ withdrawalData?.transactionId ? handleUpdateDWithdrawal : handleAddWithdrawal} 
                                                  type="button"  className="fs_btn">{withdrawalData?.transactionId ? 'UPDATE' : 'ADD'}</button>
                                                }    
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="adddeposit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{ deleteID ? 'DELETE' : depositData?.transactionId ? 'UPDATE' : 'ADD'} DEPOSIT</h5>
                                        <button type="button" className="pop_close" id="closedeposit" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>DEPOSIT AMOUNT</label>
                                            <CurrencyInput
                                                         id={'depositamount'}
                                                         name={'depositamount'}
                                                         placeholder="Enter Amount"
                                                         value={depositData?.depositamount}
                                                         className={`form-control null ${formErrors.depositamount && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         disabled={deleteID ? true : false}
                                                         prefix="$"
                                                         onFocus={handleInvestorFocus}
                                                         onValueChange={(value, name, values) => {
                                                            setDepositData((prev) => ({
                                                                ...prev,
                                                                ['depositamount']:  (values.value) || '',
                                                                
                                                            }));
                                                         }}
                                                       />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                                  <div className="form-group">
                                                      <label>Date</label>
                                                      
                                                      <input  disabled={deleteID ? true : false} id="deposit_date" type="date" className={`form-control  ${formErrors?.transactionDate && 'invalid-input'}`}  max={new Date().toISOString().split('T')[0]} placeholder="mm-dd-yyyy" name="transactionDate" onChange={(e)=>{handleChange(e,'deposit')}} value={ depositData?.transactionDate ? (new Date(depositData?.transactionDate).toISOString().split('T')[0]) : ''}  onFocus={handleInvestorFocus} />
                                                  </div>
                                              </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Notes</label>
                                            <textarea  disabled={deleteID ? true : false} id="depositnotes" name='depositnotes' value={depositData?.depositnotes || ''} onChange={(e)=>{handleChange(e,'deposit')}}  className="form-control null"  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        {depositData?.transactionId &&
                                        <div className="col-md-12">
                                        <div className="form-group">
                                        <label>Reason</label>
                                        <textarea id="deposit_reason" name='reason' value={depositData?.reason || ''} onChange={(e)=>{handleChange(e,'deposit')}}  className={`form-control  ${formErrors?.reason && 'invalid-input'}`} onFocus={handleInvestorFocus}  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                        </div>
                                                  </div>
                                              }
                                              <div className="col-md-12">
                                                  <div className="form-group">
                                                      {deleteID ?
                                                          <button id="deleteTransaction-btn" onClick={handleDeleteTransaction} type="button" className="fs_btn">Delete</button> :
                                                          <button id="denial-btn" onClick={depositData?.transactionId ? handleUpdateDeposit : handleAddDeposit} type="button" className="fs_btn">{depositData?.transactionId ? 'UPDATE' : 'ADD'}</button>
                                                      }
                                                  </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="interestupdate" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{ deleteID ? 'DELETE' :  'UPDATE'} Interest</h5>
                                        <button type="button" className="pop_close" id="interestpopup" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>INTEREST AMOUNT</label>
                                            <CurrencyInput
                                                         id={'interest'}
                                                         name={'interest'}
                                                         placeholder="Enter Amount"
                                                         value={interestData?.interest}
                                                         className={`form-control null ${formErrors.interest && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         disabled={deleteID ? true : false}
                                                         prefix="$"
                                                         onFocus={handleInvestorFocus}
                                                         onValueChange={(value, name, values) => {
                                                            setInterestData((prev) => ({
                                                                ...prev,
                                                                ['interest']:  (values.value) || '',
                                                                
                                                            }));
                                                         }}
                                                       />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                                  <div className="form-group">
                                                      <label>Date</label>
                                                      
                                                      <input  disabled={deleteID ? true : false} id="deposit_date" type="date" className={`form-control  ${formErrors?.transactionDate && 'invalid-input'}`} 
                                                       max={new Date().toISOString().split('T')[0]} placeholder="mm-dd-yyyy" 
                                                       name="transactionDate" onChange={(e)=>{handleChange(e,'interest')}} value={ interestData?.transactionDate ? (new Date(interestData?.transactionDate).toISOString().split('T')[0]) : ''}  onFocus={handleInvestorFocus} />
                                                  </div>
                                              </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Notes</label>
                                            <textarea  disabled={deleteID ? true : false} id="depositnotes" name='notes' value={interestData?.notes || ''} onChange={(e)=>{handleChange(e,'interest')}}  className="form-control null"  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        {interestData?.transactionId &&
                                        <div className="col-md-12">
                                        <div className="form-group">
                                        <label>Reason</label>
                                        <textarea id="deposit_reason" name='reason' value={interestData?.reason || ''} onChange={(e)=>{handleChange(e,'interest')}}  className={`form-control  ${formErrors?.reason && 'invalid-input'}`} onFocus={handleInvestorFocus}  rows="4" cols="50" placeholder="Enter Notes" spellCheck="false"></textarea>
                                        </div>
                                                  </div>
                                              }
                                              <div className="col-md-12">
                                                  <div className="form-group">
                                                      {deleteID ?
                                                          <button id="deleteTransaction-btn" onClick={handleDeleteInterest} type="button" className="fs_btn">Delete</button> :
                                                          <button id="denial-btn" onClick={interestData?.transactionId && handleUpdateInterest} type="button" className="fs_btn">{interestData?.transactionId ? 'UPDATE' : 'ADD'}</button>
                                                      }
                                                  </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                          <div className="modal popup new_popup fade add_fee approve_pop edit_history"
                              id="reasonpopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                              <div className="modal-dialog">
                                  <div className="modal-content">
                                      <div className="modal-header">
                                          <h5 className="modal-title" id="exampleModalLabel">EDIT HISTORY</h5>
                                          <button type="button" className="pop_close" id="interestpopup" data-bs-dismiss="modal"
                                              aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                      </div>
                                      <div className="modal-body">
                                          <table className="table db_table">

                                              <thead className="table-light">
                                                  <tr>
                                                      <th width="5%">#</th>
                                                      <th width="20%">ACTUAL VALUE</th>
                                                      <th width="20%">Edited BY</th>
                                                      <th width="20%">EDITED DATE</th>
                                                      <th width="30%">REASON</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {reasons.map((data, index) => {
                                                      return (
                                                          <>
                                                              <tr>
                                                                  <td>{index + 1}</td>
                                                                  <td>{(data?.deposit  || data?.withdraw || data?.interest || '') ? formatCurrency(data?.deposit  || data?.withdraw || data?.interest || '')  : ''}</td>
                                                                  <td>{data?.modifiedBy?.userName || ''}</td>
                                                                  <td>{data?.modifiedBy?.modified ? moment.utc(data?.modifiedBy?.modified).format("MM-DD-YYYY") : ''}</td>
                                                                  <td>
                                                                      {data?.reason}
                                                                  </td>
                                                              </tr>

                                                          </>
                                                      )
                                                  })}

                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default TransactionSummary;