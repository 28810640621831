import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {getDropDownLookups, updateLookupsData} from "../utils/reducer";
import data from '../JsonData/dropDownJson.json';
import SweetAlert from 'react-bootstrap-sweetalert';    
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage';
import { useNavigate } from 'react-router-dom';
import HomeFooter from './HomeFooter';

const DropdownManagement = () => {
      const navigate = useNavigate();
      const dispatch = useDispatch();
      const loading = useSelector((state) => state.loaderVisible);
      const [isPopupOpen, setPopupOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState('initial');
      const [selectedLookup, setSelectedLookup] = useState('initial');
      const [buttonType , setButtonType] = useState('add');
      const [show,setShow] = useState(false);
      const [dropDownData , setDropDownData] = useState([]);
      const [selectedAll , setSelectedAll] = useState(false);
      const [editIndex, setEditIndex] = useState('');
      const [error , setError] = useState(false);
      const [changeText, setChangeText] = useState('');
      const [popupMessage, setmessage] = useState('Updated successfully');
      const [dropdownOld , setDropdownOld] = useState([]);
      const [arrowClicked,setArrowClicked] = useState('');
      const [lookupBool,setLookUpBool] = useState(false);
      const [toggleChecked,setToggleChecked] = useState(false);
      const [isDropDownUpdated,setIsDropDownUpdated] = useState(false);
      let itemAdded = false;
    
      // const chunkedData = dropDownData;
      // for (let i = 0; i < dropDownData?.length; i += 8) {
      //   const chunk = dropDownData?.slice(i, i + 8);
      //   chunkedData.push(chunk);
      // };
      // console.log('chunkedData',chunkedData);
      console.log('dropDownData',dropDownData);
      const [options, setOptions] = useState([
        { value: "Loan Purpose - ITEMS", label: "Loan Purpose - ITEMS" },
        { value: "Borrower Type", label: "Borrower Type" },
        { value: "Loan Status", label: "Loan Status" },
        { value: "Exit Strategy", label: "Exit Strategy" },
        { value: "Entity Type", label: "Entity Type" },
        { value: "Property Type", label: "Property Type" },
        { value: "Draw Request Type", label: "Draw Request Type" },
        { value: "Impound Type", label: "Impound Type" },
        { value: "Prepayment Type (LOC)", label: "Prepayment Type (LOC)" },
        { value: "Prepayment Penalty", label: "Prepayment Penalty" },
        { value: "Lead/Referral Source", label: "Lead/Referral Source" },
        { value: "Liquid Assets", label: "Liquid Assets" },
        { value: "Location Type", label: "Location Type" },
      ]);

      useEffect(() => {
        
        
        getDropdowns();
        
       
        
      }, []);

      function compareDropdowns(){
         // Filter arrays to find all objects that match the selected state
        const matchedObject  = dropdownOld?.find(obj => obj?.lookup === arrowClicked);//getting clicked lookup data
        console.log("matched object",matchedObject);

        // if (!matchedObject) {
        //   return false; // No matching lookup found
        // }

        // Compare each object in matchedObject.lookupOptions with the corresponding object in dropDownData
        if(matchedObject){
          for(const obj1 of matchedObject?.lookupOptions) {
            const obj2 = dropDownData?.find(obj => obj?.option === obj1?.option);
            console.log("obj2",obj2);
            if (!obj2 || obj1?.selected !== obj2?.selected) {
              return false;
            }
          }
          // for (let i = 0; i < dropDownData?.length; i++) {
          //   const obj1 = arr1[i];
          //   const obj2 = arr2.find(obj => obj.option === obj1.option);
        
          //   if (!obj2 || obj1.selected !== obj2.selected) {
          //     return false;
          //   }
          // }
        }
     
        return true;
       
      }


      useEffect(() => {
        console.log("old dropdown",dropdownOld);
       
          // const compareValue = compareDropdowns();
          // console.log("compare Boolean,",compareValue);
          // setLookUpBool(compareValue);
        
          
          
      },[toggleChecked])


      
      const getDropdowns = () => { 
        dispatch(showLoader());
        getDropDownLookups('dropdown').then(function (response) {
          console.log('getDropDownLookups',response);
          if (response?.result) {
            // setOptions(response?.result?.data);
            const data = response?.result?.data;
            
              if(dropdownOld.length === 0){
                setDropdownOld(data);//setting lookup options
                console.log('Setting dropdownOld:', data);
              }
              
            

            const sortedData = response?.result?.data?.sort((a, b) => a.lookup.localeCompare(b.lookup));
            setOptions(sortedData);
          
           
           
          }
          dispatch(hideLoader());
          
          }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
      }

  
      const handleCloseModal = () => {
        setEditIndex('');
        setButtonType('add');
        setChangeText('');
        setError(false);
      };

      const handleChange = (code) => {
        console.log("handle change code",code);
        const selectedValue = code;
        if(selectedValue === 'initial'){
          setSelectedOption(selectedValue);
          setButtonType('add');
          setDropDownData([]);
          setSelectedAll(false);
          setEditIndex('');
          setChangeText('');
          setError(false);
        }else{
          const foundObject = options.find(item => item.lookup === selectedValue);
        const newData = foundObject?.['lookupOptions'];
        setSelectedOption(selectedValue);
        setSelectedLookup(foundObject)
        setButtonType('add');
        setDropDownData(newData);
        setSelectedAll(false);
        setEditIndex('');
        setChangeText('');
        setError(false);
        }
        setIsDropDownUpdated(false)
        setArrowClicked(code);
      };
      
      useEffect(() => {
       console.log('dropDownData----->',selectedOption, dropDownData);
       
      }, [dropDownData])
      
      const handleSelectAll = (e)=>{
        if(selectedOption === 'initial'){
          setSelectedAll(false);
          setShow(true);
        }else{
          let selectStatus = e.target.checked;
          setSelectedAll(e.target.checked);
          console.log('selectStatus----->',selectStatus);
          setDropDownData((prevDropDownData) =>
            prevDropDownData.map((item) => ({
              ...item,
              selected: selectStatus,
            }))
          );
          setIsDropDownUpdated(true)
        }
      };

      const handleSave = (data)=>{
        if(selectedOption === 'initial'){
          console.log('errorrrr');
          setShow(true);
        }else{
          dispatch(showLoader());
          
          delete selectedLookup._id;
          selectedLookup.lookupOptions = data ? data : dropDownData;

          console.log('dropdowns--optionselectedLookup',selectedLookup)
          updateLookupsData(selectedLookup).then(function (response) {
            
            getDropdowns()
            console.log('responseresponse',response);
            if(!itemAdded && buttonType == 'add'){
              setPopupOpen(true);
              setmessage('Saved Successfully');
            }else{
              setPopupOpen(true);
            }
            
            setIsDropDownUpdated(false)
            dispatch(hideLoader());
            
          }).catch((err) => {
           
            console.error.bind("Login error", err);
            dispatch(hideLoader());
          })
        }
      }

      const handleCheckboxChange = (index) => {
        if(selectedOption === 'initial'){
          console.log('select a option');
          setShow(true);
        }else{
          console.log('---------------> ',index)
          const updatedDropDownData = dropDownData.map((item, idx) => {
            if (idx === index) {
              return { ...item, selected: !item.selected };
            }
            return { ...item };
          });
          setDropDownData(updatedDropDownData);
          setToggleChecked(!toggleChecked);
          const foundObject = options.find(item => item.lookup === selectedOption);
          const newData = foundObject?.['lookupOptions'];
          const isDifferent = JSON.stringify(newData) !== JSON.stringify(updatedDropDownData);
          setIsDropDownUpdated(isDifferent);
        }
      };

      const handleChangeText = (e) => {
        const formattedText = e.target.value
        setChangeText(formattedText);
      };

      const isEnteredOptionEqual = (enteredValue) => {
          const toLowerText = enteredValue.toLowerCase();
          return dropDownData?.some(option => option.option.toLowerCase() == toLowerText);
      }
      
      const handleAdd = () => {
        let error = false;
        if(changeText.trim().length === 0){
          error = true;
        }
        setError(error);
        if(!error){
          // console.log("dropdown info",dropDownData);
          const checkItemExists = isEnteredOptionEqual(changeText.trim());
          console.log("Item exists",checkItemExists);
          if(checkItemExists ===  false){
            // dispatch(showLoader());
            const maxOptionId = dropDownData.reduce((max, item) => Math.max(max, item.optionId), 0);
            handleSave([...dropDownData, { "option": changeText.trim(), "selected": false, "optionId": maxOptionId + 1}]);
            setDropDownData([...dropDownData, { "option": changeText.trim(), "selected": false, "optionId": maxOptionId + 1}]);
            setChangeText('');
            setEditIndex('');
            document.getElementById('closeModal').click();
            itemAdded = true;
            // setPopupOpen(true);
            setmessage("Item Added successfully");
            

            // dispatch(hideLoader());
            
          }else{
            dispatch(showLoader());
           
            setPopupOpen(true);
            setmessage("Item already Exists!");
            dispatch(hideLoader());
          }
          
        };
      };

      const handleUpdate = ()=>{
        let error = false;
        if(changeText.trim().length === 0){
          error = true;
        };
        setError(error);
        if(!error){
          const checkItemExists = isEnteredOptionEqual(changeText.trim());
          if(checkItemExists ===  false){
            // dispatch(showLoader());
            let copiedData = [...dropDownData];
            copiedData[editIndex] = {...copiedData[editIndex]  , 'option' : changeText.trim()};
            handleSave(copiedData)
            setDropDownData(copiedData);
            setChangeText('');
            setEditIndex('');
            setButtonType('add');
            document.getElementById('closeModal').click();
            // dispatch(hideLoader());
            setmessage('Updated successfully');
            // setPopupOpen(true);
          }else{
            if(dropDownData?.[editIndex]?.option != changeText){
              dispatch(showLoader());
             
              setPopupOpen(true);
              setmessage("Item already Exists!");
              dispatch(hideLoader());

            }
          }
        }
      }

      const handleEdit =(index) =>{
        setEditIndex(index);
        setButtonType('update');
        console.log('edit index value---->',dropDownData[index].option)
        setChangeText(dropDownData[index].option)
      };

      const handleAlertClose = () => {
        setShow(!show);
    }
    const closePopup = () => {
      setPopupOpen(false);
    };

    const trimString = (str) => {
      
      return str?.replace(/[()]/g, '').replace(/&/g, '').replace(/-/g, '').replace(/\s+/g, '').trim();
    };


    return (
        <div id="layout-wrapper">
          {loading ? <Loader/> : null} 
          {isPopupOpen && !loading && (
        <PopupMessage
          type={popupMessage ==  'Please try with new reset link' || popupMessage == "Item already Exists!" ? 'warning' : 'success'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
                                <h4 className="mb-sm-0 font-size-18">Dropdown Management</h4>
                                <a id="dropdownManagementBack" href="#" onClick={(e) => {e.preventDefault(); navigate(-1);}}  className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                      <div className="form_section">
                        <div className='sl_block dm'>
                          <div className='left'>
                          {options.map((option, index) => (
                            <div id={`${trimString(option?.lookup)}-Lookup`} onClick={(e) => {e.preventDefault(); handleChange(option.lookup);}} className={['states_block', selectedOption === option.lookup ? 'active' : ''].join(' ')}>{option.lookup} 
                                <button type="button" className="btn btn-outline-secondary">
                                    <span className="material-icons">arrow_forward_ios</span>
                                </button>
                            </div>
                          ))}</div><div className='right'>
                            {selectedOption == 'initial' && <div className="inprogress_page">
                              <span className="material-symbols-outlined icon"> arrow_selector_tool </span><p>Please select an item from the list
                                to display items here</p></div>}
                          {selectedOption != 'initial' && <div>
                            {/* <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Items</label>
                                  <select onChange={handleChange} className="form-control">
                                    <option value={'initial'} selected>Select Item</option>
                                    {options.map((option) => (
                                      <option key={option.code} value={option.lookup}>
                                        {option.lookup}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div> */}
               
                            <div className="row">
                              <div className="col-md-8 lp_additem">
                                <label className="toggle">
                                  <input id="selectAll" className="toggle-checkbox" checked={selectedOption === 'initial' ? false : selectedAll} type="checkbox" onChange={handleSelectAll} />
                                  <div className="toggle-switch"></div>
                                  <span className="toggle-label">Select All</span> 
                                </label>
                                {selectedOption != 'initial' && <h4>{selectedOption}</h4>}
                                {/* <input type='text' value={changeText} style={{width : '300px', marginRight : '15px'}} onChange={(e)=>{handleChangeText(e)}} className={`form-control ${error && 'invalid-input'}`} onFocus={()=>{setError(false)}}  />
                                                        {buttonType === 'add' ? (<button onClick={handleAdd} className="border_btn"> <span
                                                                className="material-symbols-outlined icon"> add </span> Add
                                                            Item
                                                        </button>) : (<button onClick={handleUpdate} className="border_btn"> <span
                                                                className="material-symbols-outlined icon"> update </span> update
                                                            Item
                                                        </button>)} */}
                                {/* button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> */}
                                {selectedOption === 'initial' ? (<button id="addItemBtn1" type="button" onClick={() => setShow(true)} className="border_btn" >
                                  <span className="material-symbols-outlined icon"> add </span> Add Item
                                </button>) : (<button id="addItemBtn2" className="border_btn" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" data-bs-whatever="@mdo">
                                  <span className="material-symbols-outlined icon"> add </span> Add Item
                                </button>)}
                                {/* <button className="border_btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" data-bs-whatever="@mdo">
                                                           <span className="material-symbols-outlined icon"> add </span> Add Item
                                                        </button> */}


                              </div>


                            </div>


                            {/* <div className="toggle_blk"> */}



                            <div className="toggle_grouplist" >
                              {dropDownData.map((data, index) => (
                                <div className="toggle_blk" key={index}>
                                  <div className="item">
                                    <label className="toggle">
                                      <input
                                        className="toggle-checkbox"
                                        onChange={() => handleCheckboxChange(index)}
                                        type="checkbox"
                                        checked={selectedOption === 'initial' ? false : data.selected}
                                        id={trimString(data?.option)}
                                      />
                                      <div className="toggle-switch"></div>
                                      <span className="toggle-label">{data.option}</span>
                                    </label>
                                    {selectedOption === 'initial' ? (<button id="lookupEdit" onClick={() => setShow(true)} className="edit_btn"     >
                                      <span className="material-icons icon"> edit </span>
                                    </button>) : (<button id="lookupEdit" onClick={() => handleEdit(index)} className="edit_btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                      data-bs-whatever="@mdo">
                                      <span className="material-icons icon"> edit </span>
                                    </button>)}

                                  </div>
                                </div>
                              ))}
                            </div>




                            {/* </div> */}



                            <div className="btn-group mt_20">

                                <button id="saveLookup" type="button" onClick={() => { handleSave() }} className="fs_btn" style = {{'opacity' : (!isDropDownUpdated) ? '0.6' : '1'}}  disabled={!isDropDownUpdated}>Save</button>
                            </div>
                          </div>}
                        </div>
                        </div>
                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" */}
                <div className="modal popup " id="staticBackdrop" data-bs-backdrop="static" tabIndex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {buttonType === 'add' ? 'Add Item' : 'Update Item'}
                </h5>
                <button type="button" id='closeModal' onClick={handleCloseModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label>Item Name</label>
                    <input id="itemName" type='text' value={changeText} onChange={(e) => { handleChangeText(e) }} className={`form-control ${error && 'invalid-input'}`} onFocus={() => { setError(false) }} />
                </div>
                {buttonType === 'add' ? (
                    <button id="addItemName" type="button" onClick={handleAdd} className="fs_btn"> Add </button>
                ) : (
                    <button id="updateItem" type="button" onClick={handleUpdate} className="fs_btn"> Update </button>
                )}
            </div>
        </div>
    </div>
    </div>
             
            </div>

               <HomeFooter />
            </div>
            {show && <SweetAlert
            warning
            title="Please select A Item "
            onConfirm={handleAlertClose}
            >
            {/* Display your validation error message */}
            {/* Please select A Item  */}
            </SweetAlert>}
        </div>
    );
};

export default DropdownManagement;