import React, {useState, useEffect} from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
import { useLocation, useNavigate} from 'react-router-dom';
import {getDetailedTransactions} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import DataTable from 'react-data-table-component';
import { formatCurrency } from '../utils/commonUtils.js';
import Loader from './Loader';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { getAllFundManagmentAdmin } from '../utils/reducer';
const AdminDetailedReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [detailedReport,setDetailedReport] = useState([]);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [yearDropdown, setYearDropdown] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [message , setMessage] = useState('Email Sent Successfully');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const columns = [
        // {
        //   name: 'Partner case ID',
        //   selector: (row) => row?.partnercaseId || '',
        //   minWidth: '160px',
        //   maxWidth: '160px',
        // },
        {
          name: 'Investor name',
          selector: (row) => row?.firstname +  " " + row?.lastname || '$0.00',
          minWidth: '205px',
          maxWidth: '205px',
        },
        {
          name: 'Beginning capital',
          selector: (row) => row?.beginningCapital ? formatCurrency(row?.beginningCapital) : '$0.00',
          minWidth: '190px',
          maxWidth: '190px',
        },
        {
          name: 'YTD contribution',
          selector: (row) => row?.totalDepositAmount ? formatCurrency(row?.totalDepositAmount) :'$0.00',
          minWidth: '200px',
          maxWidth: '200px',
         
        },
        {
          name: 'YTD withdrawals',
          selector: (row) => row?.totalWithdrawAmount ? formatCurrency(row?.totalWithdrawAmount) : '$0.00',
          minWidth: '200px',
          maxWidth: '200px',
          
        },
        {
          name: 'YTD income/loss',
          selector: (row) => row?.interestGain ? formatCurrency(row?.interestGain) : '$0.00',
          minWidth: '205px',
          maxWidth: '205px',
          
        },
        {
          name: 'YTD ending capital',
          selector: (row) => row?.endingcapital ? formatCurrency(row?.endingcapital) : '$0.00',
          minWidth: '205px',
          maxWidth: '205px',
          
        },
        // {
        //   name: 'YTD fees paid',
        //   selector: (row) => row?.ytdfeespaid ? formatCurrency(row?.ytdfeespaid) : '',
        //   minWidth: '133px',
        //   maxWidth: '133px',
          
        // },
        {
          name: 'Income or growth',
          selector: (row) => row?.typeofreturn ? formatCurrency(row?.typeofreturn) : '$0.00',
          minWidth: '152px',
          maxWidth: '152px',
          
        },
        // {
        //   name: 'ERISA plan',
        //   selector: (row) => row?.erisaPlan || '',
        //   minWidth: '130px',
        //   maxWidth: '130px',
        // },
        // {
        //   name: 'Address',
        //   selector: (row) => row?.address || '',
        //   minWidth: '170px',
        //   maxWidth: '170px',
        // },
      ];
      const [currentPage,setCurrentPage] = useState(1);
      const [perPage, setPerPage] = useState(10);
      const [tableKey, setTableKey] = useState(0);
      const [totalRows, setTotalRows] = useState(0);
       
    useEffect(() => {
        getFundsName();
        setYear();
      }, []);

      const setYear =()=>{
        const startYear = 2019;
        const currentYear = new Date().getFullYear();
    
        const years = Array.from({ length: (currentYear - startYear + 1) }, (_, i) => startYear + i);
        setYearDropdown(years);
      };
      useEffect(()=>{
        if(filterData?.fundid && filterData?.year){
          getDeailReport(currentPage,perPage,filterData?.fundid,filterData?.year);
        }
    },[filterData]);
    // api calls 
    const getFundsName = () => {
        dispatch(showLoader());
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
            .then(function(response) {
              if (response?.result?.data) {
                setFundsDropdown(response.result?.data.map((fundoption) => ({
                    value: fundoption?.uuid,
                    label: fundoption?.name
                })));
            } else {
                setFundsDropdown([]);
            }
            const defaultFund = response.result.data.find(fund => fund.default === true);
            const defaultUuid = defaultFund ? defaultFund.uuid : null;
            const currentYear = new Date().getFullYear();
            if (defaultUuid) {
                setFilterData({fundid : defaultUuid , year : currentYear});
                getDeailReport(currentPage,perPage,defaultUuid,currentYear);
            } else {
              getDeailReport(currentPage,perPage,'',currentYear);
            }
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };

    const getDeailReport = (page,perpage,fundid,year) => {
      dispatch(showLoader());
      getDetailedTransactions(page,perpage,fundid,year)
          .then(function(response) {
              console.log('funds name', response);
              if (response?.result) {
                  let responsedata = response?.result?.data;
                  setDetailedReport(responsedata);
                  setTotalRows(response?.result?.totalCount);
                  dispatch(hideLoader());
              } else {
                setDetailedReport([]);
                  hideLoader([]);
              }
          })
          .catch((err) => {
              console.error("error", err);
              dispatch(hideLoader());
          });
  };
      
    const handleChange =(e)=>{
        let {name , value ,type} = e.target;
        setFilterData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handlePageChange = (page) => {
          console.log('rannnn');
          if(filterData?.fundid && filterData?.year){
              getDeailReport(page,perPage,filterData?.fundid,filterData?.year);
          }
          setCurrentPage(page);
    };
      const handlePerRowsChange = async (newPerPage, page) => {
        // if (!isSorting.current) {
          console.log('i ran tooo')
          if(filterData?.fundid && filterData?.year){
            getDeailReport(page,newPerPage,filterData?.fundid,filterData?.year);
        }
          setCurrentPage(page);
          setPerPage(newPerPage);
        // }
      };
      const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
         
        // Format table data for Excel
        const formattedData = detailedReport.map(item => ({
        // 'PARTNER CASE ID': item?.partnercaseId || '',
        'INVESTOR NAME':  item?.firstname +  " " + item?.lastname || '',
        'BEGINNING CAPITAL': item?.beginningCapital ? formatCurrency(item?.beginningCapital) : '$0.00',
        'YTD CONTRIBUTION': item?.totalDepositAmount ? formatCurrency(item?.totalDepositAmount) : '$0.00',
        'YTD WITHDRAWALS': item?.totalWithdrawAmount ? formatCurrency(item?.totalWithdrawAmount) : '$0.00',
        'YTD INCOME/LOSS': item?.interestGain ? formatCurrency(item?.interestGain) : '$0.00',
        'YTD ENDING CAPITAL': item?.endingcapital ? formatCurrency(item?.endingcapital) : '$0.00',
        // 'YTD FEES PAID': item?.ytdfeespaid ? formatCurrency(item?.ytdfeespaid) : '',
        'INCOME OR GROWTH': item?.typeofreturn ? formatCurrency(item?.typeofreturn) : '$0.00',
        // 'ERISA PLAN': item?.erisaPlan || '',
        // 'ADDRESS': item?.address || '',
            
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length

                return { wch: maxContentLength + 2 }; // Add some padding
            });

            ws['!cols'] = columnWidths;
      

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'transaction_history' + fileExtension);
    };

    const handleSendEmail = ()=>{
        //api call here
        setMessage('Email Sent Successfully');
        setPopupOpen(true)
    }
      const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
      };
    const closePopup = () => {
       setPopupOpen(false);
    };

    return (
        <div id="layout-wrapper">
            {isPopupOpen && (
        <PopupMessage
          type={'success'}
          message={message}
          onClose={closePopup}
        />
      )}

{loading ? <Loader/> : null} 
          <MainHeader />
          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement detailed_investor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Detailed Investor Report</h4>
                      
                            </div>
                            <div className="card">
                            <div className="card-body my_account">
                            <div className='search'>
                            <div className="d-flex align-items-center w-50 justify-content-between">
                            <div className="form-group">
                                    <label>Investment Fund</label>
                                    <select id="investmentfund" name="fundid" value={filterData?.fundid || ''} onChange={handleChange} className="form-control" tabindex="1">
                                    {/* <option value="">Select</option> */}
                                     {fundsDropdown?.map((fundoption, index) => {
                                        return (
                                          <option value={fundoption?.value}>{fundoption?.label}</option>
                                               )
                                       })}
                                    </select>
                                    </div>
                                  <div className="form-group">
                                    <label>Select Year</label>
                                    <select id="selectyear" name="year" value={filterData?.year} onChange={handleChange}  className="form-control false" tabindex="1">
                                    {/* <option value="">Select</option> */}
                                        {yearDropdown.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    {/* <button id="sendemail-btn" onClick={handleSendEmail} disabled={true} type="button" class="border_btn">Send Email<span className="material-symbols-outlined">mail</span></button> */}
                                    <button id="exportdetailedreport" disabled={detailedReport?.length == 0 ? true : false} style={{'opacity' : detailedReport?.length == 0 ? 0.6 : 1}}  onClick={handleExportToExcel} type="button" class="border_btn ms-2">Export<span className="material-symbols-outlined">arrow_outward</span></button>
                                   </div>
                                   </div>
                                        <div class="table-responsive detailed_investor_reporttable">
                                        {detailedReport?.length !== 0 &&
                                        <DataTable 
                                        key={tableKey}
                                                columns={columns}
                                                data={detailedReport}
                                                paginationTotalRows={totalRows}
                                                pagination
                                                paginationServer
                                                customStyles={customStyles}
                                                paginationPerPage={perPage}
                                                paginationDefaultPage={currentPage}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                paginationRowsPerPageOptions={[10, 15, 30, 40]}
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Per Page',
                                                }}
                                                persistTableHead
                                                noDataComponent="No results found" // Show a message when there are no rows
                                            />}
                                        </div>
                                        {detailedReport?.length === 0  &&
                                        <div className="inprogress_page">
                                            <span className="material-symbols-outlined icon"> content_paste_search </span>
                                            <p>No Data Found</p>
                                        </div>
                                    }
                         
                             </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminDetailedReport;