import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import {getUnderwritingstates, updateUnderwritingStates, getTermSheetRefills} from "../utils/reducer";
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import PopupMessage from './PopupMessage.js';
import HomeFooter from './HomeFooter';
const UnderwritingTermsheetStates = () => {
      const dispatch = useDispatch();
      const loading = useSelector((state) => state.loaderVisible);
      const [selectedState, setSelectedState] = useState('Indiana');
      const [states, setStates] = useState(null);  
      const [statesData, setStatesData] = useState(null); 
      const [formChanged, setFormChanged] = useState(false);
      const [savePopup,setSavePopup] = useState(false);
      const [selectAll, setSelectAll] = useState(false); 
      const [termsSheetRefils, setTermsSheetRefils] = useState([]);
      const [showAlertPopup, setShowAlertPopup] = useState(false);
      useEffect(() => {
        getStates()
        getTermSheets()
      }, []);

      const getTermSheets = ()=>{
        dispatch(showLoader());
      getTermSheetRefills().then(function (response) {
        if (response?.result) {
        console.log(response?.result);
        setTermsSheetRefils(response?.result?.data) ;
        }
        }).catch((err) => {
          console.error.bind("my account error", err);
        })
    }

    const getStates= () => { 
        dispatch(showLoader());
        getUnderwritingstates().then(function (response) {
        if (response?.result) {
          
           setStates(response?.result)
           setStatesData(response?.result)
           dispatch(hideLoader());
        }
        }).catch((err) => {
          console.error.bind("my account error", err);
          dispatch(hideLoader());
        })
}
        useEffect(() => {
            const isFormChanged = JSON.stringify(statesData) !== JSON.stringify(states);
            setFormChanged(isFormChanged);
        }, [states, statesData]);

        useEffect(() => {
          if(states){
            const allStatesTrue = states.every(state => state?.underwriting === true);
            setSelectAll(allStatesTrue)
          }
      }, [states]);


      const handleStatusChange = (stateName) => {
        // Map through states to find the state to update
        console.log('termsSheetRefils',JSON.stringify(termsSheetRefils));
        const disallowedStates = termsSheetRefils?.flatMap(item => item?.state);
        const updatedStates = states.map((state) => {
          if (state.regionname === stateName) {
            // Only apply the disallowed check if the current state.underwriting is true
            if (state.underwriting) {
              // Check if the stateName is in the disallowedStates list
              if (disallowedStates.includes(stateName?.toLowerCase())) {
                setShowAlertPopup(true);
                return state; // Return the state unchanged
              }
            }
            // Toggle the status based on current status
            return { ...state, underwriting: !state.underwriting };
          }
          return state;
        });
  
        setStates(updatedStates); // Update the states array with the new status
      };
      const handleSave= () => {
        dispatch(showLoader());
        console.log('updatedStateshere',states);
        updateUnderwritingStates(states).then(function (response) {
          getStates()
          dispatch(hideLoader());
          console.log('responseresponse',response.result);
          if(response.result === 'modified successfully'){
            setSavePopup(true);
          }
        }).catch((err) => {
            dispatch(hideLoader());
          console.error.bind("Login error", err);
        })
      }
      const closePopup = () => {
        setSavePopup(false);
     }
      const handleSelectAllChange = () => {
     
       const disallowedStates = termsSheetRefils?.flatMap(item => item?.state);
       if (selectAll) {
        // If currently selected, deactivate all
        setStates(states.map(state => {
          // Check if the current state should be deactivated
          if (state.underwriting && disallowedStates?.includes(state?.regionname?.toLowerCase())) {
            setShowAlertPopup(true)
            return state; // Return the state unchanged
          }
          return { ...state, underwriting: false };
        }));
      } else {
          // Activate all
          setStates(states.map(state => ({ ...state, underwriting: true })));
        }
        setSelectAll(!selectAll); // Toggle selectAll state
      };

      const trimString = (str) => {
      
        return str?.replace(/\s+/g, '').trim();
      };

    return (
        <div id="layout-wrapper">

        {loading ? <Loader/> : null} 
            
        {savePopup && (
                <PopupMessage
                    type={'success'}
                    message={'Saved Successfully'}
                    onClose={closePopup}
                />
            )}
            {showAlertPopup && 
                    <div className="modal popup conditional_popup deletepop_new alert_popup fade show" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content ">

                                <div className="modal-body">
                                    <button type="button" className="close" data-bs-dismiss="modal"
                                        aria-label="Close"><span className="material-symbols-outlined" onClick={()=>setShowAlertPopup(false)}>close</span></button>
                                 <span className="material-symbols-outlined alert_icon"> warning </span>
                                    <h3>Alert</h3>
                                    <p>The state is already included in the underwriting term sheet prefills. You cannot unselect it.
                                    </p>
                                    <button type="button" className="fs_btn pop_btn" onClick={()=>{setShowAlertPopup(false); }} >Ok</button>
                                </div>

                            </div>
                        </div>
                    </div>}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management states_cities">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Under Writing Term Sheet States</h4>
                                {/* <a href="#" className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a> */}
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-6 lp_additem mt-0 mb_15">
                                            <h4>States</h4>
                                            <label className="toggle">
                                                <input id="selectUnderwritingStates" className="toggle-checkbox" type="checkbox" checked={selectAll}
                                                  onChange={handleSelectAllChange}/>
                                                <div className="toggle-switch"></div>
                                                <span className="toggle-label">select all</span>
                                            </label>
                                           

                                        </div>
                                    </div>
                                    <div className="form_section lp">
                                     <div className="sl_block pd_15">
                                      
                                            <div className="row">
                                            {states?.map((state, chunkIndex) => (
                                                <div className={['col-md-3', selectedState === state?.regionname ? 'active' : ''].join(' ')} key={chunkIndex}>
                                                    {state?.regionname &&
                                                        <label className="toggle">
                                                            <input id={`underwritingState-${trimString(state?.regionname)}-${chunkIndex}`} onChange={() => handleStatusChange(state.regionname)}  className="toggle-checkbox" type="checkbox" checked={state.underwriting === true} />
                                                            <div className="toggle-switch"></div>
                                                            
                                                            <span className="toggle-label">{state?.regionname}</span>
                                                        </label>
                                                    }
                                                    {/* <button type="button" className="btn btn-outline-secondary" onClick={(e) => {e.preventDefault(); setSelectedState(state?.name);}}>
                                                        <span className="material-icons">edit</span>
                                                    </button> */}
                                                </div>
                                            ))}
                                              
                                         
                                              </div>
                                               </div>
                                            {/* <div className="right">
                                                <h5>cities</h5>
                                                <div className="row">
                                                {cities?.map((city, chunkIndex) => (
                                                    <div className="col-md-2" key={chunkIndex}>
                                                        <label className="toggle">
                                                            <input className="toggle-checkbox" type="checkbox" checked />
                                                            <div className="toggle-switch"></div>
                                                            <span className="toggle-label">{city?.name}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                                    
                                                </div>
                                            </div> */}
                                        
                                        <div className="btn-group mt_20">
                                            <button id="saveUnderwritingStates" href="#" onClick={(e) => {e.preventDefault(); handleSave()}} disabled={!formChanged} style={{ opacity: formChanged ? '1' : '0.6', backgroundColor: '#7CC149', color: '#fff' }} className="fs_btn">save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default UnderwritingTermsheetStates;