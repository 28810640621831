import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { getNotes ,addNotes} from '../utils/reducer';
import { useDispatch, useSelector} from 'react-redux';
import { useLoanInfo } from '../utils/loanInfoContext';
import Loader from './Loader';
import moment from 'moment';
import PopupMessage from './PopupMessage';
import DataTable from 'react-data-table-component';
import EditorEdit from './EditorEdit';
import { getUserNoteTemplates ,getTemplateInfo , sendEmail} from '../utils/reducer';
import { getUserDataFromLocalStorage } from '../utils/local';

const PipelineNotes = (props) => {
  const dispatch = useDispatch();
  const [notesData,setNotesData] = useState({});
  const [other,setOther] = useState('');
  const [noteErrors,setNoteErrors] = useState({});
  const [allNotes,setAllNotes] = useState([]);
  const loading = useSelector((state) => state.loaderVisible);
  const [popUp,setPopUp] = useState(false)
  const [ viewNote, setViewNote] = useState('');
  const { loanInfo, setLoanInfo} = useLoanInfo();
  const [loanid,setLoanid] = useState(props?.activeLoanid);
  const [message,setMessage] = useState('Added Successfully');
  const [content, setContent] = useState("");
  const [editorValidate,setEditorValidate] = useState(false);
  const getUserData = getUserDataFromLocalStorage();
  const user_id = getUserData?.userId;
  const user_email = getUserData?.emailid;
//   const [allNotesTemplates,setAllNotesTemplates] = useState([]);
  const [notesEmailData,setNotesEmailData] = useState({
        "pagename" : "notes",
        // "from" : user_email  || "",
        "from" : "develop@sanchaninfo.com",
        "to" : "",
        "name" : "",
        "subject" : "",
        "template" : content,
    });
    const [hidePrint,setHidePrint] = useState('yes');
    const [templateId,setTemplateId] = useState(null);
    const [emailErrors,setEmailErrors] = useState({});
    const [emailToError , setEmailToError] = useState('');
    let errEmail = {};

 useEffect(() => {
    if(loanid){
        console.log('loanidloanidloanid,loanid',loanid)
        getNotesApi();
    }
  }, [loanid]);
  useEffect(() => {
    setLoanid(loanInfo?.loanid)
  }, [loanInfo?.loanid]);


 const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);
   
        setNotesData((prev) => ({
            ...prev,
            [name]: value,
        }));
   
};
 
  const getNotesApi = ()=>{
    dispatch(showLoader());
    getNotes(props?.activeLoanid)
    .then(function(response) {
        if (response) {
            console.log('response', response);
            setAllNotes(response?.result?.data);
            dispatch(hideLoader());
        }
    })
    .catch((err) => {
        console.error("error", err); 
        dispatch(hideLoader());
    });
};

  const handleAdd = () => {
    const fieldsToCheck = {
      'contact' : 'contact', 'reason' : 'reason','description' : 'description','typeofcontact' :'typeofcontact'
    };
    console.log('ran');
    const errors = {};

    for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!notesData[fieldName]?.trim()) {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
            }
    };
    if(notesData?.reason === 'Other'){
        if (!other?.trim()) {
            errors["other"] = `Please Enter Other `;
        }
    }

    setNoteErrors(errors);
    if (Object.keys(errors).length === 0) {
        dispatch(showLoader());
        let currentDate = new Date();
        console.log('ready to api call -----> ', notesData);
        let apiData = {...notesData, 'created': currentDate, loanid : props?.activeLoanid};
        if(notesData?.reason === 'Other'){
            apiData['reason'] = other
        }
        console.log('apiDataapiDataapiDataapiData',props?.activeLoanid);
        
        addNotes(apiData).then(function (response) {
            if (response) {
               console.log(response?.result);
               dispatch(hideLoader());
               getNotesApi();
               props.getAllLoansFun();
               setNotesData({});
               setOther();
               setPopUp(true);
            }
            }).catch((err) => {
              console.error.bind("error", err);
              dispatch(hideLoader());
            })
    }
}
const closePopup =()=>{
    setPopUp(false)
}
const columns = [
    // {
    //     name: 'Outreach By',
    //     selector: (row) => <span className="icon-text"><span
    //     className="material-symbols-outlined icon"> {row?.contact === 'Email' ? 'email' : 'call'} </span>{row?.contact}</span> || '',
        
    //     minWidth: '15%',
    //     maxWidth: '15%',
    // },
    // {
    //     name: 'REASON FOR',
    //     selector: (row) => <span>{row?.reason}</span> || '',
    //     minWidth: '15%',
    //     maxWidth: '15%',
    // },
    // {
    //     name: 'Type of Contact',
    //     selector: (row) => <span>{row?.typeofcontact}</span> || '',
    //     minWidth: '15%',
    //     maxWidth: '15%',
    // },
    {
        name: 'Created On',
        selector: (row) => <span>{moment.utc(row?.created).format("MM-DD-YYYY")}</span> || '',
       
        minWidth: '15%',
        maxWidth: '15%',
    },
    {
        name: 'SUBMITTED BY',
        selector: (row) => row?.username || '',
        minWidth: '15%',
        maxWidth: '15%',
    },
    {
        name: 'Note',
        selector: (row) => row?.description || '',
            
    //         <a href="#" data-bs-toggle="modal" onClick={() => { setViewNote(row?.description) }}
    //     data-bs-target="#viewnote" data-bs-whatever="@mdo">
    //         <button type="button" className="tbl_btn">     View Note</button>
    // </a> || '',
        minWidth: '70%',
        maxWidth: '70%',
    },
   
];

const customStyles = {
    //   headRow: {
    // 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
    // 	},
    // },
    headCells: {
        style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',

        },
    },
    table: {
        style: {
            border: '1px solid #CCD8F6',
        },
    },
};

    //Emails Tab
    useEffect(() => {
        setNotesEmailData((prev) => ({
            ...prev,
            ['template']: content,
        }));
    },[content])

    // const callNotesApi = () => {
    //     dispatch(showLoader());
    //     getUserNoteTemplates(user_id).then(function (response) {
    //         console.log('get note template response', response);
    //         if(response?.statusCode === 200){
    //             setAllNotesTemplates(response?.result);
    //         }
    //         dispatch(hideLoader());
            
    //     }).catch((err) => {
    //         console.error.bind("get note templates  error", err);
    //         dispatch(hideLoader());
    //     })
    // }

    // useEffect(() => {

    //     callNotesApi();

    // },[]);

    const handleChangeEmailNotes = (e) => {
        let name = e.target.name;
        let value = e.target.value;
       
        setNotesEmailData({...notesEmailData,[name] : value}); 
             
    }

    useEffect(() => {

        if(notesEmailData?.name){

            getTemplateInfo(user_id,notesEmailData?.name).then(function (response) {
                console.log('get specific note template info response', response);
                if(response?.statusCode === 200){
                    const tempRes = response?.result[0];
                    
                    if(notesEmailData['subject'] && errEmail['subject']){
                        errEmail['subject'] = '';    
                    }
                    
                    if(notesEmailData['to'] && (errEmail['to'] || emailToError)){
                        errEmail['to'] = '';
                        setEmailToError('');
                    }
    
                    setEmailErrors(errEmail);
                    setNotesEmailData({...notesEmailData,subject : tempRes?.notesubject});
                    //setQuillValue(tempRes.notedescription);
                    setContent(tempRes?.notedescription);
    
                }
                
            }).catch((err) => {
                console.error.bind("get specific note template info error", err);
               
            })
        }
       
    },[notesEmailData?.name])

    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          //console.log("Error class added to element:", inputElement);
        }
    };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    // useEffect(() => {
    //     const inputElement = document.querySelector(`.jodit-react-container`);
    //     if(inputElement.classList.contains('invalid-input')){
    //         removeErrorClass("jodit-react-container");
    //     }
    // },[notesEmailData?.template])

    const isValidEmail = (email) => {
        // Implement your email validation logic here
        // Example: Check if the email follows a standard format
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        let emailtest = emailRegex.test(email);
        return emailtest;
      };

    const validateEmailInputs = () => {
        errEmail = {};

        let mandatoryFields = ['subject','to','template'];

        //const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        const isWhitespace = /^(<p>(\s|&nbsp;|<br>)*<\/p>)*$/;
        const contentIsEmpty = !content || content === "<p><br></p>" || isWhitespace.test(content);

        mandatoryFields?.map((field) => {
            if(field != 'template' && !notesEmailData[field]?.trim()){
                errEmail[field] = 'required';
            }
            if(field == 'template' && contentIsEmpty){
                addErrorClass("jodit-react-container"); 
            }
            if(field == 'to' &&  notesEmailData[field] && !isValidEmail(notesEmailData[field])){
                errEmail[field] = 'required';
                setEmailToError("Invalid Email!");
            }
           
        })

        setEmailErrors(errEmail);
        
    }

    const handleSendEmail = () => {
        validateEmailInputs();
        const inputElement = document.querySelector(`.jodit-react-container`);

        if(Object.keys(errEmail)?.length === 0 && !inputElement.classList.contains('invalid-input')){
            // const {name , ...emaildataWithoutName} = notesEmailData;
            const {name , ...rest} = notesEmailData;// Destructure to remove the template key
            const updatedEmailData = {...rest , loanid : loanid};
            console.log("Send email data",updatedEmailData);
            // calling api
            dispatch(showLoader());
            sendEmail(updatedEmailData).then(function (response) {
                if (response?.result) {
                    console.log("Notes Send email response",response?.result);
                    setContent('');
                    setNotesEmailData({...notesEmailData,
                        
                        to : '',
                        name : '',
                        subject : '',
                        
                    });
                    setPopUp(true);
                    setMessage(response?.result);
                    
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("Notes email error", err);
            }) 
        
        }
    }

    const handleResetEmail = () => {
        setContent('');
        setNotesEmailData({...notesEmailData,
            
            to : '',
            name : '',
            subject : '',
            
        });
    }

    const handleEmailFocus = (e) => {
        if(e.target.name == 'to'){
           setEmailToError('') ;
        }
        setEmailErrors({...emailErrors,[e.target.name]:''});
    }

    return (
        <>      
          {/* <div id="layout-wrapper" className="dashboard"> */}
            {loading ? <Loader/> : null} 
            {popUp && (
            <PopupMessage
             type={ message == 'Added Successfully' ? 'success' : 'warning'}
             message={message}
             onClose={closePopup}
            />
        )}
         <div 
           className="modal popup new_popup conditional_popup fade show"    >
               <div className="modal-dialog">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="exampleModalLabel">Add Notes</h5>
                           <button type="button"
                            className="pop_close" onClick={() => {props.setNotesFlag(false) }}><span
                               className="material-symbols-outlined">close</span></button>
                       </div>
                       <div className="modal-body">
                           <div className="row">
                         
                           <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Type Of Outreach</label>
                                        <select name='contact' onChange={handleChange} value={notesData?.contact || ''} className={`form-control ${noteErrors.contact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, contact: null }) }}>
                                            <option value="" selected="">Select</option>
                                            <option value="Call">Call</option>
                                            <option value="Email">Email</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>What Contact Was For</label>
                                        <select name='reason' onChange={handleChange} value={notesData?.reason || ''} className={`form-control ${noteErrors.reason && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, reason: null }) }}>
                                            <option value='' selected="">Select</option>
                                            <option value='Draw'>Draw</option>
                                            <option value='Payment'>Payment</option>
                                            <option value='Payoff'>Payoff</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Type of Contact</label>
                                        <select name='typeofcontact' onChange={handleChange} value={notesData?.typeofcontact || ''} className={`form-control ${noteErrors.typeofcontact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, typeofcontact: null }) }}>
                                            <option value='' selected="">Select</option>
                                            <option value='First Attempt'>First Attempt</option>
                                            <option value='Second Attempt'>Second Attempt</option>
                                            <option value='Third Attempt'>Third Attempt</option>
                                            <option value='Contacted'>Contacted</option>
                                            <option value='Bad Contact Info'>Bad Contact Info</option>
                                        </select>
                                    </div>
                                    {notesData?.reason === 'Other' && 
                                 
                                   <div className="form-group">
                                   <label>Other Please Specify</label>
                                   <input tabIndex={2} type="text" value={other} placeholder="Please Specify" onChange={(e)=>{setOther(e.target.value)}} name='other' className={`form-control ${noteErrors.other && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, other: null }) }}/>
                                   </div> 
                               
                               }
                                 
                                     </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Note</label>
                                        <textarea rows="10" value={notesData?.description || ''}  cols="40" name='description' onChange={handleChange} className={`form-control ${noteErrors.description && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, description: null }) }}> </textarea>
                                    </div> 
                                </div>
                              
                                     
                             
                                    <div className="col-md-4">
                                    <div className="form-group">
                                        <a href="#" onClick={(e)=>{e.preventDefault();handleAdd();}} className="fs_btn">Add</a>
                                    </div>
                                </div>
                             
                        
                            
                               
                               

                                {allNotes?.length > 0 &&     <div className="col-md-12">
                                   <h3 className="title mt_10 mb_10">Notes</h3>
                                   <div className="form-group">
                                      
                                   </div>
                               </div>
                         
}
                           </div>

                           {allNotes?.length > 0 && <DataTable className='notes_table termsheetmanager_table'
                        columns={columns}
                        data={allNotes}
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Per Page',
                        }}
                      />}
                       </div>

                   </div>
               </div>
           </div>
            {/* <div className="dashboard_block">
                <h2 className="form_title"> <span className="sub">Notes</span> </h2>
                <div className="card task_block notes">
                    <div className="card-body services_form user_management my_account">
                    
                
                     <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="Notes" role="tabpanel">
                     
                        <div className="sl_block form_section ">
                            <div className="left">
                                <div className="cont">
                                    <div className="form-group">
                                        <label>Type Of Outreach</label>
                                        <select name='contact' onChange={handleChange} value={notesData?.contact || ''} className={`form-control ${noteErrors.contact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, contact: null }) }}>
                                            <option value="" selected="">Select</option>
                                            <option value="Call">Call</option>
                                            <option value="Email">Email</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>What Contact Was For</label>
                                        <select name='reason' onChange={handleChange} value={notesData?.reason || ''} className={`form-control ${noteErrors.reason && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, reason: null }) }}>
                                            <option value='' selected="">Select</option>
                                            <option value='Draw'>Draw</option>
                                            <option value='Payment'>Payment</option>
                                            <option value='Payoff'>Payoff</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Type of Contact</label>
                                        <select name='typeofcontact' onChange={handleChange} value={notesData?.typeofcontact || ''} className={`form-control ${noteErrors.typeofcontact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, typeofcontact: null }) }}>
                                            <option value='' selected="">Select</option>
                                            <option value='First Attempt'>First Attempt</option>
                                            <option value='Second Attempt'>Second Attempt</option>
                                            <option value='Second Attempt'>Third Attempt</option>
                                            <option value='Contacted'>Contacted</option>
                                            <option value='Bad Contact Info'>Bad Contact Info</option>
                                        </select>
                                    </div>
                                   {notesData?.reason === 'Other' && 
                                   <div className="form-group">
                                   <label>Other Please Specify</label>
                                   <input tabIndex={2} type="text" value={other} placeholder="Please Specify" onChange={(e)=>{setOther(e.target.value)}} name='other' className={`form-control ${noteErrors.other && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, other: null }) }}/>
                               </div>}
                                            
                                        
                                    <div className="form-group">
                                        <label>Note</label>
                                        <textarea rows="5" value={notesData?.description || ''}  cols="40" name='description' onChange={handleChange} className={`form-control ${noteErrors.description && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, description: null }) }}> </textarea>
                                    </div>
                                    <div className="form-group">
                                        <a href="#" onClick={(e)=>{e.preventDefault();handleAdd();}} className="fs_btn">Add</a>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                {allNotes?.length > 0 && <DataTable className='termsheetmanager_table'
                        columns={columns}
                        data={allNotes}
                        pagination
                        customStyles={customStyles}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                        paginationComponentOptions={{
                          rowsPerPageText: 'Per Page',
                        }}
                      />}
                            
                                
                            </div>
                        </div>
                            

                        </div>
                       
                    </div>
                    
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="viewnote" data-bs-backdrop="static" tabindex="-1" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">VIEW NOTE</h5>
                                <button type="button" id='viewClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <p>{viewNote}</p>
                                        </div>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            */}
   
        </>

    );
};

export default PipelineNotes;