import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../../utils/loanInfoContext';
const StrategyInfo4 = (props) => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [error, showerror] = useState(false);

    // const handleText = (e) => {
    //     showerror(false)
    //     console.log('sdfaf',e.target.value?.replace(/\s+/g, ' '));
    //     props.updateStrategyInfo({
    //         [props.formJSON?.name]: e.target.value?.replace(/\s+/g, ' '),
    //     });
    // }; //old code

    const handleText = (e) => {
        showerror(false);
        const updatedValue = e.target.value.replace(/ {2,}/g, ' '); // Only replaces multiple spaces with a single space
        props.updateStrategyInfo({
            [props.formJSON?.name]: updatedValue,
        });
    };
    

    const navNext = (e) => {
        if(props.strategyInfo?.executionstrategysummary && props.strategyInfo.executionstrategysummary.trim() !== ''){
            setLoanInfo((prevLoanInfo) => ({
                ...prevLoanInfo,
                strategyinfo: props.strategyInfo,
              }));
            navigate('/BorrowerInfo');
        }else{
            showerror(true)
        }
    }

    return (
       <>
         <div className="cont si">
            <div className="seleted_item">
                 <span className="material-icons-outlined"> done </span> <span className="text">My Source of down payment
                     -
                     <strong> {props?.strategyInfo?.downpaymentsource} </strong> </span>
             </div>
             <div className="seleted_item">
                 <span className="material-icons-outlined"> done </span> <span className="text">My plan for paying off
                     the loan -
                     <strong> {props?.strategyInfo?.payoffloan}</strong> </span>
             </div>
             <div className="seleted_item">
                 <span className="material-icons-outlined"> done </span> <span className="text">My Applicable license -
                     <strong> {props?.strategyInfo?.applicationlicense} </strong> </span> <a href="#" onClick={(e) => {e.preventDefault(); props.handleBack();}} className="back_btn">
                     <span className="material-icons-outlined">
                         shortcut
                     </span>Change
                 </a>
             </div>
             <h3 className="feild_title">{props.formJSON?.label}</h3>
             <div className="texbox">
                 <p className="sub_title">{props.formJSON?.description}</p>
                 <textarea id={props.formJSON?.id} className="form-control" style={error ? { border: '1px solid red' } : {}} rows="4" cols="50" value={props?.strategyInfo?.executionstrategysummary} onChange={(e) =>{handleText(e)}} > </textarea>
                 <button id="strategyInfoNext" type="button" className="cp_btn" onClick={(e) => {navNext()}}>Next</button>
             </div>
            </div>
        </>
    );
};

export default StrategyInfo4;