import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useDispatch, useSelector } from 'react-redux';
import { getPaytable ,getAllServiceFee, addPayment, getPayments, getloanSummary,paymentsReminder , sendStatementPayments} from '../utils/reducer.js';
import CurrencyInput from 'react-currency-input-field';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';  
import DataTable from 'react-data-table-component';
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import Blockingtextmsg from './Blockingtext';
import { formatCurrency } from '../utils/commonUtils';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';

const ServicesPayments = () => {
    const dispatch = useDispatch();
    const blockingtext = 'Payments cannot be accessed until servicing setup gets completed';
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const data = [
        {
          id: 1,
          paymentdate :'2024-07-26T07:16:08.916Z',
          duedate : '2024-07-26T07:16:08.916Z',
          amountpaid: 300000,
          type : 'Interest Payment',
          reference: 'Payment',
          
        },
      ];
      const paydata = [
    {
        loanid: "300123",
        startdate: "2024-07-26T07:16:08.916Z",
        enddate: "2024-07-26T07:16:08.916Z",
        startbalance: 360.00,
        principalamountadded: 365.00,
        interestamountadded: 315.00,
        latefeeadded: 325.00,
        total: 398.00,
        principalamountpaid: 333.00,
        interestamountpaid: 334.00,
        latefeespaid: 395.00,
        totalamountpaid: 387.00,
        endbalance: 469.00
    },
    ]
      
    const [currentDate, setCurrentDate] = useState('');
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanSummary, setLoanSummary] = useState({});
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const loading = useSelector((state) => state.loaderVisible);
    const [errors,setErrors] = useState({});
    const [paymentData, setPaymentData] = useState({loanid:loanid, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date().toLocaleDateString('en-US')}`,finalpayment :false });
    const [paymentTable , setPaymentTable] = useState([]);
    const [payTable,setPayTable] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows2, setTotalRows2] = useState(0);
    const [page2, setPage2] = useState(1);
    const [perPage2, setPerPage2] = useState(10);
    const [paymentType,setPaymentType] = useState('add');
    const [showForm , setShowForm] = useState(false);
    const [feeTableData,setFeetableData] = useState([]);
    const [feeTablesummaryData,setFeetableSummaryData] = useState([]);
    const [totalPaymentFrom, setTotalPaymentFrom] = useState(0);
    const [amountNotSufficient, setAmountNotSufficient] = useState(false);
    const [groupedFees, setGroupedFees] = useState({});
    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
        setCurrentDate(formattedDate);
    }, []);
    useEffect(() => {
        if(payTable?.length > 0 ){
            setPaymentData({loanid:loanid, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date(payTable[0]?.endDate).toLocaleDateString('en-US')}`,finalpayment :false })
        }
    }, [payTable]);
    useEffect(() => {
        console.log('loanSummaryloanSummary',loanSummary);
    }, [loanSummary]);
    
    useEffect(() => {
        // Default to 0 if the values are not present
        const amountFromReserves = paymentData?.amountfromreserves || 0;
        const amountFromBorrower = paymentData?.amountfromborrower || 0;
    
        // Calculate the total
        const totalPaymentFrom = amountFromReserves + amountFromBorrower;
    
        // Set the state with the calculated total
        setTotalPaymentFrom(totalPaymentFrom);
    
        console.log('loanSummary:', loanSummary); 
    }, [paymentData?.amountfromreserves, paymentData?.amountfromborrower]);

    useEffect(() => {
        // Initialize total fees as 0
        let totalFeesEntered = 0;
    console.log('groupedFees',groupedFees);
        // Loop through the groupedFees to accumulate fee amounts dynamically
        Object.keys(groupedFees).forEach(feeKey => {
            const feeGroup = groupedFees[feeKey];
            const formattedKeyName = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'otherfee' : toLowerCaseNoSpace(feeGroup.quickFee[0]); // Convert to camelCase
            const feeAmount = paymentData?.[formattedKeyName] || 0; // Get the fee amount from paymentData or default to 0
            const formattedKey1098Name = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'principal1098therfee' : toLowerCaseNoSpace("principal1098"+feeGroup.quickFee[0]); 
            const fee1098Amount = paymentData?.[formattedKey1098Name] || 0;

            totalFeesEntered += feeAmount+fee1098Amount; // Add the fee amount to totalFeesEntered
        });
    
        // Get principal and interest amounts (default to 0 if not available)
        const principalamount = paymentData?.principalamount || 0;
        const interestamount = paymentData?.interestamount || 0;
    
        // Calculate the total payment sum
        const totalPayment = principalamount + interestamount + totalFeesEntered;
    
         // Check if totalPayment matches totalPaymentFrom
         console.log('totalPayment',totalPayment, 'totalPaymentFrom',totalPaymentFrom)
        if (totalPayment === totalPaymentFrom) {
            console.log('Total Payment is valid:', totalPayment);
            setAmountNotSufficient(false); 
        } else {
            console.log('Total Payment is less than expected');
            setAmountNotSufficient(true);  // Example action if the condition fails
        }
    
    }, [paymentData, groupedFees, totalPaymentFrom]);

    useEffect(() => {
        if (paymentData?.nsf) {
            setPaymentData((prevData) => {
                // Create a copy of the current paymentData
                const updatedData = { ...prevData };
    
                
    
                // Also clear principal and interest amounts
                updatedData.amountfromborrower = 0;
                updatedData.amountfromreserves = 0;
    
                return updatedData;
            });
        }
    }, [paymentData?.nsf, groupedFees]);
    
    const initializer =()=>{
        setPage(1);setPaymentData({loanid:loanid,finalpayment :false, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date().toLocaleDateString('en-US')}` });setPerPage(10);setTotalRows(0);setmessage('');setPage2(1);setPerPage2(10);setTotalRows2(0);setPaymentType('add');
        setShowForm(false);
    }
    useEffect(() => {
        if(loanid){
            initializer();
            payTableGet();
            getAllFeeApi();
            paymentsGet();
            loanSummaryGet();
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

    useEffect(() => {
        if(paymentData?.paymentmethod == 'Pull from Reserves'){
            
            setPaymentData((prevData) => {
                // Create a copy of the current paymentData
                const updatedData = { ...prevData };
    
                Object.keys(groupedFees).forEach(feeKey => {
                    const feeGroup = groupedFees[feeKey];
                    const formattedKeyName = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'otherfee' : toLowerCaseNoSpace(feeGroup.quickFee[0]); // Convert to camelCase
            
                    console.log('formattedKeyName',formattedKeyName);
                    // Delete the fee field if it exists in paymentData
                    if (updatedData[formattedKeyName]) {
                        delete updatedData[formattedKeyName];
                    }
                });
    
                updatedData.principalamount = 0;
    
                return updatedData;
            });
        }
    }, [paymentData?.paymentmethod]);

    

      useEffect(() => {
        console.log('payments data',paymentData);
      }, [paymentData]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'checkbox') {
            setPaymentData(prev => ({
                ...prev,
                [name]: e.target.checked,
                ...(name === 'nsf' && {
                    nsftimestamp: e.target.checked ? new Date().toISOString() : null  // Update timestamp only for 'nsf'
                }),
                ...(name === 'finalpayment' && {
                    finalpaymenttimestamp: e.target.checked ? new Date().toISOString() : null  // Update timestamp only for 'finalpaymenttimestamp'
                })
            }));
        } else {
            setPaymentData(prev => ({
                ...prev,
                [name]: value,
            }));
        }

    }

    const handleFocus=(e)=>{
        let name = e.target.name
        setErrors(prev => ({
            ...prev,
            [name]: null,
            feeFields: null
        }));
    };

    const handleAddPayment =()=>{
        setPaymentType('add');
        console.log('payTable',payTable);
        // Get the current month
        const currentDate = new Date(); // Months are 0-indexed in JavaScript (0 = January, 11 = December)

        // Find the object where the startDate month matches the current month
        const matchingObject = payTable.find(item => {
            const startDate = new Date(item.startDate);
            const endDate = new Date(item.endDate);
            return currentDate >= startDate && currentDate <= endDate;
        }) || (currentDate < new Date(payTable[0].startDate) ? payTable[0] : null);
        setPaymentData({loanid:loanid, amountfromreserves:0, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date(matchingObject?.endDate).toLocaleDateString('en-US')}`,finalpayment :false, duedate: matchingObject?.endDate, paymentdate: new Date(), amountfromborrower: 0 });   
        setErrors({});
        setShowForm(true)
    }

    const handleClose =()=>{
        setErrors({});
        setPaymentData({loanid:loanid, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date().toLocaleDateString('en-US')}`,finalpayment :false });   
    };

    const handleBack = ()=>{
        setErrors({});
        setPaymentData({loanid:loanid, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date().toLocaleDateString('en-US')}`,finalpayment :false },);   
        setShowForm(false);
    }

    const handleAdd =()=>{
        const fieldsCheck = {
            duedate: "duedate",
            paymentdate: "paymentdate",
            description : "description",
            paymenttype : "paymenttype",
            paymentmethod : "paymentmethod",
            reference : "reference",
            amountfromreserves : "amountfromreserves",
            amountfromborrower : "amountfromborrower",
            // extensionprovision : "extensionprovision",
            // latefee : "latefee",
            // nsffee : "nsffee",
            // servicingfee : "servicingfee",
            // principalamount: "principalamount",
            // interestamount: "interestamount",
            // extensionprovision: "extensionprovision"

          };

          if (paymentData?.nsf) {
            delete fieldsCheck.amountfromreserves;
            delete fieldsCheck.amountfromborrower;
        }

          const errors = {};
          for (const [fieldName, fieldLabel] of Object.entries(fieldsCheck)) {
            let value = paymentData[fieldName];
             if (typeof value === 'number') {
              }else{
                if(fieldName == 'paymentdate'){

                }
                console.log('sddfsdf',fieldName, paymentData);
                if (paymentData?.[fieldName] == null || (typeof paymentData[fieldName] === 'string' && paymentData[fieldName].trim() === '')) {
                    errors[fieldName] = `Please Enter ${fieldLabel}`;
                }
              }
          };
        const feeFields = ['principalamount', 'interestamount', 'extensionprovision'];

        const isAnyFeeFieldFilled = feeFields.some(feeField => {
            const value = paymentData[feeField];
            return value != null && !(typeof value === 'string' && value.trim() === '');
        }); 
        // Check if any of the specified fee fields have values
        const isAnyFeeInArrayFilled = Object.keys(groupedFees).some(feeFieldKey => {
            const feeGroup = groupedFees[feeFieldKey]; // Access the fee group
            const formattedKeyName = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'otherfee' : toLowerCaseNoSpace(feeGroup.quickFee[0]); // Format quickFee[0] to lowercase, no spaces
            const value = paymentData[formattedKeyName]; // Get the corresponding value from paymentData
            console.log('value',formattedKeyName, value);
            // Return true if value exists and is not an empty string (for strings, trim is applied)
            return value != null && !(typeof value === 'string' && value.trim() === '');
        });
        // Check if any fee in the fees array has a non-zero feeAmount, but only if feeTableData exists
        // const isAnyFeeInArrayFilled = feeTableData
        //     ? paymentData.fees?.some(fee => fee.feeAmount > 0)
        //     : false;

            console.log('isAnyFeeFieldFilled',paymentData, isAnyFeeFieldFilled, isAnyFeeInArrayFilled);
        // If neither the fee fields nor the fees array are filled, add an error
        if (!isAnyFeeFieldFilled && !isAnyFeeInArrayFilled) {
            errors.feeFields = 'Please enter at least one of the Principal Amount, Interest Amount, or any fee amount.';
        }

        if(amountNotSufficient && !paymentData?.nsf){
            errors.amountNotSufficient =  "Payment from and Payment towards should be equal.";
        }

        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            console.log(paymentData)
            dispatch(showLoader());
            addPayment(paymentData, paymentType).then(function (response) {
            dispatch(hideLoader());
            let msg = response?.id ? 'Updated Successfully' : 'Saved Successfully';
            setmessage(msg);
            setPopupOpen(true);
            setShowForm(false);
            paymentsGet();
            loanSummaryGet();
            })
                .catch((err) => {
                    console.error.bind("my account error", err);
                    dispatch(hideLoader());
                })
            
        }
    };
    const handleEdit=(row)=>{
        let data = row
        setPaymentData(data);
        setPaymentType('edit')
        setErrors({});
        setShowForm(true);
    }
    const closePopup =()=>{
        setPopupOpen(false);
        setPaymentData({loanid:loanid, reference: `Payment on ${new Date().toLocaleDateString('en-US')}`, description: `Payment for ${new Date().toLocaleDateString('en-US')}`,finalpayment :false });
    };

    const paymentsColumn = [
        {
          name: 'PAYMENT ENTERED DATE',
          selector: (row) => row?.paymentdate ?  moment.utc(row?.paymentdate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '10%',
          maxWidth: '10%',
        },
        {
          name: 'DUE DATE',
          selector: (row) => row?.duedate ?  moment.utc(row?.duedate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
        },
        {
          name: 'PAYMENT DATE',
          selector: (row) => row?.paymentdate ?  moment.utc(row?.paymentdate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
        },
        {
          name: 'AMOUNT PAID',
          selector: (row) => row?.amountfromborrower ? formatCurrency(row?.amountfromborrower+row?.amountfromreserves) : '',
        //   sortable: true,
        minWidth: '7%',
        maxWidth: '7%',
         
        },
        {
          name: 'PRINCIPAL AMOUNT',
          selector: (row) => row?.principalamount ? formatCurrency(row?.principalamount) : '',
        //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
         
        },
        {
          name: 'INTEREST AMOUNT',
          selector: (row) => row?.interestamount ? formatCurrency(row?.interestamount) : '',
        //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
         
        },
        {
            name: 'EXTENSION FEE',
            selector: (row) => row?.extensionfee ? formatCurrency(row?.extensionfee) : '',
          //   sortable: true,
          minWidth: '8%',
          maxWidth: '8%',
           
          },
        {
          name: 'SERVICING FEE',
          selector: (row) => row?.servicingfee ? formatCurrency(row?.servicingfee) : '',
        //   sortable: true,
        minWidth: '8%',
        maxWidth: '8%',
         
        },
        {
          name: 'LATE FEE',
          selector: (row) => row?.latefee ? formatCurrency(row?.latefee) : '',
        //   sortable: true,
        minWidth: '6%',
        maxWidth: '6%',
         
        },
        {
            name: 'NSF FEE',
            selector: (row) => row?.nsffee ? formatCurrency(row?.nsffee) : '',
          //   sortable: true,
          minWidth: '7%',
          maxWidth: '7%',
           
        },
        {
              name: 'OTHER FEE',
              selector: (row) => row?.otherfee ? formatCurrency(row?.otherfee) : '',
            //   sortable: true,
            minWidth: '7%',
            maxWidth: '7%',
             
        },
        {
            name: 'REFERENCE#',
            selector: (row) => row?.reference || '',
          //   sortable: true,
          minWidth: '9%',
          maxWidth: '9%',
          },
        {
          name: 'EDIT',
          cell: (row, index) => (
            <div className="btn-group">
              <button
                id={`edit-${index}`}
                type="button"
                onClick={() => handleEdit(row)}
                className="btn btn-outline-secondary"
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop"
              >
                <span className="material-symbols-outlined icon tooltip-container">
                  <span className="tooltip">Edit</span>edit
                </span>
              </button>
            </div>
          ),
          minWidth: '6%',
        maxWidth: '6%',
          
        },
    ];

    const payTableGet=()=>{
        dispatch(showLoader());
        getPaytable(loanid).then(function (response) {
            if (response?.result) {
                console.log('pay table',response?.result);
                if(response?.result){
                    setPayTable(response?.result);

                }
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }
    const paymentsGet=()=>{
        dispatch(showLoader());
        getPayments(loanid).then(function (response) {
            if (response?.result?.data?.length > 0) {
                console.log('pay getPayments',response?.result);
                setPaymentTable(response?.result.data);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    const loanSummaryGet=()=>{
        dispatch(showLoader());
        getloanSummary(loanid).then(function (response) {
            if (response?.result) {
                console.log('pay getPayments here',response?.result);
                setLoanSummary(response?.result);
                const extractedFees = Object.keys(response?.result)
                    .filter(key => !['principalObj', 'interestObj', 'totalAmountDue', 'dueAsOf', 'lateFeeObj', 'totalDrawBalance'].includes(key))
                    .map(key => {
                        // Convert the key to a readable fee type
                        const feeType = key?.replace(/([A-Z])/g, ' $1')?.replace('Obj', '')?.trim();                    // Remove extra spaces

                        // Capitalize first letter of each word
                        const formattedFeeType = feeType?.split(' ')?.map(word => word.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');

                        return {
                            feeType: formattedFeeType, // e.g., 'Servicing Fee'
                            ...response?.result?.[key]                // Include the fee details
                        };
                    });
                console.log('extractedFees', extractedFees);
                setFeetableSummaryData(extractedFees)
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    }

    
    const payTableColumn = [
        {
          name: 'START  DATE',
          selector: (row) => row?.startDate ?  moment.utc(row?.startDate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '33%',
        maxWidth: '33%',
        },
        {
          name: 'END DATE',
          selector: (row) => row?.endDate ?  moment.utc(row?.endDate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '34%',
        maxWidth: '34%',
        },
        // {
        //   name: 'START BALANCE(USD)',
        //   selector: (row) => row?.startbalance ? formatCurrency(row?.startbalance)  : '',
        // //   sortable: true,
        // minWidth: '12%',
        // maxWidth: '12%',
        // },
        {
          name: 'INTEREST AMOUNT',
          selector: (row) => row?.interestAmount ? formatCurrency(row?.interestAmount)  : '$0.00',
        //   sortable: true,
        minWidth: '33%',
        maxWidth: '33%',
        },
        // {
        //     name: 'LATE FEES',
        //     selector: (row) => row?.lateFee ? formatCurrency(row?.lateFee) : '$0.00',
        //   //   sortable: true,
        //   minWidth: '25%',
        //   maxWidth: '25%',
        //   }

       
        // {
        //   name: 'TOTAL AMT DUE(USD)',
        //   selector: (row) => row?.total ? formatCurrency(row?.total)  : '',
        // minWidth: '12%',
        // maxWidth: '12%',
        // },
        // {
        //   name: 'PRINCIPAL AMOUNT PAID (USD)',
        //   selector: (row) => row?.principalamountpaid ? formatCurrency(row?.principalamountpaid)  : '',
        // minWidth: '12%',
        // maxWidth: '12%',
        // },
        // {
        //     name: 'INTEREST AMOUNT PAID (USD)',
        //     selector: (row) => row?.interestamountpaid ? formatCurrency(row?.interestamountpaid)  : '',
        //   //   sortable: true,
        //   minWidth: '8%',
        //   maxWidth: '8%',
        //   },
        // {
        //     name: 'LATE FEES PAID',
        //     selector: (row) => row?.latefeespaid ? formatCurrency(row?.latefeespaid)  : '',
        //   //   sortable: true,
        //   minWidth: '8%',
        //   maxWidth: '8%',
        //   }
        //   ,{
        //     name: 'TOTAL AMOUNT PAID',
        //     selector: (row) => row?.totalamountpaid ? formatCurrency(row?.totalamountpaid)  : '',
        //   //   sortable: true,
        //   minWidth: '8%',
        //   maxWidth: '8%',
        //   },
        //   {
        //     name: 'END BALANCE',
        //     selector: (row) => row?.endbalance ? formatCurrency(row?.endbalance)  : '',
        //   minWidth: '12%',
        //   maxWidth: '12%',
        //   },
        
    ];
    const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        // Format table data for Excel
        const formattedData = payTable.map(item => ({
            'START DATE':item.startDate? moment.utc(item.startDate).format('MM-DD-YYYY') : '',
            'END DATE':item.endDate ? moment.utc(item.endDate).format('MM-DD-YYYY') : '',
            'INTEREST AMOUNT' :  item.interestAmount ? formatCurrency(item.interestAmount) : '$0',
            // 'LATE FEES' :  item.lateFee ? formatCurrency(item.lateFee) : '$0',
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
            // Calculate column widths based on content
            const columnWidths = Object.keys(formattedData[0]).map(column => {
                const maxContentLength = formattedData.reduce((max, row) => {
                    const cellContent = row[column] || '';
                    return Math.max(max, cellContent.toString().length);
                }, column.length); // Set initial width to column name length
                return { wch: maxContentLength + 2 }; // Add some padding
            });
            ws['!cols'] = columnWidths;
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileData = new Blob([excelBuffer], { type: fileType });
        saveAs(fileData, 'paytable_excel' + fileExtension);
    };

    const getAllFeeApi = ()=>{
        dispatch(showLoader());
        getAllServiceFee(loanInfo?.loanid).then(function (response) {
            if (response?.result) {
                console.log("get all fee api res",response?.result);
                setFeetableData(response?.result);
                let groupedFee = response?.result.reduce((acc, fee) => {
                    const feeKey = fee.quickFee[0]; // Group by the first value in quickFee array
                    if (!acc[feeKey]) {
                        acc[feeKey] = {
                            quickFee: fee.quickFee,
                            feeAmount: fee.feeAmount,
                            otherfeetitle: fee.otherfeetitle || '',
                            feeid: fee.feeid,
                            fees: [fee],
                            principal1098reportable: fee.principal1098reportable
                        };
                    } else {
                        acc[feeKey].feeAmount += fee.feeAmount; // Sum feeAmount for matched quickFee
                        acc[feeKey].fees.push(fee);
                    }
                    return acc;
                }, {});
                console.log('groupedFee',groupedFee);
                setGroupedFees(groupedFee);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("get all  fee api res", err);
            })
    };
    const feeColumn = [
        {
          name: 'FEE',
          selector: (row) => row?.quickFee || '',
        //   sortable: true,
        minWidth: '30%',
          maxWidth: '30%',
        },
        {
          name: 'EFFECTIVE DATE',
          selector: (row) => row?.effectiveDate ?  moment.utc(row?.effectiveDate).format("MM-DD-YYYY") : '',
        //   sortable: true,
        minWidth: '40%',
        maxWidth: '40%',
        },
        {
          name: 'AMOUNT',
          selector: (row) => row?.feeAmount ? formatCurrency(row?.feeAmount) : '$0.00',
        //   sortable: true,
        minWidth: '30%',
        maxWidth: '30%',
         
        },
       
        
    ];
    const feesummaryColumn = [
        {
          name: 'FEE TYPE',
          selector: (row) => row?.feeType || '',
        //   sortable: true,
        minWidth: '30%',
          maxWidth: '30%',
        },
        {
          name: 'FEE AMOUNT',
          selector: (row) => row?.totalAmount ? formatCurrency(row?.totalAmount) : '$0.00',
        //   sortable: true,
        minWidth: '30%',
        maxWidth: '30%',
         
        },
        {
            name: 'PAID',
            selector: (row) => row?.amountPaid ? formatCurrency(row?.amountPaid) : '$0.00',
          //   sortable: true,
          minWidth: '20%',
          maxWidth: '20%',
           
          },
          {
            name: 'OWED AMOUNT',
            selector: (row) => row?.amountDue ? formatCurrency(row?.amountDue) : '$0.00',
          //   sortable: true,
          minWidth: '20%',
          maxWidth: '20%',
           
          },
       
        
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const customStyles2 = {
        tableWrapper: {
            style: {
                maxHeight: '400px', 
                overflowY: 'auto',  
            },
        },
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };

console.log('paymentData',paymentData);
const handlereminder =()=>{
    dispatch(showLoader());
    paymentsReminder(loanid).then(function (response) {
        if(response?.result === 'Email sent successfully'){
            setmessage('Reminder Sent Successfully');
            setPopupOpen(true);
        }  
        dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
}


const toLowerCaseNoSpace = (str) => {
    return str.replace(/\s+/g, '')?.toLowerCase(); // Remove spaces and convert to lowercase
};

const generatePDFWithValues = async (htmlContent) => {
    try {
        dispatch(showLoader());
        const replacedContent = htmlContent;
        let contentSections = replacedContent.split('<!-- pagebreak-->');

        // Function to generate PDFs for each content section
        const generatePDFs = async () => {
            const pdfDocs = [];
            for (let i = 0; i < contentSections.length; i++) {
                let doc = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: 'a4',
                    autoPrint: true,
                });

                await new Promise((resolve) => {
                    doc.html(contentSections[i], {
                        margin: [20, 30, 20, 30],
                        html2canvas: {
                            scale: 0.545,
                        },
                        callback: () => {
                            console.log(`Generated PDF ${i + 1}/${contentSections.length}`);
                            pdfDocs.push(doc.output('arraybuffer'));
                            resolve();
                        },
                    });
                });
            }
            return pdfDocs;
        };

        // Function to merge PDFs
        const mergePDFs = async (pdfDocs) => {
            const mergedPdf = await PDFDocument.create();
            for (const pdfBytes of pdfDocs) {
                const pdf = await PDFDocument.load(pdfBytes);
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach((page) => mergedPdf.addPage(page));
            }
            return await mergedPdf.save();
        };

        // Generate and merge PDFs
        const pdfDocs = await generatePDFs();
        const mergedPdfBytes = await mergePDFs(pdfDocs);

        // Create a blob from the merged PDF
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob); // Generate a URL for the PDF blob

        // Open the PDF in a new window
        const pdfWindow = window.open(pdfUrl, '_blank');
        if (pdfWindow) {
            pdfWindow.focus(); // Focus on the new window to ensure the PDF is displayed
        } else {
            console.error('Failed to open new window');
        }

        dispatch(hideLoader());
        console.log('All PDFs generated, merged, and opened in a new window.');
        
    } catch (error) {
        console.error('Error generating or downloading file:', error);
        dispatch(hideLoader());
    }
};

const handleSendStatement = (param) => {
    let pageVal = param;
    
    dispatch(showLoader());
    sendStatementPayments(loanid,pageVal).then(function (response) {
        //if(response?.result === 'Email sent successfully'){
        const isHTML = /<\/?[a-z][\s\S]*>/i.test(response?.result); // Simple regex to check for HTML tags

        if (param === "preview" && isHTML) {
            generatePDFWithValues(response?.result,loanid);
        }else if(param == "sendstatement" && response?.result === "Email sent successfully"){
            setmessage('Statement Sent Successfully');
            setPopupOpen(true);
        }  
        dispatch(hideLoader());
    })
    .catch((err) => {
        console.error.bind("my account error", err);
        dispatch(hideLoader());
    })
}
console.log('heer is the king',);
    return (
        <div id="layout-wrapper" className="dashboard">
             {loading ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage
                    type={popupMessage.includes('Successfully') ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup}
                />
            )}
            <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
            <h2 className="form_title">Servicing <span className="space">&#10095;</span> <span className="sub">Payments</span> </h2>
            {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
            <Blockingtextmsg  textmessage={blockingtext}/> : 
            <div className="card user_management detailed_app">
            <div className="card-body my_account history loan_summary servicing_payments">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">SUMMARY</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">PAY TABLE</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " data-bs-toggle="tab" href="#HMDA" role="tab">
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">PAYMENTS</span>
                            </a>
                        </li>
                        </ul>


                    <div class="tab-content pt-15 text-muted">
                        <div class="tab-pane active summary_tab" id="ENTITY" role="tabpanel">
                        <div class="md-container">
                        <div className='search mt-10'>
                     
                        <p>Due As Of<span>{new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</span></p>
                           </div>
                           <div className="d-flex justify-content-between">
                           <div className="left">
                           <div className="d-flex justify-content-between summary mr_20">
                          <div className="detail_block"><p>Principal</p><h4>{loanSummary?.principalObj?.totalAmount ? formatCurrency(loanSummary?.principalObj?.totalAmount) : '$ 0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.principalObj?.amountPaid ? formatCurrency(loanSummary?.principalObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>Principal Owed</p><h4>{loanSummary?.principalObj?.amountDue ? formatCurrency(loanSummary?.principalObj?.amountDue) : '$ 0'}</h4></div>
                           </div>
                           <div className="d-flex justify-content-between summary">
                          <div className="detail_block"><p>Interest</p><h4>{loanSummary?.interestObj?.totalAmount ? formatCurrency(loanSummary?.interestObj?.totalAmount) : '$0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.interestObj?.amountPaid ? formatCurrency(loanSummary?.interestObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>Interest Owed</p><h4>{loanSummary?.interestObj?.amountDue ? formatCurrency(loanSummary?.interestObj?.amountDue) : '$0'}</h4></div>
                           </div>
                           {loanSummary?.lateFeeObj?.totalAmount  > 0 && <div className="d-flex justify-content-between summary ml_20 mb-0">
                          <div className="detail_block"><p>Late Fee</p><h4>{loanSummary?.lateFeeObj?.totalAmount ? formatCurrency(loanSummary?.lateFeeObj?.totalAmount) : '$0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.lateFeeObj?.amountPaid ? formatCurrency(loanSummary?.lateFeeObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>Late Fee Owed</p><h4>{loanSummary?.lateFeeObj?.amountDue ? formatCurrency(loanSummary?.lateFeeObj?.amountDue) : '$0'}</h4></div>
                           </div>}
                           </div>
                           {/* <div className="right">
                            
                          {loanSummary?.extensionFeeObj?.totalAmount  > 0 && <div className="d-flex justify-content-between summary">
                          <div className="detail_block"><p>Extension Fee</p><h4>{loanSummary?.extensionFeeObj?.totalAmount ? formatCurrency(loanSummary?.extensionFeeObj?.totalAmount) : '$0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.extensionFeeObj?.amountPaid ? formatCurrency(loanSummary?.extensionFeeObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>Extension Fee Owed</p><h4>{loanSummary?.extensionFeeObj?.amountDue ? formatCurrency(loanSummary?.extensionFeeObj?.amountDue) : '$0'}</h4></div>
                           </div>}
                           {loanSummary?.nsfFeeObj?.totalAmount  > 0 &&  <div className="d-flex justify-content-between summary">
                          <div className="detail_block"><p>NSF Fee</p><h4>{loanSummary?.nsfFeeObj?.totalAmount ? formatCurrency(loanSummary?.nsfFeeObj?.totalAmount) : '$0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.nsfFeeObj?.amountPaid ? formatCurrency(loanSummary?.nsfFeeObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>NSF Fee Owed</p><h4>{loanSummary?.nsfFeeObj?.amountDue ? formatCurrency(loanSummary?.nsfFeeObj?.amountDue) : '$0'}</h4></div>
                           </div>}
                           {loanSummary?.servicingFeeObj?.totalAmount > 0 &&  <div className="d-flex justify-content-between summary mb-0">
                          <div className="detail_block"><p>Servicing Fee</p><h4>{loanSummary?.servicingFeeObj?.totalAmount ? formatCurrency(loanSummary?.servicingFeeObj?.totalAmount) : '$0'}</h4></div>
                           <div className="detail_block"><p>Paid</p><h4>{loanSummary?.servicingFeeObj?.amountPaid ? formatCurrency(loanSummary?.servicingFeeObj?.amountPaid) : '$0'}</h4></div>
                           <div className="detail_block"><p>Servicing Fee Owed</p><h4>{loanSummary?.servicingFeeObj?.amountPaid ? formatCurrency(loanSummary?.servicingFeeObj?.amountPaid) : '$0'}</h4></div>
                           </div>}
                           </div> */}
                           </div>

                       
                       
                       <DataTable className='contacts'
                             // key={tableKey}
                                 columns={feesummaryColumn}
                                 data={feeTablesummaryData}
                                 customStyles={customStyles}
                             />
                           <div className="consolidated">
                           <p>Consolidated Balance<span>{loanSummary?.totalAmountDue ? formatCurrency(loanSummary?.totalAmountDue) : '$0'}</span></p>
                           </div>
                        </div>
                        </div>
                        <div class="tab-pane pay_table" id="APPLICANT" role="tabpanel">
                        <div className="form_section">
                        <div className="row">
                        <div className="col-md-9 mt-10">
                        <div className='search'>
                        <h3 className="title mb-0">REPAYMENT SCHEDULE</h3>
                    <div className='btn-group'>
                        <button type="button" onClick={handlereminder} className="fillbtn_new">Send Reminder<span
                                className="material-symbols-outlined">notifications_active</span></button>
                        {/* <button type="button" className="fillbtn_new">Unlock Borrower<span
                                className="material-symbols-outlined">key</span></button> */}
                        <button type="button" className="fillbtn_new" onClick={() => handleSendStatement("preview")}>Preview Statement<span
                                className="material-symbols-outlined">preview</span></button>
                        <button type="button" className="fillbtn_new" onClick={() => handleSendStatement("sendstatement")}>Send Statement<span
                                className="material-symbols-outlined">send</span></button>
                        <button onClick={handleExportToExcel} disabled={!payTable?.length > 0} type="button" className="fillbtn_new">Export<span
                                className="material-symbols-outlined">arrow_outward</span></button>
                    </div>
                </div>
               {payTable?.length > 0 ? 
               <DataTable className='contacts'
               // key={tableKey}
                   columns={payTableColumn}
                   data={payTable}
                   pagination
                   customStyles={customStyles}
                //    paginationTotalRows={totalRows2}
                //    paginationServer
                   paginationPerPage={5}
                //    paginationDefaultPage={page2}
                   // onChangeRowsPerPage={handlePerRowsChange}
                   // onChangePage={handlePageChange}
                   // onSort={handleSort}
                   // sortServer={true}
                   paginationRowsPerPageOptions={[5, 10, 20, 30]}
                   paginationComponentOptions={{
                       rowsPerPageText: 'Per Page',
                   }}
               /> : <div className="inprogress_page">
               <span className="material-symbols-outlined icon"> content_paste_search </span>
              <p>No Data Found</p>
          </div>
            }
                        
                        {/* <table className="table db_table payments_table">

                            <thead className="table-light">
                                <tr>
                                    <th width="4.5%">Loan ID</th>
                                    <th width="4.5%">sTART Date</th>
                                    <th width="5%">end date</th>
                                    <th width="2.9%">start balance</th>
                                    <th width="4.3%" className="text-center">principal amount added (usd)</th>
                                    <th width="4.2%" className="text-center">Interest Amount Added (usd)</th>
                                    <th width="5%" className="text-end">Late Fees Added</th>
                                    <th width="4%" className="text-end">tOTAL (USD)</th>
                                    <th width="4.2%" className="text-center">Principal Amount Paid (usd)</th>
                                    <th width="4.1%" className="text-center">interest amount paid (usd)</th>
                                    <th width="4%" className="text-end">Late Fees Paid</th>
                                    <th width="3%" className="text-center">Total Amount paid (usd)</th>
                                    <th width="0.3%" className="text-center">End Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>187850</td>
                                    <td>4th March, 2024</td>
                                    <td>4th March, 2024</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                </tr>
                                <tr>
                                    <td>187850</td>
                                    <td>4th March, 2024</td>
                                    <td>4th March, 2024</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                </tr>
                                <tr>
                                    <td>187850</td>
                                    <td>4th March, 2024</td>
                                    <td>4th March, 2024</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                </tr>
                                <tr>
                                    <td>187850</td>
                                    <td>4th March, 2024</td>
                                    <td>4th March, 2024</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                </tr>
                                <tr>
                                    <td>187850</td>
                                    <td>4th March, 2024</td>
                                    <td>4th March, 2024</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">360.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                    <td className="text-end">395.00</td>
                                </tr>



                            </tbody>
                        </table> */}
                   
                       </div>
                       <div className="col-md-3 fees_table">
                       <div className='search'>
                        <h3 className="title mb-0">Fees</h3>
                  
                </div>
                       <div className="table-responsive">
                       <div style={{ maxHeight: '295px', overflowY: 'auto' }}>
                       {feeTableData?.length > 0 &&
                       <DataTable className='contacts'
                           columns={feeColumn}
                           data={feeTableData}
                           defaultPageSize={5}
                        //    pagination
                           customStyles={customStyles2}
                           
                        //    paginationPerPage={5}
                        //    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        //    paginationComponentOptions={{
                        //        rowsPerPageText: 'Per Page',
                        //    }}
                       />
                       }
                       </div>
                       
                       
                       {/* <table className="table db_table payments_table">

                            <thead className="table-light">
                                <tr>
                                    <th>Fee</th>
                                    <th>EFFECTIVE DATE</th>
                                    <th>AMOUNT(USD)</th>
                                    </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>NSF</td>
                                    <td>05-22-2024</td>
                                    <td>223.00</td>
                                   
                                </tr>
                                <tr>
                                    <td>Attorney Fee</td>
                                    <td>05-22-2024</td>
                                    <td>223.00</td>
                                   
                                </tr>
                            </tbody>
                        </table> */}
                       </div>
                       </div>
                       </div>
                       </div>
                        </div>
                        <div class="tab-pane" id="HMDA" role="tabpanel">
                            {!showForm ? 
                             <div className="form_section">
                             <div className='search mt-10'>
                             <div className='d-flex align-items-center'>
                             {/* <h3 className="title mb-0 me-3">payments</h3> */}
                                 {/* <button type="button" className="fillbtn_new add_button" onClick={handleAddPayment} data-bs-toggle="modal"
                                 data-bs-target="#staticBackdrop"
                                 data-bs-whatever="@mdo">Add Payment<span
                                         className="material-symbols-outlined">add</span></button> */}
                                         <button type="button" className="fillbtn_new add_button" onClick={handleAddPayment} >Add Payment<span
                                         className="material-symbols-outlined">add</span></button>
     
                             </div>
     
                         </div>
                         
                         <DataTable 
                             // key={tableKey}
                                 columns={paymentsColumn}
                                 data={paymentTable}
                                 pagination
                                 customStyles={customStyles}
                                 paginationTotalRows={totalRows}
                                 paginationServer
                                 paginationPerPage={perPage}
                                 paginationDefaultPage={page}
                                 // onChangeRowsPerPage={handlePerRowsChange}
                                 // onChangePage={handlePageChange}
                                 // onSort={handleSort}
                                 // sortServer={true}
                                 paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                 paginationComponentOptions={{
                                     rowsPerPageText: 'Per Page',
                                 }}
                             />
                        
                             </div> 
                             : 

                             <div className="normal_page create_entity">
                             <div className="search">
                             <h4 className="mb-sm-0 font-size-18">ADD PAYMENT</h4>
                             <a href="#" className="back_btn" onClick={(e)=>{e.preventDefault();handleBack()}}>
                             <span className="material-icons icon"> arrow_back</span>BACK</a>
                             </div>
                             <div className="form_section">
                             <div className="row">
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Reference #</label>
                             <input type="text" onChange={handleChange} value={paymentData?.reference || ''}  name='reference' className={`form-control ${errors?.reference && 'invalid-input'}`} onFocus={handleFocus} placeholder="Enter Reference"/>
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Description</label>
                             <input type="text" onChange={handleChange} value={paymentData?.description || ''} name="description" className={`form-control ${errors?.description && 'invalid-input'}`} onFocus={handleFocus} placeholder="Enter Description"/>
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Due Date</label>
                            
                                     <input type="date" name='duedate'
                                        value={paymentData?.duedate ? new Date(paymentData?.duedate ).toISOString().split('T')[0] : ''} 
                                         className={`form-control ${errors?.duedate && 'invalid-input'}`} onChange={handleChange}
                                        placeholder="mm-dd-yyy" onFocus={handleFocus} />
                                                
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Payment Date</label>
                             
                                 <input type="date" name='paymentdate' value={paymentData?.paymentdate ? new Date(paymentData?.paymentdate ).toISOString().split('T')[0] : ''} 
                                    className={`form-control ${errors?.paymentdate && 'invalid-input'}`} onChange={handleChange}
                                      placeholder="mm-dd-yyy" onFocus={handleFocus}/>
                                                 
                            
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Payment Type</label>
                             <select value={paymentData?.paymenttype || ''} className={`form-control ${errors?.paymenttype && 'invalid-input'}`} onChange={handleChange} name="paymenttype" onFocus={handleFocus}>
                             <option>Select</option>
                             <option>Standard</option>
                             <option>Advance</option>
                             <option>Waiver</option>
                             </select>
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Payment Method</label>
                             <select value={paymentData?.paymentmethod || ''} className={`form-control ${errors?.paymentmethod && 'invalid-input'}`} onChange={handleChange} name="paymentmethod" onFocus={handleFocus}>
                             <option>Select</option>
                             <option value="ACH">ACH</option>
                             <option value="Cash">Cash</option>
                             <option value="Check">Check</option>
                             <option value="Previously Paid">Previously Paid</option>
                             {/* <option value="Pull From Available Draw Balance">Pull From Available Draw Balance</option>
                             <option value="Pull From Impounds">Pull From Impounds</option> */}
                             <option value="Pull from Reserves">Pull from Reserves</option>
                             </select>
                             </div>
                             </div>
                             <div className="col-md-2 interest_only">
                             <div className="form-group">
                             <label></label>
                             <label className="toggle mb-0">
                             <span className="toggle-label">NSF</span>
                             <input className="toggle-checkbox" checked={paymentData?.nsf || false} name='nsf' onChange={handleChange} type="checkbox"/>
                             <div className="toggle-switch">
                             </div>
                             </label>
                             </div>
                             </div>
                             <div className="col-md-2 interest_only">
                             <div className="form-group">
                             <label></label>
                             <label className="toggle mb-0">
                             <span className="toggle-label">Partial Payoff</span>
                             <input className="toggle-checkbox" checked={paymentData?.partialpayoff || false} name='partialpayoff'  onChange={handleChange} type="checkbox"/>
                             <div className="toggle-switch">
                             </div>
                             </label>
                             </div>
                             </div>
                             <div className="col-md-2 interest_only">
                             <div className="form-group">
                             <label></label>
                             <label className="toggle mb-0">
                             <span className="toggle-label">Final Payment</span>
                             <input className="toggle-checkbox" checked={paymentData?.finalpayment || false} name='finalpayment'  onChange={handleChange} type="checkbox"/>
                             <div className="toggle-switch">
                             </div>
                             </label>
                             </div>
                             </div>
                             </div>
                             <hr className="hr"></hr>
                             <div className="row">
                             <div className="search mb-2">
                             <h4 className="mb-sm-0 font-size-18">PAYMENT FROM</h4>
                             </div>
                            {errors?.amountNotSufficient && <label style={{color:'red'}}>{errors?.amountNotSufficient}</label>}
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Amount From Borrower</label>
                             <CurrencyInput
                                                        name='amountfromborrower'
                                                        className={`form-control ${errors?.amountfromborrower && 'invalid-input'}`}
                                                        onFocus={handleFocus}
                                                        placeholder='Enter Borrower Amount'
                                                        value={paymentData?.amountfromborrower || 0}
                                                        decimalsLimit={2}
                                                        prefix="$"
                                                        disabled={(paymentData?.paymentmethod == 'Pull from Reserves' || !paymentData?.paymentmethod || paymentData?.nsf)}
                                                        onValueChange={(value, name, values) => {
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                    'amountfromborrower': value ? value : '',
                                                            }));
                                                        }}
                                                        onBlur={() => {
                                                            // Convert to number on blur if valid
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                amountfromborrower: prevData.amountfromborrower
                                                                    ? parseFloat(prevData.amountfromborrower) || 0
                                                                    : 0, // Finalize as a number
                                                            }));
                                                        }}
                                                    />
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Amount From Reserves</label>
                             <CurrencyInput
                                                        name='amountfromreserves'
                                                        className={`form-control ${errors?.amountfromreserves && 'invalid-input'}`}
                                                        onFocus={handleFocus}
                                                        placeholder='Enter Reserves Amount'
                                                        value={paymentData?.amountfromreserves || 0}
                                                        decimalsLimit={2}
                                                        prefix="$"
                                                        disabled={(paymentData?.paymentmethod != 'Pull from Reserves' || !paymentData?.paymentmethod || paymentData?.nsf)}
                                                        onValueChange={(value, name, values) => {
                                                            const floatValue = values.float;
                                                            if (floatValue > (loanInfo?.balanceinterestreserveamount || loanInfo?.interestreserveamount)) {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['amountfromreserves']: loanInfo?.balanceinterestreserveamount || loanInfo?.interestreserveamount, // Set to max value if exceeded
                                                                }));
                                                            } else {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['amountfromreserves']: value ? value : '', // Set actual value
                                                                }));
                                                            }
                                                            // setPaymentData((prevData) => ({
                                                            //     ...prevData,
                                                            //         'amountfromreserves': values.float,
                                                            // }));
                                                        }}
                                                        onBlur={() => {
                                                            // Convert to number on blur if valid
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                amountfromreserves: prevData.amountfromreserves
                                                                    ? parseFloat(prevData.amountfromreserves) || 0
                                                                    : 0, // Finalize as a number
                                                            }));
                                                        }}
                                                        
                                                    />
                             </div>
                             </div>
                             <div className="col-md-3">
                            <p>Remaining Reserves</p>
                            <h4>{formatCurrency(loanInfo?.balanceinterestreserveamount || loanInfo?.interestreserveamount || 0)}</h4>
                        </div>
                          </div>
                          <hr className="hr"></hr>
                          <div className="row">
                             <div className="search mb-2">
                             <h4 className="mb-sm-0 font-size-18">PAYMENT TOWARDS</h4>
                             </div>
                             {errors?.feeFields && <label style={{color:'red'}}>{errors?.feeFields}</label>}
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Principal Amount</label>
                             <CurrencyInput
                                                        name='principalamount'
                                                        className={`form-control ${errors?.principalamount && 'invalid-input'}`}
                                                        onFocus={handleFocus}
                                                        placeholder='Enter Principal Amount'
                                                        value={paymentData?.principalamount || ''}
                                                        decimalsLimit={2}
                                                        prefix="$"
                                                        disabled={(paymentData?.paymentmethod == 'Pull from Reserves' || !paymentData?.paymentmethod)}
                                                        onValueChange={(value, name, values) => {
                                                            const floatValue = values.float;
                                                            if (floatValue > loanSummary?.principalObj?.amountDue) {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['principalamount']: loanSummary?.principalObj?.amountDue, // Set to max value if exceeded
                                                                }));
                                                            } else {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['principalamount']: value? value : '', // Set actual value
                                                                }));
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            // Convert to number on blur if valid
                                                            if (paymentData?.principalamount <= loanSummary?.principalObj?.amountDue) {
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                principalamount: prevData.principalamount
                                                                    ? parseFloat(prevData.principalamount) || 0
                                                                    : 0, // Finalize as a number
                                                            }));
                                                        }
                                                        }}
                                                    />
                             </div>
                             </div>
                             <div className="col-md-3">
                             <div className="form-group">
                             <label>Interest Amount</label>
                             <CurrencyInput
                                                        name='interestamount'
                                                        className={`form-control ${errors?.interestamount && 'invalid-input'}`}
                                                        onFocus={handleFocus}
                                                        placeholder='Enter Interest Amount'
                                                        value={paymentData?.interestamount || 0}
                                                        decimalsLimit={2}
                                                        prefix="$"
                                                        onValueChange={(value, name, values) => {
                                                            const floatValue = values.float;
                                                            if (floatValue > loanSummary?.interestObj?.amountDue) {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['interestamount']: loanSummary?.interestObj?.amountDue, // Set to max value if exceeded
                                                                }));
                                                            } else {
                                                                setPaymentData((prevData) => ({
                                                                    ...prevData,
                                                                    ['interestamount']: value ? value : '', // Set actual value
                                                                }));
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            // Convert to number on blur if valid
                                                            if (paymentData?.interestamount <= loanSummary?.interestObj?.amountDue) {
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                interestamount: prevData.interestamount
                                                                    ? parseFloat(prevData.interestamount) || 0
                                                                    : 0, // Finalize as a number
                                                            }));
                                                        }
                                                        }}
                                                    />
                             </div>
                             </div>
                             {/* <div className="col-md-3">
                             <div className="form-group">
                             <label>Main Late Fee</label>
                             <input type="text" value={paymentData?.latefee || ''} onChange={handleChange} onFocus={handleFocus} name="latefee" className={`form-control ${errors?.latefee && 'invalid-input'}`} placeholder="Enter Late Fee"/>
                             </div>
                             </div> */}
                             </div>
                             <div className="row">
                             {Object.keys(groupedFees).map((key) => {
            const feeGroup = groupedFees[key];
            const formattedKeyName = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'otherfee' : toLowerCaseNoSpace(feeGroup.quickFee[0]); // Convert to camelCase
            const formattedKey1098Name = (feeGroup.quickFee[0].toLowerCase() === 'other') ? 'principal1098therfee' : toLowerCaseNoSpace("principal1098"+feeGroup.quickFee[0]); 
            return (
                <div className="col-md-3" key={feeGroup.feeid}>
                    <div className="form-group">
                        <label>
                            {feeGroup.quickFee[0] === 'Other' && feeGroup.otherfeetitle
                                ? feeGroup.quickFee[0]
                                : feeGroup.quickFee[0]}
                        </label>
                        <CurrencyInput
                            name={formattedKeyName} // use feeid as the unique name
                            className={`form-control ${errors?.[feeGroup.feeid] && 'invalid-input'}`}
                            onFocus={handleFocus}
                            placeholder={`Enter ${feeGroup.quickFee[0]}`}
                            value={paymentData?.[formattedKeyName]} // show feeAmount as default value
                            decimalsLimit={2}
                            prefix="$"
                            disabled={(paymentData?.paymentmethod == 'Pull from Reserves' || !paymentData?.paymentmethod)}
                            max={feeGroup.feeAmount} // Set max as the sum of the feeAmount for the group
                            onValueChange={(value, name, values) => {
                                const floatValue = values.float;
                                
                                // Check if the value exceeds the max allowed (feeAmount)
                                if (floatValue > feeGroup.feeAmount) {
                                    setPaymentData((prevData) => ({
                                        ...prevData,
                                        [formattedKeyName]: feeGroup.feeAmount, // Set to max value if exceeded
                                    }));
                                } else {
                                    setPaymentData((prevData) => ({
                                        ...prevData,
                                        [formattedKeyName]: value ? value : '', // Set actual value
                                    }));
                                }
                            }}
                            onBlur={() => {
                                // Convert to number on blur if valid
                                if (paymentData[formattedKeyName] <= feeGroup.feeAmount) {
                                setPaymentData((prevData) => ({
                                    ...prevData,
                                    [formattedKeyName]: prevData[formattedKeyName]
                                        ? parseFloat(prevData[formattedKeyName]) || 0
                                        : 0, // Finalize as a number
                                }));
                            }
                            }}
                        />
                    </div>
                    {feeGroup?.principal1098reportable && <div className="form-group">
                        <label>
                            {feeGroup.quickFee[0] === 'Other' && feeGroup.otherfeetitle
                                ? "Principal 1098 " + feeGroup.quickFee[0]
                                : "Principal 1098 " + feeGroup.quickFee[0]}
                        </label>
                        <CurrencyInput
                            name={formattedKey1098Name} // use feeid as the unique name
                            className={`form-control ${errors?.[feeGroup.feeid] && 'invalid-input'}`}
                            onFocus={handleFocus}
                            placeholder={`Enter ${feeGroup.quickFee[0]}`}
                            value={paymentData?.[formattedKey1098Name]} // show feeAmount as default value
                            decimalsLimit={2}
                            prefix="$"
                            disabled={(paymentData?.paymentmethod == 'Pull from Reserves' || !paymentData?.paymentmethod)}
                            max={feeGroup.feeAmount} // Set max as the sum of the feeAmount for the group
                            onValueChange={(value, name, values) => {
                                const floatValue = values.float;
                                
                                // Check if the value exceeds the max allowed (feeAmount)
                                if (floatValue > feeGroup.feeAmount) {
                                    setPaymentData((prevData) => ({
                                        ...prevData,
                                        [formattedKey1098Name]: feeGroup.feeAmount, // Set to max value if exceeded
                                    }));
                                } else {
                                    setPaymentData((prevData) => ({
                                        ...prevData,
                                        [formattedKey1098Name]: value ? value : 0, // Set actual value
                                    }));
                                }
                            }}
                            onBlur={() => {
                                // Convert to number on blur if valid
                                if (paymentData[formattedKey1098Name] <= feeGroup.feeAmount) {
                                setPaymentData((prevData) => ({
                                    ...prevData,
                                    [formattedKey1098Name]: prevData[formattedKey1098Name]
                                        ? parseFloat(prevData[formattedKey1098Name]) || 0
                                        : 0, // Finalize as a number
                                }));
                            }
                            }}
                        />
                    </div>}
                </div>
            );
        })}
                             <div className="col-md-12 mb-3">
                             <div className="form-group">
                             <button type="button" onClick={handleAdd} className="fs_btn">{paymentType ==='add' ? 'Add' : 'Update'}</button>
                             </div>
                             </div>
                          </div>
                             </div>
                             </div>
     
                        }
                       


                        </div>
                    </div>
              
               

                  

                    {/* <div className="main_details payments_details mt-3">
                        <div className="detail_block">
                            <p>Due As Of</p>
                            <h4>4th March, 2024</h4>
                        </div>
                        <div className="detail_block">
                            <p>Principal Owed</p>
                            <h4>$ 0.00</h4>
                        </div>
                        <div className="detail_block">
                            <p>Interested Owed</p>
                            <h4>$ 13,25.00</h4>
                        </div>
                        <div className="detail_block">
                            <p>Late Fees Owed</p>
                            <h4>$6661.22</h4>
                        </div>
                        <div className="detail_block">
                            <p>Total Owed</p>
                            <h4>$13.985.20</h4>
                        </div>

                    </div> */}
                   

                    {/* <div className="modal popup new_popup fade" data-bs-keyboard="false" id="staticBackdrop" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" data-bs-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">{paymentType ==='add' ? 'Add' : 'Update'} PAYMENT</h5>
                                    <button type="button" id='close' onClick={handleClose} className="pop_close" data-bs-dismiss="modal"
                                        aria-label="Close"><span
                                            className="material-symbols-outlined">close</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Due Date</label>
                                                <input type="date" name='duedate'
                                                 value={paymentData?.duedate ? new Date(paymentData?.duedate ).toISOString().split('T')[0] : ''} 
                                                 className={`form-control ${errors?.duedate && 'invalid-input'}`} onChange={handleChange}
                                                    placeholder="mm-dd-yyy" onFocus={handleFocus} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Payment Date</label>
                                                <input type="date" name='paymentdate' value={paymentData?.paymentdate ? new Date(paymentData?.paymentdate ).toISOString().split('T')[0] : ''} 
                                                className={`form-control ${errors?.paymentdate && 'invalid-input'}`} onChange={handleChange}
                                                    placeholder="mm-dd-yyy" onFocus={handleFocus}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Amount Paid</label>
                                                <CurrencyInput
                                                        name='amountpaid'
                                                        className={`form-control ${errors?.amountpaid && 'invalid-input'}`}
                                                        onFocus={handleFocus}
                                                        placeholder='Enter Amount Paid'
                                                        value={paymentData?.amountpaid || ''}
                                                        decimalsLimit={2}
                                                        prefix="$"
                                                        onValueChange={(value, name, values) => {
                                                            setPaymentData((prevData) => ({
                                                                ...prevData,
                                                                    'amountpaid': values.float,
                                                            }));
                                                        }}
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select value={paymentData?.type} name='type' onChange={handleChange} className={`form-control ${errors?.type && 'invalid-input'}`} onFocus={handleFocus}>
                                                    <option selected="">Select</option>
                                                    <option value='Interest Payment'>Interest Payment</option>
                                                    <option value='Other'>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Reference</label>
                                                <textarea  value={paymentData?.reference || ''} name='reference' onChange={handleChange} rows="4" cols="50"
                                                    className={`form-control ${errors?.reference && 'invalid-input'}`}
                                                     placeholder="Enter Reference" onFocus={handleFocus}> </textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button type="button" onClick={handleAdd} className="fs_btn">{paymentType ==='add' ? 'Add' : 'Update'}</button>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div> */}
          
       
               
            </div>
        </div>
            }
           
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ServicesPayments;