import React, { useState, useEffect ,useRef} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import PopupMessage from './PopupMessage';
import { useLoanInfo } from '../utils/loanInfoContext';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { formatCurrency } from '../utils/commonUtils';
import {Appname, APIUrl,getAllDraws,getSingleDraw,addDrawRequest,updateDrawRequest,drawDeleteApi,getallContacts,orderInspectionEmail} from '../utils/reducer';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import axios from "axios";
import Loader from './Loader';
import EditorEdit from './EditorEdit.js';
import CurrencyInput from 'react-currency-input-field';
import SweetAlert from 'react-bootstrap-sweetalert'; 
import Blockingtextmsg from './Blockingtext'; 
import FileViewer from './DocViewer';
import VideoViewer from './VideoViewer.jsx';

const OrderDrawRequest = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showForm,setShowForm] = useState('Table');
    let uploadFilesData = [];
    const configdata = useSelector(state => state.configdata);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [scopeofworkFiles,setScopeofWorkFiles] = useState([]);
    const [orderinspectionFiles,setOrderInspectionFiles] = useState([]);
    const [verifyinspectionFiles,setVerifyinspectionFiles] = useState([]);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const loading = useSelector((state) => state.loaderVisible);
    const [orderRequestCount,setOrderRequestCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage,setPerPage] = useState(10);
    const [tableKey,setTableKey] = useState(0);
    const [selectedRows, setSelectedRows] = useState(false);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const editorRef = useRef(null);
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');
    const [editorValidate, setEditorValidate] = useState(false);

    const [showDoc, setshowDoc] = useState(false);
    const [showPlayer,setShowPlayer] = useState(false);
    const [type, setType] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [fileDocName, setFileDocName] = useState("");
    
    const [formDisable,setFormDisable] = useState(false);
    const [drawDisable,setDrawDisable] = useState(false);
    const [status,setStatus] = useState('');
    const [message,setMessage] = useState('');
    const [drawTableData,setDrawTableData] = useState([]);
    const [allData,SetAllData] = useState(null);

    const [emailError,setEmailError] = useState(null);
    const [emailInput, setEmailInput] = useState("");
    const columns = [
        {
            name: 'DRAWS',
            cell: (row, index) => row?.drawnumber || '',
            // selector: (row,index) => row?.index + 1 || '',

            minWidth: '5%',
            maxWidth: '5%',
        },
       
        {
            name: 'SCOPE OF WORK',
            selector: (row) => row?.scopeofwork?.scopeofwork || '',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'ORIGINAL BUDGET',
            selector: (row) => row?.budget?.total?.original ? formatCurrency(row?.budget?.total?.original) : '$0.00',

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'CURRENT BUDGET',
            selector: (row) => row?.budget?.total?.current ? formatCurrency(row?.budget?.total?.current) : '$0.00',
            

            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'APPROVED AMOUNT',
            selector: (row) => row?.approval?.amountapproved ? formatCurrency(row?.approval?.amountapproved) : '$0.00',
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: 'FEE AMOUNT',
            selector: (row) => row?.approval?.feeamount ? formatCurrency(row?.approval?.feeamount) : '$0.00',
            minWidth: '8%',
            maxWidth: '8%',
        },
               
    
        {
            name: 'PAID AMOUNT',
            selector: (row) => row?.drawpayout?.payoutamount ? formatCurrency(row?.drawpayout?.payoutamount) : '$0.00',
            minWidth: '8%',
            maxWidth: '8%',
        },
                   
      
        {
            name: 'REMAINING BUDGET',
            selector: (row) => row?.remainingbudget ? formatCurrency(row?.remainingbudget) : '',
            minWidth: '8%',
            maxWidth: '8%',
        },
        {
            name: '% ITEM COMPLETE',
            selector: (row) => row?.inspection ? calculatePercentage(row?.inspection,"sum","itemcomplete") + ' %' : '',
            minWidth: '7%',
            maxWidth: '7%',
        },
        {
            name: '% PROJECT COMPLETE',
            selector: (row) => row?.inspection ? calculatePercentage(row?.inspection,"sum","totalprojectcomplete") + ' %' : '',
            minWidth: '7%',
            maxWidth: '7%',
        },
        // {
        //     name: 'SUGGESTED AMOUNT',
        //     selector: (row) => row?.suggestedamount ? formatCurrency(row?.suggestedamount) : '',

        //     minWidth: '8%',
        //     maxWidth: '8%',
        // },
 
        {
            name: 'REQUEST AMOUNT',
            selector: (row) => row?.budget?.requestedamount ? formatCurrency(row?.budget?.requestedamount) : '',
            // cell: (row, index) => (
            //     requestedamount
            //     <input type="text" className="form-control" value="30,000" />
            // ),
            
            minWidth: '7%',
            maxWidth: '7%',
        },       
        {
            name: 'STATUS',
            cell: (row, index) => {
                let statusText = 'Pending';  // Default status
                let statusClass = '';  // Default class
        
                // Determine the status and its corresponding class
                if (row?.status ==='DENIED') {
                    statusText = 'Denied';
                    statusClass = 'text-danger';
                } else if (row?.status ==='APPROVED') {
                    statusText = 'Approved';
                    statusClass = 'text-success';
                } else if ((row?.status==='INPROGRESS') ) {
                    statusText = 'In Progress';
                    statusClass = 'text-secondary';
                } else if ((row?.status==='PAID') ) {
                    statusText = 'Draw Paid';
                    statusClass = 'text-success';
                }
        
                return (
                    <div className='status' onClick={() => handleEdit(row,index)} style={{ cursor: "pointer" }} >
                       <p className={`status_group ${statusClass}`}>{statusText}</p>
                        <span className="material-symbols-outlined icon tooltip-container">
                            edit
                       </span>
                    </div>
                );
            },
            minWidth: '9%',
            maxWidth: '9%',
        },
        // {
        //     name: 'CREATED BY',
        //     selector: (row) => row?.createdBy?.name || '',
        //     minWidth: '8%',
        //     maxWidth: '8%',
        // },
        // ,{
        //     name: 'CREATED ON',
        //     selector: (row) => row?.created ? moment(row?.created).format("MM-DD-YYYY") : '' ,
        //     minWidth: '8%',
        //     maxWidth: '8%',
        // },
        // {
        //     name: 'MODIFIED BY',
        //     selector: (row) => row?.modifiedBy?.[0].name || '',
        //     minWidth: '8%',
        //     maxWidth: '8%',
        // },
        // ,{
        //     name: 'MODIFIED ON',
        //     selector: (row) => row?.modifiedBy?.[0].modified ? moment(row?.modifiedBy?.[0].modified).format("MM-DD-YYYY") : '' ,
        //     minWidth: '8%',
        //     maxWidth: '8%',
        // },
        
        {
            name: 'ACTIONS',
            cell: (row, index) => (
                <>
                 <div className="btn-group">
                    {/* <button   onClick={() => handleView(row,index)} type="button" className="btn btn-outline-secondary"><span
                        className="material-symbols-outlined icon">
                        visibility </span></button> */}
                
                <button
                  id={`edit-${index}`}
                  type="button"
                  onClick={() => handleEditAdd(row,index)}
                  className="btn btn-outline-secondary"
                >
                  <span  className="material-symbols-outlined icon tooltip-container">
                    <span className="tooltip">Edit</span>edit
                  </span>
                </button>
                        {row?.status === 'PAID' ? '' :

                            <button
                                id={`delete-${index}`}
                                type="button"
                                onClick={() => handleDeleteClick(row)}
                                className="btn btn-outline-secondary"
                            >
                                <span className="material-symbols-outlined icon tooltip-container">
                                    <span className="tooltip">Delete</span>delete
                                </span>
                            </button>

                        }
              </div></>
            ),
            minWidth: '6%',
            maxWidth: '6%',

        },
    ];

    const calculatePercentage = (data, operation, key) => {
        const activeItems = data?.filter(item => item.status === "ACTIVE");
    
        const total = activeItems?.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);

        if (operation === 'sum') {
            return total;
        } else if (operation === 'avg') {
            return activeItems.length ? (total / activeItems.length).toFixed(2) : 0;
        } else {
            return 0
        }
    };
    //delete functions
    const [deleteData,setDeleteData] = useState('');
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [unsavedDeleteData,setUnsavedDeleteData] = useState({});
    const [unsavedConfirmation,setUnsavedConfirmation] = useState(false);
    const [savedDeleteData,setSavedDeleteData] = useState({});
    const [savedConfirmation,setSavedConfirmation] = useState(false);

    //scope of work add
    const [scopeofworkData,setScopeOfWorkData] = useState({});
    const [scopeofworkErrors,setScopeOfWorkErrors] = useState({});

    //budegt add
    const [budgetData, setBudgetData] = useState({
        labour: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        material: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        others: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        // credit: { original: '', current: '' },
        total: { percent: '', original: '', current: '' }
    });
    // const [budgetData, setBudgetData] = useState({
    //     labour: { quantity: 0, unit: 0, price: 0, percent: 0, original: 0, current: 0 },
    //     material: { quantity: 0, unit: 0, price: 0, percent: 0, original: 0, current: 0 },
    //     others: { quantity: 0, unit: 0, price: 0, percent: 0, original: 0, current: 0 },
    //     total: { percent: 0, original: 0, current: 0 }
    // });

    // inspections in add
    const [allInspectionData, setAllInspectionData] = useState([]);
    const [inspectionsData , setInspectionsData] = useState({});
    const [inspectionErrors, setInspectionErrors] = useState({});
    const [inspectionId, setinspectionId] = useState('');
    const [showConfirmation , setShowConfirmation] = useState(false)

    //supporting docs
    const [supportingDocsData,setSupportingDocsData] = useState({});
    const [supportingDocsErrors,setSupportingDocsErrors] = useState({});

    // scope of work
    const [singleLineItems,setSingleLineItems] = useState({});
    const [singleLineErrors,setSingleLineErrors] = useState({});
    const [popUpStatus,setPopupStatus] = useState(false);
    const fieldMapping = {
        "Add New": {},
        "Appliances": {
          "location": "Whole House/Building",
          "system": "Miscellaneous"
        },
        "Bathroom - Tub/Shower Faucets / Trim Kits": {
          "location": "Bathroom",
          "system": "Plumbing"
        },
        "Bathroom - Tubs & Showers & Tile Surrounds": {
            "location": "Bathroom",
            "system": "Plumbing"
        },
        "Carpentry - Baseboards & Trim": {
            "location": "Whole House/Building",
          "system": "Wood work"
        },
        "Countertops - Granite": {
          "location": "Kitchen",
          "system": "Stone work/Concrete"
        },
        "Countertops - Other": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Concrete / Flatwork": {
            "location": "Whole House/Building",
          "system": "Flooring"
        },
        "Demo / Trash Clean Out": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Doors (Actual Doors - Exterior)": {
            "location": "Whole House/Building",
          "system": "Windows and Doors"
        },
        "Doors (Actual Doors - Interior)": {
            "location": "Whole House/Building",
            "system": "Windows and Doors"
        },
        "Drywall Repairs": {
            "location": "Whole House/Building",
          "system": "Walls"
        },
        "Electrical Repairs": {
            "location": "Whole House/Building",
          "system": "Electrical"
        },
        "Exterior Paint": {
          "location": "Exterior",
          "system": "Miscellaneous"
        },
        "Fixtures - Ceiling Fans": {
            "location": "Whole House/Building",
          "system": "Fixtures/Lighting"
        },
        "Fixtures - Door Knobs": {
            "location": "Whole House/Building",
            "system": "Fixtures/Lighting"
        },
        "Fixtures - Kitchen, Bath Faucets": {
            "location": "Kitchen",
            "system": "Plumbing"
        },
        "Fixtures - Other Lighting": {
            "location": "Whole House/Building",
            "system": "Fixtures/Lighting"
        },
        "Flooring - Carpet": {
            "location": "Whole House/Building",
            "system": "Flooring"
        },
        "Flooring - Tile & Hardwood": {
            "location": "Whole House/Building",
            "system": "Flooring"
        },
        "Flooring - Vinyl": {
            "location": "Whole House/Building",
            "system": "Flooring"
        },
        "Foundation Repair": {
            "location": "Whole House/Building",
            "system": "Stone work/Concrete"
        },
        "Framing / Wall Movement": {
            "location": "Whole House/Building",
            "system": "Walls"
        },
        "GC Fee": {
          "location": "",
          "system": ""
        },
        "HVAC (Repairs / Replace)": {
            "location": "Whole House/Building",
          "system": "HVAC"
        },
        "Insulation": {
            "location": "Whole House/Building",
          "system": "Specialty"
        },
        "Interior Paint (Cabinets)": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Interior Paint (Walls, Trim)": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Landscaping": {
          "location": "Exterior",
          "system": "Landscaping/Earth work"
        },
        "Miscellaneous": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Plumbing Repairs": {
            "location": "Whole House/Building",
          "system": "Plumbing"
        },
        "Power Wash": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Remediate/Pest Pre-Treat": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Replace Cabinets - Bathrooms & Other": {
            "location": "Bathroom",
            "system": "Wood work"
        },
        "Replace Cabinets - Kitchen": {
            "location": "Kitchen",
          "system": "Wood work"
        },
        "Replace/Repair Siding, Soffit, Fascia, Brick": {
            "location": "Whole House/Building",
            "system": "Specialty"
        },
        "Roof (Repairs / Replace)": {
          "location": "Roof",
          "system": "Roofing"
        },
        "Sales Clean": {
            "location": "Whole House/Building",
            "system": "Miscellaneous"
        },
        "Water heater": {
            "location": "Whole House/Building",
            "system": "Plumbing"
        },
        "Windows (Repairs / Replace)": {
            "location": "Whole House/Building",
            "system": "Windows and Doors"
        }
      };
    const [contractorDropdown, setContractorDropdown] = useState([])

    //order inspection
    const [orderinspectionData,setOrderInspectionData] = useState({additionalemail : []});
    const [orderinspectionErrors,setOrderInspectionErrors] = useState({});

    //verify inspection
    const [verifyInspectionData,setVerifyInspectionData] = useState({});
    const [verifyInspectionErrors,setVerifyinspectionErrors] = useState({});

    //Draw Fee
    const [drawFeeData,setDrawFeeData] = useState({});
    const [drawFeeErrors,setDrawFeeErrors] = useState({});

    //Draw Payout
    const [drawPayoutData,setDrawPayoutData] = useState({ });
    const [drawPayoutErrors,setDrawPayoutErrors] = useState({});

    // approve and denial states
    const [approveData ,setApproveData] = useState({APPROVED : false});
    const [approveData2 ,setApproveData2] = useState({});
    const [denialData ,setDenialData] = useState({DENIED : false});
    const [popupErrors,setPopupErrors] = useState({});
    const [disablePopUp,setDisablePopUp] = useState(false);

    // apiStates 
    const [drawRequestid,setDrawRequestId] = useState('')


    const handleInitializer = ()=>{
        setCurrentPage(1);
        setPerPage(10);
        setTableKey(prevKey => prevKey + 1);setShowForm('Table');
        setSingleLineErrors({});setSupportingDocsErrors({});setOrderInspectionErrors({});setVerifyinspectionErrors({});setDrawFeeErrors({});
        setDrawPayoutErrors({});setScopeOfWorkErrors({})
        handleClearRows(false);setDisablePopUp(false);setPopupErrors({});setSelectedFiles([]);setScopeofWorkFiles([]);
         setVerifyinspectionFiles([]);setOrderInspectionFiles([]);
         setDisablePopUp(false);setDrawDisable(false);setDrawDisable(false);SetAllData(null);
         setAllInspectionData([]);
         setInspectionsData({});
          setInspectionErrors({});
        setinspectionId(null);
        setBudgetData({
            labour: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
            material: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
            others: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
            // credit: { original: '', current: '' },
            total: { percent: '', original: '', current: '' }
        });
         
    };

    // api calls 
    // all draws
    const getAllDrawsApi= () => { 
        dispatch(showLoader());
        getAllDraws(loanid).then(function (response) {
            console.log('response--->',response);
            dispatch(hideLoader());
        if (response?.result) {
            setDrawTableData(response?.result?.data);
            setTotalRows(response?.result?.totalCount);
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    //single draw
    const getSingleDrawApi= (drawrequestid) => { 
        dispatch(showLoader());
        getSingleDraw(loanid,drawrequestid).then(function (response) {
            console.log('response--->',response);
            dispatch(hideLoader());
        if (response?.result) {
            SetAllData(response?.result?.data?.[0]);

            setScopeOfWorkData(response?.result?.data?.[0]?.scopeofwork || {});

            setBudgetData(response?.result?.data?.[0]?.budget || {
                labour: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
                material: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
                others: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
                // credit: { original: '', current: '' },
                total: { percent: '', original: '', current: '' }
            });

            setAllInspectionData(response?.result?.data?.[0]?.inspection || []);

            setSupportingDocsData(response?.result?.data?.[0]?.supportingdocs || {});

            setOrderInspectionData( response?.result?.data?.[0]?.orderinspection || {additionalemail : []});
            setContent(response?.result?.data?.[0]?.orderinspection?.notes || '');

            setVerifyInspectionData(response?.result?.data?.[0]?.verifyinspection || {});

            setDrawPayoutData(response?.result?.data?.[0]?.drawpayout || {});
            setContent2(response?.result?.data?.[0]?.drawpayout?.paynotes || '');
            if(response?.result?.data?.[0]?.status === 'PAID'){
                setDrawDisable(true);
            }else{
                setDrawDisable(false);
            };
            setStatus(response?.result?.data?.[0]?.status);

            setApproveData(response?.result?.data?.[0]?.approval || {});
            setApproveData2(response?.result?.data?.[0]?.approval || {})
            setDenialData(response?.result?.data?.[0]?.denial || {});
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    // add draw
    const testAddDraw = ()=>{
        let apidata = 
            {
                "loanid": 300255,
                "requesteddate":"15-6-2024",
                "requestedamount":210000,
                status : "PENDING"
            };
        setMessage('Draw Added Successfully');
        addDrawApi(apidata);
    }
    const addDrawApi= (data) => { 
        dispatch(showLoader());
        addDrawRequest(loanid,data).then(function (response) {
            console.log('response--->',response);
            setDrawRequestId(response?.result);
            getSingleDrawApi(response?.result);
            dispatch(hideLoader());
            setPopupStatus(true);
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    // update draw
    const updateDrawRequestApi= (data) => { 
        dispatch(showLoader());
        updateDrawRequest(loanid,data).then(function (response) {
            console.log('response--->',response);
            dispatch(hideLoader());
        if (response?.result) {
            getSingleDrawApi(drawRequestid);
            setPopupStatus(true);
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    //delete draw 
    const deleteDraws= () => { 
        dispatch(showLoader());
        drawDeleteApi(loanid,deleteData?.drawrequestid).then(function (response) {
            console.log('response--->',response);
            getAllDrawsApi();
            setPopupStatus(true);
            // dispatch(hideLoader());
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    // contractor contact 
    const fetchContacts = () => {
        dispatch(showLoader());
        const type = 'Contractor';
        let pagenumber = '';
        let perPage = '';
        let search = '';
        let sortBy = '';
        let sortType = '';
        getallContacts(pagenumber, perPage, type, search, sortBy, sortType).then(function (response) {
            if (response?.result) {
                console.log(response?.result);
                setContractorDropdown(response?.result?.data)
            }else{
                setContractorDropdown([]);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("contacts error", err);
                dispatch(hideLoader());
            })
    };
    //order inspection email 
    const orderInspectionEmailApi= (data) => { 
        dispatch(showLoader());
        orderInspectionEmail(loanid,data).then(function (response) {
            dispatch(hideLoader());
            setPopupStatus(true);
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })
    };
    
    useEffect(() => {
        console.log('allInspectionData',allInspectionData);
      }, [allInspectionData]);
      
    useEffect(() => {
        if(loanid){
            handleInitializer();
            getAllDrawsApi();
            fetchContacts()
            // setDrawTableData([...drawVariable])
        }
      }, [loanid]);

      useEffect(() => {
        setLoanid(loanInfo?.loanid);
    }, [loanInfo?.loanid]);

    // scope of work add 
    const handleScopeofWorkChange = (e)=>{
        let {value , name} = e.target;
            setScopeOfWorkData((prev) => ({
                ...prev,
                [name]: value,
            }));
    };
    const handleScopeFocus=(e)=>{
        let name = e.target.name
        setScopeOfWorkErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const hanldeSaveScopeofwork = ()=>{
        const fieldsToCheck = {
            scopeofwork: 'scopeofwork', 
            // estcompletiondate: 'estcompletiondate', 
            completiondate: 'completiondate',contractor: 'contractor',
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = scopeofworkData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!scopeofworkData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setScopeOfWorkErrors(errors);
        if (Object.keys(errors).length === 0) {
            hanldeUploadDocument('scopeofwork');
            console.log('no errors',scopeofworkData);
        }
    };

    //budget functions 
    const handleBudgetChange = (category, field, value) => {
        const numericValue = (value);
        if (field === 'current') {
            const currentValues = {
                labour: parseFloat(budgetData?.labour?.current) || 0,
                material: parseFloat(budgetData?.material?.current) || 0,
                others: parseFloat(budgetData?.others?.current) || 0,
                // credit: budgetData?.credit?.current || 0
            };
            console.log('value',numericValue)
            const sumOtherValues = Object.keys(currentValues)
                .filter(key => key !== category)
                .reduce((sum, key) => sum + (parseFloat(currentValues[key]) || 0), 0);
            const newTotal = sumOtherValues + parseFloat(numericValue);
    
            if (newTotal <= (parseFloat(budgetData?.requestedamount) || 0)) {
                setBudgetData(prevBudget => ({
                    ...prevBudget,
                    [category]: {
                        ...prevBudget[category],
                        [field]: numericValue
                    }
                }));
            }
        }else if (field === 'percent') {
            const currentValues = {
                labour: parseFloat(budgetData?.labour?.percent) || 0,
                material: parseFloat(budgetData?.material?.percent) || 0,
                others: parseFloat(budgetData?.others?.percent) || 0,
            };
            const sumOtherValues = Object.keys(currentValues)
                .filter(key => key !== category)
                .reduce((sum, key) => sum + currentValues[key], 0);
            const newTotal = sumOtherValues + parseFloat(numericValue || 0);
            if (newTotal <= 100) {
                setBudgetData(prevBudget => ({
                    ...prevBudget,
                    [category]: {
                        ...prevBudget[category],
                        [field]: numericValue,
                    },
                }));
            }
        }
        
         else {
            setBudgetData(prevBudget => ({
                ...prevBudget,
                [category]: {
                    ...prevBudget[category],
                    [field]: numericValue
                }
            }));
        }
    };

    useEffect(() => {
        const labourPercent = parseFloat(budgetData?.labour?.percent) || 0;
        const materialPercent = parseFloat(budgetData?.material?.percent) || 0;
        const othersPercent = parseFloat(budgetData?.others?.percent) || 0;
        const totalPercent = labourPercent + materialPercent + othersPercent;
        if(budgetData){
            setBudgetData(prevBudget => ({
                ...prevBudget,
                total: {
                    ...prevBudget.total,
                    percent: parseFloat(totalPercent)
                }
            }));
        }
       
    }, [budgetData?.labour?.percent, budgetData?.material?.percent, budgetData?.others?.percent]);
    useEffect(() => {
        const labourOriginal = budgetData?.labour?.original || 0;
        const materialOriginal = budgetData?.material?.original || 0;
        const othersOriginal = budgetData?.others?.original || 0;
        // const creditOriginal = budgetData?.credit?.original || 0;
        const totalOriginal = parseFloat(labourOriginal) + parseFloat(materialOriginal) + parseFloat(othersOriginal);
        if(budgetData){
            setBudgetData(prevBudget => ({
                ...prevBudget,
                total: {
                    ...prevBudget.total,
                    original: parseFloat(totalOriginal)
                }
            }));
        }
       
    }, [budgetData?.labour?.original, budgetData?.material?.original, budgetData?.others?.original]);
    useEffect(() => {
        const labourCurrent = budgetData?.labour?.current || 0;
        const materialCurrent = budgetData?.material?.current || 0;
        const othersCurrent = budgetData?.others?.current || 0;
        // const creditCurrent = budgetData?.credit?.current || 0;
        const totalOriginal = parseFloat(labourCurrent) + parseFloat(materialCurrent) + parseFloat(othersCurrent) ;
        if(budgetData){
            setBudgetData(prevBudget => ({
                ...prevBudget,
                total: {
                    ...prevBudget.total,
                    current: parseFloat(totalOriginal)
                }
            }));
        }
       
    }, [budgetData?.labour?.current, budgetData?.material?.current, budgetData?.others?.current]);

    useEffect(() => {
        console.log("budgetData",budgetData)
    }, [budgetData]);
    const formatBudgetForAPI = (budget) => {
        const transformValues = (item) => {
            return Object.fromEntries(
                Object.entries(item).map(([key, value]) => [
                    key,
                    value !== "" ? parseFloat(value) : value, 
                ])
            );
        };
    
        const formattedBudget = {};
        for (const [key, value] of Object.entries(budget)) {
            if (typeof value === "object" && value !== null) {
                formattedBudget[key] = transformValues(value); // Transform nested objects
            } else if (value !== "" && !isNaN(value)) {
                formattedBudget[key] = parseFloat(value); // Convert top-level numbers to float
            } else {
                formattedBudget[key] = value; // Retain non-numeric or empty values
            }
        }
    
        return formattedBudget;
    };
    const hanldeBudgetSave = () => {
        const labourCurrent = budgetData?.labour?.current || 0;
        const materialCurrent = budgetData?.material?.current || 0;
        const othersCurrent = budgetData?.others?.current || 0;
        // const creditCurrent = budgetData?.credit?.current || 0;
        const totalCurrent = parseFloat(labourCurrent) + parseFloat(materialCurrent) + parseFloat(othersCurrent) ;
        if (totalCurrent > (parseFloat(budgetData?.requestedamount || 0))) {
            setMessage("Total current amounts exceed the requested amount");
            setPopupStatus(true);
            return; 
        }
        let apiData = {
            loanid: loanid,
            status: allData?.status ? allData?.status : 'PENDING',
            "drawrequestid": drawRequestid,
            budget : {
                ...(formatBudgetForAPI(budgetData))
            }
        };
        let msg = allData?.budget ? 'Budget Updated Successfully' : 'Budget Saved Successfully';
        setMessage(msg);
        if (!drawRequestid) {
            delete apiData['drawrequestid'];
            addDrawApi(apiData);
        } else {
            updateDrawRequestApi(apiData)
        }
    };


    // inspections functions
    const handleInspectionChange = (e) => {
        let { value, name, type, checked } = e.target;
        if (type === 'checkbox') {
            setInspectionsData((prev) => ({
                ...prev,
                [name]: checked,
            }));
        } else if (name === 'itemcomplete') {
            const newValue = parseFloat(value) || 0;
            const activeSum = allData?.inspection
            ?.filter(item => item?.status === 'ACTIVE')
            ?.reduce((sum, item) => sum + (item?.itemcomplete || 0), 0) || 0;

            const potentialSum = activeSum + newValue;
            console.log(potentialSum);
            if(newValue> 0){
                if (potentialSum <= 100) {
                    setInspectionsData((prev) => ({
                        ...prev,
                        [name]: newValue,
                    }));
                }
            }else{
                setInspectionsData((prev) => ({
                    ...prev,
                    [name]: '',
                }));
            }
        }else if(name === 'totalprojectcomplete'){
            const newValue = parseFloat(value) || 0;
            setInspectionsData((prev) => ({
                ...prev,
                [name]: newValue,
            }));
        }
        else {
            setInspectionsData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleInspectionFocus=(e)=>{
        let name = e.target.name
        setInspectionErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const hanldeInspectionSave = ()=>{
        const fieldsToCheck = {
            totalprojectcomplete: 'totalprojectcomplete', itemcomplete: 'itemcomplete', inspecteddate: 'inspecteddate',inspectedby: 'inspectedby',
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = inspectionsData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!inspectionsData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setInspectionErrors(errors);
        if (Object.keys(errors).length === 0) {
            var ObjectID = require("bson-objectid");
           var inspectionid = new ObjectID().toString(); 
            setMessage('Inspection Saved Successfully')
            let apiData = {
                loanid : loanid,
                status : allData?.status ? allData?.status : 'PENDING',
                "drawrequestid" : drawRequestid,
                inspection : [
                    ...(allData?.inspection || []), 
                    {...inspectionsData , inspectionid : inspectionid,status : 'ACTIVE'}
                    
                ]};
            if(!drawRequestid){
                delete apiData['drawrequestid'];
                // setAllInspectionData(apiData?.inspection);
                addDrawApi(apiData);
            }else{
                updateDrawRequestApi(apiData)
            }
            setInspectionsData({});
            document.getElementById('closeinspection').click();
        }
    };
    const inspectionDeleteClick = (e,id)=>{
        // e.preventDefault();
        console.log(id)
        setinspectionId(id);
        setShowConfirmation(true)
    };
    const deleteInspection = ()=>{
        setMessage('Deleted Successfully');
        const updatedInspections = allInspectionData.map(inspection => 
            inspection.inspectionid === inspectionId 
                ? { ...inspection, status: 'ARCHIVE' } 
                : inspection 
        );
        console.log('updatedInspections',updatedInspections)
        let apiData = {
            loanid : loanid,
            status : allData?.status ? allData?.status : 'PENDING',
            "drawrequestid" : drawRequestid,
            inspection : [
                ...updatedInspections
            ]};
        console.log(apiData,inspectionId);
        updateDrawRequestApi(apiData)
        setShowConfirmation(false);
    }
    
    // supporting docs functions
    const hanldeSupportingDocs = (e)=>{
        let {value , name} = e.target;
        setSupportingDocsData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const supportingDocsFocus=(e)=>{
        let name = e.target.name
        setSupportingDocsErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const handleSupportingDocsSave = ()=>{
        const fieldsToCheck = {
            type: 'type'
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = supportingDocsData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!supportingDocsData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setSupportingDocsErrors(errors);
        if (Object.keys(errors).length === 0) {
            hanldeUploadDocument('supportingdocs');
            // let apiData = {
            //     loanid : loanid,
            //     "drawrequestid" : drawRequestid,
            //     supportdocs : {
            //     ...supportingDocsData,
            // }};
            // console.log('supporting docs apidata---->',apiData)
            // updateDrawRequestApi(apiData)
            // uploadFilesData = [];
            // setMessage('Supporting Documents Saved Successfully');
        }
    };

    //scope of work functions
    const handleScopeofWork = (e)=>{
        let {value , name} = e.target;
        if(name === 'lineitemname'){
            console.log(fieldMapping[value]);
            setSingleLineItems((prev) => ({
                ...prev,
                [name]: value,
                location : fieldMapping?.[value]?.location || '',
                system : fieldMapping?.[value]?.system || '',
            }));
        }else{
            setSingleLineItems((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        
    };
    const handleFocus=(e)=>{
        let name = e.target.name
        setSingleLineErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    // const hanldeSaveScopeofwork = ()=>{
    //     const fieldsToCheck = {
    //         lineitemname: 'lineitemname', laborcost: 'laborcost', materialcost: 'materialcost',notes: 'notes',sku : 'sku',contractorlicense : 'contractorlicense',contractortrade:'contractortrade'
    //     };
    //     const errors = {};

    //     for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
    //         let value = singleLineItems[fieldName];
    //          if (typeof value === 'number') {
    //           }else{
    //             if (!singleLineItems[fieldName]?.trim()) {
    //                 errors[fieldName] = `Please Enter ${fieldLabel} `;
    //             }
    //           }
    //     };
    //     console.log("errors",errors)
    //     setSingleLineErrors(errors);
    //     if (Object.keys(errors).length === 0) {
    //         // setShowForm('Table')
    //         setMessage('Scope of Work Saved Successfully');
    //         setPopupStatus(true);
    //         setSingleLineItems({
    //             ...singleLineItems,})
    //     }
    // };

    //order inspection functions
    const handleOrderInspection = (e)=>{
        let {value , name} = e.target;
        setOrderInspectionData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const orderInspectionFocus=(e)=>{
        let name = e.target.name
        setOrderInspectionErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const hanldeSaveOrderInspection = ()=>{
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        const fieldsToCheck = {
            orderdate: 'orderdate', scheduleddate: 'scheduleddate', completeddate: 'completeddate',contacts: 'contacts',
            // ,additionalemail : "additionalemail", emailsubject : 'emailsubject',
            lockboxcode : 'lockboxcode'
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = orderinspectionData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!orderinspectionData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        // if(orderinspectionData?.additionalemail){
        //     if(!emailRegex.test(orderinspectionData?.additionalemail)){
        //         errors['additionalemail'] = 'Email Structure Error';
        //     }
        // }
        console.log("errors",errors)
        setOrderInspectionErrors(errors);
        if (Object.keys(errors).length === 0) {
            hanldeUploadDocument('orderinspection');
        }
    };

    //verify inspection functions
    const hanldeVerifyinspection = (e)=>{
        let {value , name , type} = e.target;
        if(type === 'checkbox'){
            setVerifyInspectionData((prev) => ({
                ...prev,
                [name]: e.target.checked,
            }));
        }else{
            setVerifyInspectionData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const verifyInspectionFocus=(e)=>{
        let name = e.target.name
        setVerifyinspectionErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const handleVerifyInspectionSave = ()=>{
        const fieldsToCheck = {
            verifieddate : 'verifieddate'
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = verifyInspectionData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!verifyInspectionData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setVerifyinspectionErrors(errors);
        if (Object.keys(errors).length === 0) {
            hanldeUploadDocument('verifyinspection');
        }
    };

    // draw fee functions
    const handleDrawFee = (e)=>{
        let {value , name} = e.target;
        setDrawFeeData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const drawFeeFocus=(e)=>{
        let name = e.target.name
        setDrawFeeErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const hanldCreateDrawFee = ()=>{
        const fieldsToCheck = {
            paymentmethod: 'paymentmethod', feeamount: 'feeamount'
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = drawFeeData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!drawFeeData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setDrawFeeErrors(errors);
        if (Object.keys(errors).length === 0) {
            setMessage('Draw Fee Created Successfully');
            // setShowForm('Table')
            setPopupStatus(true);
            setDrawFeeData({
                ...drawFeeData,
                // drawaccepted : true
            });
        }
    };

    //draw payout functions
    const hanldeDrawPayout = (e)=>{
        let {value , name} = e.target;
        setDrawPayoutData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const drawPayoutFocus=(e)=>{
        let name = e.target.name
        setDrawPayoutErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const hanldePayDrawPayout = ()=>{
        const fieldsToCheck = {
            // payoutmethod: 'payoutmethod',
            //  payoutamount: 'payoutamount',
             dateofdraw : 'dateofdraw',
            lastdayofdraw : 'lastdayofdraw', payoutdescription : 'payoutdescription', processeddate:'processeddate'
        };
        const errors = {};
        if(drawPayoutData?.payoutmethod === ''){
            errors['payoutmethod'] = 'please enter payout'
        }
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = drawPayoutData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!drawPayoutData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors)
        setDrawPayoutErrors(errors);
        if (Object.keys(errors).length === 0) {
        setMessage('Draw Paid Successfully');
        const paidDate = new Date().toISOString();
        let apiData = {
            loanid : loanid,
            status :'PAID',
            "drawrequestid" : drawRequestid,
            drawpaiddate : paidDate,
            drawpayout : {
            ...drawPayoutData,
            paynotes : content2,
            payoutmethod : drawPayoutData?.payoutmethod || 'Wire',
            payoutamount : approveData2?.amountapproved  - approveData2?.feeamount,
        }};
        updateDrawRequestApi(apiData)
        }
    };


    // table functions
    const handleback =()=>{
        setShowForm('Table');
        getAllDrawsApi();
        setSingleLineErrors({});setSupportingDocsErrors({});setOrderInspectionErrors({});setVerifyinspectionErrors({});setDrawFeeErrors({});
        setDrawPayoutErrors({});setPopupErrors({});
        setDisablePopUp(false);setDrawDisable(false);setDrawDisable(false);
    };
    const handleEdit =(data,index) =>{
    getSingleDrawApi(data?.drawrequestid);
    setDrawRequestId(data?.drawrequestid);
    setOrderRequestCount(data?.drawnumber);


    setSelectedFiles([]);setScopeofWorkFiles([]);
         setVerifyinspectionFiles([]);setOrderInspectionFiles([]);
        setFormDisable(false);
        setShowForm('Form');
    };
    const handleEditAdd =(data,index)=>{
        setShowForm('ViewForm');
        setScopeofWorkFiles([]);
        getSingleDrawApi(data?.drawrequestid);
       setDrawRequestId(data?.drawrequestid);
       setOrderRequestCount(data?.drawnumber);
    }
    const handleDeleteClick =(data)=>{
        setDeleteData(data);
        setDeleteConfirmation(true)
    };
    const handleDelete =()=>{
    setMessage('Deleted Successfully');
        setDeleteConfirmation(false);
        deleteDraws();
    };
    const closePopup2 =()=>{
        setDeleteConfirmation(false);
        setUnsavedConfirmation(false);
        setSavedConfirmation(false);
        setShowConfirmation(false);
      };
    const handleAdd = ()=>{
    // setOrderRequestCount(drawTableData?.length + 1);
    setBudgetData({
        labour: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        material: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        others: { quantity: '', unit: '', price: '', percent: '', original: '', current: '' },
        // credit: { original: '', current: '' },
        total: { percent: '', original: '', current: '' }
    });
        setFormDisable(false)
        setShowForm('ViewForm');
        setScopeOfWorkErrors({});
        setScopeofWorkFiles([]);
        setScopeOfWorkData({});
        setAllInspectionData([]);setInspectionsData({});setInspectionErrors({});
        setinspectionId(null);
        setSingleLineItems({});setSupportingDocsData({});setOrderInspectionData({additionalemail : []});setVerifyInspectionData({});
        setDrawFeeData({});setDrawPayoutData({});setApproveData({APPROVED : false}); setDenialData({DENIED : false});setApproveData2({})
        setSingleLineErrors({});setSupportingDocsErrors({});setOrderInspectionErrors({});setVerifyinspectionErrors({});setDrawFeeErrors({});
        setDrawPayoutErrors({});setDrawRequestId('');SetAllData(null);
    };
    const handleView =(data,index)=>{
        getSingleDrawApi(data?.drawrequestid);
        setOrderRequestCount(data?.drawnumber);
        setShowForm('Form');
        setFormDisable(true);
        setDrawDisable(true);
        setDisablePopUp(true);
    };
    

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        console.log('Selected Rows: ', selectedRows);
      };
      const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
      };
      const closePopup =()=>{
        setPopupStatus(false);
      };

    // approve and denial functions
    const hanldePopupEnable = ()=>{
        setDisablePopUp(false)
    }
    const hanldePopupDisabled = ()=>{
        setDisablePopUp(true)
    }
    const handleApproveChange = (e)=>{
        let {value , name} = e.target;
        setApproveData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleDenialChange = (e)=>{
        let {value , name} = e.target;
        setDenialData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const popUpFocus= (e) =>{
        let name = e.target.name
        setPopupErrors(prev => ({
            ...prev,
            [name]: null,
        }));
    };
    const approveAmount = ()=>{
        const fieldsToCheck = {
            amountapproved: 'amountapproved',feeamount : 'feeamount',
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = approveData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!approveData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        if(approveData?.amountapproved && approveData?.feeamount){
            if(parseFloat(approveData?.amountapproved) < parseFloat(approveData?.feeamount)){
                errors['amountapproved'] = `please enter amountapproved`;
                errors['feeamount'] = `please enter feeamount`;
            }
        }
        console.log("errors",errors);
        setPopupErrors(errors);
        if (Object.keys(errors).length === 0) {
            let apiData = approveData;
            const numericInputs = ['amountapproved', 'feeamount'];
                numericInputs.forEach(key => {
                    if (apiData.hasOwnProperty(key)) {
                        apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                    }
                });
        setMessage('Amount Approved Successfully');
            
            let dataToaApi = {
                loanid : loanid,
                status : 'APPROVED',
                "drawrequestid" : drawRequestid,
                approval : {
                ...apiData,
                paymentmethod : approveData?.paymentmethod || 'Wire',
                APPROVED : true,
            } 
            };
            updateDrawRequestApi(dataToaApi)
            document.getElementById('closeapprove').click();
        }
    };
    const denailRequest = ()=>{
        const fieldsToCheck = {
            reasonfordenial: 'reasonfordenial'
        };
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = denialData[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!denialData[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
        };
        console.log("errors",errors);
        setPopupErrors(errors);
        if (Object.keys(errors).length === 0) {
        // setPopupStatus(true);
           setMessage('Draw Denied Successfully');
           
            let dataToaApi = {
                loanid : loanid,
                status: "DENIED",
                "drawrequestid" : drawRequestid,
                denial : {
                ...denialData,
                DENIED : true,
            }};
            updateDrawRequestApi(dataToaApi)
            document.getElementById('closedenial').click();
        }
    };
  
    // upload functions 
    const handleFileChange = (event,text) => {
        const files = event.target.files;
        const MAX_FILE_SIZE = 50 * 1024 * 1024;
        if(files?.[0]?.size > MAX_FILE_SIZE){
            setMessage('File Size Cannot Exceed 50MB')
            setPopupStatus(true);
            console.log('max size')
            return
        }else{
            console.log(files?.size,MAX_FILE_SIZE,'size in limit')
        }
        if(text === 'supportingdocs'){
            setSelectedFiles([...selectedFiles, ...files]);
        }else if(text === 'scopeofwork'){
            setScopeofWorkFiles([...scopeofworkFiles, ...files])
        }else if(text === 'orderinspection'){
            setOrderInspectionFiles([...orderinspectionFiles, ...files])
        }
        else if(text === 'verifyinspection'){
            setVerifyinspectionFiles([...verifyinspectionFiles, ...files])
        }
        console.log('files',files);
      };
      const handleUnsavedFileDelete = () => {
        if (unsavedDeleteData?.text === 'supportingdocs') {
          setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== unsavedDeleteData?.index));
        } else if (unsavedDeleteData?.text === 'scopeofwork') {
          setScopeofWorkFiles((prevFiles) => prevFiles.filter((_, index) => index !== unsavedDeleteData?.index));
        } else if (unsavedDeleteData?.text === 'orderinspection') {
          setOrderInspectionFiles((prevFiles) => prevFiles.filter((_, index) => index !== unsavedDeleteData?.index));
        }
        else if (unsavedDeleteData?.text === 'verifyinspection') {
            setVerifyinspectionFiles((prevFiles) => prevFiles.filter((_, index) => index !== unsavedDeleteData?.index));
          }
        setUnsavedDeleteData({});
        setUnsavedConfirmation(false);
        setMessage('Deleted Successfully');
        setPopupStatus(true)
      };
    const handleUnsavedClick =(index,text)=>{
        setUnsavedDeleteData({
            "text" : text,
            "index" : index
        })
        setUnsavedConfirmation(true);
    };
    const handleSavedDeleteClick = (index,text)=>{
        setSavedDeleteData({
            "text" : text,
            "index" : index
        })
        setSavedConfirmation(true);
        console.log('saved clicl')
    };
    const savedFileDelete = ()=>{
        if (savedDeleteData?.text === 'supportingdocs') {
            let savedFileArray = supportingDocsData?.documents;
            let updatedDocs = savedFileArray.filter((_, index) => index !== savedDeleteData?.index);
            setSupportingDocsData({
                ...supportingDocsData,
                documents : [
                    ...updatedDocs
                ]
            })
          } else if (savedDeleteData?.text === 'scopeofwork') {
            // scope of work remaining
            let savedFileArray = scopeofworkData?.documents;
            let updatedDocs = savedFileArray.filter((_, index) => index !== savedDeleteData?.index);
            setScopeOfWorkData({
                ...scopeofworkData,
                documents : [
                    ...updatedDocs
                ]
            })
          }  else if (savedDeleteData?.text === 'orderinspection') {
            let savedFileArray = orderinspectionData?.documents;
            let updatedDocs = savedFileArray.filter((_, index) => index !== savedDeleteData?.index);
            setOrderInspectionData({
                ...orderinspectionData,
                documents : [
                    ...updatedDocs
                ]
            })
          }
          else if (savedDeleteData?.text === 'verifyinspection') {
            let savedFileArray = verifyInspectionData?.documents;
            let updatedDocs = savedFileArray.filter((_, index) => index !== savedDeleteData?.index);
            setVerifyInspectionData({
                ...verifyInspectionData,
                documents : [
                    ...updatedDocs
                ]
            })
          }
          setSavedConfirmation(false);
          setMessage('Deleted Successfully');
          setPopupStatus(true)
    }
 

    const hanldeUploadDocument = async(tabName) => {
        dispatch(showLoader());
        let Files = [];
            try {
        if(tabName === 'supportingdocs'){
            Files = selectedFiles;
            await Promise.all(Files.map((file, index) => handleUpload(file, index)));
            let apiData = {
                loanid : loanid,
                status : allData?.status === 'PENDING' ? 'INPROGRESS' : allData?.status,
                "drawrequestid" : drawRequestid,
                supportingdocs : {
                ...supportingDocsData,
                documents : [
                    ...(supportingDocsData?.documents ? supportingDocsData?.documents : []),
                    ...uploadFilesData
                ],
            }};
            console.log('supportingdocs apidata---->',apiData)
            updateDrawRequestApi(apiData)
            uploadFilesData = [];
            
            let msg = allData?.supportingdocs ? 'Supporting Docs Updated Successfully' : 'Supporting Docs Saved Successfully';
            console.log(msg)
            setMessage(msg);
            setSelectedFiles([]);
        }else if(tabName === 'scopeofwork'){
            // scope of work remaining
            Files = scopeofworkFiles;
            await Promise.all(Files.map((file, index) => handleUpload(file, index)));
            let apiData = {
                loanid : loanid,
                status : allData?.status ? allData?.status : 'PENDING',
                "drawrequestid" : drawRequestid,
                scopeofwork : {
                ...scopeofworkData,
                documents : [
                    ...(scopeofworkData?.documents ? scopeofworkData?.documents : []),
                    ...uploadFilesData
                ],
            }};
            let msg = allData?.scopeofwork ? 'Scope of Work Updated Successfully' : 'Scope of Work Saved Successfully';
            console.log(msg)
            setMessage(msg);
            if(!drawRequestid){
                delete apiData['drawrequestid'];
                addDrawApi(apiData);
            }else{
                updateDrawRequestApi(apiData)
            }
            uploadFilesData = [];
           
            setScopeofWorkFiles([]);
        }else if(tabName === 'orderinspection'){
            Files = orderinspectionFiles;
            await Promise.all(Files.map((file, index) => handleUpload(file, index)));
            let apiData = {
                loanid : loanid,
                status : allData?.status === 'PENDING' ? 'INPROGRESS' : allData?.status,
                "drawrequestid" : drawRequestid,
                orderinspection : {
                ...orderinspectionData,
                notes : content,
                documents : [
                    ...(orderinspectionData?.documents ? orderinspectionData?.documents : []),
                    ...uploadFilesData,
                ]
            }};
            console.log('orderinspection  apidata---->',apiData)
            let msg = allData?.orderinspection ? 'Order Inspection Updated Successfully' : 'Order Inspection Saved Successfully'
            setMessage(msg);
            updateDrawRequestApi(apiData);
            uploadFilesData = [];
            
            setOrderInspectionFiles([]);
        }else if(tabName === 'verifyinspection'){
            Files = verifyinspectionFiles
            await Promise.all(Files.map((file, index) => handleUpload(file, index)));
            let apiData = {
                loanid : loanid,
                status : allData?.status === 'PENDING' ? 'INPROGRESS' : allData?.status,
                "drawrequestid" : drawRequestid,
                verifyinspection : {
                ...verifyInspectionData,
                documents : [
                    ...(verifyInspectionData?.documents ? verifyInspectionData?.documents : []),
                    ...uploadFilesData,
                ]
            }};
            console.log('orderinspection  apidata---->',apiData)
            let msg = allData?.verifyinspection ? 'Verify Inspection Updated Successfully' : 'Verify Inspection Saved Successfully'
            setMessage(msg);
            updateDrawRequestApi(apiData);
            uploadFilesData = [];
            setVerifyinspectionFiles([]);
        }
                // dispatch(showLoader());
            //   await Promise.all(selectedFiles.map((file, index) => handleUpload(file, index)));
              
            //   hanldeSave();
            //   handleSupportingDocsSave();
              console.log('Form data submitted:');
              // You can add your form submission logic here
          } catch (error) {
              console.error('Error uploading files:', error);
          }
        
      };
    const handleUpload = async (file, index) => {
        try {
    
            var bucket;
            const timestamp = Date.now();
            let format = file?.name?.split(".");
            var fileformat = format[format.length - 1];
            bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
            var ObjectID = require("bson-objectid");
            var filecode = new ObjectID().toString(); 
            let uploadFilePath = Appname + "/drawsrequest/" +loanid +"/"+drawRequestid + "/" + filecode + "/" + timestamp + "." + fileformat;
            var data = { source_bucket: bucket, sourcepath: uploadFilePath };
            const fileData = {
                filename : file?.name,
                filepath: uploadFilePath,
                documentid: filecode
            };
            // uploadFilesData[index]['filepath'] = uploadFilePath;
            // uploadFilesData[index]['documentid'] = filecode;
            uploadFilesData.push(fileData);
            const response = await axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' });
            if (response?.data && response?.data?.result) {
            //   uploadFilesData[index]['file'] = uploadFilePath;
            //   uploadFilesData[index]['documentid'] = filecode;
                var url = response?.data?.result;
                await axios.put(url, file, {
                    "headers": {
                        "Content-Type": "multipart/form-data",
                        "Accept": "/",
                        "Cache-Control": "no-cache",
                        "Accept-Encoding": "gzip, deflate",
                        "Connection": "keep-alive",
                        "cache-control": "no-cache"
                    }
                });
                // const updatedFiles = [...selectedFiles];
                // updatedFiles[index]['path'] = uploadFilePath;
                // console.log("updatedFiles",updatedFiles,filecode)
                // setFormData({
                //     ...formData,
                //     selectedFiles: updatedFiles
                // });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
     };
     const handleViewOpen = (e, file) => {
        console.log(file)
        const fileType = file?.filepath?.split('.').pop();
        const videoFormats = ['mp4', 'webm', 'ogg', 'ogv', 'mkv', 'mov', 'avi'];
        let source;
        source = configdata?.common?.resourcesCloudfront  + file?.filepath;
        setFileDocName(file?.filename)
        setPlayContent(source);
        
        setType(fileType);
    if (videoFormats.includes(fileType)) {
        setShowPlayer(true); 
    } else {
        setshowDoc(true); 
    }
    };
    const handleEmailChange = (e) => {
        setEmailInput(e.target.value);
        setEmailError(null);
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        let emailtest = emailRegex.test(email);
        return emailtest;
    };

    const removeEmail = (emailToRemove) => {
        setOrderInspectionData((prevData) => ({
            ...prevData,
            additionalemail: prevData.additionalemail.filter((email) => email !== emailToRemove),
        }));
    };

    const handleEmailKeyDown = (e) => {
        if (["Enter", ",", " "].includes(e.key)) {
            e.preventDefault();
            const email = emailInput.trim();
            if (email && isValidEmail(email)) {
                if (!orderinspectionData?.additionalemail.includes(email)) {
                    setOrderInspectionData((prevData) => ({
                        ...prevData,
                        additionalemail: [...prevData.additionalemail, email], // Add new email to the array
                    }));
                }
                setEmailInput(""); // Clear input
            } else {
                setEmailError("Invalid Email!");
            }
        }
    };
    const handleEmailSend = () => {
        // Validate the 'toemail' array - check if all emails are valid
        const invalidEmails = orderinspectionData?.additionalemail?.filter(email => !isValidEmail(email));
        
        if (orderinspectionData?.additionalemail && invalidEmails?.length !== 0) {
            setEmailError(true);
        } else if ((orderinspectionData?.additionalemail?.length > 0 && invalidEmails?.length === 0) ) {
           //api call
           let maildata = {
            loanid : loanid,
            toemailid : orderinspectionData?.additionalemail,
            subject : orderinspectionData?.emailsubject || 'Order Inspection',
            template : content,
           };
           setMessage('Email Sent Successfully');
           orderInspectionEmailApi(maildata);

           let apiData = {
            loanid : loanid,
            status : allData?.status === 'PENDING' ? 'INPROGRESS' : allData?.status,
            "drawrequestid" : drawRequestid,
            orderinspection : {
            ...allData?.orderinspection,
            notes : content,
            additionalemail : orderinspectionData?.additionalemail,
            emailsubject : orderinspectionData?.emailsubject,
        }};
        const updateDrawRequestApi= (data) => { 
            dispatch(showLoader());
            updateDrawRequest(loanid,data).then(function (response) {
                console.log('response--->',response);
                dispatch(hideLoader());
            if (response?.result) {
                getSingleDrawApi(drawRequestid);
            }
            }).catch((err) => {
              console.error.bind("error", err);
              dispatch(hideLoader());
            })
        };
        updateDrawRequestApi(apiData);
        } 
    };

    const ReusableSavedDoc = ({files,deleteType}) => {
        return (
            <>
                {
                    files?.documents ?
                    files?.documents?.map((file, index) => {
                        const filePath = file.filepath || "";
                        const isImage = filePath.match(/\.(jpeg|jpg|gif|png|bmp)$/i);
                        const isPDF = filePath.match(/\.pdf$/i);
                        const isDoc = filePath.match(/\.(doc|docx)$/i);
                        const isVideo = filePath.match(/\.(mp4|mov|avi|wmv)$/i);
                        const isExcel = filePath.match(/\.(xls|xlsx)$/i);
                        
                        let iconSrc = "assets/images/icons/document.png";
                        if (isImage) iconSrc = configdata?.common?.resourcesCloudfront + file.filepath;
                        else if (isPDF) iconSrc = "assets/images/icons/png.png";
                        else if (isDoc) iconSrc = "assets/images/icons/word.png";
                        else if (isVideo) iconSrc = "assets/images/icons/videoclip.png";
                        else if (isExcel) iconSrc = "assets/images/icons/xls.png";

                            return (
                                <div className="item" key={index}>
                                    <div className="doc">
                                        <img
                                           src={iconSrc}
                                            alt={`Uploaded document ${index}`}
                                            className="img"
                                        />
                                        <div className="action_buttons">
                                       <span id={`view-btn-saved-${index}`} className="material-icons" style={{ cursor: "pointer" }}
                                            onClick={(e) => handleViewOpen(e, file)}> visibility </span>
                                       <span  style={{ cursor: "pointer" }} className="material-icons">
                                            <a id={`download-btn-saved-${deleteType}-${index}`} href={configdata?.common?.resourcesCloudfront + file.filepath} download={file?.filename} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            file_download
                                            </a>
                                        </span>
                                        <span
                                         id={`delete-btn-saved-${deleteType}-${index}`}
                                            style={{ cursor: "pointer" }}
                                            className="material-icons"
                                            onClick={() => handleSavedDeleteClick(index,deleteType)}
                                        >
                                            delete
                                        </span>
                                        </div>
                                    </div>
                                    <div className="date">
                                        <p>{file?.filename}</p>
                                    </div>
                                </div>
                            );
                        }) : ''
                }
            </>
        )
    };
    const ReusableUnSavedDoc = ({files,deleteType}) => {
        return (
            <>
                {files.map((file, index) => {
                    const fileUrl = URL.createObjectURL(file);
                    const fileName = file.name || "";
                    const isImage = fileName.match(/\.(jpeg|jpg|gif|png|bmp)$/i);
                    const isPDF = fileName.match(/\.pdf$/i);
                    const isDoc = fileName.match(/\.(doc|docx)$/i);
                    const isVideo = fileName.match(/\.(mp4|mov|avi|wmv)$/i);
                    const isExcel = fileName.match(/\.(xls|xlsx)$/i);
    
                    let iconSrc = "assets/images/icons/document.png";
                    if (isImage) iconSrc = fileUrl;
                    else if (isPDF) iconSrc = "assets/images/icons/png.png";
                    else if (isDoc) iconSrc = "assets/images/icons/word.png";
                    else if (isVideo) iconSrc = "assets/images/icons/videoclip.png";
                    else if (isExcel) iconSrc = "assets/images/icons/xls.png";

                    return (
                        <div className="item" key={index}>
                            <div className="doc">
                                <img
                                     src={iconSrc}
                                    className="img"
                                    alt={`Uploaded document ${index}`}
                                />
                                <div className="action_buttons">
                                       <span  style={{ cursor: "pointer" }} className="material-icons">
                                    <a id={`download-btn-unsaved-${deleteType}-${index}`} href={fileUrl} download={file?.name} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    file_download
                                    </a>
                                </span>
                                <span
                                id={`delete-btn-unsaved-${deleteType}-${index}`} 
                                    style={{ cursor: "pointer" }}
                                    className="material-icons"
                                    onClick={() => handleUnsavedClick(index, deleteType)}
                                >
                                    delete
                                </span>
                                        </div>
                            </div>
                            <div className="date">
                                <p>{file?.name}</p> 
                            </div>
                        </div>
                    );
                })}
            </>
        )
    }

    return (
          <div id="layout-wrapper" className="dashboard">
            {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName} />}
            {
                showPlayer &&
<VideoViewer source={playContent} type={type} close={setShowPlayer} name={fileDocName}/>
            }
           
        {loading ? <Loader/> : null} 
        {popUpStatus && !loading &&(
        <PopupMessage
         type={(message === 'Total current amounts exceed the requested amount' || message === 'File Size Cannot Exceed 50MB') ? 'warning' : 'success' }
         message={message}
         onClose={closePopup}
        />
    )}
    {savedConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={savedFileDelete}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-file-yes',
                        className: 'btn btn-primary',
                        onClick: savedFileDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-file-no',
                        className: 'btn btn-light',
                        onClick: closePopup2,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this File</p>
            </SweetAlert>}
            {unsavedConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleUnsavedFileDelete}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-file-unsaved-yes',
                        className: 'btn btn-primary',
                        onClick: handleUnsavedFileDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-file-unsaved-no',
                        className: 'btn btn-light',
                        onClick: closePopup2,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this File</p>
            </SweetAlert>}
    {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-draw-yes',
                        className: 'btn btn-primary',
                        onClick: handleDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-draw-no',
                        className: 'btn btn-light',
                        onClick: closePopup2,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this Draw</p>
            </SweetAlert>}
            {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={deleteInspection}
                onCancel={closePopup2}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-inspection-yes',
                        className: 'btn btn-primary',
                        onClick: deleteInspection,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-inspection-no',
                        className: 'btn btn-light',
                        onClick: closePopup2,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this inspection</p>
            </SweetAlert>}
        <PipelineHeader />
        <div className="dashboard_block odr">
            <PipelineStatus />
            <h2 className="form_title"> <span>Draws <span className="space">&#10095;</span> <span className="sub">Order Draw Request</span></span> 
            {showForm !== 'Table' && 
            <a href="#" id='back-btn' onClick={(e) => {e.preventDefault();handleback();}} className="back_btn"><span className="material-icons icon"> chevron_left
            </span>BACK</a>
            }
            </h2>
          
            <div className="card user_management detailed_app draw_request single_lineitem">
                <div className="card-body my_account">
                    {/* List Start  */}
                    {
                        showForm === 'Table' && 
                        <>
                         <div className="search">
                        <div className="d-flex align-items-center">
                            <h4 className="mb-sm-0 font-size-18 me-4">Order draw REQUEST</h4>
                            <button id='add-draw-btn' type="button" onClick={handleAdd} className="fillbtn_new add_button">add<span
                                className="material-symbols-outlined icon">add</span></button>
                        </div>
                        {/* <label className="toggle mb-0">
                            <span className="toggle-label">Lock Borrower SOW Changes</span>
                            <input className="toggle-checkbox" type="checkbox" />
                            <div className="toggle-switch"></div>
                        </label> */}
                    </div>
                    <div className="table-responsive">
                    {drawTableData?.length > 0 ?
                            <div className="table-responsive">
                            <DataTable 
                                key={tableKey}
                                columns={columns}
                                data={drawTableData}
                                pagination
                                customStyles={customStyles}
                                // paginationTotalRows={totalRows}
                                // paginationServer
                                paginationPerPage={10}
                                paginationDefaultPage={1}
                                selectableRows
                               clearSelectedRows={toggledClearRows}
                               onSelectedRowsChange={handleChange}
                                // onChangeRowsPerPage={handlePerRowsChange}
                                // onChangePage={handlePageChange}
                                // onSort={handleSort}
                                sortServer={true}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                            />
                        </div> : 
<div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>
                            }
                    </div>
                        </>
                    }
                    
                  {/* List End  */}

               {/* Edit Start  */}
               {showForm ==='Form' &&
               <> 
         
         <div className='odr_settings'>
                            <div className='dr'>
                                <h4>Draw Request</h4>
                                <div className='num'>{orderRequestCount}</div>
                            </div>
                            
                             <div className='btns'>
                             {
                            //  (!denialData?.DENIED && !drawPayoutData.drawaccepted)
                             (status !== 'DENIED' && status !== 'PAID')&& 
                              <button type="button" onClick={hanldePopupEnable} id='denydraw' className="border_btn icon" data-bs-target="#denialModal" data-bs-whatever="@mdo" data-bs-toggle="modal"><span className="material-symbols-outlined icon">block</span> Deny</button>
                             }
                            {(status !== 'APPROVED' && status !== 'PAID') && 
                            <button type="button" onClick={hanldePopupEnable}
                            //   disabled={!verifyInspectionData?.drawaccepted} 
                              className={`solid_btn icon`} data-bs-target="#staticBackdrop" id='acceptdraw' data-bs-whatever="@mdo" data-bs-toggle="modal"><span className="material-symbols-outlined icon">check</span> APPROVE</button>
                            }
                             
                             </div>
                            
                           
                            {/* disabled */}
                            {( status ==='APPROVED' ||  status === 'PAID') && 
                            <div className="total_estimate approved_value">
                            <p> APPROVED<span> {approveData?.amountapproved ? formatCurrency(approveData2?.amountapproved) : '$0.00'}</span></p>
                            {status !== 'PAID' &&  <span data-bs-target="#staticBackdrop" data-bs-whatever="@mdo" data-bs-toggle="modal" id="approveedit" className="material-symbols-outlined">edit_square</span>}
                            
                          
                            </div>
                            // <p  data-bs-target="#staticBackdrop" data-bs-whatever="@mdo" data-bs-toggle="modal" className="APPROVED"
                            // //  onClick={hanldePopupDisabled}
                            //  >APPROVED<span>$ {approveData?.amountapproved ? formatCurrency(approveData?.amountapproved) : '$0.00'}</span></p>
                            }
                            {( status === 'DENIED') && 
                             <p data-bs-target="#denialModal" id='afterdenied' data-bs-whatever="@mdo" data-bs-toggle="modal" className="approved denied ms-2" onClick={hanldePopupDisabled}>DENIED</p>
                            }
                           
                        </div>
               <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        {/* <li className="nav-item" role="presentation">
                            <a className="nav-link active" data-bs-toggle="tab" href="#SUPPORTDOCUMENTS" role="tab"
                                aria-selected="true">
                                <span className=" material-symbols-outlined icon"> note_stack </span>
                                <span className="d-none d-sm-block">SUPPORT DOCUMENTS</span>
                            </a>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#SCOPEOFWORK" role="tab"
                                aria-selected="false" tabIndex="-1">
                                <span className=" material-symbols-outlined icon"> plumbing </span>
                                <span className="d-none d-sm-block">SCOPE OF WORK</span>
                            </a>
                        </li> */}
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" data-bs-toggle="tab" href="#ORDERINSPECTION" role="tab"
                                aria-selected="true">
                                <span className=" material-symbols-outlined icon"> frame_inspect </span>
                                <span className="d-none d-sm-block">ORDER INSPECTION</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#VERIFYINSPECTION" role="tab"
                                aria-selected="false" tabIndex="-1">
                                <span className=" material-symbols-outlined icon"> beenhere </span>
                                <span className="d-none d-sm-block">VERIFY INSPECTION</span>
                            </a>
                        </li>
                        {(status === 'APPROVED' || status ==='PAID')&& 
                        <>
                        {/* <li className="nav-item" role="presentation">
                         <a className="nav-link" data-bs-toggle="tab" href="#DRAWFEE" role="tab"
                             aria-selected="false" tabIndex="-1">
                             <span className=" material-symbols-outlined icon"> calculate </span>
                             <span className="d-none d-sm-block">DRAW FEE</span>
                         </a>
                     </li> */}
                     <li className="nav-item" role="presentation">
                         <a className="nav-link" data-bs-toggle="tab" href="#DRAWPAYOUT" role="tab"
                             aria-selected="false" tabIndex="-1">
                             <span className=" material-symbols-outlined icon"> account_balance_wallet </span>
                             <span className="d-none d-sm-block">DRAW PAYOUT</span>
                         </a>
                     </li>
                        </>
                        }
                    </ul>
                    <div className="tab-content pt-15 text-muted">
                        {/* <div className="tab-pane active" id="SUPPORTDOCUMENTS" role="tabpanel">


                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">SUPPORT DOCUMENTS</h4>

                            </div>
                            <div className="form_section">
                                <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label>Select from the List</label>
                                                <select name='type' value={supportingDocsData?.type ||''} onChange={hanldeSupportingDocs} onFocus={supportingDocsFocus}
                                                 className={`form-control ${supportingDocsErrors?.type && 'invalid-input'}`}
                                                 disabled={formDisable}>
                                                    <option value=''>Select</option>
                                                    <option value='Escrow Letter Funds' selected>Escrow Letter Funds</option>
                                                    <option value='Quality Control Release' selected>Quality Control Release</option>
                                                    <option value='Ace Request' selected>Ace Request</option>
                                                    <option value='Loan Closing Request and Fee Sheet' selected>Loan Closing Request and Fee Sheet</option>
                                                    <option value='Request to open escrow' selected>Request to open escrow</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Additional Comments (Optional)</label>
                                                <textarea name='comments' onChange={hanldeSupportingDocs} value={supportingDocsData?.comments ||''} className="form-control null" rows="4" cols="50" disabled={formDisable}></textarea>
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='form-group'>
                                                <label>Upload Documents</label>
                                                <div className="create_process">
                                                    <div className="documents">
                                                        <ReusableSavedDoc files={supportingDocsData} deleteType={'supportingdocs'} />
                                                        <ReusableUnSavedDoc files={selectedFiles} deleteType={'supportingdocs'} />
                                                        

                                                        <div className="upload_button" ><input onChange={(e)=>{handleFileChange(e,'supportingdocs')}}  type="file" name="upload" className="udisplay-none" id="upload" />
                                                        <img  src="assets/images/icons/add_image.png" /></div></div></div>
                                            </div></div>
                                        <div className='col-md-12 mt_20'>
                                        <div className="form-group"><button 
                                        disabled={formDisable}
                                        //  onClick={hanldeUploadDocument}
                                        onClick={handleSupportingDocsSave} 
                                         className="add_button fillbtn_new d-inline-block">{allData?.supportingdocs ? "Update" : "Save"}</button></div>
                                        </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="tab-pane bulk_item" id="SCOPEOFWORK" role="tabpanel">
                            {/* {!supportingDocsData?.drawaccepted ? 
                            <Blockingtextmsg  textmessage={'Scope of Work Cannot be Accessed until Supporting Docs Tab Gets Finished'}/> 
                            : */}
                            <div className="form_section">
                            <div className="row">
                               <div className="col-md-3">
                                   <div className="form-group">
                                       <label>Line Item Name</label>
                                           <select id="lineitemname" value={singleLineItems?.lineitemname} name="lineitemname" onChange={handleScopeofWork} onFocus={handleFocus} disabled={formDisable}  className={`form-control ${singleLineErrors.lineitemname && 'invalid-input'}`} >
                                               <option value="">Select</option>
                                               {/* <option value="Add New">Add New</option> */}
                                               <option value="Appliances">Appliances</option>
                                               <option value="Bathroom - Tub/Shower Faucets / Trim Kits">Bathroom - Tub/Shower Faucets / Trim Kits</option>
                                               <option value="Bathroom - Tubs &amp; Showers &amp; Tile Surrounds">Bathroom - Tubs &amp; Showers &amp; Tile Surrounds</option>
                                               <option value="Carpentry - Baseboards &amp; Trim">Carpentry - Baseboards &amp; Trim</option>
                                               <option value="Countertops - Granite">Countertops - Granite</option>
                                               <option value="Countertops - Other">Countertops - Other</option>
                                               <option value="Concrete / Flatwork">Countertops Concrete / Flatwork</option>
                                               <option value="Demo / Trash Clean Out">Demo / Trash Clean Out</option>
                                               <option value="Doors (Actual Doors - Exterior)">Doors (Actual Doors - Exterior)</option>
                                               <option value="Doors (Actual Doors - Interior)">Doors (Actual Doors - Interior)</option>
                                               <option value="Drywall Repairs">Drywall Repairs</option>
                                               <option value="Electrical Repairs">Electrical Repairs</option>
                                               <option value="Exterior Paint">Exterior Paint</option>
                                               <option value="Fixtures - Ceiling Fans">Fixtures - Ceiling Fans</option>
                                               <option value="Fixtures - Door Knobs">Fixtures - Door Knobs</option>
                                               <option value="Fixtures - Kitchen, Bath Faucets">Fixtures - Kitchen, Bath Faucets</option>
                                               <option value="Fixtures - Other Lighting">Fixtures - Other Lighting</option>
                                               <option value="Flooring - Carpet">Flooring - Carpet</option>
                                               <option value="Flooring - Tile &amp; Hardwood">Flooring - Tile &amp; Hardwood</option>
                                               <option value="Flooring - Vinyl">Flooring - Vinyl</option>
                                               <option value="Foundation Repair">Foundation Repair</option>
                                               <option value="Framing / Wall Movement">Framing / Wall Movement</option>
                                               <option value="GC Fee">GC Fee</option>
                                               <option value="HVAC (Repairs / Replace)">HVAC (Repairs / Replace)</option>
                                               <option value="Insulation">Insulation</option>
                                               <option value="Interior Paint (Cabinets)">Interior Paint (Cabinets)</option>
                                               <option value="Interior Paint (Walls, Trim)">Interior Paint (Walls, Trim)</option>
                                               <option value="Landscaping">Landscaping</option>
                                               <option value="Miscellaneous">Miscellaneous</option>
                                               <option value="Plumbing Repairs">Plumbing Repairs</option>
                                               <option value="Power Wash">Power Wash</option>
                                               <option value="Remediate/Pest Pre-Treat">Remediate/Pest Pre-Treat</option>
                                               <option value="Replace Cabinets - Bathrooms &amp; Other">Replace Cabinets - Bathrooms &amp; Other</option>
                                               <option value="Replace Cabinets - Kitchen">Replace Cabinets - Kitchen</option>
                                               <option value="Replace/Repair Siding, Soffit, Fascia, Brick">Replace/Repair Siding, Soffit, Fascia, Brick</option>
                                               <option value="Roof (Repairs / Replace)">Roof (Repairs / Replace)</option>
                                               <option value="Sales Clean">Sales Clean</option>
                                               <option value="Water heater">Water heater</option>
                                               <option value="Windows (Repairs / Replace)">Windows (Repairs / Replace)</option>
                                           </select>
                                   </div>
                               </div>
                               <div className="col-md-3">
                               <div className="form-group">
                                               <label>SKU #</label>
                                               <input type="text" className={`form-control ${singleLineErrors.sku && 'invalid-input'}`} disabled={formDisable}  value={singleLineItems?.sku || ''}  onFocus={handleFocus} name='sku'onChange={handleScopeofWork} placeholder="Enter SKU #" />
                                           </div>
                                   
                               </div>
                               <div className="col-md-3">
                               <div className="form-group">
                                               <label>Location</label>
                                               <select className="form-control" value={singleLineItems?.location || ''}  disabled={true} name="location"  title="ItemLocation and System will be chosen based on the selection from New Line Item">
                                               <option value="Unattached">Unattached</option>
                                                       <option value="Whole House/Building">Whole House/Building</option>
                                                       <option value="(Use System Category)">(Use System Category)</option>
                                                       <option value="Exterior">Exterior</option>
                                                       <option value="Landscaping">Landscaping</option>
                                                       <option value="Roof">Roof</option>
                                                       <option value="Garage">Garage</option>
                                                       <option value="Entry/Foyer">Entry/Foyer</option>
                                                       <option value="Deck/Patio">Deck/Patio</option>
                                                       <option value="Laundry/Utility">Laundry/Utility</option>
                                                       <option value="Kitchen">Kitchen</option>
                                                       <option value="Dining Room">Dining Room</option>
                                                       <option value="Living Room">Living Room</option>
                                                       <option value="Hallway">Hallway</option>
                                                       <option value="Powder Room">Powder Room</option>
                                                       <option value="Bathroom">Bathroom</option>
                                                       <option value="Master Bathroom">Master Bathroom</option>
                                                       <option value="Bedroom">Bedroom</option>
                                                       <option value="Bonus Room">Bonus Room</option>
                                                       <option value="Basement">Basement</option>
                                                       <option value="Pool">Pool</option>
                                                       <option value="Driveway">Driveway</option>
                                                       <option value="Office">Office</option>
                                                       <option value="Retail Space">Retail Space</option>
                                                       <option value="Waiting/Reception Area">Waiting/Reception Area</option>
                                                       <option value="Storage">Storage</option>
                                                       <option value="Parking Lot">Parking Lot</option>
                                                       <option value="Business Specific Area">Business Specific Area</option>
                                                       <option value="Other">Other</option>
                                                   </select>
                                           </div>
                               </div>
                               <div className="col-md-3">
                                   
                               <div className="form-group">
                                               <label>System</label>
                                                   <select className="form-control" value={singleLineItems?.system || ''}  disabled={true} name="system" title="ItemLocation and System will be chosen based on the selection from New Line Item">
                                                       <option value="">Unattached</option>
                                                       <option value="None/Not Applicable">None/Not Applicable</option>
                                                       <option value="Multi-system">Multi-system</option>
                                                       <option value="Miscellaneous">Miscellaneous</option>
                                                       <option value="Specialty">Specialty</option>
                                                       <option value="HVAC">HVAC</option>
                                                       <option value="Plumbing">Plumbing</option>
                                                       <option value="Electrical">Electrical</option>
                                                       <option value="Wood work">Wood work</option>
                                                       <option value="Steel work">Steel work</option>
                                                       <option value="Stone work/Concrete">Stone work/Concrete</option>
                                                       <option value="Landscaping/Earth work">Landscaping/Earth work</option>
                                                       <option value="Mechanical">Mechanical</option>
                                                       <option value="Foundation">Foundation</option>
                                                       <option value="Windows and Doors">Windows and Doors</option>
                                                       <option value="Flooring">Flooring</option>
                                                       <option value="Walls">Walls</option>
                                                       <option value="Roofing">Roofing</option>
                                                       <option value="Fixtures/Lighting">Fixtures/Lighting</option>
                                                       <option value="Pool">Pool</option>
                                                   </select>
                                           </div>
                               </div>

                               <div className="col-md-3">
                                   <div className="row">
                                       <div className="col-md-12">
                                           <div className="form-group">
                                               <label>Notes</label>
                                               <textarea id="w3review" value={singleLineItems?.notes || ''}  name="notes" onChange={handleScopeofWork} onFocus={handleFocus} rows="4" cols="50"
                                                   className={`form-control ${singleLineErrors.notes && 'invalid-input'}`} spellCheck="false"
                                                   disabled={formDisable}   placeholder="Add Reasons"> </textarea>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-md-9">
                                   <div className="row">
                                       <div className="col-md-4">
                                       <div className="form-group">
                                       <label>Labor Cost</label>
                                       <CurrencyInput
                                               name='laborcost'
                                               className={`form-control ${singleLineErrors.laborcost && 'invalid-input'}`}
                                               placeholder='Enter Labor Cost'
                                               value={singleLineItems?.laborcost || ''}
                                               decimalsLimit={2}
                                               prefix="$"
                                               disabled={formDisable}
                                               onFocus={handleFocus} 
                                               onValueChange={(value, name, values) => {
                                                   setSingleLineItems((prevData) => ({
                                                       ...prevData,
                                                           'laborcost': values.value,
                                                   }));
                                               }}
                                           />
                                   </div>
                                       </div>
                                       <div className="col-md-4">
                                           
                                           <div className="form-group">
                                       <label>Material Cost</label>
                                       <CurrencyInput
                                               name='materialcost'
                                               className={`form-control ${singleLineErrors.materialcost && 'invalid-input'}`}
                                               placeholder='Enter Material Cost'
                                               value={singleLineItems?.materialcost || ''}
                                               decimalsLimit={2}
                                               disabled={formDisable}
                                               prefix="$"
                                               onFocus={handleFocus} 
                                               onValueChange={(value, name, values) => {
                                                   setSingleLineItems((prevData) => ({
                                                       ...prevData,
                                                           'materialcost': values.value,
                                                   }));
                                               }}
                                           />
                                   </div>
                                       </div>
                                       <div className="col-md-4">
                                       <div className="form-group">
                                       <label></label>
                                       <div className="total_estimate">
                                           <p>Total Estimate </p>
                                           <span>$0.00</span>
                                       </div>
                                   </div>
                                       </div>
                                       <div className="col-md-4">
                                           <div className="form-group">
                                               <label>Contractors License </label>
                                               <input type="text"  onFocus={handleFocus} value={singleLineItems?.contractorlicense || ''} disabled={formDisable} className={`form-control ${singleLineErrors.contractorlicense && 'invalid-input'}`} name='contractorlicense'onChange={handleScopeofWork}  placeholder="Enter Contractors License" />
                                           </div>
                                       </div>
                                       <div className="col-md-4">
                                           <div className="form-group">
                                               <label>Contractors Trade</label>
                                               <input type="text" onFocus={handleFocus} value={singleLineItems?.contractortrade || ''}  disabled={formDisable} className={`form-control ${singleLineErrors.contractortrade && 'invalid-input'}`} name='contractortrade'onChange={handleScopeofWork}  placeholder="Enter Contractors Trade" />
                                           </div>
                                       </div>
                                   </div>
                               </div>


                               <div
                                   className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pb-0 mb-2">
                                   <h4 className="font-size-18 mb-2">INSURANCE/CONTRACTOR IMAGESs</h4>

                               </div>
                               <div className="create_process">
                                   <div className="documents">
                                   <ReusableSavedDoc files={singleLineItems} deleteType={'scopeofwork'} />
                                   <ReusableUnSavedDoc files={scopeofworkFiles} deleteType={'scopeofwork'} />
                                       <div className="upload_button">
                                           <input type="file" onChange={(e)=>{handleFileChange(e,'scopeofwork')}} name="upload" className="udisplay-none"
                                               id="upload" /><img src="assets/images/icons/add_image.png" />
                                       </div>
                                   </div>

                               </div>
                               <div className="col-md-12 mt-2 mb-5 mt-4">
                                   <div className="form-group">
                                       <button onClick={hanldeSaveScopeofwork} disabled={formDisable} className="add_button fillbtn_new d-inline-block">{allData?.scopeofwork ? "Update" : "Save"}</button>
                                   </div>
                               </div>
                           </div> 
                       </div>
                        {/* } */}
                            
                            
                        </div>
                        <div className="tab-pane bulk_item active" id="ORDERINSPECTION" role="tabpanel">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">ORDER INSPECTION</h4>
                            </div>
                            {/* {!singleLineItems?.drawaccepted ? <Blockingtextmsg  textmessage={'Order Inspection Cannot be Accessed until Scope of Work Tab Gets Finished'}/> 
                            : */}
                            <div className="form_section">
                             <div className='row'>
                             <div className='col-md-7'>
                             <div className='row'>
                             <div className='col-md-4'>
                             <div className="form-group">
                             <label>Ordered date</label>
                             <input type="date" name="orderdate" id='ordereddate'
                             value={orderinspectionData?.orderdate ? (new Date(orderinspectionData?.orderdate).toISOString().split('T')[0]) : ''}
                             className={`form-control ${orderinspectionErrors.orderdate && 'invalid-input'}`} onFocus={orderInspectionFocus}
                             placeholder="mm-dd-yyyy"  onChange={handleOrderInspection} disabled={formDisable}/>
                             </div>
                            </div>
                            <div className='col-md-4'>
                             <div className="form-group">
                             <label>Scheduled Date</label>
                             <input type="date" name="scheduleddate" id='scheduleddate'
                             value={orderinspectionData?.scheduleddate ? (new Date(orderinspectionData?.scheduleddate).toISOString().split('T')[0]) : ''}
                             className={`form-control ${orderinspectionErrors.scheduleddate && 'invalid-input'}`} onFocus={orderInspectionFocus}
                             placeholder="mm-dd-yyyy" onChange={handleOrderInspection} disabled={formDisable}/>
                             </div>
                            </div>
                            <div className='col-md-4'>
                             <div className="form-group">
                             <label>Completed Date</label>
                             <input type="date" name="completeddate"  id="completeddate" placeholder="mm-dd-yyyy"
                             value={orderinspectionData?.completeddate ? (new Date(orderinspectionData?.completeddate).toISOString().split('T')[0]) : ''}
                             className={`form-control ${orderinspectionErrors.completeddate && 'invalid-input'}`} onFocus={orderInspectionFocus}
                              onChange={handleOrderInspection} disabled={formDisable}/>
                             </div>
                            </div>
                            <div className='col-md-4'>
                             <div className="form-group">
                             <label>Contacts</label>
                            <select name="contacts" id="contacts"   className={`form-control ${orderinspectionErrors.contacts && 'invalid-input'}`} onFocus={orderInspectionFocus}
                             placeholder="Enter Contacts" value={orderinspectionData?.contacts || ''} onChange={handleOrderInspection} disabled={formDisable}>
                                 <option >Select a Contact</option>
                                 <option value='broker'>Broker</option>
                                    <option value='Borrower'>Borrower</option>
                            </select>
                             </div>
                            </div>
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Additional Email</label>
                                        <div className="email-input-container" style={{border: '1px solid #ccc', padding: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px'}}>
                                            
                                            {/* Input field for email */}
                                            <input
                                                type="text"
                                                id='additionalemail'
                                                placeholder="Enter Additional Emails"
                                                className="form-control"
                                                value={emailInput}
                                                onChange={handleEmailChange}
                                                onKeyDown={handleEmailKeyDown}
                                                onBlur={() => {
                                                    // On blur, validate and add email
                                                    if (isValidEmail(emailInput.trim())) {
                                                        setOrderInspectionData((prevData) => ({
                                                            ...prevData,
                                                            additionalemail: [...prevData?.additionalemail , emailInput.trim()],
                                                        }));
                                                        setEmailInput(""); // Clear input
                                                    } else if (emailInput.trim()) {
                                                        setEmailError("Invalid Email!");
                                                    }
                                                }}
                                            />
                                             <div className="selected_email">
                                             {orderinspectionData?.additionalemail?.map((email, index) => (
                                                <div key={index} className="email" >
                                                    {email}
                                                    <span
                                                    id={`removeemail${index}`}
                                                        onClick={() => removeEmail(email)}
                                                        style={{marginLeft: '8px', cursor: 'pointer'}}
                                                    >
                                                        &times;
                                                    </span>
                                                </div>
                                            ))}
                                             </div>

                                        </div>

                                        {/* Error message for invalid email */}
                                        {emailError && orderinspectionData?.additionalemail?.length !== 0 && <p style={{color: 'red', fontSize: '1.2em'}}>Invalid email!</p>}
                                    </div>
                                    {/* Instructional message for multiple emails */}
                                    <p className="help_text">
                                       (You can add multiple email addresses. Press "Enter" to add.)
                                    </p>
                                </div>
                            {/* <div className='col-md-4'>
                             <div className="form-group">
                             <label>Additional Email</label>
                             <input type="text" name="additionalemail" onFocus={orderInspectionFocus} 
                             className={`form-control ${orderinspectionErrors.additionalemail && 'invalid-input'}`} 
                             placeholder="Enter Additional Email" value={orderinspectionData?.additionalemail || ''} onChange={handleOrderInspection}
                             disabled={formDisable} />
                             </div>
                            </div> */}
                            <div className='col-md-4'>
                             <div className="form-group">
                             <label>Lockbox Code</label>
                             <input type="text" name="lockboxcode" id="lockboxcode" onFocus={orderInspectionFocus} 
                             className={`form-control ${orderinspectionErrors.lockboxcode && 'invalid-input'}`} 
                             placeholder="Enter Lockbox Code" value={orderinspectionData?.lockboxcode || ''} onChange={handleOrderInspection}
                             disabled={formDisable}/>
                             </div>
                            </div>
                            <div className='col-md-6'>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pb-0 mb-2">
                                        <h4 className="font-size-18 mb-2">Upload Documents</h4>
                                    </div>
                                    <div className="create_process">
                                        <div className="documents">
                                        <ReusableSavedDoc files={orderinspectionData} deleteType={'orderinspection'} />
                                        <ReusableUnSavedDoc files={orderinspectionFiles} deleteType={'orderinspection'} />
                                        
                                            <div className="upload_button">
                                                <input type="file" name="uploadorderinspection" id='upload'  className="udisplay-none" onChange={(e)=>{handleFileChange(e,'orderinspection')}}
                                                     /><img src="assets/images/icons/add_image.png" />
                                            </div>
                                        </div>
                                     </div>
                            </div>
                            <div className="col-md-12 mt-2 mb-5 mt-4">
                                        <div className="form-group">
                                            <button onClick={hanldeSaveOrderInspection} id='saveinspection-btn' disabled={formDisable} className="add_button fillbtn_new d-inline-block">{allData?.orderinspection ? "Update" : "Save"}</button>
                                           {(allData?.orderinspection && orderinspectionData?.additionalemail?.length > 0) &&  <button id='sendemail-btn' onClick={handleEmailSend} disabled={formDisable} className="add_button fillbtn_new d-inline-block ms-2">Send Email</button>}
                                            
                                        </div>
                                    </div>
                             </div>
                             </div>
                             <div className='col-md-5'>
                             <div className='row'>
                             <div className='col-md-12'>
                             <div className="form-group">
                             <label>Email Subject</label>
                             <input type="text" name="emailsubject" onFocus={orderInspectionFocus} id='emailsubject'
                             className={`form-control ${orderinspectionErrors.emailsubject && 'invalid-input'}`} 
                             placeholder="Enter Email Subject" value={orderinspectionData?.emailsubject || ''} onChange={handleOrderInspection}
                             disabled={formDisable}/>
                             </div>
                             </div>
                             <div className='col-md-12'>
                             <div className="form-group odr_editer">
                             <EditorEdit ref={editorRef} ReadOnlyflag={formDisable} content={content} setContent={setContent} editorValidate={editorValidate} setEditorValidate={setEditorValidate} />
                             </div>
                             </div>
                             </div>
                             </div>
                             </div>
                            </div>
                            {/* } */}


                            </div>
                        <div className="tab-pane bulk_item" id="VERIFYINSPECTION" role="tabpanel">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">VERIFY INSPECTION</h4>

                            </div>
                            {/* {!orderinspectionData?.drawaccepted ? <Blockingtextmsg  textmessage={'Verify Inspection Cannot be Accessed until Order Inspection Tab Gets Finished'}/>
                            :  */}
                            <div className="form_section">
                                <div className='row'>
                                <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label>Verified On</label>
                                                    <input type="date" name="verifieddate" id="verifieddate"
                                                        value={verifyInspectionData?.verifieddate ? (new Date(verifyInspectionData?.verifieddate).toISOString().split('T')[0]) : ''}
                                                        className={`form-control ${verifyInspectionErrors.verifieddate && 'invalid-input'}`}
                                                        placeholder="Enter Labor Cost" onChange={hanldeVerifyinspection} onFocus={verifyInspectionFocus} disabled={formDisable} />
                                                </div>
                                              
                                            {/* <div className="form-group">
                                                <label>Additional Comments (Optional)</label>
                                                <textarea name='comments' id='additionalcomments' onChange={hanldeVerifyinspection} value={verifyInspectionData?.comments ||''} 
                                                disabled={formDisable}
                                                className="form-control null" rows="4" cols="50"></textarea>
                                            </div> */}
                                        </div>
                                        <div className='col-md-8 interest_only'>
                                        <div className="form-group">
                                           <label></label>
                                            <label className="toggle mb-0">
                                                <span className="toggle-label">Verified</span>
                                                <input id='verified' className="toggle-checkbox" name="verified" checked={verifyInspectionData?.verified} onChange={hanldeVerifyinspection}   type="checkbox" />
                                                <div className="toggle-switch"></div>
                                            </label>
                                         </div>
                                       
                                            {/* <div className='form-group'>
                                                <label>Upload Documents</label>
                                                <div className="create_process">
                                                    <div className="documents">
                                                    <ReusableSavedDoc files={verifyInspectionData} deleteType={'verifyinspection'} />
                                                    <ReusableUnSavedDoc files={verifyinspectionFiles} deleteType={'verifyinspection'} />
                                                    

                                                            <div className="upload_button"><input id='upload' type="file" name="uploadverifyinspection" onChange={(e)=>{handleFileChange(e,'verifyinspection')}} className="udisplay-none"  /><img src="assets/images/icons/add_image.png" /></div></div></div>
                                            </div> */}
                                            </div>

                                            <div className='col-md-4'>
                                            <div className="form-group">
                                                <label>Additional Comments (Optional)</label>
                                                <textarea name='comments' id='additionalcomments' onChange={hanldeVerifyinspection} value={verifyInspectionData?.comments ||''} 
                                                disabled={formDisable}
                                                className="form-control null" rows="4" cols="50" style={{height: '167px'}}></textarea>
                                            </div>
                                            </div>
                                            <div className='col-md-8'>
                                            <div className='form-group'>
                                                <label>Upload Documents</label>
                                                <div className="create_process">
                                                    <div className="documents">
                                                    <ReusableSavedDoc files={verifyInspectionData} deleteType={'verifyinspection'} />
                                                    <ReusableUnSavedDoc files={verifyinspectionFiles} deleteType={'verifyinspection'} />
                                                    

                                                            <div className="upload_button"><input id='upload' type="file" name="uploadverifyinspection" onChange={(e)=>{handleFileChange(e,'verifyinspection')}} className="udisplay-none"  /><img src="assets/images/icons/add_image.png" /></div></div></div>
                                            </div>
                                            </div>
                                        <div className='col-md-12 mt_20'>
                                        <div className="form-group"><button id='saveverifyinspection-btn' onClick={handleVerifyInspectionSave} disabled={formDisable} className="add_button fillbtn_new d-inline-block">{allData?.verifyinspection ? "Update" : "Save"}</button></div>
                                        </div>
                                </div>
                            </div>
                            {/* } */}
                            
                            
                            </div>
                            <div className="tab-pane bulk_item" id="DRAWFEE" role="tabpanel">

                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="title mb-0">DRAW FEE</h4>

                            </div>
                            {/* {!verifyInspectionData?.drawaccepted ? <Blockingtextmsg  textmessage={'Draw Fee Cannot be Accessed until Verify Inspection Tab Gets Finished'}/>
                            
                            : */}
                            <div className="form_section">
                        <div className='row'>
                                <div className='col-md-3'>
                                    <div className="form-group">
                                        <label>Payment Method</label>
                                            <select name='paymentmethod' onChange={handleDrawFee} value={drawFeeData?.paymentmethod || ''} disabled={formDisable} onFocus={drawFeeFocus} className={`form-control ${drawFeeErrors.paymentmethod && 'invalid-input'}`}>
                                                <option value='' selected>Select</option>
                                                <option value="ACH">ACH</option>
                                                <option value="Add to End Of Loan">Add to End Of Loan</option>
                                                <option value="Add To Principal">Add To Principal</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Check">Check</option>
                                                <option value="Credit Card">Credit Card</option>
                                                <option value="Previously Paid">Previously Paid</option>
                                                {/* <option value="Pull From Available Draw Balance">Pull From Available Draw Balance</option> */}
                                                <option value="Pull From Draw Payout">Pull From Draw Payout</option>
                                                <option value="Pull From Reserves">Pull From Reserves</option>
                                            </select>
                                    </div>
                                    <div className="form-group">
                                    <label>Fee Amount</label>
                                    <CurrencyInput
                                            name='feeamount'
                                            className={`form-control ${drawFeeErrors.feeamount && 'invalid-input'}`}
                                            placeholder='Enter Fee Amount'
                                            value={drawFeeData?.feeamount || ''}
                                            decimalsLimit={2}
                                            prefix="$"
                                            disabled={formDisable}
                                            onFocus={drawFeeFocus} 
                                            onValueChange={(value, name, values) => {
                                                setDrawFeeData((prevData) => ({
                                                    ...prevData,
                                                        'feeamount': values.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 mt_20'>
                                <div className="form-group"><button onClick={hanldCreateDrawFee} className="add_button fillbtn_new d-inline-block">Create</button></div>
                                </div>
                        </div>
                          </div> 
                          {/* } */}
                            
                            </div>
                                <div className="tab-pane bulk_item" id="DRAWPAYOUT" role="tabpanel">
                                    <div className="form_section">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="title mb-0">DRAW  PAYOUT</h4>
                                        </div>
                                        {/* {!drawFeeData?.drawaccepted ? <Blockingtextmsg  textmessage={'Draw Payout Cannot be Accessed until Verify Inspection Tab Gets Finished'}/>
                                        
                                    : */}
<div className='row'>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Approved Amount</label>
                                                            <div className="total_estimate">
                                                                <p>{approveData2?.amountapproved ? formatCurrency(approveData2?.amountapproved) : '$0.00'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Fee Amount</label>
                                                            <div className="total_estimate">
                                                            <p>{approveData2?.feeamount ? formatCurrency(approveData2?.feeamount) : '$0.00'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Payout Method</label>
                                                            <select name='payoutmethod' id='drawpayoutmethod' className={`form-control ${drawPayoutErrors.payoutmethod && 'invalid-input'}`}
                                                                value={drawPayoutData?.payoutmethod || 'Wire'} onChange={hanldeDrawPayout} onFocus={drawPayoutFocus} disabled={formDisable || drawDisable}
                                                            >
                                                                {/* <option value='' selected>Select</option> */}
                                                                {/* <option value="Add to End Of Loan">Add to End Of Loan</option>
                                                                <option value="Add To Principal">Add To Principal</option>
                                                                <option value="Available Draw Balance">Available Draw Balance</option> */}
                                                                <option value="ACH">ACH</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Check">Check</option>
                                                                {/* <option value="Credit Card">Credit Card</option> */}
                                                                {/* <option value="From Collections">From Collections</option>
                                                                <option value="N/A - Notation Only">N/A - Notation Only</option> */}
                                                                <option value="Previously Paid">Previously Paid</option>
                                                                {/* <option value="Pull From Draw Payout">Pull From Draw Payout</option>
                                                                <option value="Pull From Reserves">Pull From Reserves</option> */}
                                                                <option value="Wire">Wire</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Payout Amount</label>
                                                            <CurrencyInput
                                                                name='payoutamount'
                                                                className={`form-control ${drawPayoutErrors.payoutamount && 'invalid-input'}`}
                                                                placeholder='Enter Fee Amount'
                                                                value={drawPayoutData?.payoutamount || (approveData2?.amountapproved  - approveData2?.feeamount) || ''}
                                                                decimalsLimit={2}
                                                                prefix="$"
                                                                id='drawpayoutamount'
                                                                disabled={true}
                                                                onFocus={drawPayoutFocus}
                                                                onValueChange={(value, name, values) => {
                                                                    setDrawPayoutData((prevData) => ({
                                                                        ...prevData,
                                                                        'payoutamount': values.value,
                                                                    }));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Date of Draw</label>
                                                            <input type="date" name='dateofdraw' className={`form-control ${drawPayoutErrors.dateofdraw && 'invalid-input'}`}
                                                                disabled={formDisable || drawDisable} value={drawPayoutData?.dateofdraw || ''} onChange={hanldeDrawPayout} onFocus={drawPayoutFocus} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Last Day to Draw</label>
                                                            <input type="date" name='lastdayofdraw' className={`form-control ${drawPayoutErrors.lastdayofdraw && 'invalid-input'}`}
                                                                disabled={formDisable || drawDisable} value={drawPayoutData?.lastdayofdraw || ''} onChange={hanldeDrawPayout} onFocus={drawPayoutFocus} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Processed Date</label>
                                                            <input type="date" name='processeddate' className={`form-control ${drawPayoutErrors.processeddate && 'invalid-input'}`}
                                                                disabled={formDisable || drawDisable} value={drawPayoutData?.processeddate || ''} onChange={hanldeDrawPayout} onFocus={drawPayoutFocus} />
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Processed Date</label>
                                                            <div className="total_estimate">
                                                                <p>05-22-2024</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Processed By</label>
                                                            <div className="total_estimate">
                                                                <p>{loanInfo?.loanassignment?.changeprocessorto?.name || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Payout Description</label>
                                                            <input type="text" placeholder='Enter Payout Description' name='payoutdescription' className={`form-control ${drawPayoutErrors.payoutdescription && 'invalid-input'}`}
                                                                disabled={formDisable || drawDisable} value={drawPayoutData?.payoutdescription || ''} onChange={hanldeDrawPayout} onFocus={drawPayoutFocus} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Payout Notes</label>
                                                            <EditorEdit ref={editorRef} ReadOnlyflag={formDisable || drawDisable} content={content2} setContent={setContent2} editorValidate={editorValidate} setEditorValidate={setEditorValidate} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mt_20'>
                                                {status !== 'PAID' && 
                                                   <div className="form-group"><button onClick={hanldePayDrawPayout} disabled={formDisable || drawDisable} className="add_button fillbtn_new d-inline-block">PAY BORROWER</button></div>
                                                }
                                            </div>
                                        </div>
                                    {/* } */}

                                        

                                    </div>


                                </div>
                    </div>
               </>}
                    
                  {/* Edit end  */}

                 {/* View Start */}
                 {showForm === 'ViewForm' && 
                 
                 <>
                 <div className="search">
               </div>
                 <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#DETAILS" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Scope of Work</span>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" href="#CONTRACTOR" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">CONTRACTOR</span>
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" href="#BUDGET" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">BUDGET</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" href="#INSPECTIONS" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">INSPECTIONS</span>
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="DETAILS" role="tabpanel">


                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Scope of Work</h4>

                            </div>
                            <div className="form_section">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Scope of Work</label>
                                             <input type="text" id='scopeofwork' className={`form-control ${scopeofworkErrors.scopeofwork && 'invalid-input'}`} disabled={formDisable}  value={scopeofworkData?.scopeofwork || ''}  onFocus={handleScopeFocus} name='scopeofwork' onChange={handleScopeofWorkChange} placeholder="Enter Scope of work" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractor</label>
                                            <select className={`form-control ${scopeofworkErrors.contractor && 'invalid-input'}`} name='contractor' id='contractor' disabled={formDisable}  value={scopeofworkData?.contractor || ''} onFocus={handleScopeFocus} onChange={handleScopeofWorkChange}>
                                                <option value={''}>Please Select</option>
                                                {contractorDropdown?.map((contact,index)=>{
                                                    return (
                                                        <>
                                                         <option key={index} value={contact?.firstname + ' '+ contact?.lastname}>{contact?.firstname + ' '+ contact?.lastname}</option>
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Est Completion Date</label>
                                             <input type="date" className={`form-control ${scopeofworkErrors.estcompletiondate && 'invalid-input'}`} disabled={formDisable}  value={scopeofworkData?.estcompletiondate || ''}  onFocus={handleScopeFocus} name='estcompletiondate' onChange={handleScopeofWorkChange}  />
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Completion Date</label>
                                            <input type="date" className={`form-control ${scopeofworkErrors.completiondate && 'invalid-input'}`} disabled={formDisable}  value={scopeofworkData?.completiondate || ''}  onFocus={handleScopeFocus} name='completiondate' id='completiondate' onChange={handleScopeofWorkChange}  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <textarea  name="notes" id='notes' rows="4" cols="50" value={scopeofworkData?.notes || ''}
                                                className="form-control notes_textarea" spellCheck="false" onChange={handleScopeofWorkChange}
                                              placeholder="Enter Notes"> </textarea>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pb-0 mb-2">
                                        <h4 className="font-size-18 mb-2">Upload Documents</h4>
                                    </div>
                                    <div className="create_process">
                                        <div className="documents">
                                        <ReusableSavedDoc files={scopeofworkData} deleteType={'scopeofwork'} />
                                        <ReusableUnSavedDoc files={scopeofworkFiles} deleteType={'scopeofwork'} />
                                                       
                                        
                                           <div className="upload_button">
                                           <input type="file" id='upload' onChange={(e)=>{handleFileChange(e,'scopeofwork')}} name="uploadscopeofwork" className="udisplay-none" /><img  src="assets/images/icons/add_image.png" />
                                       </div>
                                        </div>
                                     </div>
                                </div>
                                  
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group">
                                            <button onClick={hanldeSaveScopeofwork} id='savescopeofwork-btn' disabled={formDisable} className="add_button fillbtn_new d-inline-block">{allData?.scopeofwork ? "Update" : "Submit"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="tab-pane" id="CONTRACTOR" role="tabpanel">


                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18 co_borrower">CONTRACTOR</h4>
                            </div>
                            <div className="form_section">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractors Name</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractors Phone</label>
                                            <input type="number" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractors Email</label>
                                            <input type="email" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractors Licence</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Contractors Table</label>
                                            <input type="text" className="form-control" placeholder="Enter" />
                                        </div>
                                    </div>
                                    <h4 className="mb-sm-0 font-size-14 co_borrower">CONTRACTOR IMAGES</h4>
                                  
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane" id="BUDGET" role="tabpanel">


                            <div className="page-title-box d-sm-flex align-items-center search budget">
                                <h4 className="mb-sm-0 font-size-18 title">BUDGET</h4>
                                <div className='right'>
                                <div className="form-group fbt">
                                            <label>Requested Amount</label>
                                            <CurrencyInput
                                                className="form-control"
                                                placeholder="Enter Requested Amount"
                                                name = 'requestedamount'
                                                id='requestedamount'
                                                prefix="$"
                                                decimalsLimit={2}
                                                value={budgetData?.requestedamount || ''}
                                                onValueChange={(value, name, values) => {
                                                    setBudgetData(prevBudget => ({
                                                        ...prevBudget,
                                                        requestedamount : (values.value)
                                                    }));
                                                }}
                                            />
                                        </div>
                                </div>
                         
                            </div>
                            <div className="table-responsive budegt-table">
                                        <table className="table db_table">
                                            <thead className="table-light">
                                                <tr>
                                                    <th width="9%">Item</th>
                                                    <th width="6%">Quantity</th>
                                                    <th width="6%">Unit</th>
                                                    <th width="8%">Price</th>
                                                    <th width="8%">Percent Of Scope</th>
                                                    <th width="8%">Original Budget</th>
                                                    <th width="8%">Current Budget</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {['labour', 'material', 'others'].map(category => (
                                                    <tr key={category}>
                                                        <td>{category.charAt(0).toUpperCase() + category.slice(1)}</td>
                                                        {['quantity', 'unit', 'price', 'percent', 'original', 'current'].map(field => (
                                                            <td key={field}>
                                                                {['price', 'original', 'current'].includes(field) ? (
                                                                    <CurrencyInput
                                                                        className="form-control"
                                                                        placeholder="Enter"
                                                                        id={category+'-'+field}
                                                                        prefix="$"
                                                                        decimalsLimit={2}
                                                                        name={field}
                                                                        value={budgetData?.[category]?.[field] || ''}
                                                                        onValueChange={(value, name, values) => {
                                                                            handleBudgetChange(category, field, values.value)
                                                                        }}
                                                                        
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Enter"
                                                                        id={category+'-'+field}
                                                                        name={field}
                                                                        value={budgetData?.[category]?.[field] || ''}
                                                                        onInput={(e) => {
                                                                            if(field === 'percent'){
                                                                                if (e.target.value > 100) {
                                                                                    e.target.value = 100;
                                                                                } else if (e.target.value < 0) {
                                                                                    e.target.value = '';
                                                                                }
                                                                            }else if(e.target.value < 0){
                                                                                e.target.value = '';
                                                                            }
                                                                        }}
                                                                        onChange={(e) => handleBudgetChange(category, field, e.target.value)}
                                                                    />
                                                                )}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                                {/* <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='bold_green'>Credit</td>
                                                    <td></td>
                                                    {['original', 'current'].map(field => (
                                                        <td key={field}>
                                                            <CurrencyInput
                                                                        className="form-control"
                                                                        placeholder="Enter"
                                                                        prefix="$"
                                                                        decimalsLimit={2}
                                                                        name={field}
                                                                        value={budgetData?.credit?.[field] || ''}
                                                                        onValueChange={(value, name, values) => {
                                                                            handleBudgetChange('credit', field, values.value)
                                                                        }}
                                                                        
                                                                    />
                                                        </td>
                                                    ))}
                                                </tr> */}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='bold_green'>Total</td>
                                                    <td className='bold'>{budgetData?.total?.percent ? budgetData?.total?.percent + '%' : 0+'%'}</td>
                                                    <td className='bold'>{budgetData?.total?.original ? formatCurrency(budgetData?.total?.original) : '$0.00'}</td>
                                                    <td className='bold'>{budgetData?.total?.current ? formatCurrency(budgetData?.total?.current) : '$0.00'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                            </div>
                         
                                    <div className='row payment_blocks'>
                                        <div className="col-md-6 mt-6 mb-5 mt-4">
                                            <div className="form-group">
                                                <button onClick={hanldeBudgetSave} id='savebudget-btn' disabled={formDisable} className="add_button fillbtn_new d-inline-block">{allData?.budget ? "Update" : "Save"}</button>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="main_details">
                                                {/* <div className="detail_block">
                                        <p>Estimate Total</p>
                                        <h4>{budgetData?.total?.original ? formatCurrency(budgetData?.total?.original) : '$0.00'}</h4>
                                    </div> */}
                                                <div className="detail_block">
                                                    <p>Actual Total</p>
                                                    <h4>{budgetData?.total?.current ? formatCurrency(budgetData?.total?.current) : '$0.00'}</h4>
                                                </div>
                                                <div className="detail_block">
                                                    <p>Total Approved</p>
                                                    <h4>{allData?.approval?.amountapproved ? formatCurrency(allData?.approval?.amountapproved) : '$0.00'}</h4>
                                                </div>
                                                <div className="detail_block">
                                                    <p>Total Paid Out</p>
                                                    <h4>{allData?.drawpayout?.payoutamount ? formatCurrency(allData?.drawpayout?.payoutamount) : '$0.00'}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                  
                        </div>

                        <div className="tab-pane inspection_tab" id="INSPECTIONS" role="tabpanel">


                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">INSPECTIONS</h4>
                                <div className="form-group">
                                
                                 <button data-bs-target="#createinspection" id='addinspection' data-bs-whatever="@mdo" data-bs-toggle="modal" className="fillbtn_new add_button" >Create
                                 <span
                                className="material-symbols-outlined icon">add</span></button>
                                 
                                    {/* <a href="#" className="fs_btn" >Create<span
                                        className="material-symbols-outlined">
                                        add
                                    </span></a> */}
                                </div>

                            </div>
                            {allInspectionData?.filter(data => data?.status === 'ACTIVE').length > 0 ? 
                                        <div className="table-responsive">
                                            <table className="table db_table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th width="3%"> #</th>
                                                        <th width="8%">Inspected By</th>
                                                        <th width="8%">Inspected Date</th>
                                                        <th width="8%">% Item Complete</th>
                                                        <th width="9%">% Total Project Complete</th>
                                                        <th width="8%">Inspection Complete</th>
                                                        <th width="8%">Flagged By Insp</th>
                                                        <th width="6%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allInspectionData
                                                        ?.filter(data => data?.status === 'ACTIVE')
                                                        .map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{data?.inspectedby}</td>
                                                                <td>{data?.inspecteddate ? moment.utc(data?.inspecteddate).format("MM-DD-YYYY") : 'N/A'}</td>
                                                                <td>{data?.itemcomplete ? data?.itemcomplete + ' %' : ''}</td>
                                                                <td>{data?.totalprojectcomplete ? data?.totalprojectcomplete + ' %' : ''}</td>
                                                                <td>
                                                                    <label className="toggle">
                                                                        <input className="toggle-checkbox" type="checkbox" checked={data?.inspectioncomplete || false} />
                                                                        <div className="toggle-switch"></div>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <label className="toggle">
                                                                        <input className="toggle-checkbox" type="checkbox" checked={data?.flaggedbyinsp || false} />
                                                                        <div className="toggle-switch"></div>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <button className="icon_btn edit">
                                                                        <span id={`deleteinspection-btn-${index}`} onClick={(e) => inspectionDeleteClick(e, data.inspectionid)} className="material-symbols-outlined">delete</span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div> :
                                 <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>       
                            }
                        </div>
                    </div>
                 </> } 
               {/* View end  */}
                </div>
                <div className="modal popup new_popup fade add_inspection" 
                            id="createinspection" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">ADD INSPECTIONS</h5>
                                        <button type="button" className="pop_close" onClick={()=>setInspectionsData({})} id="closeinspection" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label>Inspected By</label>
                                            <input id="inspectedby" value={inspectionsData?.inspectedby || ''} onFocus={handleInspectionFocus}  onChange={handleInspectionChange} type="text" name="inspectedby" placeholder="Enter Inspected By" className={`form-control ${inspectionErrors.inspectedby && 'invalid-input'}`}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label>Inspected Date</label>
                                            <input  id="inspecteddate" value={inspectionsData?.inspecteddate || ''} onFocus={handleInspectionFocus}  onChange={handleInspectionChange} type="date" name="inspecteddate" className={`form-control ${inspectionErrors.inspecteddate && 'invalid-input'}`} placeholder="mm-dd-yyyy"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label>%Item Complete</label>
                                            <input id="itemcomplete" value={inspectionsData?.itemcomplete || ''} onFocus={handleInspectionFocus}  onChange={handleInspectionChange}  type="number" name="itemcomplete" placeholder="Enter %Item Complete"
                                            onInput={(e) => { 
                                                if (e.target.value > 100) {
                                                    e.target.value = 100;
                                                }else if(e.target.value < 0){
                                                    e.target.value = '';
                                                }
                                            }} className={`form-control ${inspectionErrors.itemcomplete && 'invalid-input'}`}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <label>%Total Project Complete</label>
                                            <input  id="totalprojectcomplete" value={inspectionsData?.totalprojectcomplete || ''} onFocus={handleInspectionFocus}  onChange={handleInspectionChange} type="number" name="totalprojectcomplete" placeholder="Enter %Total Project Complete"
                                            onInput={(e) => { 
                                                if (e.target.value > 100) {
                                                    e.target.value = 100;
                                                }else if(e.target.value < 0){
                                                    e.target.value = '';
                                                }
                                            }} className={`form-control ${inspectionErrors.totalprojectcomplete && 'invalid-input'}`}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 interest_only">
                                        <div className="form-group">
                                           <label></label>
                                            <label className="toggle mb-0 mt-1">
                                                <span className="toggle-label">Inspection Complete</span>
                                                <input className="toggle-checkbox" name="inspectioncomplete" checked={inspectionsData?.inspectioncomplete} onChange={handleInspectionChange}  type="checkbox" />
                                                <div className="toggle-switch"></div>
                                            </label>
                                         </div>
                                    </div>
                                    <div className="col-md-6 interest_only">
                                        <div className="form-group">
                                           <label></label>
                                            <label className="toggle mb-0 mt-1">
                                                <span className="toggle-label">Flagged By Insp</span>
                                                <input className="toggle-checkbox" name="flaggedbyinsp" checked={inspectionsData?.flaggedbyinsp} onChange={handleInspectionChange}  type="checkbox" />
                                                <div className="toggle-switch"></div>
                                            </label>
                                         </div>
                                    </div>
                                        


                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button id="addinspection-btn"  type="button" onClick={hanldeInspectionSave}  className="fs_btn">ADD</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">APPROVE</h5>
                                        <button type="button" className="pop_close" id="closeapprove" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label> Approved Amount</label>
                                            <CurrencyInput
                                                    name='amountapproved'
                                                    id='approovedamount'
                                                    className={`form-control ${popupErrors.amountapproved && 'invalid-input'}`}
                                                    placeholder='Enter Approved Amount'
                                                    value={approveData?.amountapproved || ''}
                                                    decimalsLimit={2}
                                                    prefix="$"
                                                    disabled={disablePopUp || drawDisable}
                                                    onFocus={popUpFocus} 
                                                    onValueChange={(value, name, values) => {
                                                        setApproveData((prevData) => ({
                                                            ...prevData,
                                                                'amountapproved': values.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label> Fee Amount</label>
                                            <CurrencyInput
                                                    name='feeamount'
                                                    id='feeamount'
                                                    className={`form-control ${popupErrors.feeamount && 'invalid-input'}`}
                                                    placeholder='Enter Fee Amount'
                                                    value={approveData?.feeamount || ''}
                                                    decimalsLimit={2}
                                                    prefix="$"
                                                    disabled={disablePopUp || drawDisable}
                                                    onFocus={popUpFocus} 
                                                    onValueChange={(value, name, values) => {
                                                        setApproveData((prevData) => ({
                                                            ...prevData,
                                                                'feeamount': values.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                    <div className="form-group">
                                        <label>Payout Method</label>
                                            <select name='paymentmethod' id='payoutmethod-approve' onChange={handleApproveChange} value={approveData?.paymentmethod || 'Wire'} disabled={disablePopUp || drawDisable} onFocus={popUpFocus}  className={`form-control ${popupErrors.paymentmethod && 'invalid-input'}`}>
                                                {/* <option value='' selected>Select</option> */}
                                                                {/* <option value="Add to End Of Loan">Add to End Of Loan</option>
                                                                <option value="Add To Principal">Add To Principal</option>
                                                                <option value="Available Draw Balance">Available Draw Balance</option> */}
                                                                <option value="ACH">ACH</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Check">Check</option>
                                                                {/* <option value="Credit Card">Credit Card</option> */}
                                                                {/* <option value="From Collections">From Collections</option>
                                                                <option value="N/A - Notation Only">N/A - Notation Only</option> */}
                                                                <option value="Previously Paid">Previously Paid</option>
                                                                {/* <option value="Pull From Draw Payout">Pull From Draw Payout</option>
                                                                <option value="Pull From Reserves">Pull From Reserves</option> */}
                                                                <option value="Wire">Wire</option>
                                                {/* <option value="Pull From Available Draw Balance">Pull From Available Draw Balance</option> */}
                                            </select>
                                    </div>
                                   
                                </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Additional Comments</label>
                                            <textarea  id='additionalcomments-approve' disabled={disablePopUp || drawDisable} className="form-control null" value={approveData?.additionalcomments || ''} onChange={handleApproveChange} name="additionalcomments" rows="4" cols="50" placeholder="Enter Additional Comments" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        
                                           
                                               <div className="col-md-12">
                                            <div className="form-group">
                                                {
                                                  <button id="approve-btn" onClick={approveAmount} type="button" disabled={disablePopUp || drawDisable}  className="fs_btn">APPROVE</button>
                                                }    
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal popup new_popup fade add_fee approve_pop" 
                            id="denialModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">DENY</h5>
                                        <button type="button" className="pop_close" id="closedenial" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined">close</span></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Reason for Denial</label>
                                            <input disabled={disablePopUp} id="reasonfordenial" value={denialData?.reasonfordenial || ''} onFocus={popUpFocus}  onChange={handleDenialChange} type="text" name="reasonfordenial" placeholder="Enter Reason for Denial" className={`form-control ${popupErrors.reasonfordenial && 'invalid-input'}`}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>Additional Comments</label>
                                            <textarea disabled={disablePopUp} id="denialcomments" value={denialData?.denialcomments || ''} onChange={handleDenialChange} className="form-control null" name="denialcomments" rows="4" cols="50" placeholder="Enter Additional Comments" spellCheck="false"></textarea>
                                            </div>
                                        </div>
                                        
                                           
                                               <div className="col-md-12">
                                            <div className="form-group">
                                                {!disablePopUp && 
                                                <button id="denial-btn" onClick={denailRequest}  type="button"  className="fs_btn">DENY</button>
                                                }
                                                        
                                                    </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
        <PipelineFooter />
    </div>
    );
};

export default OrderDrawRequest;